/* eslint-disable sonarjs/prefer-immediate-return */
import { State } from "../dep_components/context";
import { datesScope, itemQuantityChangeAction } from "../dep_constants";
import DatesHelper from "../dep_helpers/DatesHelper";
import ShoppingCartService from "../dep_services/shoppingCartService";
import TrackingService from "../dep_services/tracking/trackingService";
import { RentalDatesType } from "../dep_types";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemType,
} from "../dep_types/CartTypes";
import {
  SetProductVariantChangeType,
  VariantSelectOptionType,
} from "../dep_types/ProductTypes";

// eslint-disable-next-line import/no-default-export
export default class ShoppingCartContextService {
  static async addToCartResaleItem(
    state: State,
    cartItem: CartProductItemType | CartSetItemType,
    confirmAddToCart: (
      addedCartItem: CartItemType,
      newCartItems: CartItemType[],
    ) => void,
  ): Promise<void> {
    const { cartItems } = state;

    const newCartItems = ShoppingCartService.addItem(cartItem, cartItems);

    TrackingService.trackAddToCart(cartItem);

    confirmAddToCart(cartItem, newCartItems);
  }

  static addGearFinderItemsToCart(
    state: State,
    cartItemsToAdd: CartProductItemType[],
    dispatchSetSiteRentalDates: (siteRentalDates: RentalDatesType) => void,
    dispatchSetShoppingCartRentalDates: (
      shoppingCartRentalDates: RentalDatesType,
    ) => void,
  ): CartItemType[] | undefined {
    let { siteRentalDates } = state;

    const defaultDates = {
      startDate: DatesHelper.defaultStartDate,
      endDate: DatesHelper.defaultEndDate,
    };

    if (
      !siteRentalDates ||
      !siteRentalDates.startDate ||
      !siteRentalDates.endDate
    ) {
      siteRentalDates = defaultDates;

      dispatchSetSiteRentalDates(defaultDates);
    }

    const newCartRentalDates =
      siteRentalDates && siteRentalDates.startDate
        ? siteRentalDates
        : defaultDates;

    dispatchSetShoppingCartRentalDates(newCartRentalDates);

    ShoppingCartService.updateRentalDates(newCartRentalDates);

    const newCartItems = cartItemsToAdd.reduce(
      (previous: CartItemType[], current) => {
        const newItems = ShoppingCartService.addItem(
          current,
          previous,
          newCartRentalDates,
        );

        return newItems;
      },
      state.cartItems,
    );

    return newCartItems;
  }

  static addToCart(
    state: State,
    cartItem: CartProductItemType | CartSetItemType,
    confirmAddToCart: (
      addedCartItem: CartItemType,
      newCartItems: CartItemType[],
    ) => void,
    dispatchSetSiteRentalDates: (siteRentalDates: RentalDatesType) => void,
    dispatchSetShoppingCartRentalDates: (
      shoppingCartRentalDates: RentalDatesType,
    ) => void,
    dispatchOpenDateSyncModal: (cartItemParam: CartItemType) => void,
  ): void {
    const { shoppingCartRentalDates, cartItems } = state;
    let { siteRentalDates } = state;

    const defaultDates = {
      startDate: DatesHelper.defaultStartDate,
      endDate: DatesHelper.defaultEndDate,
    };

    if (
      !siteRentalDates ||
      !siteRentalDates.startDate ||
      !siteRentalDates.endDate
    ) {
      siteRentalDates = defaultDates;

      dispatchSetSiteRentalDates(defaultDates);
    }

    const cartIsEmpty = cartItems.length === 0;

    if (
      cartIsEmpty ||
      !shoppingCartRentalDates ||
      !shoppingCartRentalDates.startDate ||
      !shoppingCartRentalDates.endDate
    ) {
      const newCartRentalDates =
        siteRentalDates && siteRentalDates.startDate
          ? siteRentalDates
          : defaultDates;

      dispatchSetShoppingCartRentalDates(newCartRentalDates);

      ShoppingCartService.updateRentalDates(newCartRentalDates);

      const newCartItems = ShoppingCartService.addItem(
        cartItem,
        state.cartItems,
        newCartRentalDates,
      );

      TrackingService.trackAddToCart(cartItem);

      confirmAddToCart(cartItem, newCartItems);

      return;
    }

    const datesMatch = DatesHelper.rentalDatesAreEqual(
      siteRentalDates,
      shoppingCartRentalDates,
    );

    if (datesMatch) {
      const newCartItems = ShoppingCartService.addItem(
        cartItem,
        cartItems,
        shoppingCartRentalDates,
      );

      TrackingService.trackAddToCart(cartItem);

      confirmAddToCart(cartItem, newCartItems);
    } else {
      dispatchOpenDateSyncModal(cartItem);
    }
  }

  static reduceCartItemQuantity(
    state: State,
    cartItemId: string,
    dispatchUpdateCartItems: (updateCartItems: CartItemType[]) => void,
  ): void {
    const newItems = ShoppingCartService.changeItemQuantity(
      state.cartItems,
      state.shoppingCartRentalDates,
      cartItemId,
      itemQuantityChangeAction.REDUCE,
    );

    if (!newItems) {
      return;
    }

    dispatchUpdateCartItems(newItems);
  }

  static syncRentalDatesAndAddProductToCart(
    state: State,
    scope: string,
    dispatchAddToCart: (
      addedCartItem: CartItemType,
      newCartItems: CartItemType[],
    ) => void,
    dispatchSetSiteRentalDates: (siteRentalDates: RentalDatesType) => void,
    dispatchCloseDateSyncModal: () => void,
    dispatchSetShoppingCartRentalDates: (
      shoppingCartRentalDates: RentalDatesType,
    ) => void,
  ): void {
    if (scope === datesScope.CART) {
      dispatchSetSiteRentalDates(state.shoppingCartRentalDates);
      dispatchCloseDateSyncModal();
    } else if (scope === datesScope.SITE) {
      dispatchSetShoppingCartRentalDates(state.siteRentalDates);

      const newCartRentalDates = state.siteRentalDates;

      ShoppingCartService.updateRentalDates(newCartRentalDates);

      const newCartItems = ShoppingCartService.addItem(
        state.cartItemToSave,
        state.cartItems,
        newCartRentalDates,
      );

      TrackingService.trackAddToCart(state.cartItemToSave);

      dispatchAddToCart(state.cartItemToSave, newCartItems);

      // TODO: Recheck availability for the entire CART

      dispatchCloseDateSyncModal();
    }
  }

  static changeCartSetProductVariant(
    state: State,
    cartItemId: string,
    cartSetGroupItemId: string,
    updatedVariantData: SetProductVariantChangeType,
    dispatchUpdateCartItems: (updateCartItems: CartItemType[]) => void,
  ): void {
    const newItems = ShoppingCartService.changeSetItemVariant(
      state.cartItems,
      state.shoppingCartRentalDates,
      cartItemId,
      cartSetGroupItemId,
      updatedVariantData,
    );

    if (!newItems) {
      return;
    }

    dispatchUpdateCartItems(newItems);
  }

  static changeCartSimpleProductVariant(
    state: State,
    cartItem: CartProductItemType,
    newVariantOption: VariantSelectOptionType,
    dispatchUpdateCartItems: (updateCartItems: CartItemType[]) => void,
  ): void {
    const newItems = ShoppingCartService.changeItemVariant(
      state.cartItems,
      cartItem,
      newVariantOption,
      state.shoppingCartRentalDates,
    );

    if (!newItems) {
      return;
    }

    dispatchUpdateCartItems(newItems);
  }

  static removeFromCart(
    cartId: string,
    dispatchRemoveFromCart: (cartIdParam: string) => void,
  ): void {
    dispatchRemoveFromCart(cartId);
    ShoppingCartService.removeItem(cartId);
  }

  static addCartItemQuantity(
    state: State,
    cartItemId: string,
    dispatchUpdateCartItems: (updateCartItems: CartItemType[]) => void,
  ): void {
    const newItems = ShoppingCartService.changeItemQuantity(
      state.cartItems,
      state.shoppingCartRentalDates,
      cartItemId,
      itemQuantityChangeAction.ADD,
    );

    if (!newItems) {
      return;
    }

    dispatchUpdateCartItems(newItems);
  }

  static clearCart(keepCart: boolean, dispatchClearCart: () => void): void {
    if (!keepCart) {
      ShoppingCartService.clearCart();
    }
    dispatchClearCart();
  }
}
