import { useRouter } from "next/router";
import { useState } from "react";

import { ColorSwatch, SearchProductType, VariantType } from "@/dep_types";
import { defaultProductImageId } from "@/dep_whitelabel/commonKeys";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { useGetAvailableVariants } from "./useGetAvailableVariants";
import { useGetProductCardLink } from "./useGetProductCardLink";

import { getArrayFromQueryParam } from "../helpers/getArrayFromQueryParam";
import { getAvailableSwatches } from "../helpers/getAvailableSwatches";

export type UseProductCardContext = {
  product: SearchProductType;
  selectedImageId: string;
  selectedSwatch: ColorSwatch;
  productUrl: string;
  availableSwatches: ColorSwatch[];
  availableVariants: VariantType[];
  updateSelectedSwatch: (swatch: ColorSwatch) => void;
  setPreviewSwatch: (swatch: ColorSwatch) => void;
  removePreviewSwatch: () => void;
};

export function useProductCardContext(
  product: SearchProductType,
): UseProductCardContext {
  const { keys } = useWhitelabelContext();
  const { query } = useRouter();

  /**
   * Get only the available swatches based off of the params
   */
  const availableVariants = useGetAvailableVariants(product?.variants);
  const availableSwatches = getAvailableSwatches(
    availableVariants,
    product.swatches,
  );

  /**
   * Get a default image if no swatch is available/selected.
   */
  const defaultImageId =
    product.image?.publicId ??
    product.cloudinaryImage?.publicId ??
    keys?.DEFAULT_PRODUCT_IMAGE_ID ??
    defaultProductImageId;

  const swatchFromParams = getSwatchFromParams();

  const [selectedSwatch, setSelectedSwatch] = useState<ColorSwatch>(
    swatchFromParams ?? product.swatches?.[0],
  );

  const [selectedImageId, setSelectedImageId] = useState<string>(
    selectedSwatch?.displayImage?.publicId ?? defaultImageId,
  );

  const updateSelectedSwatch = (swatch: ColorSwatch) => {
    setSelectedSwatch(swatch);
    if (swatch.displayImage?.publicId) {
      setSelectedImageId(swatch.displayImage.publicId);
    }
  };

  const setPreviewSwatch = (swatch: ColorSwatch) => {
    if (swatch.displayImage?.publicId) {
      setSelectedImageId(swatch.displayImage.publicId);
    }
  };

  const removePreviewSwatch = () => {
    if (selectedSwatch) {
      setSelectedImageId(
        selectedSwatch.displayImage?.publicId ?? defaultImageId,
      );
    }
  };

  /**
   * Get the link for the product card.
   */
  const productUrl = useGetProductCardLink(product, selectedSwatch);

  return {
    product,
    selectedImageId,
    selectedSwatch,
    productUrl,
    availableSwatches,
    availableVariants,
    updateSelectedSwatch,
    setPreviewSwatch,
    removePreviewSwatch,
  };

  function getSwatchFromParams() {
    const colors = getArrayFromQueryParam(query.color);
    const swatches: ColorSwatch[] = [];

    colors?.forEach((color) => {
      const swatch = availableSwatches.find(
        (swatch) => swatch.color.toLowerCase() === color.toLowerCase(),
      );

      if (swatch) {
        swatches.push(swatch);
      }
    });

    return swatches?.[0];
  }
}
