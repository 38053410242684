import { ReviveTheme } from "@/revive/theme";

export const citizinery: ReviveTheme = {
  partnerId: 6,
  partnerName: "the-citizenry",
  colors: {
    announcement: "#91694C",
    confirmation: "#385678",
    error: "#EA4335",
    black: "#383838",
    primary: {
      50: "#AF8E7A",
      600: "#9E7B67",
    },
    secondary: {
      50: "#385678",
      100: "#2C4460",
      600: "#935643",
    },
    gray: {
      50: "#FAFBFD",
      100: "#F2F0F0",
      200: "#DCDCDC",
      300: "#C7C8CA",
      400: "#A7A7A7",
      500: "#504F4F",
    },
  },
  components: {
    Heading: {
      variants: {
        h2: {
          fontSize: "xl",
          letterSpacing: "wide",
          textTransform: "uppercase",
        },
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          textTransform: "uppercase",
        },
      },
    },
  },
};
