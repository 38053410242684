/* eslint-disable class-methods-use-this */
import OrderLog from "@/dep_helpers/order/OrderLog";

import TrackingStrategy from "./trackingStrategy";

type ConversionParams = {
  // eslint-disable-next-line camelcase
  order_id: string;
  revenue: number;
  email: string;
  // eslint-disable-next-line camelcase
  promo_code: string | null;
};

type IdentifyParams = {
  // eslint-disable-next-line camelcase
  external_id: string;
  email: string;
  // eslint-disable-next-line camelcase
  phone_number: string;
};

type RockerboxType = {
  track: (key: string, params?: ConversionParams | IdentifyParams) => void;
};

declare const RB: RockerboxType;
// eslint-disable-next-line import/no-default-export
export default class RockerboxTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    typeof window !== "undefined" && typeof RB !== "undefined" && Boolean(RB);

  trackAddToCart(): void {
    // do not track
  }

  trackConversion(
    _partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void {
    const {
      customerData: { emailAddress, phoneNumber },
      discountCode,
      rentalTotal,
      resaleTotal,
    } = orderLog;

    RB.track("identify", {
      external_id: "",
      email: emailAddress,
      phone_number: phoneNumber,
    });

    const conversionParams = (orderId: string): ConversionParams => ({
      order_id: orderId,
      revenue: rentalTotal + resaleTotal,
      email: emailAddress,
      promo_code: discountCode,
    });

    if (resaleOrderId) {
      RB.track("conv.resale_us", conversionParams(resaleOrderId));
    }
    if (rentalOrderId) {
      RB.track("conv.rental_us", conversionParams(rentalOrderId));
    }
  }

  trackEvent(): void {
    // do not track
  }

  trackPageView(): void {
    RB.track("view");
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackProductPageView(): void {
    // do not track
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackStartCheckout(): void {
    // do not track
  }

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }
}
