const siteNavigationLink = {
  baseStyle: {
    fontWeight: "medium",
    letterSpacing: "1px",
  },
  variants: {
    secondary: {
      fontWeight: "normal",
      letterSpacing: "0.4px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default siteNavigationLink;
