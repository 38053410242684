/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC } from "react";

import { GlobalLabelsType } from "@/dep_types/content/api/components/partner/globalLabels";

import LinkButton from "@/dep_components/Commons/Buttons/LinkButton";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type Props = {
  editVariant: boolean;
  isReadonly?: boolean;
  showEditButtonAlert?: boolean;
  setShowEditButtonAlert?(value: any): void;
  handleEditVariant?(globalLabels: GlobalLabelsType): void;
};

const CartEditVariantButton: FC<Props> = ({
  editVariant,
  isReadonly,
  showEditButtonAlert,
  setShowEditButtonAlert,
  handleEditVariant,
}: Props) => {
  const { globalLabels } = useWhitelabelContext();

  return (
    <>
      {!editVariant && !isReadonly && (
        <Popover
          returnFocusOnClose={false}
          isOpen={!!showEditButtonAlert}
          onClose={() =>
            setShowEditButtonAlert && setShowEditButtonAlert(false)
          }
          placement="bottom"
          closeOnBlur
          closeOnEsc
        >
          <PopoverTrigger>
            <Box>
              <LinkButton
                onClick={() =>
                  handleEditVariant && handleEditVariant(globalLabels)
                }
                text="Change"
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor="black"
            borderRadius={0}
            color="white"
            _focus={{
              boxShadow: "none",
              outline: "none",
            }}
          >
            <PopoverArrow bg="black" />
            <PopoverBody>
              There are no other possible variants available for selection
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

CartEditVariantButton.defaultProps = {
  showEditButtonAlert: undefined,
  setShowEditButtonAlert: undefined,
  handleEditVariant: undefined,
  isReadonly: false,
};

// eslint-disable-next-line import/no-default-export
export default CartEditVariantButton;
