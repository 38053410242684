import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function ProductCardBrandName({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <Box fontWeight="medium" fontSize="xs">
      {children}
    </Box>
  );
}
