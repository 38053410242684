/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { differenceInDays, format, parseISO } from "date-fns";
import { nanoid } from "nanoid";

import localStorageKeys from "@/dep_constants/localStorageKeys";
import { GlobalLabelsType } from "@/dep_types/content/api/components/partner/globalLabels";

import HttpResponse from "./http/HttpResponse";
import API from "./httpService";
import localStorageService from "./localStorageService";

import {
  itemQuantityChangeAction,
  shoppingCartErrorTypes,
} from "../dep_constants";
import CartHelper from "../dep_helpers/CartHelper";
import DatesHelper from "../dep_helpers/DatesHelper";
import ProductHelper from "../dep_helpers/ProductHelper";
import { RentalDatesType } from "../dep_types";
import {
  ApiResponse,
  ShoppingCartValidateResponse,
} from "../dep_types/ApiTypes";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemSetGroup,
  CartSetItemType,
} from "../dep_types/CartTypes";
import { OrderCartType } from "../dep_types/OrderTypes";
import {
  SetProductVariantChangeType,
  VariantSelectOptionType,
} from "../dep_types/ProductTypes";

// eslint-disable-next-line import/no-default-export
export default class ShoppingCartService {
  static async getCartAvailability(
    partnerId: string,
    cartItems: CartItemType[],
    shoppingCartRentalDates: RentalDatesType,
    discountCode?: string,
  ): Promise<ShoppingCartValidateResponse> {
    const cartItemsOrder = CartHelper.mapCartToOrder(
      cartItems,
    ) as OrderCartType;

    const apiDates = DatesHelper.parseGlobalRentalDatesToAPIParams(
      shoppingCartRentalDates,
    );

    cartItemsOrder.dateFrom = apiDates.startDate?.toString();
    cartItemsOrder.dateTo = apiDates.endDate?.toString();

    cartItemsOrder.discountCode = discountCode;

    const response: ApiResponse = await API.post(
      partnerId,
      "/cart",
      cartItemsOrder,
    ).then((r: HttpResponse) => r.get());

    return response.data;
  }

  static async getRentalMinDate(partnerId: string): Promise<string> {
    const response: ApiResponse = await API.get(
      partnerId,
      "/rentalStartDate",
    ).then((r: HttpResponse) => r.get());

    const today = new Date();
    const numberOfDaysToAdd = 3;
    const fallbackDate = today.setDate(today.getDate() + numberOfDaysToAdd);
    const formattedFallbackDate = format(fallbackDate, "yyyy-MM-dd");

    return response.data?.firstAvailableDate || formattedFallbackDate;
  }

  static initializeCartId(): void {
    let cartId = localStorageService.get(localStorageKeys.CART_ID);

    if (!cartId) {
      cartId = nanoid(10);
      localStorageService.save(localStorageKeys.CART_ID, cartId);
    }
  }

  static getCartId(): string {
    return localStorageService.get(localStorageKeys.CART_ID);
  }

  static renewCartId(): void {
    localStorageService.remove(localStorageKeys.CART_ID);
    const newCartId = nanoid(10);

    localStorageService.save(localStorageKeys.CART_ID, newCartId);
  }

  static getImpactId(): string | null {
    const clickIdData = localStorageService.get(
      localStorageKeys.IMPACT_CLICK_ID,
    );

    if (!clickIdData || !clickIdData.clickId) return null;
    if (new Date().getTime() <= clickIdData.timestamp) {
      return clickIdData.clickId;
    }
    localStorageService.remove(localStorageKeys.IMPACT_CLICK_ID);

    return null;
  }

  static saveImpactId(id: string): void {
    localStorageService.remove(localStorageKeys.IMPACT_CLICK_ID);
    const expiry = new Date();

    expiry.setDate(expiry.getDate() + 60);
    localStorageService.save(localStorageKeys.IMPACT_CLICK_ID, {
      clickId: id,
      timestamp: expiry.getTime(),
    });
  }

  static removeOldNonCompatibleItems(): void {
    const cartItems: CartItemType[] = localStorageService.get(
      localStorageKeys.SHOPPING_CART_ITEMS,
    );

    if (!cartItems || cartItems.length === 0) {
      return;
    }

    const correctCartItems = cartItems.filter((cartItem) => {
      if (cartItem.isSet) {
        (cartItem as CartSetItemType).cartSetGroups = (
          cartItem as CartSetItemType
        ).cartSetGroups.filter(
          (cartSetItemSetGroup) => cartSetItemSetGroup.length > 0,
        );

        const isMissingRequiredField = (
          cartItem as CartSetItemType
        ).cartSetGroups.some((cartSetItemSetGroup) =>
          cartSetItemSetGroup.some(
            (cartSetItemSetGroupItem) =>
              !cartSetItemSetGroupItem.variantId ||
              !cartSetItemSetGroupItem.productId,
          ),
        );

        return !isMissingRequiredField;
      }

      return true;
    });

    const newItems = [...correctCartItems];

    localStorageService.save(localStorageKeys.SHOPPING_CART_ITEMS, newItems);
  }

  static getCartItems(): any {
    return localStorageService.get(localStorageKeys.SHOPPING_CART_ITEMS);
  }

  static getCartDates(): RentalDatesType | null {
    const initialCartDates = localStorageService.get(
      localStorageKeys.SHOPPING_CART_DATES,
    );

    if (initialCartDates) {
      const today = new Date();
      // TODO: Move to DatesHelper
      const startDayOfReservation = parseISO(initialCartDates.startDate);
      const minDays = (DatesHelper.minRentalDays || 1) - 1;

      const days = differenceInDays(startDayOfReservation, today);

      if (days < minDays) {
        localStorageService.remove(localStorageKeys.SHOPPING_CART_DATES);

        return null;
      }

      return {
        startDate: parseISO(initialCartDates.startDate),
        endDate: parseISO(initialCartDates.endDate),
      };
    }

    return null;
  }

  // TODO: Refactor: It this updates RentalDates AND items, the name "updateRentalDates" is not correct.
  static updateRentalDates(
    newCartRentalDates: RentalDatesType,
    previousCartItems?: CartItemType[],
  ): CartItemType[] {
    localStorageService.save(
      localStorageKeys.SHOPPING_CART_DATES,
      newCartRentalDates,
    );

    if (!previousCartItems) {
      return [];
    }

    const newState = [...previousCartItems];

    ShoppingCartService.updateCartPrices(newState, newCartRentalDates);

    localStorageService.save(localStorageKeys.SHOPPING_CART_ITEMS, newState);

    return newState;
  }

  static changeItemQuantity(
    previousCartItems: CartProductItemType[],
    shoppingCartRentalDates: RentalDatesType,
    cartItemId: string,
    action:
      | typeof itemQuantityChangeAction.ADD
      | typeof itemQuantityChangeAction.REDUCE,
  ): CartItemType[] | null {
    const newItems = [...previousCartItems];
    const cartItem = newItems.find((c) => c.cartId === cartItemId);
    const lastTier =
      cartItem?.priceDecrementingItems?.[
        cartItem.priceDecrementingItems.length - 1
      ];
    const hasPriceDecrementingItems =
      cartItem?.priceDecrementingItems &&
      cartItem?.priceDecrementingItems?.length > 0 &&
      lastTier;

    if (!cartItem) {
      return null;
    }

    if (action === itemQuantityChangeAction.ADD) {
      cartItem.quantity += 1;
      if (hasPriceDecrementingItems) {
        lastTier.quantity += 1;
        cartItem.priceDecrementingTotalPrice =
          ProductHelper.calculatePriceDecrementingTotalPrice(cartItem);
        cartItem.priceChanged = { changed: false, lastAction: "add" };
      }
    } else {
      cartItem.quantity -= 1;
      if (hasPriceDecrementingItems) {
        lastTier.quantity -= 1;
        const index =
          cartItem.priceDecrementingItems &&
          cartItem.priceDecrementingItems.indexOf(lastTier);

        if (
          cartItem.priceDecrementingItems &&
          index &&
          lastTier.quantity === 0
        ) {
          cartItem.priceDecrementingItems.splice(index, 1);
        }
        cartItem.priceDecrementingTotalPrice =
          ProductHelper.calculatePriceDecrementingTotalPrice(cartItem);
        cartItem.priceChanged = { changed: false, lastAction: "reduce" };
      }
      if (cartItem.quantity === 0) {
        const cartItemIndex = newItems.indexOf(cartItem);

        newItems.splice(cartItemIndex, 1);
      }
    }

    if (cartItem.isResale) {
      if (cartItem.quantity > 0) {
        ShoppingCartService.updateResaleItemPrice(cartItem);
      }
    } else {
      ShoppingCartService.updateCartPrices(newItems, shoppingCartRentalDates);
    }

    localStorageService.save(localStorageKeys.SHOPPING_CART_ITEMS, newItems);

    return newItems;
  }

  static changeItemVariant(
    previousCartItems: CartItemType[],
    cartItem: CartProductItemType | CartSetItemType,
    newVariantOption: VariantSelectOptionType,
    shoppingCartRentalDates: RentalDatesType,
  ): CartItemType[] | null {
    const newItems = [...previousCartItems];

    if (!cartItem.isSet) {
      const existentCartItem = (
        previousCartItems as CartProductItemType[]
      ).find((productCartItem) => productCartItem.cartId === cartItem.cartId);

      if (existentCartItem) {
        existentCartItem.cartId = ProductHelper.generateSimpleProductCartId(
          existentCartItem.product.id,
          newVariantOption.id,
        );
        existentCartItem.quantity = 1;
        existentCartItem.variant = newVariantOption.id;
        existentCartItem.variantParamsText = newVariantOption.name;

        // TODO RENTAL: Change just the item to update?
        ShoppingCartService.updateCartPrices(newItems, shoppingCartRentalDates);

        localStorageService.save(
          localStorageKeys.SHOPPING_CART_ITEMS,
          newItems,
        );
      }

      return newItems;
    }

    return null;
  }

  static changeSetItemVariant(
    previousCartItems: CartItemType[],
    shoppingCartRentalDates: RentalDatesType,
    cartItemId: string,
    cartSetGroupItemId: string,
    updatedVariantData: SetProductVariantChangeType,
  ): CartItemType[] | null {
    const newCartItems = [...previousCartItems];

    const existentCartItem = newCartItems.find(
      (productCartItem) => productCartItem.cartId === cartItemId,
    );

    if (!existentCartItem) {
      return null;
    }

    let existentCartSetGroupItem: CartSetItemSetGroup | undefined;

    (existentCartItem as CartSetItemType).cartSetGroups.some(
      (cartSetItemSetGroups) => {
        const foundItem = cartSetItemSetGroups.find(
          (cartSetItemSet) =>
            cartSetItemSet.cartSetGroupItemId === cartSetGroupItemId,
        );

        if (foundItem) {
          existentCartSetGroupItem = foundItem;

          return true;
        }

        return false;
      },
    );

    if (!existentCartSetGroupItem) {
      return null;
    }

    existentCartSetGroupItem.variantId = updatedVariantData.variantId;
    existentCartSetGroupItem.variantName = updatedVariantData.variantName;
    existentCartSetGroupItem.variantShortName =
      updatedVariantData.variantShortName;
    existentCartSetGroupItem.productId = updatedVariantData.productId;
    existentCartSetGroupItem.productName = updatedVariantData.productName;
    existentCartSetGroupItem.productImage = updatedVariantData.productImage;

    ShoppingCartService.updateCartPrices(newCartItems, shoppingCartRentalDates);

    localStorageService.save(
      localStorageKeys.SHOPPING_CART_ITEMS,
      newCartItems,
    );

    return newCartItems;
  }

  static addItem(
    cartItem: CartItemType,
    previousCartItems: CartItemType[],
    newCartRentalDates?: RentalDatesType,
  ): CartItemType[] {
    if (!cartItem.isSet) {
      const newCartItems = [...previousCartItems];

      const existentCartItem = (newCartItems as CartProductItemType[]).find(
        (productCartItem) => productCartItem.cartId === cartItem.cartId,
      );

      // Here the prices get updated
      if (existentCartItem) {
        // Update price with price decrementing
        existentCartItem.quantity += (cartItem as CartProductItemType).quantity;

        if ((cartItem as CartProductItemType).isResale) {
          existentCartItem.previousPrice = cartItem.totalPrice;

          const resaleUnitPrice = (cartItem as CartProductItemType)
            .resaleUnitPrice as number;

          existentCartItem.totalPrice =
            resaleUnitPrice * (existentCartItem.quantity || 1);

          // update price decrementing item
          const productCartItem = cartItem as CartProductItemType;

          if (productCartItem.requestedPriceDecrementingUnit) {
            const { conditionPriceDecrementingTierStockIndex, price } =
              productCartItem.requestedPriceDecrementingUnit;
            const priceDecrementingItem =
              existentCartItem.priceDecrementingItems?.find(
                (item) =>
                  item.tierStockIndex ===
                  conditionPriceDecrementingTierStockIndex,
              );

            if (priceDecrementingItem) {
              // As we are adding 1 by 1, let's just increase this by 1.
              priceDecrementingItem.quantity += 1;
            } else {
              existentCartItem.priceDecrementingItems?.push({
                tierStockIndex: conditionPriceDecrementingTierStockIndex,
                quantity: productCartItem.quantity,
                price,
              });
              existentCartItem.priceDecrementingItems?.sort(
                (a, b) => a.tierStockIndex - b.tierStockIndex,
              );
            }
          }
          if (existentCartItem.priceDecrementingTotalPrice) {
            existentCartItem.priceDecrementingTotalPrice =
              ProductHelper.calculatePriceDecrementingTotalPrice(
                existentCartItem,
              );
          }
        } else {
          // TODO: Remove this if rental is not a business requirement anymore
          ShoppingCartService.updateCartPrices(
            newCartItems,
            newCartRentalDates as RentalDatesType,
          );
        }

        localStorageService.save(
          localStorageKeys.SHOPPING_CART_ITEMS,
          newCartItems,
        );

        return newCartItems;
      }

      // InitializePrices
      const productCartItem = cartItem as CartProductItemType;

      if (productCartItem.requestedPriceDecrementingUnit) {
        const { conditionPriceDecrementingTierStockIndex, price } =
          productCartItem.requestedPriceDecrementingUnit;

        productCartItem.priceDecrementingItems = [
          {
            tierStockIndex: conditionPriceDecrementingTierStockIndex,
            quantity: productCartItem.quantity,
            price,
          },
        ];
        productCartItem.priceDecrementingTotalPrice =
          productCartItem.quantity * price;
      }
    }

    cartItem.priceChanged = { changed: false, lastAction: "add" };

    const newState = [...previousCartItems, cartItem];

    if (!(cartItem as CartProductItemType).isResale) {
      ShoppingCartService.updateCartPrices(
        newState,
        newCartRentalDates as RentalDatesType,
      );
    }

    localStorageService.save(localStorageKeys.SHOPPING_CART_ITEMS, newState);

    return newState;
  }

  static removeItem(cartId: string): void {
    // TODO: Possible refactor. Should I take the previousState from local storage? better take it from the reducer.
    const previousState = localStorageService.get(
      localStorageKeys.SHOPPING_CART_ITEMS,
    );

    const items = previousState || [];

    const newItems = [...items];

    const cartItem = newItems.find(
      (item: CartSetItemType | CartProductItemType) =>
        item.cartId.toString() === cartId.toString(),
    );

    if (cartItem) {
      const cartItemIndex = newItems.indexOf(cartItem);

      newItems.splice(cartItemIndex, 1);
      localStorageService.save(localStorageKeys.SHOPPING_CART_ITEMS, newItems);
    }
  }

  static clearCart(): void {
    localStorageService.remove(localStorageKeys.SHOPPING_CART_ITEMS);
    localStorageService.remove(localStorageKeys.SHOPPING_CART_DATES);
  }

  static setCartItemPrice(
    cartItem: CartItemType,
    cartRentalDates: RentalDatesType,
  ): void {
    const quantity = (cartItem as CartProductItemType).quantity || 1;
    const total = ProductHelper.calculatePriceForRentalShoppingCartItem(
      cartItem.product,
      cartRentalDates,
      quantity,
    );

    cartItem.totalPrice = total;
  }

  // TODO RESALE: This is being called for addItem, changeItemVariant, changeSetItemVariant, updateRentalDates
  static updateCartPrices(
    cartItems: CartItemType[],
    cartRentalDates: RentalDatesType,
  ): void {
    cartItems
      .filter((item) => !(item as CartProductItemType).isResale)
      .forEach((item) =>
        ShoppingCartService.setCartItemPrice(item, cartRentalDates),
      );
  }

  static updateResaleItemPrice(cartItem: CartProductItemType): void {
    cartItem.totalPrice =
      (cartItem.resaleUnitPrice as number) * cartItem.quantity;
  }

  static async setAndCheckAvailability(
    partnerId: string,
    cartItems: CartItemType[],
    shoppingCartRentalDates: RentalDatesType,
    discountCode?: string,
  ): Promise<any> {
    CartHelper.setInitialItemsStatusToAvailable(cartItems);

    const hasRentalItems = CartHelper.hasRentalItems(cartItems);

    if (
      hasRentalItems &&
      (!shoppingCartRentalDates || !shoppingCartRentalDates.startDate)
    ) {
      return {
        error: shoppingCartErrorTypes.NO_DATES,
        cartItems,
        shipping: 0,
        subtotal: 0,
        total: 0,
      };
    }

    const response: ShoppingCartValidateResponse =
      await ShoppingCartService.getCartAvailability(
        partnerId,
        cartItems,
        shoppingCartRentalDates,
        discountCode,
      );

    if (response.isValid) {
      return {
        error: null,
        cartItems,
        ...response,
      };
    }

    const hasUnavailableResaleItems =
      response.resaleVariantsUnavailable &&
      response.resaleVariantsUnavailable.length > 0;
    const hasUnavailableRentalItems =
      response.variantsUnavailable && response.variantsUnavailable.length > 0;

    if (
      !response.isValid &&
      hasRentalItems &&
      !hasUnavailableResaleItems &&
      !hasUnavailableRentalItems
    ) {
      return {
        error: shoppingCartErrorTypes.INVALID_DATE_RANGE,
        cartItems,
        ...response,
      };
    }

    let errorStatus = shoppingCartErrorTypes.ITEMS_UNAVAILABLE;

    cartItems.forEach((item) => {
      if (item.isSet) {
        (item as CartSetItemType).cartSetGroups.forEach((setGroupSelections) =>
          setGroupSelections.forEach((variantSelection) => {
            const variantUnavailable = response.variantsUnavailable.some(
              (variantUnavailableResponseItem) =>
                variantUnavailableResponseItem.variantId ===
                parseInt(variantSelection.variantId, 10),
            );

            if (variantUnavailable) {
              variantSelection.unavailable = variantUnavailable;
              item.unavailable = true;
            }
          }),
        );
      }

      if ((item as CartProductItemType).isResale) {
        const unavailabilityData = response.resaleVariantsUnavailable?.find(
          (resaleVariantUnavailable) =>
            resaleVariantUnavailable.variantId ===
              (item as CartProductItemType).variant &&
            resaleVariantUnavailable.condition === item.condition,
        );

        if (unavailabilityData) {
          item.stockMaxAvailable = unavailabilityData.stockAvailable;
          if (item.priceDecrementingItems) {
            const unavailableUnitsCount =
              item.quantity - unavailabilityData.stockAvailable;

            if (unavailableUnitsCount > 0) {
              item.unavailable = true;
            }
            const pricesDoNotMatch =
              unavailabilityData.price !== item.priceDecrementingTotalPrice;

            if (pricesDoNotMatch) {
              item.priceDecrementingItems =
                ProductHelper.recalculatePriceDecrementingCartItems(
                  unavailabilityData.stockAvailableArray,
                  unavailabilityData.priceArray,
                  item.quantity,
                  unavailableUnitsCount,
                  true,
                );
              item.priceDecrementingTotalPrice =
                ProductHelper.calculatePriceDecrementingTotalPrice(item);
              errorStatus = shoppingCartErrorTypes.PRICE_DECREMENTING_MISMATCH;
              if (item.priceChanged?.lastAction === "add") {
                item.priceChanged = {
                  changed: true,
                  lastAction: item.priceChanged?.lastAction || "add",
                };
              }
            }
          } else {
            item.unavailable = true;
          }
        }
      } else {
        const unavailabilityData = response.variantsUnavailable?.find(
          (rentalVariantUnavailable) => {
            const hasSameVariantId =
              rentalVariantUnavailable.variantId ===
              (item as CartProductItemType).variant;

            if (!rentalVariantUnavailable.condition) {
              return hasSameVariantId;
            }

            return (
              hasSameVariantId &&
              rentalVariantUnavailable.condition === item.condition
            );
          },
        );

        if (unavailabilityData) {
          item.unavailable = true;
          item.stockMaxAvailable = unavailabilityData.stockAvailable;
        }
      }
    });

    return {
      error: errorStatus,
      cartItems,
      ...response,
    };
  }

  static async getSimpleProductAvailability(
    partnerId: string,
    cartItems: CartItemType[],
    selectedVariantId: number,
    productId: number,
    fromDate: string,
    toDate: string,
  ): Promise<any> {
    const params = {
      fromDate,
      toDate,
    };

    const response: ApiResponse = await API.get(
      partnerId,
      `/Product/${productId}/variant/availability`,
      params,
    ).then((r: HttpResponse) => r.get());

    if (!response.data) {
      return {
        error: true,
        message: "There was a problem trying to get availability data.",
      };
    }

    let availability = response.data;

    availability = ProductHelper.filterPossibleVariantsForSimpleProducts(
      cartItems as CartProductItemType[],
      availability,
      selectedVariantId,
    );

    const variantAvailableList = ProductHelper.filterAvailableItems(
      cartItems,
      availability,
    );

    const variantOptions = variantAvailableList.map(
      ProductHelper.mapVariantToOption,
    );

    return {
      availability,
      variantOptions,
    };
  }

  static async getSetGroupAvailability(
    partnerId: string,
    cartItems: CartItemType[],
    productId: number,
    setGroupId: string,
    fromDate: string,
    toDate: string,
    globalLabels: GlobalLabelsType,
  ): Promise<any> {
    const params = {
      fromDate,
      toDate,
    };

    const response: ApiResponse = await API.get(
      partnerId,
      `/Product/${productId}/setGroup/${setGroupId}/availability`,
      params,
    ).then((r: HttpResponse) => r.get());

    if (!response.data) {
      return {
        error: true,
        message: "There was a problem trying to modify the order",
      };
    }

    const availability = response.data;

    const apiProductResponse = await API.getProduct(partnerId, productId);
    const productResponse = apiProductResponse.data;

    const variantAvailableList = ProductHelper.filterAvailableItems(
      cartItems,
      availability,
    );

    const variantOptions = variantAvailableList.map((variantAvailableItem) =>
      ProductHelper.mapSetProductAvailabilityItemToVariantOptions(
        variantAvailableItem,
        productResponse,
        globalLabels,
      ),
    );

    return {
      availability,
      variantOptions,
    };
  }
}
