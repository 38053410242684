/* eslint-disable @typescript-eslint/no-explicit-any */
import APICategoryTilesType from "@/dep_types/content/api/components/sections/categoryTiles";
import { CategoryTilesType } from "@/dep_types/content/sections";

import linkedTileDataMapper from "../components/linkedTile";

const categoryTiles = (
  apiCategoryTilesData: APICategoryTilesType,
): CategoryTilesType => ({
  id: apiCategoryTilesData.id,
  componentKey: apiCategoryTilesData.__component,
  title: apiCategoryTilesData.title,
  tiles: apiCategoryTilesData.tiles.map((tile: any) =>
    linkedTileDataMapper(tile),
  ),
});

// eslint-disable-next-line import/no-default-export
export default categoryTiles;
