const CheckoutStatusType = {
  DRAFT: "DRAFT",
  PROCESSING: "PROCESSING",
  ERROR_PAYMENT: "ERROR_PAYMENT",
  ERROR_ADDRESS: "ERROR_ADDRESS",
  ERROR_UNAVAILABLE_PRODUCT: "ERROR_UNAVAILABLE_PRODUCT",
  ERROR_UNAVAILABLE_PRICE: "ERROR_UNAVAILABLE_PRICE",
  ERROR_REQUIRED_FIELDS: "ERROR_REQUIRED_FIELDS",
  ERROR_API_ERROR: "ERROR_API_ERROR",
  CONFIRMED: "CONFIRMED",
} as const;

// eslint-disable-next-line no-redeclare
type CheckoutStatusType =
  (typeof CheckoutStatusType)[keyof typeof CheckoutStatusType];

// eslint-disable-next-line import/no-default-export
export default CheckoutStatusType;
