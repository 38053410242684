/* eslint-disable sonarjs/prefer-immediate-return */
import qs from "qs";

import { GenericCmsQuery } from "./GenericCmsQuery";

export interface NavigationItem {
  label: string;
  href: string;
}

interface CmsLandingPage {
  data: {
    attributes: {
      slug: string;
    };
  };
}

interface NavigationQueryMenuItem {
  label: string;
  landingPage: CmsLandingPage;
}

interface NavigationQueryResponse {
  menuItems: NavigationQueryMenuItem[];
}

export async function FetchNavigationItems(
  partner: string,
): Promise<NavigationItem[]> {
  const query = qs.stringify({
    filters: {
      partner: {
        partner_id: {
          $eq: partner,
        },
      },
    },
    populate: ["menuItems", "menuItems.landingPage"],
  });

  const navigationItems = await GenericCmsQuery<NavigationQueryResponse>(
    "navigations",
    query,
  ).then((data) => {
    const items = data?.menuItems?.map((item) => ({
      label: item.label,
      href: `/${item.landingPage.data.attributes.slug}`,
    }));

    return items;
  });

  return navigationItems;
}
