const variantSelector = {
  variants: {
    selected: {
      optionButton: {
        color: "primary.600",
        backgroundColor: "white",
        borderColor: "primary.600",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default variantSelector;
