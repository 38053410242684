/* eslint-disable @typescript-eslint/no-explicit-any */
import { RedisClientType } from "redis";

import getRedisClient from "./getRedisClient";

const cacheLifespan = 60 * 60 * 24 * 7; // one week
const cacheDuration = 30; // 30 seconds

const cacheRequestData = (
  requestData: any,
  cacheClient: RedisClientType,
  cacheKey: string,
  defaultErrorMessage: string,
): void => {
  // Never cache null data
  if (requestData.data) {
    cacheClient.set(cacheKey, JSON.stringify(requestData), {
      EX: cacheLifespan,
    });
  } else {
    // throw an error if the data is null
    const errorMessage = requestData.error?.message || defaultErrorMessage;
    const error = new Error(errorMessage);

    error.name = requestData.error?.name || "UnknownError";
    throw error;
  }
};

const handleError = (
  requestData: {
    [key: string]: any;
  },
  errorPrefix: string,
  error: any,
): any => {
  // TODO: When we have the ability to report errors to DataDog on the server,
  // remove the inclusion of the error in the data and just report the error
  // to DataDog here instead of passing to the FE to report there
  requestData.error = {
    message: `${errorPrefix}: ${error.message}`,
    name: error.name,
  };
};

const getSWRCacheValue = async (
  cacheKey: string,
  requestCallback: () => Promise<any>,
  errorPrefix: string,
  defaultErrorMessage: string,
  isPreviewMode: boolean,
): Promise<any> => {
  // Never cache the data returned while in the preview state
  if (isPreviewMode) {
    return requestCallback();
  }

  let requestData = {};

  const cacheClient = await getRedisClient();
  const cacheExists = await cacheClient.exists(cacheKey);

  try {
    if (!cacheExists) {
      // cache miss, need to fetch
      try {
        requestData = await requestCallback();
      } catch (requestCallbackError: any) {
        // If this results in an error, then we are in big
        // trouble because there is no cache to fallback to,
        // let's just report the error and move on
        const requestCallbackErrorPrefix = `There was an error retrieving data from the callback and no cached value was available. -- ${errorPrefix}`;

        handleError(
          requestData,
          requestCallbackErrorPrefix,
          requestCallbackError,
        );

        return requestData;
      }

      try {
        cacheRequestData(
          requestData,
          cacheClient,
          cacheKey,
          defaultErrorMessage,
        );
      } catch (cacheDataError: any) {
        // If cacheRequestData results in an error then we need
        // to simply return the data from the requestCallback since
        // it was successful and report the error
        const cacheDataErrorPrefix = `There was an error caching data. -- ${errorPrefix}`;

        handleError(requestData, cacheDataErrorPrefix, cacheDataError);

        return requestData;
      }
    } else {
      // cache hit, will get data from redis
      const cacheValue = (await cacheClient.get(cacheKey)) || "{}";

      requestData = JSON.parse(cacheValue);

      const timeLeft = await cacheClient.ttl(cacheKey);
      const refreshCache = timeLeft < cacheLifespan - cacheDuration;

      // refresh the cache after the defined amount of time
      if (refreshCache) {
        requestCallback()
          .then((freshRequestData) => {
            cacheRequestData(
              freshRequestData,
              cacheClient,
              cacheKey,
              defaultErrorMessage,
            );
          })
          .catch((error: any) => {
            // TODO: When we have the ability to report errors to DataDog
            // on the server, let's report them here
            // eslint-disable-next-line no-console
            console.error(
              `We experienced an error while refreshing the cache. -- ${errorPrefix}: ${error.message}`,
            );
          });
      }
    }
  } catch (error: any) {
    // In the event of an error, let's fall back to the cached data
    // which should be good because we never cache null data
    const cacheFallback = (await cacheClient.get(cacheKey)) || "{}";

    requestData = JSON.parse(cacheFallback);

    handleError(requestData, errorPrefix, error);
  }

  return requestData;
};

// eslint-disable-next-line import/no-default-export
export default getSWRCacheValue;
