import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

const ShoppingCartIcon: FC<IconProps> = ({ ...other }) => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...other}
  >
    <path d="M8 19.25C8.48325 19.25 8.875 18.8582 8.875 18.375C8.875 17.8918 8.48325 17.5 8 17.5C7.51675 17.5 7.125 17.8918 7.125 18.375C7.125 18.8582 7.51675 19.25 8 19.25Z" />
    <path d="M17.625 19.25C18.1082 19.25 18.5 18.8582 18.5 18.375C18.5 17.8918 18.1082 17.5 17.625 17.5C17.1418 17.5 16.75 17.8918 16.75 18.375C16.75 18.8582 17.1418 19.25 17.625 19.25Z" />
    <path
      fill="transparent"
      d="M1 0.875H4.5L6.845 12.5913C6.92501 12.9941 7.14417 13.356 7.4641 13.6135C7.78404 13.8711 8.18436 14.0079 8.595 14H17.1C17.5106 14.0079 17.911 13.8711 18.2309 13.6135C18.5508 13.356 18.77 12.9941 18.85 12.5913L20.25 5.25H5.375"
    />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default ShoppingCartIcon;
