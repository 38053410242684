import { Box, Flex } from "@chakra-ui/react";
import React, { FC } from "react";

import { LinkedButtonType } from "@/dep_types/content/components";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";

type Props = {
  socialIcons: LinkedButtonType[];
};

const PartnerFooterSocialLinks: FC<Props> = ({ socialIcons }: Props) => (
  <Flex
    direction="row"
    alignItems="center"
    justifyContent={{ base: "center", md: "flex-end" }}
    w="100%"
  >
    {socialIcons.map((socialIcon: LinkedButtonType) => (
      <Box mb={1} key={`footer_link_social_${socialIcon.icon?.source}`}>
        <LinkedCTAButton
          {...socialIcon}
          iconWidth="30px"
          variant="socialIcon"
        />
      </Box>
    ))}
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default PartnerFooterSocialLinks;
