import { parts } from "@/dep_theme/common/components/productCard";

export const productCard = {
  parts,
  baseStyle: {
    productName: {
      fontFamily: "heading",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    productPrice: {
      fontSize: "sm",
      fontWeight: "bold",
      fontFamily: "heading",
    },
  },
  variants: {
    vertical: {},
    horizontal: {
      productName: {
        fontWeight: "bold",
      },
    },
  },
};
