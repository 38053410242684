const variantSelector = {
  parts: ["optionButton", "check"],
  baseStyle: {
    optionButton: {
      padding: 2.5,
      borderWidth: "1px",
      borderRadius: "4px",
      borderColor: "gray.200",
      minWidth: "99px",
    },
    check: {
      background: "#6DBDA3",
    },
  },
  variants: {
    selected: {
      optionButton: {
        color: "secondary.600",
        backgroundColor: "secondary.50",
        borderColor: "secondary.600",
      },
    },
    unavailable: {
      optionButton: {
        color: "gray.300",
        borderStyle: "dashed",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default variantSelector;
