import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

const CalendarIcon: FC<IconProps> = ({ ...other }) => (
  <Icon
    width="15px"
    height="16px"
    viewBox="0 0 15 16"
    fill="currentColor"
    stroke="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...other}
  >
    <path d="M13.0047 2H11.4441V0.375C11.4441 0.16875 11.2685 0 11.054 0H9.75349C9.53892 0 9.36335 0.16875 9.36335 0.375V2H5.20186V0.375C5.20186 0.16875 5.0263 0 4.81172 0H3.51126C3.29668 0 3.12112 0.16875 3.12112 0.375V2H1.56056C0.699 2 0 2.67188 0 3.5V14.5C0 15.3281 0.699 16 1.56056 16H13.0047C13.8662 16 14.5652 15.3281 14.5652 14.5V3.5C14.5652 2.67188 13.8662 2 13.0047 2ZM12.8096 14.5H1.75563C1.64834 14.5 1.56056 14.4156 1.56056 14.3125V5H13.0047V14.3125C13.0047 14.4156 12.9169 14.5 12.8096 14.5Z" />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default CalendarIcon;
