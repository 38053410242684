const faqCategorySection = {
  parts: ["title", "subtitle", "card"],
  baseStyle: {
    title: {
      color: "gray.500",
      fontWeight: "bold",
      letterSpacing: "2px",
      lineHeight: "42px",
      fontFamily: "normal",
    },
    subtitle: {
      color: "gray.500",
      fontWeight: "bold",
      fontSize: "30px",
      letterSpacing: "2px",
      lineHeight: "42px",
      textTransform: "uppercase",
    },
    card: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "2px",
      lineHeight: "24px",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "gray.100",
      borderRadius: 0,
      _hover: {
        backgroundColor: "black",
        color: "white",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqCategorySection;
