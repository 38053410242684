const discountCode = {
  parts: ["applyLabel"],
  baseStyle: {
    applyLabel: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      color: "primary.600",
      fontSize: "14px",
      fontFamily: "normal",
      fontWeight: "medium",
      cursor: "pointer",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default discountCode;
