const footer = {
  parts: ["container", "title", "body", "copyright", "assistiveText"],
  baseStyle: {
    container: {
      backgroundColor: "gray.50",
      color: "gray.500",
    },
    title: {
      fontWeight: 700,
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    body: {
      fontSize: "15px",
      letterSpacing: "0.5px",
      lineHeight: "24px",
      fontFamily: "normal",
      fontWeight: 400,
    },
    copyright: {
      mb: 4,
      fontSize: "15px",
      letterSpacing: "0.5px",
      lineHeight: "24px",
      fontFamily: "normal",
      textAlign: {
        base: "center",
        md: "left",
      },
    },
    assistiveText: {
      fontFamily: "normal",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default footer;
