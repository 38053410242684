import linkDataMapper from "@/dep_helpers/content/mappers/components/link";
import buttonDataMapper from "@/dep_helpers/content/mappers/components/linkedButton";
import APIFooterInfoType from "@/dep_types/content/api/components/partner/footerInfo";
import { FooterInfoType } from "@/dep_types/content/partner";

const footerInfo = (apiFooterInfoData: APIFooterInfoType[]): FooterInfoType[] =>
  apiFooterInfoData.map((f) => ({
    id: f.id,
    title: f.title,
    body: f.body,
    buttons: f.buttons.map((b) => buttonDataMapper(b)),
    link: f.link ? linkDataMapper(f.link) : null,
    image: f.image,
  }));

// eslint-disable-next-line import/no-default-export
export default footerInfo;
