import { Box, Flex } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  dateLabel?: string;
};

const DateBox: FC<Props> = ({ dateLabel }: Props) => (
  <Flex direction="row" justifyContent="center">
    <Box as="span" fontFamily="normal" fontWeight="medium">
      {dateLabel}
    </Box>
  </Flex>
);

DateBox.defaultProps = {
  dateLabel: "Add Date",
};

// eslint-disable-next-line import/no-default-export
export default DateBox;
