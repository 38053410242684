import { Flex, Link, useMultiStyleConfig } from "@chakra-ui/react";

import TrackingService from "@/dep_services/tracking/trackingService";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import { RichText } from "@/dep_components/Commons/RichText";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

export function TopTextBanner() {
  const { headerInfo } = useWhitelabelContext();
  const styles = useMultiStyleConfig("topTextBanner");

  if (!headerInfo) {
    return null;
  }

  return (
    <Flex id={commonKeys.TEXT_BANNER_ID} sx={styles.container}>
      {headerInfo?.backToSiteButton && (
        <Link
          sx={styles.link}
          href={headerInfo.backToSiteButton.url}
          target="_blank"
          onClick={() =>
            TrackingService.trackButtonClick(
              headerInfo.backToSiteButton?.trackingInfo?.name ||
                "backtositebutton",
              headerInfo.backToSiteButton?.trackingInfo?.location || "Header",
            )
          }
        >
          {headerInfo.backToSiteButton.text}
        </Link>
      )}
      {headerInfo.bannerText && (
        <RichText sx={styles?.text} text={headerInfo.bannerText} />
      )}
    </Flex>
  );
}
