const categoryTiles = {
  parts: ["title", "card"],
  baseStyle: {
    title: {
      textTransform: "none",
      fontSize: {
        base: "26px",
        md: "48px",
      },
      letterSpacing: "0.4px",
    },
    card: {
      fontWeight: 400,
      fontFamily: "product-title",
      letterSpacing: "1px",
      fontSize: "36px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default categoryTiles;
