import { forwardRef, Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import layoutConstants from "@/dep_constants/layout";

const Container = forwardRef<BoxProps, "div">((props, ref) => (
  <Box
    {...props}
    paddingX={{
      base: layoutConstants.containerPadding.base,
      md: layoutConstants.containerPadding.md,
      xl: layoutConstants.containerPadding.xl,
    }}
    ref={ref}
  />
));

// eslint-disable-next-line import/no-default-export
export default Container;
