export const topTextBanner = {
  parts: ["container", "link", "title"],
  baseStyle: {
    container: {
      letterSpacing: "1px",
    },
    link: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    text: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
};
