const categoryTiles = {
  parts: ["card"],
  baseStyle: {
    title: {
      color: "primary.600",
      fontFamily: "title",
      letterSpacing: {
        base: "1px",
        md: "3px",
      },
      fontSize: {
        base: "22px",
        md: "48px",
      },
      fontWeight: "bold",
      marginBottom: 4,
      textAlign: "center",
    },
    card: {
      textTransform: "uppercase",
      fontFamily: "title",
      color: "white",
      fontWeight: "bold",
      letterSpacing: "0.03em",
      borderRadius: "4px",
      lineHeight: {
        base: "36px",
        md: "28.26px",
      },
      fontSize: {
        base: "36px",
        md: "18px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default categoryTiles;
