import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function ProductCardPrice({ children }: Readonly<PropsWithChildren>) {
  const { productPrice } = useMultiStyleConfig("ProductCard");

  return (
    <Flex sx={productPrice} direction="column" gap="0.5">
      {children}
    </Flex>
  );
}
