const footer = {
  parts: ["container", "title", "body", "copyright"],
  baseStyle: {
    container: {
      backgroundColor: "secondary.600",
    },
    title: {
      fontFamily: "title",
      letterSpacing: "0.4px",
      fontWeight: 500,
    },
    body: {
      fontFamily: "normal",
      letterSpacing: "0.16px",
      fontWeight: 300,
    },
    copyright: {
      fontFamily: "normal",
      letterSpacing: "0.16px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default footer;
