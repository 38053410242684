/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import DatesHelper from "../dep_helpers/DatesHelper";

export interface State {
  checkRangeSelectedIsValid: (items: any) => void;
  handleOnRangeFocusChange: (focusRangeState: any) => void;
  rangeSelectionComplete: boolean;
  rangeSelectionIsValid: boolean;
}

const useDatePickerValidationHook = (): State => {
  const [rangeSelectionComplete, setRangeSelectionComplete] =
    useState<boolean>(false);
  const [rangeSelectionIsValid, setRangeSelectionIsValid] =
    useState<boolean>(false);

  const checkRangeSelectedIsValid = (items: any): void => {
    const rangeIsValid = DatesHelper.checkRangeSelectedIsValid(
      items.startDate,
      items.endDate,
    );

    setRangeSelectionIsValid(rangeIsValid);
  };

  const handleOnRangeFocusChange = (focusRangeState: any): void => {
    const selectionComplete =
      focusRangeState[0] === 0 && focusRangeState[1] === 0;

    setRangeSelectionComplete(selectionComplete);
  };

  return {
    checkRangeSelectedIsValid,
    handleOnRangeFocusChange,
    rangeSelectionComplete,
    rangeSelectionIsValid,
  };
};

// eslint-disable-next-line import/no-default-export
export default useDatePickerValidationHook;
