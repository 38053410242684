const customText = {
  baseStyle: {
    fontFamily: "normal",
    fontSize: {
      base: "14px",
      md: "16px",
    },
  },
  variants: {
    superlative: {
      p: {
        base: "6px",
        md: "10px",
      },
      fontSize: {
        base: "10px",
        md: "13px",
      },
      lineHeight: {
        base: "12px",
        md: "15px",
      },
      width: "fit-content",
      textTransform: "uppercase",
      textAlign: "left",
      color: "white",
    },
    formTitle: {
      fontSize: "16px",
      fontWeight: "medium",
      color: "black",
    },
    formSectionTitle: {
      fontSize: "21px",
      fontWeight: "medium",
      color: "black",
    },
    formHighlightTitle: {
      fontSize: "16px",
      fontWeight: "medium",
      color: "primary.600",
    },
    formText: {
      fontSize: "14px",
      fontWeight: "medium",
      color: "black",
    },
    formSummaryValue: {
      fontSize: "14px",
      fontWeight: "medium",
      color: "black",
    },
    formDiscountKey: {
      fontSize: "12px",
      fontWeight: "medium",
      color: "primary.600",
    },
    pdpProductDescription: {
      fontFamily: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      listStylePosition: "inside",
    },
  },
  defaultProps: {},
};

// eslint-disable-next-line import/no-default-export
export default customText;
