enum EventLocationType {
  HOME = "home page",
  PRODUCT_DETAIL_PAGE = "product detail page",
  CART_MODAL = "cart modal page",
  COLLECTION = "collection page",
  SEARCH = "search drawer",
  LANDING_PAGE = "landing page",
  GEAR_FINDER_PAGE = "gear finder page",
  NOT_FOUND_PAGE = "404 page",
}

// eslint-disable-next-line import/no-default-export
export default EventLocationType;
