const checkbox = {
  parts: ["container", "control", "label", "icon"],
  baseStyle: {
    control: {
      w: "30px",
      h: "30px",
      borderRadius: "4px",
      border: "1px solid",
      borderColor: "gray.300",
      bg: "white",
      p: 2,
      _checked: {
        borderColor: "black",
        color: "white",
        bg: "black",
        p: 2,
        fontWeight: "2px",
        _hover: {
          bg: "black",
          color: "white",
        },
        _disabled: {
          borderColor: "gray.300",
        },
      },
      _disabled: {
        borderColor: "gray.300",
        color: "gray.300",
      },
    },
    label: {
      marginLeft: 5,
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default checkbox;
