const faqValueProp = {
  parts: ["title", "question", "answer"],
  baseStyle: {
    title: {
      fontWeight: "medium",
    },
    question: {
      fontWeight: "medium",
    },
    answer: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default faqValueProp;
