const priceContainer = {
  parts: ["originalLabel", "label", "price"],
  baseStyle: {
    originalLabel: {
      color: "gray.500",
    },
    label: {},
    price: {
      fontWeight: 500,
      letterSpacing: "0.8px",
      lineHeight: "24px",
      color: "gray.500",
      fontSize: "15px",
    },
    priceDescriptionPrefix: {
      letterSpacing: "0.8px",
      color: "gray.500",
      fontSize: "12px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default priceContainer;
