import { environment } from "../../dep_constants";

let overrides;

const local = {};

const development = {};

const staging = {};

const production = {
  AMPLITUDE_KEY: "87bce1820eaf043b25c7483e7a5a8cdc",
};

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

const configurations = {
  ...overrides,
  // TODO: Change it
  // DEFAULT_PRODUCT_IMAGE_ID: "ecomm-cms-prod/primary_black_b82c174443",
};

// eslint-disable-next-line import/no-default-export
export default configurations;
