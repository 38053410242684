const getPageCategory = (): string => {
  let category = "Unknown";
  const { pathname } = window.location;

  const categoriesByPath = [
    {
      path: "/",
      categoryName: "Homepage",
      isStrict: true,
    },
    {
      path: "/group",
      categoryName: "Collection",
      isStrict: false,
    },
    {
      path: "/products",
      categoryName: "Product",
      isStrict: false,
    },
    {
      path: "/faq",
      categoryName: "FAQ",
      isStrict: false,
    },
    {
      path: "/privacy-policy",
      categoryName: "Privacy Policy",
      isStrict: true,
    },
    {
      path: "/rental-agreement",
      categoryName: "Rental Agreement",
      isStrict: true,
    },
    {
      path: "/findmyorder",
      categoryName: "Order Lookup",
      isStrict: true,
    },
    {
      path: "/order-confirmation",
      categoryName: "Order Confirmation",
      isStrict: true,
    },
    {
      path: "/order",
      categoryName: "Order Details",
      isStrict: true,
    },
    {
      path: "/gear-finder",
      categoryName: "Gear Finder",
      isStrict: true,
    },
  ];

  const matchingCategory = categoriesByPath.find((categoryData) => {
    if (categoryData.isStrict) {
      return pathname === categoryData.path;
    }

    return pathname.startsWith(categoryData.path);
  });

  if (matchingCategory) {
    category = matchingCategory.categoryName;
  }

  return category;
};

// eslint-disable-next-line import/no-default-export
export default getPageCategory;
