import { Box, useStyleConfig } from "@chakra-ui/react";
import { ResponsiveValue, ThemeTypings } from "@chakra-ui/styled-system";
import * as CSS from "csstype";
import Link from "next/link";
import React, { FC, ReactNode } from "react";

declare type Token<
  CSSType,
  ThemeKey = unknown,
> = ThemeKey extends keyof ThemeTypings
  ? ResponsiveValue<CSSType | ThemeTypings[ThemeKey]>
  : ResponsiveValue<CSSType>;

type Props = {
  url: string;
  children: ReactNode;
  opensInNewWindow: boolean;
  variant?: string;
  textDecoration?: Token<CSS.Property.TextDecoration | number>;
};

const SiteLink: FC<Props> = ({
  children,
  url,
  opensInNewWindow,
  variant,
  textDecoration,
}) => {
  const styles = useStyleConfig("SiteLink", { variant });

  return (
    <Link href={url} passHref prefetch={false} key={url}>
      <Box
        target={opensInNewWindow ? "_blank" : "_self"}
        rel="noopener noreferrer"
        as="a"
        sx={styles}
        textDecoration={textDecoration}
      >
        {children}
      </Box>
    </Link>
  );
};

SiteLink.defaultProps = {
  variant: undefined,
  textDecoration: undefined,
};

// eslint-disable-next-line import/no-default-export
export default SiteLink;
