import HttpRequest, { HttpRequestType } from "./HttpRequest";
import HttpResponse from "./HttpResponse";
import { QueryParams } from "./RequestParams";

// eslint-disable-next-line import/no-default-export
export default class GetRequest<T> extends HttpRequest<T> {
  constructor(partnerId: string) {
    super(HttpRequestType.GET, partnerId);
  }

  execute = async (
    endpoint: string,
    queryParams?: QueryParams,
  ): Promise<HttpResponse> =>
    this.executeEndpoint(endpoint, { query: queryParams, body: undefined });
}
