/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import TrackingStrategy from "./trackingStrategy";

import OrderLog from "../../../dep_helpers/order/OrderLog";
import { ProductType } from "../../../dep_types";
import { CartItemType } from "../../../dep_types/CartTypes";

// eslint-disable-next-line import/no-default-export
export default class FacebookPixelTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    // @ts-expect-error TODO: Investigate this
    typeof window !== "undefined" && Boolean(window.fbq);

  trackPageView(): void {
    this.fbq().track("PageView");
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductPageView(product: ProductType): void {
    this.fbq().trackCustom("ProductPageView", {
      content_name: product.name,
      content_id: product.id,
    });
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackAddToCart = (newCartItem: CartItemType): void => {
    const { product } = newCartItem;

    this.fbq().track("AddToCart", {
      content_name: product.name,
      content_id: product.id,
      content_ids: [product.id],
    });
  };

  trackConversion = (_partnerId: string, orderLog: OrderLog): void => {
    const {
      cartItems,
      cartTotalPrice,
      rentalShippingCost,
      rentalTotal,
      resaleShippingCost,
      resaleTotal,
    } = orderLog;

    this.fbq().track("Purchase", {
      currency: "USD",
      value: rentalTotal + resaleTotal,
      num_items: cartItems.length,
      cart_price: cartTotalPrice,
      shipping_cost: rentalShippingCost + resaleShippingCost,
    });
  };

  trackEvent = (eventName: string, data?: object): void => {
    this.fbq().trackCustom(eventName, data);
  };

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }

  trackStartCheckout(cartItems: CartItemType[], cartTotalPrice: number): void {
    this.fbq().track("InitiateCheckout", {
      currency: "USD",
      value: cartTotalPrice,
      num_items: cartItems.length,
    });
  }

  private fbq = (): any => ({
    track: (title: string, data?: object) => {
      // @ts-expect-error TODO: Investigate this
      window.fbq("track", title, data);
    },
    trackCustom: (title: string, data?: object) => {
      // @ts-expect-error TODO: Investigate this
      window.fbq("trackCustom", title, data);
    },
  });
}
