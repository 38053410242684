import { ColorSwatch, VariantType } from "@/dep_types";

export function getAvailableSwatches(
  variants: VariantType[],
  swatches: ColorSwatch[],
) {
  const allVariantColors = variants.map((variant) => variant.parameters?.Color);

  return (
    swatches?.filter((swatch) =>
      allVariantColors.includes(swatch.color.toLowerCase()),
    ) ?? []
  );
}
