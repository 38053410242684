/* eslint-disable @typescript-eslint/no-explicit-any */
import APIHowItWorksType from "@/dep_types/content/api/components/sections/howItWorks";
import { HowItWorksType } from "@/dep_types/content/sections";

import buttonDataMapper from "../components/linkedButton";

const howItWorks = (apiHowItWorksData: APIHowItWorksType): HowItWorksType => ({
  id: apiHowItWorksData.id,
  componentKey: apiHowItWorksData.__component,
  title: apiHowItWorksData.title,
  steps: apiHowItWorksData.steps.map((step: any) => ({
    title: step.title,
    description: step.description,
  })),
  button: buttonDataMapper(apiHowItWorksData.button),
});

// eslint-disable-next-line import/no-default-export
export default howItWorks;
