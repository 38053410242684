import { Box, Flex, Text, useMultiStyleConfig } from "@chakra-ui/react";
import React, { FC } from "react";

import DatesHelper from "@/dep_helpers/DatesHelper";
import { RentalDatesType } from "@/dep_types";

import LinkButton from "@/dep_components/Commons/Buttons/LinkButton";
import CalendarIcon from "@/dep_components/icons/CalendarIcon";

import DateBox from "./DateBox";

type Props = {
  rentalDates?: RentalDatesType;
  variant?: "noBackground";
  onEditDates?(): void;
};

const SingleLineDateRangeBox: FC<Props> = ({
  rentalDates,
  onEditDates,
  variant,
}: Props) => {
  const styles = useMultiStyleConfig("SingleLineDateRangeBox", { variant });

  const dates =
    rentalDates && DatesHelper.parseGlobalRentalDatesToDisplayDate(rentalDates);

  return (
    <Flex
      flex={1}
      px="22px"
      py="15px"
      justifyContent="center"
      borderRadius="5px"
      sx={styles.container}
      data-testid="singleLineDateRangeBox"
    >
      <Box color="black" width="16px" mt="2px" mr="14px">
        <CalendarIcon />
      </Box>
      <DateBox dateLabel={dates && (dates.startDate as string)} />
      <Text lineHeight={5} px="5px">
        -
      </Text>
      <DateBox dateLabel={dates && (dates.endDate as string)} />
      <Flex
        flex={1}
        textAlign="right"
        direction="column"
        justifyContent="center"
        pl="17px"
        fontWeight="bold"
      >
        {onEditDates ? (
          <LinkButton text="Edit" onClick={onEditDates} />
        ) : (
          <Text>Edit</Text>
        )}
      </Flex>
    </Flex>
  );
};

SingleLineDateRangeBox.defaultProps = {
  rentalDates: undefined,
  onEditDates: undefined,
  variant: undefined,
};

// eslint-disable-next-line import/no-default-export
export default SingleLineDateRangeBox;
