import { GetPartnerFonts } from "@/api/cms/GetPartnerFonts";
import { reviveTheme, ReviveTheme } from "@/revive/theme";
import * as themes from "@/styles/themes";

export async function buildPartnerTheme(
  partnerId: string | number,
): Promise<ReviveTheme | undefined> {
  const theme =
    Object.values(themes).find((theme) => theme.partnerId == partnerId) ??
    reviveTheme;

  const cmsFonts = await GetPartnerFonts(theme.partnerName);

  if (!cmsFonts || cmsFonts.length === 0) {
    return theme;
  }
  /**
   * TODO: Chakra prefers `body` and `heading` as fonts.
   * We should remove the use of `normal` and `title`.
   */
  const body = cmsFonts.find((font) => font.name === "normal");
  const heading = cmsFonts.find((font) => font.name === "title") ?? body;

  const bodyFamily = body?.fallbacks
    ? `${body.family}, ${body.fallbacks}`
    : body?.family;

  const headingFamily = heading?.fallbacks
    ? `${heading.family}, ${heading.fallbacks}`
    : heading?.family;

  const fonts = {
    body: bodyFamily,
    heading: headingFamily,
    normal: bodyFamily,
    title: headingFamily,
  };

  /**
   * Build our fontFace import
   */
  let fontFaces = ``;

  heading?.styles.forEach((style) => {
    fontFaces += `
@font-face {
  font-family: ${heading.family};
  src: ${style.source};
  font-weight: ${style.weight};
  font-style: ${style.style};
}
`;
  });

  body?.styles.forEach((style) => {
    fontFaces += `
@font-face {
  font-family: ${body.family};
  src: ${style.source};
  font-weight: ${style.weight};
  font-style: ${style.style};
}
`;
  });

  return { ...theme, ...{ fonts, fontFaces } };
}
