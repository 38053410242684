import { PropsWithChildren, createContext } from "react";

import { SearchProductType } from "@/dep_types";

import {
  useProductCardContext,
  UseProductCardContext,
} from "../hooks/useProductCardContext";

export const ProductCardContext = createContext<
  UseProductCardContext | undefined
>(undefined);

type ProductCardProviderProps = {
  readonly product: SearchProductType;
};

export function ProductCardProvider({
  product,
  children,
}: PropsWithChildren<ProductCardProviderProps>) {
  const value = useProductCardContext(product);

  return (
    <ProductCardContext.Provider value={value}>
      {children}
    </ProductCardContext.Provider>
  );
}
