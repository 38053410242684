import { GearFinderPageSectionType } from "@/dep_types/content/gearFinderPage";
import { HomepageSectionType } from "@/dep_types/content/homepage";
import { NotFoundPageSectionType } from "@/dep_types/content/notFoundPage";
import { ProductsPageSectionType } from "@/dep_types/content/productsPage";
import { RecommendedProductsType } from "@/dep_types/content/sections";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import extendRecommendedProductsSection from "./extendRecommendedProductsSection";

const { COMPONENT_KEYS } = commonKeys;

const extendSectionsData = (
  partnerId: string,
  sections:
    | HomepageSectionType[]
    | ProductsPageSectionType[]
    | NotFoundPageSectionType[],
): Promise<
  | HomepageSectionType[]
  | ProductsPageSectionType[]
  | NotFoundPageSectionType[]
  | GearFinderPageSectionType[]
> =>
  Promise.all(
    sections.map(async (section) => {
      if (section.componentKey === COMPONENT_KEYS.RECOMMENDED_PRODUCTS) {
        return extendRecommendedProductsSection(
          partnerId,
          section as RecommendedProductsType,
        );
      }

      return section;
    }),
  );

// eslint-disable-next-line import/no-default-export
export default extendSectionsData;
