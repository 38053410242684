/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FC, ReactNode, useContext } from "react";

import { PartnerType } from "@/dep_types/content/partner";

import { useArriveContext } from "./context";

import partnerIds from "../dep_constants/partnerIds";
import resolveConfigurations from "../dep_whitelabel";

export const WhitelabelContext = createContext<any>(null);

type PropsWhitelabelProvider = {
  partnerIdTheme: string;
  partnerData: PartnerType | null;
  children: ReactNode;
};

export interface WhitelabelContextType extends PartnerType {
  partnerIdTheme: string;
  isAO: boolean;
  isBurton: boolean;
  [key: string]: any;
}

export const WhitelabelProvider: FC<PropsWhitelabelProvider> = ({
  partnerIdTheme,
  partnerData,
  ...other
}) => {
  const whitelabelConfigurations = resolveConfigurations(partnerIdTheme);

  return (
    <WhitelabelContext.Provider
      value={{
        ...whitelabelConfigurations,
        partnerIdTheme,
        isAO: partnerIdTheme === partnerIds.ARRIVE_OUTDOORS,
        isBurton: partnerIdTheme === partnerIds.BURTON,
        isCitizenry: partnerIdTheme === partnerIds.CITIZENRY,
        isYeti: partnerIdTheme === partnerIds.YETI,
        isBlanqi: partnerIdTheme === partnerIds.BLANQI,
        ...partnerData,
      }}
      {...other}
    />
  );
};

export const useWhitelabelContext = (): WhitelabelContextType => {
  const context = useContext(WhitelabelContext);

  if (context === undefined) {
    throw new Error(
      "useWhitelabelContext must be used within a WhitelabelProvider",
    );
  }

  return context;
};

type Props = {
  children: ReactNode;
  partnerData: PartnerType | null;
};

export const ManagedWhitelabelContext: FC<Props> = ({
  children,
  partnerData,
}: Props) => {
  const { partnerIdTheme } = useArriveContext();

  return (
    <WhitelabelProvider
      partnerIdTheme={partnerIdTheme}
      partnerData={partnerData}
    >
      {children}
    </WhitelabelProvider>
  );
};
