import {
  Collapse,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";

import { CategoryType } from "@/dep_types";

import ChevronIcon from "@/dep_components/icons/ChevronIcon";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import SiteNavigationLink from "./SiteNavigationLink";

import TrackingService from "../../../dep_services/tracking/trackingService";

interface Props extends CategoryType {
  parentCategoryName: string;
  categoryGroup: string;
  onCloseDrawer: () => void;
}

const SiteNavigationSubcategory: FC<Props> = ({
  parentCategoryName,
  categoryGroup,
  collections,
  collectionCategory,
  onCloseDrawer,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isYeti } = useWhitelabelContext();

  return (
    <>
      <Text
        as="button"
        size="md"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontWeight="medium"
        width="100%"
        letterSpacing={isYeti ? "0.03px" : "auto"}
        onClick={() => {
          TrackingService.trackNavigationClick(
            parentCategoryName,
            collectionCategory.name,
            "",
          );
          onToggle();
        }}
      >
        {collectionCategory.name}
        <ChevronIcon
          width="8px"
          stroke="gray.400"
          direction={isOpen ? "down" : "right"}
        />
      </Text>
      <Collapse in={isOpen}>
        <UnorderedList listStyleType="none" margin={0} marginTop={2.5}>
          <ListItem>
            <SiteNavigationLink
              link={`/group/${categoryGroup}/category/${collectionCategory.id}`}
              menuItem={parentCategoryName}
              primaryCategory={collectionCategory.name}
              secondaryCategory=""
              variant="secondary"
              onCloseDrawer={onCloseDrawer}
            >
              View All
            </SiteNavigationLink>
          </ListItem>
          {collections.map((collection) => (
            <ListItem key={collection.id}>
              <SiteNavigationLink
                link={`/group/${categoryGroup}/category/${collectionCategory.id}?collections=${collection.id}`}
                menuItem={parentCategoryName}
                primaryCategory={collectionCategory.name}
                secondaryCategory={collection.name}
                variant="secondary"
                onCloseDrawer={onCloseDrawer}
              >
                {collection.name}
              </SiteNavigationLink>
            </ListItem>
          ))}
        </UnorderedList>
      </Collapse>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SiteNavigationSubcategory;
