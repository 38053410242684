/* eslint-disable */
import React, { FC } from 'react';
import { Icon } from "@chakra-ui/react"

type Props = {
  stroke?: string;
};

const SearchIcon: FC<Props> = ({
  stroke
}) => {
  return (
    <Icon boxSize="4" viewBox="0 0 16 16" fill="none" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.22222 13.4444C10.6587 13.4444 13.4444 10.6587 13.4444 7.22222C13.4444 3.78578 10.6587 1 7.22222 1C3.78578 1 1 3.78578 1 7.22222C1 10.6587 3.78578 13.4444 7.22222 13.4444Z"/>
      <path d="M15 15L11.6167 11.6167"/>
    </Icon>
  );
};

SearchIcon.defaultProps = {
  stroke: 'black'
};

// eslint-disable-next-line import/no-default-export
export default SearchIcon;
