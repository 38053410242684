const categoryTiles = {
  parts: ["title", "card"],
  baseStyle: {
    title: {
      gridColumn: "1 / span 12",
      marginBottom: {
        base: "3.5",
        md: "6",
        xl: "10",
      },
      textAlign: {
        base: "left",
        md: "center",
      },
    },
    card: {
      aspectRatio: {
        base: "1/ 1",
        md: "auto",
      },
      backgroundColor: "black",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "8px",
      maxWidth: { base: "100%", md: 420 },
      width: {
        base: "100%",
        md: "auto",
      },
      height: {
        base: "100%",
        md: "172px",
        xl: "312px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      paddingX: "4",
      paddingY: "8",
      color: "white",
      fontWeight: {
        base: "medium",
        xl: "bold",
      },
      fontSize: {
        base: "18px",
        xl: "42px",
      },
      lineHeight: {
        base: "1.57",
        xl: "48px",
      },
      letterSpacing: {
        base: "-.03em",
        xl: "-.04em",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default categoryTiles;
