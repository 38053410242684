import { createContext, useContext, PropsWithChildren } from "react";

import { GlobalModal } from "@/features/shared/Modals/GlobalModal";
import { useModalManager } from "@/hooks/useModalManager";
import { Modal } from "@/revive/components/Modal/Modal";

type ModalContextProps = {
  openModal: (modalId: string) => void;
  closeModal: () => Promise<void>;
  isOpen: boolean;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export function ModalProvider({ children }: PropsWithChildren<object>) {
  const { modalActions, matchingModal } = useModalManager();

  return (
    <ModalContext.Provider value={modalActions}>
      {children}
      {matchingModal && (
        <Modal
          size={matchingModal.size}
          isOpen={modalActions.isOpen}
          onClose={modalActions.closeModal}
          title={matchingModal.title}
        >
          <GlobalModal matchingModal={matchingModal} />
        </Modal>
      )}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
