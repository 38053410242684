/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from "lodash";
import { MutableRefObject, useCallback, useRef } from "react";

const useDebouncedCallback = (
  callback: () => void,
  delay: number,
): (() => void) => {
  const callbackRef = useRef() as MutableRefObject<any>;

  callbackRef.current = callback;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay),
    [],
  );
};

// eslint-disable-next-line import/no-default-export
export default useDebouncedCallback;
