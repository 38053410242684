import APINotFoundPageDataType from "@/dep_types/content/api/data/notFoundPageData";
import { ErrorObjectType } from "@/dep_types/content/error";
import { NotFoundPageResponseType } from "@/dep_types/content/notFoundPage";
import {
  CategoryTilesType,
  FAQSectionType,
  RecommendedProductsType,
} from "@/dep_types/content/sections";

import buttonDataMapper from "../components/button";
import linkedButtonDataMapper from "../components/linkedButton";
import contentErrorDataMapper from "../error";
import sectionsDataMapper from "../sections";

type NotFoundPageDynamicContentType =
  | RecommendedProductsType
  | CategoryTilesType
  | FAQSectionType;

const notFound = (
  apiNotFoundPageData: APINotFoundPageDataType,
  error?: ErrorObjectType,
): NotFoundPageResponseType => ({
  data: {
    title: apiNotFoundPageData.attributes.title,
    description: apiNotFoundPageData.attributes.description,
    searchButton: buttonDataMapper(apiNotFoundPageData.attributes.searchButton),
    homeButton: linkedButtonDataMapper(
      apiNotFoundPageData.attributes.homeButton,
    ),
    sections: sectionsDataMapper(
      apiNotFoundPageData.attributes.sections,
    ) as NotFoundPageDynamicContentType[],
  },
  error: error ? contentErrorDataMapper(error) : null,
});

// eslint-disable-next-line import/no-default-export
export default notFound;
