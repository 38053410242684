import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { Image } from "cloudinary-react";
import React, { FC } from "react";

import { FooterInfoType } from "@/dep_types/content/partner";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import { RichText } from "@/dep_components/Commons/RichText";
import SiteLink from "@/dep_components/Commons/SiteLink";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

const PartnerFooterInfo: FC = () => {
  const { footerInfo, logoSize } = useWhitelabelContext();
  const styles = useMultiStyleConfig("Footer");

  const dpr = 3;

  const baseWidth = logoSize?.mobileWidth || 80;
  const smWidth = logoSize?.tabletWidth || 80;
  const lgWidth = logoSize?.desktopWidth || 100;

  const imageWidth = useBreakpointValue(
    {
      base: baseWidth,
      sm: smWidth,
      lg: lgWidth,
    },
    "base",
  );

  const imageHeight = useBreakpointValue(
    {
      base: 46,
      md: 54,
      xl: 62,
    },
    "base",
  );

  return (
    <>
      {footerInfo?.map((fInfo: FooterInfoType) => (
        <Flex
          flex={1}
          direction="column"
          maxWidth={{
            base: "100%",
            md: "40%",
          }}
          key={`footer_info_${fInfo.id}`}
          textAlign="left"
          mb={{
            base: 8,
            md: 0,
          }}
        >
          {fInfo.image && fInfo.image?.data?.attributes?.hash ? (
            <Box sx={styles.title}>
              <Image
                width={imageWidth}
                height={imageHeight}
                publicId={fInfo.image.data.attributes.hash}
                alt={fInfo.title || "Logo"}
                fetchFormat="auto"
                crop="mfit"
                dpr={dpr}
              />
            </Box>
          ) : (
            <Text sx={styles.title}>{fInfo.title}</Text>
          )}
          <RichText sx={styles.body} text={fInfo.body} />
          {fInfo.link && (
            <Box marginTop={15}>
              <SiteLink
                url={fInfo.link.url}
                opensInNewWindow={fInfo.link.opensInNewWindow}
                variant="footer"
              >
                {fInfo.link.text}
              </SiteLink>
            </Box>
          )}
          {fInfo.buttons && fInfo.buttons.length > 0 && (
            <Flex
              direction="row"
              justifyContent={{
                base: "space-between",
                md: "flex-start",
              }}
            >
              {fInfo.buttons.map((button, index) => (
                <Flex
                  key={`footer_info_buttons_${button.text}`}
                  mt={4}
                  mr={index + 1 < fInfo.buttons.length ? 4 : 0}
                  flexGrow={{
                    base: 1,
                    lg: 0,
                  }}
                  flexBasis={{
                    base: 0,
                    lg: 0,
                  }}
                  width={{
                    base: "100%",
                    lg: "200px",
                  }}
                  minWidth={{ lg: "200px" }}
                >
                  <LinkedCTAButton
                    icon={button.icon}
                    trackingInfo={button.trackingInfo}
                    opensInNewWindow={button.opensInNewWindow}
                    text={button.text}
                    url={button.url}
                    variant={button.variant}
                  />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      ))}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PartnerFooterInfo;
