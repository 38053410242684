import PartnerBorderBox from "./components/BorderBox";
import PartnerButton from "./components/Button";
import PartnerCheckoutLayout from "./components/CheckoutLayout";
import PartnerOrderNumber from "./components/OrderNumber";
import PartnerOrderStatusStep from "./components/OrderStatusStep";

const components = {
  PartnerButton,
  PartnerBorderBox,
  PartnerCheckoutLayout,
  PartnerOrderNumber,
  PartnerOrderStatusStep,
};

// eslint-disable-next-line import/no-default-export
export default components;
