/* eslint-disable */
import React from 'react';

const ChevronLeftIcon = () => {
  return (
    <svg viewBox="0 0 8 14" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="iPhone-11-Copy-5"
          transform="translate(-29.000000, -35.000000)"
          stroke="#B7B7B7"
          strokeWidth="2"
        >
          <polyline
            id="Path"
            transform="translate(33.000000, 42.000000) rotate(-270.000000) translate(-33.000000, -42.000000) "
            points="27 39 33 45 39 39"
          />
        </g>
      </g>
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default ChevronLeftIcon;
