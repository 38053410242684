import qs from "qs";

import { CmsClient } from "@/api/CmsClient";

type PartnerData = {
  hasMultipleBrands: boolean;
  globalLabels: {
    originalPriceLabel?: string;
  };
  product: {
    resalePrefix?: string;
    rentalPrefix?: string;
  };
};

export async function GetPartnerData(
  partnerName: string,
): Promise<PartnerData> {
  const query = qs.stringify({
    filters: {
      partner_id: {
        $eq: partnerName,
      },
    },
    populate: ["hasMultipleBrands", "globalLabels", "product"],
  });

  try {
    return await CmsClient()
      .get(`/partners?${query}`)
      .then(({ data }) => data?.data[0]?.attributes);
  } catch {
    throw Error(`Something went wrong getting partner data`);
  }
}
