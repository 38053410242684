const button = {
  variants: {
    hero: {
      cursor: "pointer",
      backgroundColor: "#ffffff",
      color: "black",
      fontFamily: "normal",
      fontWeight: "500",
      width: "100%",
      maxWidth: "300px",
      _hover: {
        backgroundColor: "#FF6553",
        color: "#ffffff",
      },
    },
    primary: {
      width: { base: "250px", sm: "300px" },
      backgroundColor: "#FF6553",
      color: "#ffffff",
    },
    modalPrimary: {
      px: "15px",
      backgroundColor: "#FF6553",
      color: "#ffffff",
      minWidth: 0,
    },
    tertiary: {
      backgroundColor: "#4e4d5e",
      color: "#ffffff",
    },
    light: {
      backgroundColor: "#ffffff",
      color: "#373642",
      border: "1px solid #B8BEC3",
    },
    modalTertiary: {
      px: "15px",
      backgroundColor: "#4e4d5e",
      color: "#ffffff",
      minWidth: 0,
    },
    modalTransparent: {
      width: "100%",
      background: "transparent",
      color: "black",
      _hover: {
        background: "gray.100",
      },
    },
    cartAndContinue: {
      px: "15px",
      backgroundColor: "#FF6553",
      color: "#ffffff",
      width: "100%",
    },
    reservation: {
      py: "35px",
      fontSize: { base: "18px", md: "20px" },
      backgroundColor: "secondary.600",
      width: "100%",
      _hover: {
        backgroundColor: "#307c69",
        _disabled: {
          backgroundColor: "#5dd2b5",
        },
      },
    },
    checkoutPlaceOrder: {
      maxWidth: "313px",
      py: 8,
      backgroundColor: "#28B799",
      borderRadius: "3px",
      width: "100%",
      textTransform: "none",
      letterSpacing: "-3%",
      fontSize: "16px",
      _hover: {
        backgroundColor: "#63c3af",
        _disabled: {
          backgroundColor: "#666666",
        },
      },
      _disabled: {
        backgroundColor: "#666666",
      },
    },
    discount: {
      py: "35px",
      fontSize: "20px",
      backgroundColor: "#FFF",
      width: "100%",
      color: "#3F3E4C",
      _hover: {
        backgroundColor: "lightgrey",
      },
    },
    selectSize: {
      minWidth: 0,
      p: "25px",
      backgroundColor: "#F18C78",
      _hover: {
        backgroundColor: "#b06658",
      },
    },
    sizeModalSave: {
      width: "100%",
      backgroundColor: "#4e4d5e",
      color: "#ffffff",
      _hover: { backgroundColor: "#5e5d6e" },
    },
    faqCheckStatus: {
      backgroundColor: "#FF6553",
      color: "#ffffff",
    },
    orderConfirmationDark: {
      backgroundColor: "#4e4d5e",
      color: "#ffffff",
    },
  },
  defaultProps: {
    // size: "md",
    variant: "primary",
  },
};

// eslint-disable-next-line import/no-default-export
export default button;
