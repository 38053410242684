import { Flex, FlexProps } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

interface Props extends FlexProps {
  children: ReactNode;
}

const ErrorAlert: FC<Props> = ({ children, ...other }: Props) => (
  <Flex
    direction="column"
    mb="10px"
    px={{ base: "10px", md: "24px" }}
    pb={{ base: 0, md: "3px" }}
    pt={{ base: "4px", md: "5px" }}
    fontSize={{ base: "10px", md: "13px" }}
    textAlign="center"
    borderRadius="5px"
    textTransform="uppercase"
    backgroundColor="#f1e3ff" // TODO: Style this as PILLS
    fontFamily="normal"
    fontWeight="light"
    {...other}
  >
    {children}
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default ErrorAlert;
