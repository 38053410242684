const modalContent = {
  parts: ["title", "bodyText"],
  baseStyle: {
    title: {
      fontSize: "24px",
      fontFamily: "normal",
      fontWeight: 500,
    },
    bodyText: {
      fontFamily: "normal",
      fontWeight: 100,
      letterSpacing: "0.8px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default modalContent;
