import Link from "next/link";
import { PropsWithChildren } from "react";

import { useModal } from "@/providers/ModalProvider";

type ModalLinkProps = {
  href: string;
};

export function ModalLink({
  href,
  children,
}: PropsWithChildren<ModalLinkProps>) {
  const { openModal } = useModal();

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // Get the modal query param from the href
    const url = new URL(href, window.location.origin);
    const params = new URLSearchParams(url.search);
    const modalValue = params.get("modal");

    !!modalValue && openModal(modalValue);
  };

  return (
    <Link href={href} passHref>
      <a onClick={handleLinkClick} tabIndex={0}>
        {children}
      </a>
    </Link>
  );
}
