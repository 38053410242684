/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";

import CheckoutStatusType from "../dep_components/Checkout/type/CheckoutStatusType";
import OrderLog from "../dep_helpers/order/OrderLog";
import ShoppingCartService from "../dep_services/shoppingCartService";
import { OrderDetailsType } from "../dep_types/OrderDetailsTypes";

export interface State {
  rentalOrder: OrderDetailsType | null;
  resaleOrder: OrderDetailsType | null;
  orderLog: OrderLog | null;
  checkoutStatus: CheckoutStatusType;
  confirmOrder(
    rentalOrder: OrderDetailsType,
    resaleOrder: OrderDetailsType,
    orderLog: OrderLog,
  ): void;
  clearConfirmedOrder(): void;
  setCheckoutStatus(status: any): void;
}

export const OrderConfirmationContext = React.createContext<State | undefined>(
  undefined,
);
type Props = {
  initialCheckoutStatus?: CheckoutStatusType;
  children: any;
};

export const OrderConfirmationProvider = ({
  initialCheckoutStatus,
  children,
}: Props): any => {
  const [rentalOrder, setRentalOrder] = useState<OrderDetailsType | null>(null);
  const [resaleOrder, setResaleOrder] = useState<OrderDetailsType | null>(null);
  const [orderLog, setOrderLog] = useState<OrderLog | null>(null);
  const [checkoutStatus, setCheckoutStatus] = useState<CheckoutStatusType>(
    initialCheckoutStatus || CheckoutStatusType.DRAFT,
  );

  const confirmOrder = (
    rto: OrderDetailsType,
    rso: OrderDetailsType,
    ol: OrderLog,
  ): void => {
    ShoppingCartService.renewCartId();
    setRentalOrder(rto);
    setResaleOrder(rso);
    setOrderLog(ol);
  };

  const clearConfirmedOrder = (): void => {
    setRentalOrder(null);
    setResaleOrder(null);
    setOrderLog(null);
  };

  const value = useMemo(
    () => ({
      rentalOrder,
      resaleOrder,
      orderLog,
      checkoutStatus,
      confirmOrder,
      clearConfirmedOrder,
      setCheckoutStatus,
    }),
    [rentalOrder, resaleOrder, orderLog, checkoutStatus],
  );

  return (
    <OrderConfirmationContext.Provider value={value}>
      {children}
    </OrderConfirmationContext.Provider>
  );
};

OrderConfirmationProvider.defaultProps = {
  initialCheckoutStatus: CheckoutStatusType.DRAFT,
};

const useOrderConfirmation = (): State => {
  const context = React.useContext(OrderConfirmationContext);

  if (!context) {
    throw new Error(
      "useOrderConfirmation must be used within a OrderConfirmationProvider",
    );
  }

  return context;
};

// eslint-disable-next-line import/no-default-export
export default useOrderConfirmation;
