export const topTextBanner = {
  parts: ["container", "link"],
  baseStyle: {
    container: {
      color: "white",
    },
    link: {
      fontWeight: "normal",
      _hover: {
        color: "white",
      },
    },
  },
};
