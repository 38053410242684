const progress = {
  variants: {
    "gear-finder": {
      track: {
        backgroundColor: "gray.200",
        height: "4px",
        borderRadius: "4px",
        width: {
          base: "69px",
          xl: "202px",
        },
      },
      filledTrack: {
        backgroundColor: "black",
        transition: ".25s ease width, .25s ease background-color",
      },
    },
    "gear-finder-completed": {
      track: {
        backgroundColor: "gray.200",
        height: "4px",
        borderRadius: "4px",
        width: {
          base: "69px",
          xl: "202px",
        },
      },
      filledTrack: {
        backgroundColor: "secondary.600",
        transition: ".25s ease width, .25s ease background-color",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default progress;
