import { Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ErrorMessageText: FC<Props> = ({ children }: Props) => (
  <Flex
    as="p"
    fontSize="14px"
    color="error"
    fontFamily="normal"
    fontWeight="bold"
  >
    {children}
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default ErrorMessageText;
