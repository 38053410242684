const brandPartnershipHero = {
  parts: ["section", "brands", "title", "subtitle", "button"],
  baseStyle: {
    section: {},
    brands: {},
    title: {
      fontFamily: "title",
      letterSpacing: "3px",
      fontWeight: "600",
      textTransform: "uppercase",
      color: "white",
    },
    subtitle: {
      fontFamily: "normal",
      fontSize: "18px",
      fontWeight: 600,
      color: "white",
    },
    button: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default brandPartnershipHero;
