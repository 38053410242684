// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, trackingId?: string) => {
  if (!trackingId) return;

  window.gtag("config", trackingId, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface GtagEvent {
  action: string;
  category?: string;
  label?: string;
  value?: number;
  send_to?: string;
  allow_custom_scripts?: boolean;
}

export const event = ({
  action,
  category,
  label,
  value,
  send_to,
  allow_custom_scripts,
}: GtagEvent) => {
  window.gtag("event", action, {
    allow_custom_scripts,
    event_category: category,
    event_label: label,
    value,
    send_to,
  });
};

declare global {
  interface Window {
    gtag: Gtag.Gtag;
  }
}
