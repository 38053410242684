import { Box } from "@chakra-ui/react";

import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import { DesktopHeader } from "./Desktop/DesktopHeader";
import { MobileHeader } from "./Mobile/MobileHeader";

import { CategoryType } from "../../dep_types";

interface HeaderProps {
  categoryGroups: Record<string, CategoryType[]>;
  navigationItems?: NavigationItem[];
}

export function Header({ categoryGroups, navigationItems }: HeaderProps) {
  return (
    <Box
      position="sticky"
      top="0"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.100"
      zIndex="docked"
    >
      <MobileHeader
        categoryGroups={categoryGroups}
        navigationItems={navigationItems}
      />
      <DesktopHeader
        categoryGroups={categoryGroups}
        navigationItems={navigationItems}
      />
    </Box>
  );
}
