import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

interface Props extends IconProps {
  direction: "right" | "left" | "up" | "down";
}

const ChevronIcon: FC<Props> = ({ direction, ...other }) => {
  const rotationAmounts = {
    right: "-90",
    left: "90",
    up: "180",
    down: "0",
  };

  const transformValue = `rotate(${rotationAmounts[direction]}deg)`;

  return (
    <Icon
      width="14px"
      height="8px"
      viewBox="0 0 14 8"
      fill="none"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform={transformValue}
      _groupHover={{ stroke: "gray.500" }}
      {...other}
    >
      <path d="M1 1L7 7L13 1" />
    </Icon>
  );
};

// eslint-disable-next-line import/no-default-export
export default ChevronIcon;
