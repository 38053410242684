import { environment } from "../../dep_constants";

let overrides;

const local = {};

const development = {};

const staging = {
  ONE_TRUST_SCRIPT_ID: "7f7457c2-30a3-4bc0-aeae-77229426cb54-test",
};

const production = {
  AMPLITUDE_KEY: "6db86b890e69a8e7d5d5734d64a28b2f",
  ONE_TRUST_SCRIPT_ID: "7f7457c2-30a3-4bc0-aeae-77229426cb54",
};

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

const configurations = {
  ...overrides,
  // TODO: Change it
  // DEFAULT_PRODUCT_IMAGE_ID: "ecomm-cms-prod/primary_black_b82c174443",
};

// eslint-disable-next-line import/no-default-export
export default configurations;
