const iconValueProp = {
  parts: ["container", "textContainer", "title", "description", "icon"],
  baseStyle: {
    container: {
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "left",
    },
    textContainer: {
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "left",
    },
    title: {
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: "18px",
      lineHeight: 1.57,
      letterSpacing: "-.03em",
      marginBottom: 1.5,
      maxWidth: "100%",
    },
    description: {
      fontFamily: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: 1.48,
      letterSpacing: "-.03em",
      maxWidth: "100%",
    },
    icon: {
      marginBottom: {
        base: 7,
        md: 10,
      },
      width: {
        base: "28px",
        md: "35px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default iconValueProp;
