import { Box, Flex, Skeleton } from "@chakra-ui/react";

import { usePartnerData } from "@/hooks/usePartnerData";

import { useProductCard } from "@/dep_components/Commons/Products/ProductCard//hooks/useProductCard";
import { ProductCardPrice } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardPrice";
import { ProductCardPricePrefix } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardPricePrefix";
import { getProductPriceRange } from "@/dep_components/Commons/Products/ProductCard/helpers/getProductPriceRange";

export function ProductCardPriceRange() {
  const { data, isLoading } = usePartnerData();
  const { availableVariants, product } = useProductCard();

  const resalePrices = getProductPriceRange(availableVariants);
  const { productSaleType, rentalInStock, resaleInStock } = product;

  const isRentalAndResale = productSaleType === "both";
  const isResale =
    (isRentalAndResale || productSaleType === "resale") &&
    resalePrices.length > 0 &&
    resaleInStock;
  const isRental =
    (isRentalAndResale || productSaleType === "rental") && rentalInStock;

  return (
    <Flex gap={12}>
      {isRental && (
        <Box>
          <Skeleton isLoaded={!isLoading}>
            <ProductCardPricePrefix>
              {data?.product?.rentalPrefix ?? "Rent from"}
            </ProductCardPricePrefix>
          </Skeleton>
          <ProductCardPrice>
            <Flex gap="0.5" alignItems="center">
              ${product.price[0]}
              <Box as="small" letterSpacing="0">
                /day
              </Box>
            </Flex>
          </ProductCardPrice>
        </Box>
      )}
      {isResale && (
        <Box>
          <Skeleton isLoaded={!isLoading}>
            <ProductCardPricePrefix>
              {data?.product?.resalePrefix ?? "Shop resale"}
            </ProductCardPricePrefix>
          </Skeleton>
          <ProductCardPrice>
            ${resalePrices[0]}{" "}
            {resalePrices.length > 1 &&
              `- $${resalePrices[resalePrices.length - 1]}`}
          </ProductCardPrice>
        </Box>
      )}
    </Flex>
  );
}
