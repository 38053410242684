const text = {
  parts: [
    "accordionTitle",
    "cartCheckoutExtraInfo",
    "cartPaymentTotalRowTitle",
    "checkoutFormSummarySectionTitle",
    "formHighlightTitle",
    "link",
    "menuCategory",
    "orderDetailsPrice",
    "orderDetailsOneLineLeftTextTotal",
    "orderDetailsOneLineLeftText",
    "orderDetailsTotalRow",
    "productOptionLabelParameterSelection",
    "shoppingCartPrice",
    "variantParametersGroupText",
    "primaryResidenceCheckboxText",
    "acceptTermsAndConditionsCheckboxText",
  ],
  baseStyle: {
    cartCheckoutExtraInfo: {
      letterSpacing: "0.8px",
      color: "gray.500",
    },
    cartCheckoutSubtotal: {},
    link: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    accordionTitle: {
      color: "gray.500",
      fontSize: "15px",
      fontWeight: "500",
      letterSpacing: "0.8px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    menuCategory: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "2px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    orderDetailsOneLineLeftTextTotal: {
      color: "gray.500",
    },
    orderDetailsOneLineLeftText: {
      color: "gray.500",
    },
    orderDetailsPrice: {
      color: "gray.500",
    },
    orderDetailsTotalRow: {
      color: "gray.500",
      fontSize: 18,
    },
    shoppingCartPrice: {},
    checkoutFormSummarySectionTitle: {
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    formHighlightTitle: {
      letterSpacing: "0.8px",
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
    },
    cartPaymentTotalRowTitle: {
      letterSpacing: "0.8px",
      fontWeight: 300,
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
    },
    productOptionLabelParameterSelection: {
      letterSpacing: "0.8px",
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
    },
    variantParametersGroupText: {
      letterSpacing: "0.8px",
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
    },
    sortByBoxTitle: {
      color: "gray.400",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    primaryResidenceCheckboxText: {
      color: "gray.500",
    },
    acceptTermsAndConditionsCheckboxText: {
      color: "gray.500",
    },
    filterTitle: {
      textTransform: "uppercase",
    },
    sortBox: {
      textTransform: "uppercase",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default text;
