const priceContainer = {
  parts: ["originalLabel", "label", "price"],
  baseStyle: {
    originalLabel: {
      color: "primary.600",
    },
    label: {},
    price: {
      fontWeight: "bold",
      fontFamily: "title",
      textTransform: "uppercase",
      letterSpacing: "0.08px",
      color: "primary.600",
      fontSize: "14px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default priceContainer;
