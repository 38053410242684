import HttpRequest, { HttpRequestType } from "./HttpRequest";
import HttpResponse from "./HttpResponse";

// eslint-disable-next-line import/no-default-export
export default class PostRequest<T> extends HttpRequest<T> {
  constructor(partnerId: string) {
    super(HttpRequestType.POST, partnerId);
  }

  execute = async (endpoint: string, body?: T): Promise<HttpResponse> =>
    this.executeEndpoint(endpoint, { query: undefined, body });
}
