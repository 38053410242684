import { ModalContent } from "@/hooks/useModalManager";

import { RichText } from "@/dep_components/Commons/RichText";

type GlobalModalProps = {
  readonly matchingModal: ModalContent;
};

export function GlobalModal({ matchingModal }: GlobalModalProps) {
  return (
    <>
      {matchingModal?.component ? (
        <matchingModal.component />
      ) : (
        <RichText text={matchingModal?.content ?? ""} />
      )}
    </>
  );
}
