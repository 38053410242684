const button = {
  // The styles all button have in common
  baseStyle: {},
  variants: {
    newPrimary: {
      py: "25px",
      px: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      maxWidth: "100%",
      height: "auto",
      padding: "3.5",
      backgroundColor: "black",
      color: "white",
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: "16px",
      lineHeight: "1.48",
      letterSpacing: "-0.03em",
      textTransform: "none",
      _hover: {
        backgroundColor: "gray.500",
        _disabled: {
          backgroundColor: "gray.100",
        },
      },
      _disabled: {
        backgroundColor: "gray.100",
        color: "gray.300",
      },
    },
    newSecondary: {
      py: "25px",
      px: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: "100%",
      height: "auto",
      padding: "3.5",
      backgroundColor: "white",
      color: "black",
      border: "1px solid",
      borderColor: "gray.200",
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: "16px",
      lineHeight: "1.48",
      letterSpacing: "-0.03em",
      textTransform: "none",
      transition: "background-color .25s ease",
      _hover: {
        backgroundColor: "gray.200",
      },
    },
    secondaryLight: {
      py: "25px",
      px: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      maxWidth: "100%",
      height: "auto",
      padding: "3.5",
      backgroundColor: "white",
      color: "black",
      border: "1px solid",
      borderColor: "gray.500",
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: "16px",
      letterSpacing: "-0.03em",
      textTransform: "none",
      _hover: {
        backgroundColor: "white",
        color: "black",
      },
    },
    secondaryDark: {
      py: "25px",
      px: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: "100%",
      height: "auto",
      padding: "3.5",
      backgroundColor: "transparent",
      color: "gray.200",
      border: "1px solid",
      borderColor: "gray.300",
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: "16px",
      letterSpacing: "-0.03em",
      textTransform: "none",
      _hover: {
        backgroundColor: "gray.50",
        color: "black",
      },
    },
    orderConfirmationDark: {
      py: "25px",
      px: "18px",

      fontFamily: "normal",
      fontWeight: "bold",
      color: "#FFFFFF",
      width: "345px",
      height: "72px",
      textTransform: "uppercase",
    },
    orderConfirmationLight: {
      py: "25px",
      px: "18px",
      backgroundColor: "#ffffff",
      color: "#373642",
      border: "1px solid #B8BEC3",
      width: "345px",
      height: "72px",
      fontSize: "md",
      fontFamily: "normal",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    iconButton: {
      py: "25px",
      px: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: "20px",
      height: "20px",
      backgroundColor: "transparent",
      color: "white",
      border: 0,
      fontFamily: "normal",
      fontWeight: "400",
      fontSize: "22px",
      textTransform: "none",
    },
    socialIcon: {
      py: "25px",
      px: "18px",
      textTransform: "uppercase",
      fontFamily: "normal",
      fontWeight: "bold",
      color: "#FFFFFF",
      _active: {
        background: "transparent",
      },
      background: "transparent",
      padding: 2,
      width: "auto",
    },
    modalPrimary: {
      width: "full",
    },
    modalTertiary: {
      width: "full",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default button;
