import { Text } from "@chakra-ui/react";
import React, { FC } from "react";

import { LinkedButtonType } from "@/dep_types/content/components";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import TrackingService from "../../../dep_services/tracking/trackingService";

const BackToSiteButton: FC<LinkedButtonType> = ({
  url,
  text,
  trackingInfo,
  opensInNewWindow,
}) => {
  const { isYeti } = useWhitelabelContext();

  return (
    <Text
      as="a"
      size="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      fontWeight="medium"
      paddingY={7}
      href={url}
      letterSpacing={isYeti ? "0.03px" : "auto"}
      onClick={() =>
        TrackingService.trackButtonClick(
          trackingInfo?.name || "backtositebutton",
          trackingInfo?.location || "Header",
        )
      }
      target={opensInNewWindow ? "_blank" : "_self"}
    >
      {text}
    </Text>
  );
};

// eslint-disable-next-line import/no-default-export
export default BackToSiteButton;
