import { useRouter } from "next/router";

import { VariantType } from "@/dep_types";

import { getArrayFromQueryParam } from "../helpers/getArrayFromQueryParam";

export function useGetAvailableVariants(variants?: VariantType[]) {
  const { query } = useRouter();

  const conditions = getArrayFromQueryParam(query.conditions);

  if (!variants) return [];
  if (!conditions) return variants;

  /**
   * Filter the variants based on the conditions, return if
   * the variants have conditions that match the query.
   */
  return variants.filter((variant) => {
    let hasMatch = false;

    if (!variant?.conditions) return false;

    conditions.forEach((condition) => {
      const key = condition.toLowerCase();
      const keyArray = `${key}Array`;

      if (
        // @ts-expect-error - TS doesn't know that the key is a valid condition
        variant?.conditions?.[key] > 0 &&
        // @ts-expect-error - TS doesn't know that the key is a valid condition
        variant?.conditions?.[keyArray]?.some((value: number) => value > 0)
      ) {
        hasMatch = true;
      }
    });

    return hasMatch;
  });
}
