import format from "date-fns/format";

import DatesHelper from "@/dep_helpers/DatesHelper";
import getPageCategory from "@/dep_helpers/tracking/getPageCategory";
import AwinTrackingStrategy from "@/dep_services/tracking/stratagies/awinTrackingStrategy";
import RockerboxTrackingStrategy from "@/dep_services/tracking/stratagies/rockerboxTrackingStrategy";
import TikTokTrackingStrategy from "@/dep_services/tracking/stratagies/tikTokTrackingStrategy";

import GTagTrackingStrategy from "./stratagies/GTagTrackingStrategy";
import AmplitudeTrackingStrategy from "./stratagies/amplitudeTrackingStrategy";
import { CriteoTrackingStrategy } from "./stratagies/criteoTrackingStrategy";
import FacebookPixelTrackingStrategy from "./stratagies/facebookPixelTrackingStrategy";

import OrderLog from "../../dep_helpers/order/OrderLog";
import {
  ProductType,
  RentalDatesType,
  SearchProductType,
} from "../../dep_types";
import { CartItemType } from "../../dep_types/CartTypes";
import EventLocationType from "../../dep_types/EventLocationType";

const GENERIC_EVENTS = {
  CLICKED_BUTTON: "clicked CTA",
};

// eslint-disable-next-line import/no-default-export
export default class TrackingService {
  private static strategies = [
    new AmplitudeTrackingStrategy(),
    new AwinTrackingStrategy(),
    new FacebookPixelTrackingStrategy(),
    new GTagTrackingStrategy(),
    new RockerboxTrackingStrategy(),
    new TikTokTrackingStrategy(),
    new CriteoTrackingStrategy(),
  ];

  static trackPageView = async (): Promise<void> => {
    const pageCategory = getPageCategory();

    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackPageView(pageCategory);
      });
  };

  static trackScrollDepth = async (scrollDepth: number): Promise<void> => {
    const pageCategory = getPageCategory();
    const url = window.location.href;

    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackScrollDepth(scrollDepth, pageCategory, url);
      });
  };

  static trackProductPageView = async (product: ProductType): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackProductPageView(product);
      });
  };

  static trackProductOutOfStock = async (
    product: ProductType,
    siteRentalDates: RentalDatesType,
  ): Promise<void> => {
    const url = window.location.href;

    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackProductOutOfStock(product, siteRentalDates, url);
      });
  };

  static trackProductClick = async (
    location: EventLocationType,
    product: ProductType | SearchProductType,
    siteRentalDates: RentalDatesType,
    fromUrl: string,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackProductClick(location, product, siteRentalDates, fromUrl);
      });
  };

  static trackCalendarOpened = async (
    location: EventLocationType,
    products: ProductType[],
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        const productNames = products.map((product) => product.name);
        const categoryNames = products.map((product) => product.category);

        s.trackCalendarOpened(location, productNames, categoryNames);
      });
  };

  static trackCalendarDatesConfirmed = async (
    location: EventLocationType,
    products: ProductType[],
    rentalDates: RentalDatesType,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        const productNames = products.map((product) => product.name);
        const categoryNames = products.map((product) => product.category);
        const formattedDates =
          DatesHelper.parseGlobalRentalDatesToOrderAmplitudeLogDates(
            rentalDates,
          );
        const startDay = format(rentalDates.startDate as Date, "EEEE");
        const startMonth = format(rentalDates.startDate as Date, "MMMM");
        const endMonth = format(rentalDates.endDate as Date, "MMMM");

        const months =
          startMonth === endMonth ? [startMonth] : [startMonth, endMonth];

        const tripDuration =
          DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
            rentalDates.startDate as Date,
            rentalDates.endDate as Date,
          );

        s.trackCalendarDatesConfirmed(
          location,
          productNames,
          categoryNames,
          String(formattedDates.startDate),
          String(formattedDates.endDate),
          tripDuration,
          months,
          startDay,
        );
      });
  };

  static trackCalendarDatesCleared = async (
    location: EventLocationType,
    products: ProductType[],
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        const productNames = products.map((product) => product.name);
        const categoryNames = products.map((product) => product.category);

        s.trackCalendarDatesCleared(location, productNames, categoryNames);
      });
  };

  static trackNavigationClick = async (
    menuItem: string,
    primaryCategory: string,
    secondaryCategory: string,
  ): Promise<void> => {
    const pageCategory = getPageCategory();
    const url = window.location.href;

    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackNavigationClick(
          menuItem,
          primaryCategory,
          secondaryCategory,
          pageCategory,
          url,
        );
      });
  };

  static trackCollectionPageView = async (
    category: string,
    collections: string,
    saleTypes: string[],
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackCollectionPageView(category, collections, saleTypes);
      });
  };

  static trackAddToCart = async (newCartItem: CartItemType): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackAddToCart(newCartItem);
      });
  };

  static trackStartCheckout = async (
    cartItems: CartItemType[],
    cartTotalPrice: number,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackStartCheckout(cartItems, cartTotalPrice);
      });
  };

  static trackConversion = async (
    partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackConversion(partnerId, orderLog, rentalOrderId, resaleOrderId);
      });
  };

  static trackOrderCancelled = async (
    orderId: string,
    value: number,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackOrderCancelled(orderId, value);
      });
  };

  static trackOrderEdited = async (
    orderId: string,
    editing: string,
    valueChange?: string,
    totalDifference?: number,
    newTotal?: number,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackOrderEdited(
          orderId,
          editing,
          valueChange,
          totalDifference,
          newTotal,
        );
      });
  };

  static trackButtonClick = async (
    buttonName: string,
    buttonLocation: string,
  ): Promise<void> => {
    const pageCategory = getPageCategory();
    const url = window.location.href;

    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackEvent(GENERIC_EVENTS.CLICKED_BUTTON, {
          buttonName,
          buttonLocation,
          pageCategory,
          url,
        });
      });
  };

  static trackEvent = async (
    eventName: string,
    data?: object,
  ): Promise<void> => {
    TrackingService.strategies
      .filter((s) => s.initialized())
      .forEach((s) => {
        s.trackEvent(eventName, data);
      });
  };
}
