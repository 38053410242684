import { PropsWithChildren } from "react";

import { QueryProvider } from "@/providers/QueryProvider";

export function WhitelabelProvider({
  children,
  ...rest
}: PropsWithChildren<unknown>) {
  return <QueryProvider {...rest}>{children}</QueryProvider>;
}
