/* eslint-disable @typescript-eslint/no-explicit-any */
import ArriveConfigs from "./arrive/configs";
import ArriveKeys from "./arrive/keys";
import BlanqiConfigs from "./blanqi/configs";
import BlanqiKeys from "./blanqi/keys";
import BurtonConfigs from "./burton/configs";
import BurtonKeys from "./burton/keys";
import CitizenryConfigs from "./citizenry/configs";
import CitizenryKeys from "./citizenry/keys";
import { commonKeys } from "./commonKeys";
import YetiConfigs from "./yeti/configs";
import YetiKeys from "./yeti/keys";

import partnerIds from "../dep_constants/partnerIds";

const resolveConfigurations = (themePartnerId: string): any => {
  const partnerId = themePartnerId;

  let configs: any;

  if (partnerId === partnerIds.ARRIVE_OUTDOORS) {
    configs = {
      isAO: true,
      config: ArriveConfigs,
      keys: { ...commonKeys, ...ArriveKeys },
    };
  } else if (partnerId === partnerIds.BURTON) {
    configs = {
      isBurton: true,
      config: BurtonConfigs,
      keys: { ...commonKeys, ...BurtonKeys },
    };
  } else if (partnerId === partnerIds.CITIZENRY) {
    configs = {
      isCitizenry: true,
      config: CitizenryConfigs,
      keys: { ...commonKeys, ...CitizenryKeys },
    };
  } else if (partnerId === partnerIds.YETI) {
    configs = {
      isYeti: true,
      config: YetiConfigs,
      keys: { ...commonKeys, ...YetiKeys },
    };
  } else if (partnerId === partnerIds.BLANQI) {
    configs = {
      isBlanqi: true,
      config: BlanqiConfigs,
      keys: { ...commonKeys, ...BlanqiKeys },
    };
  } else {
    configs = {
      isAO: true,
      config: ArriveConfigs,
    };
  }

  return configs;
};

// eslint-disable-next-line import/no-default-export
export default resolveConfigurations;
