import { environment } from "../../dep_constants";

let overrides;

const local = {
  AMPLITUDE_KEY: "e9f8b6b08f1f8b9a4ae93d6058c518d3",
  GOOGLE_FLOODLIGHT_ID: "13515713",
};

const development = {
  AMPLITUDE_KEY: "e9f8b6b08f1f8b9a4ae93d6058c518d3",
  GOOGLE_FLOODLIGHT_ID: "13515713",
};

const staging = {
  AMPLITUDE_KEY: "e9f8b6b08f1f8b9a4ae93d6058c518d3",
  ONE_TRUST_SCRIPT_ID: "bab286f0-3b68-4eb8-8298-5493200413f7-test",
  GOOGLE_FLOODLIGHT_ID: "13515713",
};

const production = {
  AMPLITUDE_KEY: "dd619f5268e0b1fbd9444d72fe85c29e",
  ONE_TRUST_SCRIPT_ID: "bab286f0-3b68-4eb8-8298-5493200413f7",
  GOOGLE_FLOODLIGHT_ID: "13515713",
};

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

const configurations = {
  ...overrides,
  DEFAULT_PRODUCT_IMAGE_ID: "ecomm-cms-prod/primary_black_b82c174443",
};

// eslint-disable-next-line import/no-default-export
export default configurations;
