const dropDownList = {
  parts: ["label", "select"],
  baseStyle: {
    label: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      color: "primary.600",
    },
    select: { textTransform: "uppercase" },
  },
};

// eslint-disable-next-line import/no-default-export
export default dropDownList;
