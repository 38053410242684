/* eslint-disable class-methods-use-this */
import TrackingStrategy from "./trackingStrategy";

type TikTokType = {
  page: () => void;
};

declare const ttq: TikTokType;
// eslint-disable-next-line import/no-default-export
export default class TikTokTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    typeof window !== "undefined" && typeof ttq !== "undefined" && Boolean(ttq);

  trackAddToCart(): void {
    // do not track
  }

  trackConversion(): void {
    // do not track
  }

  trackEvent(): void {
    // do not track
  }

  trackPageView(): void {
    ttq.page();
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackProductPageView(): void {
    // do not track
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackStartCheckout(): void {
    // do not track
  }

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }
}
