const siteLink = {
  baseStyle: {
    fontFamily: "normal",
    color: "primary.600",
    textDecoration: "underline",
    cursor: "pointer",
    transition: "filter .15s ease",
    _hover: { filter: "brightness(1.5)" },
  },
  variants: {
    footer: {
      fontSize: "12px",
      color: "white",
      _hover: { color: "white" },
    },
    cookie: {
      color: "secondary.600",
      _hover: { color: "secondary.50" },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default siteLink;
