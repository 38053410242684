/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Text, useMultiStyleConfig } from "@chakra-ui/react";
import Link from "next/link";
import { FC, ReactNode } from "react";

import { Icon } from "@/revive/components/Icon";

import LinkButton from "@/dep_components/Commons/Buttons/LinkButton";
import AlertPill from "@/dep_components/Commons/Pills/AlertPill/AlertPill";
import InfoPopover from "@/dep_components/Commons/Tooltip/InfoPopover";
import ConditionalWrapper from "@/dep_components/Layout/ConditionalWrapper";
import NewQuantitySelector from "@/dep_components/ShoppingCart/CartItems/Edit/NewQuantitySelector";
import CartItemHelper from "@/dep_components/ShoppingCart/CartItems/Helpers/CartItemHelper";
import PriceBreakdownSection from "@/dep_components/ShoppingCart/CartItems/PriceBreakdown/PriceBreakdownSection";
import UnavailablePill from "@/dep_components/ShoppingCart/UnavailablePill/UnavailablePill";

import { useFeatureFlags } from "@/dep_hooks/cms/useFeatureFlags";

import CartEditVariantButton from "./Edit/CartEditVariantButton";
import ProductThumbnail from "./ProductThumbnail";
import SetChildrenSection from "./SetChildrenSection";
import SetPill from "./SetPill";

import Utils from "../../../dep_helpers/Utils";
import useCartItemEditHook from "../../../dep_hooks/useCartItemEditHook";
import useCartItemEditQuantity from "../../../dep_hooks/useCartItemEditQuantity";
import {
  DropDownListItemType,
  RentalDatesType,
  SetProductType,
} from "../../../dep_types";
import {
  CartProductItemType,
  CartSetItemType,
} from "../../../dep_types/CartTypes";
import { VariantSelectOptionType } from "../../../dep_types/ProductTypes";
import DropDownList from "../../Commons/FormFilterElements/DropDownList";
import { useWhitelabelContext } from "../../whitelabelContext";

type Props = {
  cartItem: CartProductItemType | CartSetItemType;
  removeFromCart?(): void;
  linkToProductPage?: boolean;
  shoppingCartRentalDates?: RentalDatesType;
  changeCartSimpleProductVariant?(
    cartItem: CartProductItemType,
    selectedOption: VariantSelectOptionType,
  ): void;
  hideBorderBottom?: boolean;
  variant?: "large";
  isReadonly?: boolean;
};

const CartItem: FC<Props> = ({
  cartItem,
  removeFromCart,
  linkToProductPage = false,
  shoppingCartRentalDates,
  changeCartSimpleProductVariant,
  hideBorderBottom,
  variant,
  isReadonly,
}: Props) => {
  const styles = useMultiStyleConfig("CartItem", {
    variant: cartItem.unavailable ? "unavailable" : undefined,
  });

  const textStyles = useMultiStyleConfig("Text", {});

  const { hasMultipleBrands } = useWhitelabelContext();

  const { isSet } = cartItem;
  const { product } = cartItem;

  const {
    addQuantity,
    reduceQuantity,
    showMaxReachedAlert,
    setShowMaxReachedAlert,
  } = useCartItemEditQuantity();

  const { data: featureFlags } = useFeatureFlags();

  const {
    selectedVariant,
    changeSimpleProductVariant,
    itemVariantEditRef,
    editSimpleProductVariant,
    simpleProductAvailableVariantsForEdit,
    handleEditSimpleProductVariant,
    showSimpleProductEditButtonAlert,
    setShowSimpleProductEditButtonAlert,
  } = useCartItemEditHook(
    cartItem as CartProductItemType,
    shoppingCartRentalDates,
    changeCartSimpleProductVariant,
  );

  const priceBreakdownItems = CartItemHelper.getPriceBreakdownItems(
    cartItem as CartProductItemType,
  );

  const totalPrice = Utils.toCurrency(cartItem.totalPrice);
  const priceDecrementingTotalPrice = cartItem?.priceDecrementingTotalPrice
    ? Utils.toCurrency(cartItem.priceDecrementingTotalPrice)
    : totalPrice;

  const cartPrice = featureFlags?.enablePriceDecrementing
    ? priceDecrementingTotalPrice
    : totalPrice;

  return (
    <Flex
      direction="column"
      pt="20px"
      pb="20px"
      borderBottom={!hideBorderBottom ? "4px solid" : ""}
      borderColor="#f5f5f5"
      w="100%"
    >
      <Flex direction="row">
        <Box
          mr="10px"
          width={{
            base: "80px",
            md: variant === "large" ? 200 : 100,
          }}
          height={{
            base: "80px",
            md: variant === "large" ? 200 : 100,
          }}
        >
          <ProductThumbnail
            product={product}
            width={variant === "large" ? 200 : 100}
            height={variant === "large" ? 200 : 100}
            isUnavailable={cartItem.unavailable}
            variantImageCloudinaryPublicId={
              (cartItem as CartProductItemType).variantImageCloudinaryPublicId
            }
          />
        </Box>
        <Flex flex={1} direction="column">
          <Flex flex={1} direction="row">
            <Flex flex={1} direction="column">
              <Flex
                direction="row"
                fontSize={{
                  base: "13px",
                  md: "14px",
                }}
                fontFamily="normal"
                fontWeight="medium"
              >
                {hasMultipleBrands && (
                  <Box color="black" mr="8px" sx={styles.brandName}>
                    {product.brand.name}
                  </Box>
                )}
                {cartItem?.priceChanged?.changed && !cartItem.unavailable && (
                  <>
                    <AlertPill text="Price Changed" />
                    <InfoPopover label="Prices may vary based on availability of that product in that condition." />
                  </>
                )}
                {cartItem.unavailable && (
                  <UnavailablePill
                    stockRequested={cartItem.quantity}
                    stockAvailable={cartItem.stockMaxAvailable}
                  />
                )}
                {isSet && <SetPill />}
              </Flex>
              <Box
                minHeight={{
                  base: "40px",
                  md: "0",
                }}
                fontSize={{
                  base: "16px",
                  md: "16px",
                }}
                fontFamily="normal"
                fontWeight="bold"
                mb={{
                  base: "2px",
                  md: "0",
                }}
              >
                <ConditionalWrapper
                  hasWrapper={linkToProductPage}
                  wrapper={(wrapperChildren: ReactNode) => (
                    <Link
                      href={`/products/${product.slug}`}
                      passHref
                      prefetch={false}
                    >
                      <Box
                        as="a"
                        cursor="pointer"
                        outline="none !important"
                        textAlign="left"
                        transition="color .15s ease"
                        _hover={{
                          color: "gray.400",
                        }}
                        _focus={{ outline: "none !important" }}
                        sx={styles.productName}
                      >
                        {wrapperChildren}
                      </Box>
                    </Link>
                  )}
                >
                  {cartItem.productVariantDisplayName || product.name}
                </ConditionalWrapper>
              </Box>
            </Flex>
            <Flex
              display={{
                base: "block",
                md: "none",
              }}
              direction="column"
              alignSelf="flex-start"
              whiteSpace="nowrap"
              sx={styles.priceContainer}
            >
              <Text>${cartPrice}</Text>
            </Flex>
            {removeFromCart && (
              <Flex
                width="16px"
                display={{
                  base: "none",
                  md: "block",
                }}
                onClick={removeFromCart}
                justifyContent="flex-start"
                alignItems="flex-start"
                _hover={{ cursor: "pointer" }}
              >
                <Box width="100%" color="gray.400">
                  <Icon name="x" size="sm" />
                </Box>
              </Flex>
            )}
          </Flex>
          <Flex
            display={{
              base: "none",
              md: "block",
            }}
            direction="column"
            alignSelf="flex-end"
            whiteSpace="nowrap"
            sx={styles.priceContainer}
          >
            <Text sx={textStyles.shoppingCartPrice}>${cartPrice}</Text>
          </Flex>
          <Flex flex={1} direction="row">
            <Flex
              direction={{
                base: "column",
                md: "row",
              }}
            >
              <NewQuantitySelector
                isSet={isSet}
                addQuantity={addQuantity}
                reduceQuantity={reduceQuantity}
                isReadonly={isReadonly}
                cartItem={cartItem as CartProductItemType}
                showMaxReachedAlert={showMaxReachedAlert}
                setShowMaxReachedAlert={setShowMaxReachedAlert}
              />
              {!isSet && (
                <>
                  <Flex
                    direction="column"
                    pl={{
                      base: 0,
                      md: 5,
                    }}
                    fontSize={{ base: "14px" }}
                    fontFamily="normal"
                    fontWeight="normal"
                  >
                    <Box as="span" mt="6px" sx={styles.variantText}>
                      {(cartItem as CartProductItemType).variantParamsText}{" "}
                    </Box>
                    <Flex as="span" sx={styles.variantText}>
                      {!(cartItem as CartProductItemType).isResale && (
                        <CartEditVariantButton
                          editVariant={editSimpleProductVariant}
                          showEditButtonAlert={showSimpleProductEditButtonAlert}
                          isReadonly={isReadonly}
                          setShowEditButtonAlert={
                            setShowSimpleProductEditButtonAlert
                          }
                          handleEditVariant={handleEditSimpleProductVariant}
                        />
                      )}
                    </Flex>
                  </Flex>
                </>
              )}
              {removeFromCart && (
                <Flex
                  display={{
                    base: "block",
                    md: "none",
                  }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Box width="100%">
                    <LinkButton text="Remove" onClick={removeFromCart} />
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {priceBreakdownItems && priceBreakdownItems.length > 1 && (
        <PriceBreakdownSection
          priceTiers={priceBreakdownItems}
          cartItem={cartItem as CartProductItemType}
        />
      )}
      {!isSet && editSimpleProductVariant && (
        <Flex justifyContent="flex-end" paddingTop={1}>
          {simpleProductAvailableVariantsForEdit && (
            <Box ref={itemVariantEditRef as any}>
              <DropDownList
                id="variant"
                placeholder="- Select a new variant -"
                selected={selectedVariant}
                items={
                  simpleProductAvailableVariantsForEdit as DropDownListItemType[]
                }
                onChange={(event) => {
                  changeSimpleProductVariant(parseInt(event.target.value, 10));
                }}
              />
            </Box>
          )}
        </Flex>
      )}
      {isSet && (
        <SetChildrenSection
          product={product as SetProductType}
          cartSetGroups={(cartItem as CartSetItemType).cartSetGroups}
          cartItem={cartItem as CartSetItemType}
          isReadonly={isReadonly}
        />
      )}
    </Flex>
  );
};

CartItem.defaultProps = {
  removeFromCart: undefined,
  linkToProductPage: undefined,
  shoppingCartRentalDates: undefined,
  changeCartSimpleProductVariant: undefined,
  hideBorderBottom: undefined,
  variant: undefined,
  isReadonly: false,
};

// eslint-disable-next-line import/no-default-export
export default CartItem;
