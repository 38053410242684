const text = {
  parts: [
    "cartCheckoutExtraInfo",
    "link",
    "accordionTitle",
    "menuCategory",
    "shoppingCartPrice",
    "checkoutFormSummarySectionTitle",
    "variantParametersGroupText",
    "productOptionLabelParameterSelection",
    "cartCheckoutSubtotal",
    "pdpConditionSelector",
  ],
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "normal",
    letterSpacing: "0.4px",
    link: {
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    cartCheckoutSubtotal: {
      fontWeight: 900,
      fontSize: "26px",
      fontFamily: "title",
    },
    variantParametersGroupText: {
      fontWeight: "medium",
      fontSize: "14px",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    cartCheckoutExtraInfo: {},
    accordionTitle: {},
    menuCategory: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    shoppingCartPrice: {
      fontFamily: "normal",
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    checkoutFormSummarySectionTitle: {
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontSize: "16px",
    },
    productOptionLabelParameterSelection: {
      fontSize: "14px",
    },
    pdpConditionSelector: {
      fontWeight: "medium",
      fontSize: "16px",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
  },
  variants: {
    orderDetailsItem: {
      fontSize: "xs",
      letterSpacing: "0.4px",
    },
    formHighlightTitle: {
      fontSize: "16px",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    formInfoSubtitle: {
      fontSize: "14px",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default text;
