import ShoppingCartService from "@/dep_services/shoppingCartService";
import { RentalDatesType } from "@/dep_types";
import { CartItemType } from "@/dep_types/CartTypes";

import { State } from "@/dep_components/context";

// eslint-disable-next-line import/no-default-export
export default class DatesContextService {
  static setShoppingCartRentalDates(
    state: State,
    shoppingCartRentalDates: RentalDatesType,
    dispatchSetShoppingCartRentalDates: (
      newShoppingCartRentalDates: RentalDatesType,
    ) => void,
    dispatchUpdateCartItems: (updateCartItems: CartItemType[]) => void,
  ): void {
    dispatchSetShoppingCartRentalDates(shoppingCartRentalDates);

    const newItems = ShoppingCartService.updateRentalDates(
      shoppingCartRentalDates,
      state.cartItems,
    );

    dispatchUpdateCartItems(newItems);
  }
}
