const input = {
  baseStyle: {
    tab: {
      textTransform: "uppercase",
      fontFamily: "normal",
      fontWeight: "normal",
      _hover: {
        backgroundColor: "#ececec",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
  },
  variants: {
    line: {
      px: "30px",
      tab: {
        _selected: {
          color: "black",
          borderBottom: "2px solid",
          borderBottomColor: "#72B2DE",
          fontFamily: "normal",
          fontWeight: "bold",
        },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default input;
