const singleLineDateRangeBox = {
  parts: ["container"],
  baseStyle: {
    container: {
      backgroundColor: "gray.100",
      transition: "filter .15s ease",
      _hover: {
        cursor: "pointer",
        filter: "brightness(95%)",
      },
      fontSize: "14px",
    },
  },
  variants: {
    noBackground: {
      container: {
        backgroundColor: "transparent",
        _hover: {
          cursor: "pointer",
          backgroundColor: "transparent",
        },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default singleLineDateRangeBox;
