const faqContactUs = {
  parts: ["title", "body"],
  baseStyle: {
    title: {
      fontFamily: "title",
      letterSpacing: "3px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "primary.600",
    },
    body: {
      fontFamily: "normal",
      fontSize: "16px",
      fontWeight: "normal",
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqContactUs;
