/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";

import { OrderDetailsType } from "../dep_types/OrderDetailsTypes";

export interface State {
  order: OrderDetailsType | null;
  setOrder: (details: OrderDetailsType) => void;
}

export const OrderEditContext = React.createContext<State | undefined>(
  undefined,
);
type Props = {
  children: any;
};

export const OrderEditProvider = ({ children }: Props): any => {
  const [order, setOrder] = useState<OrderDetailsType | null>(null);

  const value = useMemo(
    () => ({
      order,
      setOrder,
    }),
    [order],
  );

  return (
    <OrderEditContext.Provider value={value}>
      {children}
    </OrderEditContext.Provider>
  );
};

// TODO: Review this hook, it's rendering lot of times.
const useOrderEdit = (): State => {
  const context = React.useContext(OrderEditContext);

  if (!context) {
    throw new Error("useOrderEdit must be used within a OrderEditProvider");
  }

  return context;
};

// eslint-disable-next-line import/no-default-export
export default useOrderEdit;
