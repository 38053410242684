import { SystemStyleObject } from "@chakra-ui/react";
import { FC } from "react";

import { CTAButton } from "./CTAButton";

type Props = {
  onClick(): void;
  text: string;
  variant?: string;
  disabled?: boolean;
  sx?: SystemStyleObject;
};

const ModalSmallActionButton: FC<Props> = ({
  onClick,
  text,
  variant,
  disabled,
  sx,
}: Props) => (
  <CTAButton onClick={onClick} variant={variant} isDisabled={disabled} sx={sx}>
    {text}
  </CTAButton>
);

ModalSmallActionButton.defaultProps = {
  variant: undefined,
  disabled: undefined,
  sx: undefined,
};

// eslint-disable-next-line import/no-default-export
export default ModalSmallActionButton;
