const localStorageKeys = {
  COOKIE_BANNER_ID: "cookie_banner_accepted",
  SITEWIDE_RENTAL_DATES: "sitewide_rental_dates",
  SHOPPING_CART_DATES: "shopping_cart_dates",
  SHOPPING_CART_ITEMS: "shopping_cart_items",
  CART_ID: "cart_id",
  IMPACT_CLICK_ID: "impact_id",
  ANONYMOUS_USER_ID: "anonymous_user_id",
};

// eslint-disable-next-line import/no-default-export
export default localStorageKeys;
