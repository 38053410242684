import { Flex, keyframes, useMultiStyleConfig } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { PropsWithChildren } from "react";

type MenuItemProps = {
  href?: string;
  onMouseEnter?: () => void;
};

export function MenuItem({
  children,
  href,
  onMouseEnter,
}: PropsWithChildren<MenuItemProps>) {
  const styles = useMultiStyleConfig("MenuItem");
  const { asPath } = useRouter();
  const pathname = asPath.split("?")[0];

  const isActive = href === pathname;

  const underlineAnimation = keyframes`
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  `;

  const underlineStyle = {
    _before: {
      content: '""',
      position: "absolute",
      height: "1",
      width: "100%",
      backgroundColor: isActive ? "primary.600" : "gray.300",
      bottom: 0,
      left: 0,
      transition: "background-color .25s ease",
      animation: `${underlineAnimation} .3s ease`,
    },
  };

  return (
    <Flex
      position="relative"
      height="100%"
      alignItems="center"
      fontWeight="medium"
      letterSpacing="tight"
      cursor={href ? "pointer" : "default"}
      _hover={{
        ...underlineStyle,
      }}
      _before={{
        ...(isActive && { ...underlineStyle._before }),
      }}
      _focusWithin={{
        ...underlineStyle,
      }}
      sx={styles}
      tabIndex={href ? -1 : 0}
      onFocus={onMouseEnter}
      onMouseEnter={onMouseEnter}
    >
      <MenuItemWrapper href={href}>{children}</MenuItemWrapper>
    </Flex>
  );
}

function MenuItemWrapper({
  children,
  href,
}: PropsWithChildren<Pick<MenuItemProps, "href">>) {
  const styles = {
    display: "flex",
    alignItems: "center",
    height: "100%",
  };

  if (href) {
    return (
      <NextLink href={href} passHref>
        <a style={styles}>{children}</a>
      </NextLink>
    );
  }

  return <>{children}</>;
}
