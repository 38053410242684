import iconDataMapper from "@/dep_helpers/content/mappers/components/icon";
import APIButtonType from "@/dep_types/content/api/components/button";
import { ButtonType } from "@/dep_types/content/components";

const button = (apiButtonData: APIButtonType): ButtonType => ({
  variant: apiButtonData.variant,
  text: apiButtonData.text,
  trackingInfo: apiButtonData.trackClick
    ? {
        name: apiButtonData.trackingName,
        location: apiButtonData.trackingLocation,
      }
    : null,
  icon: apiButtonData.icon?.data
    ? iconDataMapper(apiButtonData.icon?.data?.attributes)
    : null,
});

// eslint-disable-next-line import/no-default-export
export default button;
