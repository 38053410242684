const button = {
  // The styles all button have in common
  baseStyle: {},
  variants: {
    primary: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      backgroundColor: "black",
      _hover: {
        backgroundColor: "black",
        color: "white",
      },
    },
    newPrimary: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    newSecondary: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    secondaryLight: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    secondaryDark: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    orderConfirmationLight: {
      fontFamily: "normal",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    iconButton: {
      fontFamily: "normal",
      fontWeight: "400",
      letterSpacing: "0.16px",
      textTransform: "uppercase",
    },
    checkoutPlaceOrder: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      backgroundColor: "black",
      color: "white",
      _hover: {
        backgroundColor: "gray.400",
        color: "white",
      },
    },
    modalPrimary: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      backgroundColor: "black",
      color: "white",
      _hover: {
        backgroundColor: "gray.400",
        color: "white",
      },
    },
    modalTertiary: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      background: "white",
      color: "black",
      _hover: {
        color: "gray.300",
      },
    },
    reservation: {
      fontWeight: "bold",
      height: "70px",
      letterSpacing: "1px",
      fontFamily: "normal",
      textTransform: "uppercase",
      color: "white",
      fontSize: {
        base: "18px",
        md: "20px",
      },
      backgroundColor: "secondary.600",
      width: "100%",
      _hover: {
        backgroundColor: "gray.400",
        _disabled: {
          backgroundColor: "gray.100",
        },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default button;
