import { Button, useStyleConfig, ButtonProps } from "@chakra-ui/react";
import { ReactElement, MouseEventHandler, PropsWithChildren } from "react";

interface CTAButtonProps extends ButtonProps {
  variant?: string;
  href?: string;
  target?: "_blank" | "_self";
  type?: "button" | "submit" | "reset";
  dataTestId?: string;
  onClick?: MouseEventHandler;
}

export function CTAButton({
  variant,
  href,
  target,
  type,
  dataTestId,
  children,
  onClick,
  sx,
  ...other
}: PropsWithChildren<CTAButtonProps>): ReactElement {
  const styles = useStyleConfig("Button", { variant });

  const isLink = href !== undefined;

  return (
    <Button
      onClick={onClick}
      sx={{
        ...styles,
        ...sx,
      }}
      as={isLink ? "a" : undefined}
      type={!isLink ? type : undefined}
      href={isLink ? href : undefined}
      target={isLink ? target : undefined}
      data-testid={dataTestId}
      {...other}
    >
      {children}
    </Button>
  );
}

CTAButton.defaultProps = {
  onClick: undefined,
  variant: undefined,
  href: undefined,
  target: "_self",
  type: "button",
  dataTestId: undefined,
};

// eslint-disable-next-line import/no-default-export
export default CTAButton;
