import linkDataMapper from "@/dep_helpers/content/mappers/components/link";
import buttonDataMapper from "@/dep_helpers/content/mappers/components/linkedButton";
import APIFooterLinksType from "@/dep_types/content/api/components/partner/footerLinks";
import { FooterLinkType } from "@/dep_types/content/partner";

const footerLinks = (
  apiFooterLinksData: APIFooterLinksType,
): FooterLinkType => ({
  id: apiFooterLinksData.id,
  copyright: apiFooterLinksData.copyright,
  body: apiFooterLinksData.body,
  socialIcons: apiFooterLinksData.socialIcons.map((b) => buttonDataMapper(b)),
  links: apiFooterLinksData.links.map((b) => linkDataMapper(b)),
});

// eslint-disable-next-line import/no-default-export
export default footerLinks;
