const dropDownList = {
  parts: ["label", "select"],
  baseStyle: {
    label: {
      fontSize: "14px",
      color: "black",
      fontFamily: "normal",
      fontWeight: "bold",
      mt: "25px",
    },
    select: {
      fontFamily: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      borderRadius: 5,
      border: "1px solid",
      backgroundColor: "inherit",
      borderColor: "inherit",
    },
  },
  variants: {
    form: {
      label: {
        mt: "0",
        fontSize: "14px",
        color: "black",
        borderRadius: "4px",
        fontFamily: "normal",
        fontWeight: "light",
        _placeholder: { color: "gray.500" },
      },
      select: {
        color: "gray.500",
        backgroundColor: "white",
        borderColor: "gray.200",
        borderRadius: "4px",
        height: "48px",
        fontFamily: "normal",
        fontWeight: "light",
        _placeholder: { color: "gray.500" },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default dropDownList;
