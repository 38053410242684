/* eslint-disable sonarjs/no-duplicate-string */
const button = {
  baseStyle: {},
  variants: {
    primary: {
      maxWidth: "unset",
      background: "primary.600",
      fontFamily: "title",
      width: "fit-content",
      padding: "15px 35px",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "1px",
      _hover: {
        _disabled: {},
      },
      _disabled: {},
    },
    newPrimary: {
      maxWidth: "unset",
      fontFamily: "title",
      background: "primary.600",
      letterSpacing: "1px",
      width: "fit-content",
      padding: "15px 35px",
      fontWeight: "bold",
      textTransform: "uppercase",
      _hover: {
        _disabled: {},
      },
      _disabled: {},
    },
    newSecondary: {
      padding: "15px 35px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      _hover: {},
    },
    secondaryLight: {
      maxWidth: "unset",
      fontFamily: "title",
      color: "primary.600",
      letterSpacing: "1px",
      width: "fit-content",
      padding: "15px 35px",
      fontWeight: "bold",
      margin: "0 auto",
      textTransform: "uppercase",
      _hover: {},
    },
    secondaryDark: {
      maxWidth: "unset",
      fontFamily: "title",
      width: "fit-content",
      letterSpacing: "1px",
      backgroundColor: "secondary.600",
      color: "white",
      fontWeight: "bold",
      padding: "15px 35px",
      border: 0,
      borderColor: "rgba(255, 255, 255, 0.25)",
      borderRadius: "4px",
      textTransform: "uppercase",
      _hover: {},
    },
    tertiary: {
      maxWidth: "unset",
      fontFamily: "title",
      color: "primary.600",
      letterSpacing: "1px",
      width: "fit-content",
      padding: "15px 35px",
      fontWeight: "bold",
      border: 0,
      margin: "0 auto",
      textTransform: "uppercase",
      _hover: {},
    },
    modalPrimary: {
      maxWidth: "unset",
      fontFamily: "title",
      background: "primary.600",
      letterSpacing: "1px",
      padding: "15px 35px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "white",
      _hover: {
        _disabled: {},
      },
      _disabled: {},
    },
    modalTertiary: {
      maxWidth: "unset",
      fontFamily: "title",
      color: "primary.600",
      letterSpacing: "1px",
      padding: "15px 35px",
      fontWeight: "bold",
      border: 0,
      textTransform: "uppercase",
      _hover: {},
    },
    orderConfirmationDark: {
      padding: "15px 35px",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    orderConfirmationLight: {
      padding: "15px 35px",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    iconButton: {
      padding: "15px 35px",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    socialIcon: {
      letterSpacing: "1px",
      textTransform: "uppercase",
      padding: 2,
    },
    checkoutPlaceOrder: {
      maxWidth: "313px",
      background: "primary.600",
      color: "white",
      fontFamily: "title",
      width: "fit-content",
      padding: "15px 35px",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "1px",
      _hover: {
        backgroundColor: "secondary.50",
        _disabled: {},
      },
      _disabled: {},
    },
    reservation: {
      padding: "15px 35px",
      height: "70px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontFamily: "title",
      color: "white",
      fontSize: {
        base: "18px",
        md: "20px",
      },
      backgroundColor: "primary.600",
      width: "100%",
      _hover: {
        backgroundColor: "secondary.50",
        _disabled: {
          backgroundColor: "gray.100",
        },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default button;
