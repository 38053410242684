import { environment } from "../dep_constants";

let overrides;

const local = {
  AMPLITUDE_KEY: "ed3634fe82b76b4464035b8813364b35",
  API_URL: process.env.LOCAL_API_URL ?? "https://localhost:5001/api",
  STRIPE_PUBLIC_KEY:
    "pk_test_51IMGGdKV9oHaVE38j4LZw2GgBFhBJffo6zkigrtfWb6vnWVwQUcELIVF2CZ27L26JpeFqkrwYQ3QVpQwKykKzADI00gOt93vZf",
};
const development = {
  AMPLITUDE_KEY: "ed3634fe82b76b4464035b8813364b35",
  API_URL:
    process.env.LOCAL_API_URL ?? "https://dev-api.arriveoutdoors.com/api",
  STRIPE_PUBLIC_KEY: "pk_test_AoqVEed2tboQDAT6oLk38qx200DGRbBDVl",
};
const staging = {
  API_URL: "https://com-api.arriveoutdoors.com/api",
  AMPLITUDE_KEY: "ed3634fe82b76b4464035b8813364b35",
  STRIPE_PUBLIC_KEY: "pk_live_aVYgk4wkRGfSEUI6i0eEzLiR00rBM4Sfw1",
};
const production = {
  API_URL: "https://com-api.arriveoutdoors.com/api",
  STRIPE_PUBLIC_KEY: "pk_live_aVYgk4wkRGfSEUI6i0eEzLiR00rBM4Sfw1",
};

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

export const commonKeys = {
  CLOUDINARY_NAME: "arrive-outdoors",
  CLOUDINARY_BASE_IMAGE_URL:
    "https://res.cloudinary.com/arrive-outdoors/image/upload",
  CLOUDINARY_BASE_VIDEO_URL:
    "https://res.cloudinary.com/arrive-outdoors/video/upload",

  TEXT_BANNER_ID: "sitewide-text-banner",

  DEFAULT_PRODUCT_IMAGE_ID: "missing_image_gwbvpp",

  GET_UNPUBLISHED_SET_ITEMS: true,
  KEEP_CART_AFTER_CHECKOUT_ORDER: false,

  ALL_CATEGORY: "all",

  COMPONENT_KEYS: {
    RECOMMENDED_PRODUCTS: "sections.recommended-products",
    BRAND_VALUE_PROP: "sections.brand-value-prop",
    CATEGORY_TILES: "sections.category-tiles",
    HOW_RENTALS_WORK: "sections.how-it-works",
    BRAND_PARTNERSHIP_HERO: "sections.brand-partnership-hero",
    MEDIA_VALUE_PROP: "sections.media-value-prop",
    FAQ: "sections.fa-qs",
    HERO: "sections.hero",
  },

  ...overrides,
};

export const defaultProductImageId = "missing_image_gwbvpp";

export type CommonKeys = {
  API_URL: string;
  CLOUDINARY_NAME: string;
  CLOUDINARY_BASE_IMAGE_URL: string;
  STRIPE_PUBLIC_KEY: string;
  ZENDESK_API_KEY: string;
  TEXT_BANNER_ID: string;

  // Overrides
  AMPLITUDE_KEY: string;
  DEFAULT_PRODUCT_IMAGE_ID: string;

  GET_UNPUBLISHED_SET_ITEMS: boolean;
  KEEP_CART_AFTER_CHECKOUT_ORDER: boolean;
};
