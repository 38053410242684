import { ReviveTheme } from "@/revive/theme";

export const burton: ReviveTheme = {
  partnerId: 5,
  partnerName: "burton",
  colors: {
    announcement: "#4D4D4D",
    confirmation: "#28B761",
    error: "#EA4335",
    black: "#1A1A1A",
    primary: {
      50: "#F8F9FF",
      600: "#0122C7",
    },
    secondary: {
      50: "#F6FAFA",
      600: "#3D7C7D",
    },
    tertiary: {
      50: "#FFFAFA",
      600: "#90362A",
    },
    gray: {
      50: "#F6F6F6",
      100: "#F2F2F2",
      200: "#E3E3E3",
      300: "#C8C8C8",
      400: "#A5A5A5",
      500: "#909090",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "base",
      },
    },
  },
};
