const faqValueProp = {
  parts: ["title", "question", "answer"],
  baseStyle: {
    title: {
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "none",
      textAlign: {
        base: "left",
        md: "center",
      },
    },
    question: {
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
      textAlign: "left",
    },
    answer: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default faqValueProp;
