import PartnerBrandPartnershipHero from "./components/BrandPartnershipHero";
import PartnerButton from "./components/Button";
import PartnerCategoryTiles from "./components/CategoryTiles";
import PartnerCustomText from "./components/CustomText";
import PartnerDropDownList from "./components/DropDownList";
import PartnerFAQCategorySection from "./components/FAQCategorySection";
import PartnerFAQValueProp from "./components/FAQValueProp";
import PartnerFooter from "./components/Footer";
import PartnerFormSectionHeading from "./components/FormSectionHeading";
import PartnerHeading from "./components/Heading";
import PartnerIconValueProp from "./components/IconValueProp";
import PartnerInputText from "./components/InputText";
import PartnerLinkButton from "./components/LinkButton";
import PartnerMediaValueProp from "./components/MediaValueProp";
import { menuItem } from "./components/MenuItem";
import PartnerOrderNumber from "./components/OrderNumber";
import PartnerOrderStatusStep from "./components/OrderStatusStep";
import PartnerPriceContainer from "./components/PriceContainer";
import PartnerRichText from "./components/RichText";
import PartnerSiteLink from "./components/SiteLink";
import PartnerSiteNavigationLink from "./components/SiteNavigationLink";
import PartnerText from "./components/Text";
import PartnerTitle from "./components/Title";
import PartnerVariantSelector from "./components/VariantSelector";
import { brandValueProp } from "./components/brandValueProp";
import { colorSwatch } from "./components/colorSwatch";
import { productCard } from "./components/productCard";
import { topTextBanner } from "./components/topTextBanner";

const components = {
  colorSwatch,
  PartnerButton,
  PartnerBrandPartnershipHero,
  brandValueProp,
  PartnerCategoryTiles,
  PartnerCustomText,
  PartnerDropDownList,
  PartnerFAQCategorySection,
  PartnerFAQValueProp,
  PartnerHeading,
  PartnerFormSectionHeading,
  PartnerFooter,
  PartnerIconValueProp,
  PartnerInputText,
  PartnerLinkButton,
  PartnerMediaValueProp,
  PartnerMenuItem: menuItem,
  PartnerOrderNumber,
  PartnerOrderStatusStep,
  productCard,
  PartnerPriceContainer,
  PartnerRichText,
  PartnerSiteLink,
  PartnerSiteNavigationLink,
  PartnerText,
  PartnerTitle,
  topTextBanner,
  PartnerVariantSelector,
};

// eslint-disable-next-line import/no-default-export
export default components;
