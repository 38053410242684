const footer = {
  parts: ["container", "title", "body", "copyright", "assistiveText"],
  baseStyle: {
    container: {
      backgroundColor: "primary.600",
    },
    title: {
      color: "secondary.600",
      fontSize: "16px",
      letterSpacing: "0.08px",
      fontWeight: "500",
      fontFamily: "title",
    },
    body: {
      fontSize: "14px",
      fontFamily: "normal",
      fontWeight: 400,
    },
    copyright: {
      mb: 4,
      fontSize: "14px",
      fontFamily: "normal",
      textAlign: {
        base: "center",
        md: "left",
      },
    },
    assistiveText: {
      fontFamily: "normal",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default footer;
