import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { GetModals, ModalsCms } from "@/api/cms/GetModals";
import { prefetchQueryClient } from "@/providers/QueryProvider";
import { useTheme } from "@/revive/hooks/useTheme";

const queryContent = (partnerName: string) => ({
  queryKey: ["modals-cms", partnerName],
  queryFn: () => GetModals(partnerName),
});

export async function prefetchUseModalsCms(partnerName: string) {
  await prefetchQueryClient.prefetchQuery(queryContent(partnerName));

  return prefetchQueryClient;
}

type UseModalsCmsProps = {
  openModal: (modalId: string) => void;
};

export function useModalsCms({ openModal }: UseModalsCmsProps) {
  const { partnerName } = useTheme();

  const router = useRouter();
  const modalQueryId = Array.isArray(router?.query?.modal)
    ? router?.query?.modal[0]
    : router?.query?.modal;

  const { data, isLoading } = useQuery<ModalsCms, Error>({
    ...queryContent(partnerName),
    ...{
      enabled: Boolean(partnerName),
    },
    onSuccess: () => {
      modalQueryId && openModal(modalQueryId);
    },
  });

  return {
    data,
    isLoading,
  };
}
