/* eslint-disable sonarjs/cognitive-complexity */
import { useState } from "react";

import { BaseConditionSummary, ConditionKey } from "@/dep_types";

import { useArriveContext } from "../dep_components/context";
import ProductsService from "../dep_services/productService";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemType,
} from "../dep_types/CartTypes";

export interface State {
  addQuantity: (cartItem: CartProductItemType) => Promise<void>;
  reduceQuantity: (cartItem: CartProductItemType) => void;
  showMaxReachedAlert: boolean;
  setShowMaxReachedAlert: (show: boolean) => void;
}

const useCartItemEditQuantity = (): State => {
  const [showMaxReachedAlert, setShowMaxReachedAlert] =
    useState<boolean>(false);

  const {
    addCartItemQuantity,
    reduceCartItemQuantity,
    shoppingCartRentalDates,
    cartItems,
    partnerIdTheme,
  } = useArriveContext();

  const addQuantity = async (cartItem: CartProductItemType): Promise<void> => {
    if (cartItem.isResale) {
      const variant = await ProductsService.getVariant(
        partnerIdTheme,
        cartItem.product.id,
        cartItem.variant,
      );

      const variantConditions = variant?.conditions || {};
      const matchingConditionKey =
        Object.keys(variantConditions).find(
          (conditionKey) =>
            conditionKey.toLowerCase() === cartItem.condition?.toLowerCase(),
        ) || "";
      const conditionStock = (variantConditions as BaseConditionSummary)[
        matchingConditionKey as ConditionKey
      ];

      if (!conditionStock) {
        return;
      }

      if (cartItem.quantity < conditionStock) {
        addCartItemQuantity(cartItem.cartId);
      } else {
        setShowMaxReachedAlert(true);
      }

      return;
    }

    const availableVariants = await ProductsService.getProductAvailability(
      partnerIdTheme,
      cartItem.product.id,
      shoppingCartRentalDates,
    );

    if (!availableVariants) {
      return;
    }

    const variantWithAvailablity = availableVariants.find(
      (variant) => variant.id === cartItem.variant,
    );

    if (!variantWithAvailablity) {
      return;
    }

    let unitsSelectedOnSets = 0;

    cartItems.forEach((existentCartItem: CartItemType) => {
      if (existentCartItem.isSet) {
        (existentCartItem as CartSetItemType).cartSetGroups.forEach(
          (cartSetGroupSelections) => {
            cartSetGroupSelections.forEach((variantSelection) => {
              if (
                variantSelection.variantId.toString() ===
                cartItem.variant.toString()
              ) {
                unitsSelectedOnSets += 1;
              }
            });
          },
        );
      }
    });

    const canAddMore =
      cartItem.quantity + unitsSelectedOnSets <
      variantWithAvailablity.maxAvailable;

    if (canAddMore) {
      addCartItemQuantity(cartItem.cartId);
    } else {
      setShowMaxReachedAlert(true);
    }
  };

  const reduceQuantity = (cartItem: CartProductItemType): void => {
    reduceCartItemQuantity(cartItem.cartId);
  };

  return {
    addQuantity,
    reduceQuantity,
    showMaxReachedAlert,
    setShowMaxReachedAlert,
  };
};

// eslint-disable-next-line import/no-default-export
export default useCartItemEditQuantity;
