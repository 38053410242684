/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";

import commonTexts from "@/dep_constants/commonTexts";
import EventLocationType from "@/dep_types/EventLocationType";
import ProductSaleTypeEnum from "@/dep_types/commerce-api/productSaleType";
import { useModal } from "@/providers/ModalProvider";

import LinkButton from "@/dep_components/Commons/Buttons/LinkButton";
import SingleLineDateRangeBox from "@/dep_components/Commons/DateRangeBox/SingleLineDateRangeBox";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import CartItem from "./CartItems/CartItem";
import Footer from "./Footer";

import CartHelper from "../../dep_helpers/CartHelper";
import ShoppingCartService from "../../dep_services/shoppingCartService";
import TrackingService from "../../dep_services/tracking/trackingService";
import { CartItemType } from "../../dep_types/CartTypes";
import ErrorAlert from "../Commons/ErrorAlert";
import DatePicker from "../DatePicker";
import { useArriveContext } from "../context";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const ShoppingCart: FC = () => {
  const router = useRouter();

  const { partnerIdTheme } = useArriveContext();
  const [cartError, setCartError] = useState<string | null>(null);

  const [cartItemsWithAvailability, setCartItemsWithAvailability] = useState<
    CartItemType[]
  >([]);

  const drawerSize = useBreakpointValue({
    base: "full",
    md: "lg",
  });

  const {
    saleType: partnerSaleType,
    isYeti,
    globalLabels,
  } = useWhitelabelContext();

  const {
    cartItems,
    shoppingCartOpened,
    closeShoppingCart,
    removeFromCart,
    shoppingCartRentalDates,
    changeCartSimpleProductVariant,
  } = useArriveContext();

  const toast = useToast();

  const checkAvailability = async (onCheckout: boolean): Promise<void> => {
    let mutatedCartItems = cartItems;

    if (cartItems && cartItems.length > 0) {
      if (onCheckout) {
        // Removes any price change pills/flags when going from cart to checkout
        mutatedCartItems = cartItems.map(
          (item: { priceChanged: { changed: boolean } }) => {
            item.priceChanged.changed = false;

            return item;
          },
        );
      }
      const response = await ShoppingCartService.setAndCheckAvailability(
        partnerIdTheme,
        mutatedCartItems,
        shoppingCartRentalDates,
      );

      setCartItemsWithAvailability(response.cartItems);
      setCartError(response.error);
      // Only show this toast message if price mismatch happened on clicking checkout button
      if (onCheckout && response.error === "priceDecrementingMismatch") {
        if (!toast.isActive("price-decrementing-toast")) {
          toast({
            id: "price-decrementing-toast",
            description:
              "There have been updates made to the prices in your cart, please review before proceeding.",
            status: "error",
            position: "bottom-right",
            isClosable: true,
            duration: 10000,
          });
        } else {
          toast.close("price-decrementing-toast");
        }
      }
    } else {
      setCartItemsWithAvailability([]);
      setCartError(null);
    }
  };

  useEffect(() => {
    checkAvailability(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  const handleReserveCart = async (): Promise<void> => {
    if (cartItemsWithAvailability.length === 0) {
      return;
    }

    await checkAvailability(true);

    if (
      cartItemsWithAvailability.some((item) => item.unavailable) ||
      toast.isActive("price-decrementing-toast")
    ) {
      return;
    }

    const cartTotalPrice = CartHelper.getTotal(cartItems);

    TrackingService.trackStartCheckout(
      cartItemsWithAvailability,
      cartTotalPrice,
    );
    router.push("/checkout");
  };

  const hasUnavailableRentalItems = CartHelper.hasUnavailableRentalItems(
    cartItemsWithAvailability,
  );
  const hasUnavailableResaleItems = CartHelper.hasUnavailableResaleItems(
    cartItemsWithAvailability,
  );

  let cartUnavailableText;

  if (hasUnavailableRentalItems && hasUnavailableResaleItems) {
    cartUnavailableText = isYeti
      ? commonTexts.shoppingCart.CART_UNAVAILABLE_PLEASE_REVIEW_YETI
      : commonTexts.shoppingCart.CART_UNAVAILABLE_PLEASE_REVIEW;
  } else if (hasUnavailableRentalItems) {
    cartUnavailableText = isYeti
      ? commonTexts.shoppingCart.CART_UNAVAILABLE_FOR_RENTAL_DATES_YETI
      : commonTexts.shoppingCart.CART_UNAVAILABLE_FOR_RENTAL_DATES;
  }

  let cartKey = 0;

  const rentalItems = cartItemsWithAvailability
    ? CartHelper.filterRentalItems(cartItemsWithAvailability)
    : [];
  const resaleItems = cartItemsWithAvailability
    ? CartHelper.filterResaleItems(cartItemsWithAvailability)
    : [];
  const hasRentalItems = rentalItems.length > 0;
  const hasResaleItems = resaleItems.length > 0;

  const isRentAvailable =
    partnerSaleType === ProductSaleTypeEnum.BOTH ||
    partnerSaleType === ProductSaleTypeEnum.RENTAL;

  const showDatePicker = isRentAvailable && hasRentalItems;

  const products = cartItems.map((item: CartItemType) => item.product);

  const { openModal } = useModal();

  const handleOpenClearCartModal = (): void => {
    openModal("clear-cart");
  };

  return (
    <Drawer
      isOpen={shoppingCartOpened}
      onClose={closeShoppingCart}
      placement="right"
      size={drawerSize}
    >
      <DrawerOverlay>
        <DrawerContent height="100%">
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingTop={{
              base: 2.5,
              md: 4,
            }}
            paddingBottom={0}
            paddingLeft={{
              base: 2.5,
              md: 6,
            }}
            paddingRight={{
              base: 2.5,
              md: 6,
            }}
            color="black"
            fontFamily="normal"
            fontWeight="medium"
          >
            <Flex
              as="button"
              type="button"
              alignItems="center"
              cursor="pointer"
              onClick={closeShoppingCart}
            >
              <Box width="7px" marginRight={2.5} marginTop={0.5}>
                <ChevronLeftIcon />
              </Box>
              <Box fontSize="16px" paddingTop="2px">
                {isYeti ? "My Bag" : "Continue Shopping"}
              </Box>
            </Flex>
            <LinkButton
              text={isYeti ? "Clear Bag" : "Clear Cart"}
              onClick={handleOpenClearCartModal}
            />
          </DrawerHeader>
          {cartUnavailableText && (
            <ErrorAlert mx="20px">{cartUnavailableText}</ErrorAlert>
          )}
          <DrawerBody
            px={{
              base: "10px",
              md: "24px",
            }}
            mr="10px"
            mb="15px"
            css={{
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#d2d2d2",
                width: "2px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "black",
                width: "10px",
                borderRadius: "24px",
              },
            }}
          >
            <Flex flex={1} direction="column">
              {isRentAvailable &&
                hasResaleItems &&
                globalLabels.cartRentalLabel && (
                  <Flex
                    alignItems="center"
                    onClick={closeShoppingCart}
                    p="10px"
                    fontSize={{
                      base: "18px",
                      md: "20px",
                    }}
                    fontFamily="normal"
                    fontWeight="black"
                    borderBottom="1px solid"
                    borderColor="#908EA0"
                    _hover={{ cursor: "pointer" }}
                  >
                    {globalLabels.cartRentalLabel}
                  </Flex>
                )}
              {showDatePicker && (
                <DatePicker
                  location={EventLocationType.CART_MODAL}
                  productsForTracking={products}
                  cartDates
                  fullWidth
                >
                  <SingleLineDateRangeBox />
                </DatePicker>
              )}
              <Flex
                flex={1}
                direction="column"
                pr={{
                  base: 0,
                  md: "20px",
                }}
              >
                {rentalItems.map((item) => {
                  cartKey++;

                  return (
                    <CartItem
                      key={cartKey}
                      cartItem={item}
                      removeFromCart={() => removeFromCart(item)}
                      linkToProductPage
                      shoppingCartRentalDates={shoppingCartRentalDates}
                      changeCartSimpleProductVariant={
                        changeCartSimpleProductVariant
                      }
                    />
                  );
                })}
              </Flex>
              {hasResaleItems && (
                <>
                  {globalLabels.cartResaleLabel && (
                    <Flex
                      alignItems="center"
                      onClick={closeShoppingCart}
                      p="10px"
                      fontSize={{
                        base: "18px",
                        md: "20px",
                      }}
                      fontFamily="normal"
                      fontWeight="black"
                      borderBottom="1px solid"
                      borderColor="#908EA0"
                      _hover={{ cursor: "pointer" }}
                    >
                      {globalLabels.cartResaleLabel}
                    </Flex>
                  )}
                  <Flex
                    flex={1}
                    direction="column"
                    pr={{
                      base: 0,
                      md: "20px",
                    }}
                  >
                    {resaleItems.map((item) => {
                      cartKey++;

                      return (
                        <CartItem
                          key={cartKey}
                          cartItem={item}
                          removeFromCart={() => removeFromCart(item)}
                          // TODO: We should remove this isYeti and re-direct with the correct slug using the paramSize
                          linkToProductPage={!isYeti}
                          shoppingCartRentalDates={shoppingCartRentalDates}
                          changeCartSimpleProductVariant={
                            changeCartSimpleProductVariant
                          }
                        />
                      );
                    })}
                  </Flex>
                </>
              )}
            </Flex>
          </DrawerBody>
          <DrawerFooter
            display="flex"
            m={0}
            p={0}
            justifyContent="flex-start"
            borderTop="1px solid black"
          >
            <Footer
              cartItems={cartItems}
              handleReserveCart={handleReserveCart}
              cartError={cartError}
            />
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

// eslint-disable-next-line import/no-default-export
export default ShoppingCart;
