import buttonDataMapper from "@/dep_helpers/content/mappers/components/linkedButton";
import navigationItemDataMapper from "@/dep_helpers/content/mappers/components/navigationItem";
import APIHeaderInfoType from "@/dep_types/content/api/components/partner/headerInfo";
import { HeaderInfoType } from "@/dep_types/content/partner";

const headerInfo = (apiHeaderInfoData: APIHeaderInfoType): HeaderInfoType => ({
  id: apiHeaderInfoData.id,
  bannerText: apiHeaderInfoData.bannerText,
  backToSiteButton: apiHeaderInfoData.backToSiteButton
    ? buttonDataMapper(apiHeaderInfoData.backToSiteButton)
    : null,
  navigationItems: apiHeaderInfoData.navigationItems.map(
    navigationItemDataMapper,
  ),
});

// eslint-disable-next-line import/no-default-export
export default headerInfo;
