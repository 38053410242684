import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";

import { usePartner } from "@/hooks/usePartner";
import { useModal } from "@/providers/ModalProvider";

import ModalSmallActionButton from "@/dep_components/Commons/Buttons/ModalSmallActionButton";
import { useArriveContext } from "@/dep_components/context";

export function ClearCartModalContent() {
  const modalContentStyles = useMultiStyleConfig("ModalContent");
  const partnerName = usePartner();

  const isYeti = partnerName === "yeti";

  const { clearCart } = useArriveContext();
  const { closeModal } = useModal();

  const handleClickClose = (): void => {
    closeModal();
  };

  const handleClickClearCart = (): void => {
    closeModal().then(() => {
      clearCart();
    });
  };

  return (
    <Box>
      <Box sx={modalContentStyles.title}>
        {isYeti ? "Empty bag" : "Empty cart"}
      </Box>
      <Box sx={modalContentStyles.bodyText}>
        {`Are you sure you want to remove all the items from your ${
          isYeti ? "bag?" : "cart?"
        }`}
        <br />
        <br />
      </Box>
      <Flex flex={1} gap={2} justifyContent="space-between">
        <ModalSmallActionButton
          onClick={handleClickClose}
          variant="modalTertiary"
          text="Cancel"
        />
        <ModalSmallActionButton
          onClick={handleClickClearCart}
          variant="modalPrimary"
          text="Confirm"
        />
      </Flex>
    </Box>
  );
}
