const orderStatusStep = {
  parts: ["line", "number", "text"],
  baseStyle: {
    line: {},
    number: {
      fontFamily: "title",
      fontWeight: "bold",
      borderRadius: "4px",
    },
    text: {
      position: "absolute",
      marginTop: {
        base: "35px",
        lg: "50px",
      },
      overflowWrap: "normal",
      width: {
        base: "55px",
        lg: "80px",
      },
      textAlign: "center",
      height: "2px",
      fontSize: {
        base: "9px",
        lg: "12px",
      },
      fontFamily: "normal",
      fontWeight: "medium",
    },
  },
  variants: {
    inactive: {
      line: {
        backgroundColor: "gray.300",
      },
      number: {
        backgroundColor: "gray.300",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderStatusStep;
