import {
  ConditionSummary,
  ProductImage,
  RentalDatesLabelsType,
  VariantParametersType,
  VariantType,
} from "./index";

export type AvailabilityType = {
  id: number;
  maxAvailable: number;
  parameters: VariantParametersType;
  conditions?: ConditionSummary;
  productId: number;
  sku: string;
  stock: number;
  stockBuffer: number;
  thumbnail: {
    id: number;
    publicId: string;
    url: string;
  };
};

export type PriceType = {
  /**
   * Base price per night before trip length discounts
   */
  basePricePerNight: number;
  /**
   * Average of each day price / total nights
   */
  pricePerNight: number;
  /**
   * Total for the selected dates
   */
  total: number;
  /**
   * If is fixed this value will be total if is percentage this value will be pricePerNight.
   */
  price: number;
};

export type VariantSelectOptionType = {
  id: number;
  name: string;
  capitalizedName: string;
  variantName?: string;
  subProduct?: {
    id: string;
    name: string;
    image: string;
  };
};

export type SetProductVariantChangeType = {
  variantId: string;
  variantName: string;
  variantShortName: string;
  productId: string;
  productName: string;
  productImage: ProductImage;
};

export const ProductSaleTypes: ProductSaleType[] = [
  {
    id: 1,
    name: "Rental",
    enumValue: "rental",
  },
  {
    id: 2,
    name: "Resale",
    enumValue: "resale",
  },
  {
    id: 3,
    name: "Both",
    enumValue: "both",
  },
];

export type ProductSaleType = {
  id: number;
  name: string;
  enumValue: string;
};

export type ProductPriceRangeType = {
  lp?: string;
  up?: string;
};

export type ProductCollectionInputAPI = {
  cat?: string;
  cId?: string[];
  bIds?: string[];
  con?: string[];
  pst?: string;
  opp?: boolean;
  f?: string;
  t?: string;
  p?: string;
  sea?: string[];
  s?: string;
  lp?: string;
  up?: string;
  w?: string;
  ep?: boolean;
  scIds?: string[];
  iv?: boolean;
  fs?: string[];
  PageSize: number;
  PageNumber: number;
};

export type FetchProductsForCollectionParamsType = {
  partnerId?: string;
  categoryId?: string | null;
  collectionId?: string | null;
  collectionIds?: string[] | null;
  onlyPopularProducts?: boolean;
  brandIds?: string[] | null;
  productSaleTypes?: string[] | null;
  conditions?: string[] | null;
  sizeIds?: string[] | null;
  genderIds?: string[] | null;
  seasonIds?: string[] | null;
  colorIds?: string[] | null;
  capacityIds?: string[] | null;
  priceRange?: ProductPriceRangeType | null;
  suggestedCollectionIds?: string[] | null;
  weight?: string | null;
  excludeProducts?: boolean;
  facetsToInclude?: string[] | null;
  includeVariants?: boolean;
  rentalDates?: RentalDatesLabelsType | null;
  sortBy?: string | null;
  pageNumber?: number;
  pageSize?: number;
};

export interface CollectionFilter {
  id: string | number;
  name: string;
  count?: number;
  metadata?: string;
}

export interface BrandType extends CollectionFilter {}

/**
 * An object that contains which conditions we should show based the input filters and also keeps track of the variants
 * that belong to each tier condition. Keep in mind that variants could be duplicated between the tiers!!
 */
export type ConditionsToShowType = {
  showTier1: boolean;
  variantsTier1: VariantType[];
  showTier2: boolean;
  variantsTier2: VariantType[];
  showTier3: boolean;
  variantsTier3: VariantType[];
};
