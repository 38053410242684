const orderStatusStep = {
  variants: {
    active: {
      line: {
        backgroundColor: "secondary.600",
      },
      number: {
        backgroundColor: "secondary.600",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderStatusStep;
