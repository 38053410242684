import APITextMultiple from "@/dep_types/content/api/components/textMultiple";
import { TextMultipleType } from "@/dep_types/content/components";

const textMultiple = (apiTextMultiple: APITextMultiple): TextMultipleType => ({
  id: apiTextMultiple.id,
  name: apiTextMultiple.name,
});

// eslint-disable-next-line import/no-default-export
export default textMultiple;
