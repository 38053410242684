/* eslint-disable @typescript-eslint/no-explicit-any */
import { useOutsideClick } from "@chakra-ui/react";
import { useRef, useState } from "react";

import { GlobalLabelsType } from "@/dep_types/content/api/components/partner/globalLabels";

import { useArriveContext } from "../dep_components/context";
import DatesHelper from "../dep_helpers/DatesHelper";
import ShoppingCartService from "../dep_services/shoppingCartService";
import { CartSetItemSetGroup, CartSetItemType } from "../dep_types/CartTypes";
import {
  SetProductVariantChangeType,
  VariantSelectOptionType,
} from "../dep_types/ProductTypes";

export interface State {
  selectedVariant: string | undefined;
  setSelectedVariant: (variant: string) => void;
  changeSetProductVariant: (
    cartItem: CartSetItemType,
    setGroup: CartSetItemSetGroup,
    newVariantId: string,
  ) => Promise<void>;
  itemVariantEditRef: any;
  editSetProductVariant: boolean;
  setProductAvailableVariantsForEdit: any;
  handleEditSetProductVariant: (
    globalLabels: GlobalLabelsType,
  ) => Promise<void>;
  showSetProductEditButtonAlert: boolean;
  setShowSetProductEditButtonAlert: (show: boolean) => void;
}

const useCartSetItemEditHook = (
  cartItem: CartSetItemType,
  cartSetGroupSelection: CartSetItemSetGroup,
): State => {
  const [editSetProductVariant, setEditSetProductVariant] =
    useState<boolean>(false);

  const [selectedVariant, setSelectedVariant] = useState<string>();

  const {
    partnerIdTheme,
    cartItems,
    changeCartSetProductVariant,
    shoppingCartRentalDates,
  } = useArriveContext();

  const [showSetProductEditButtonAlert, setShowSetProductEditButtonAlert] =
    useState<any>(false);

  const [
    setProductAvailableVariantsForEdit,
    setSetProductAvailableVariantsForEdit,
  ] = useState<any>();

  const itemVariantEditRef = useRef();

  useOutsideClick({
    ref: itemVariantEditRef as any,
    handler: () => setEditSetProductVariant(false),
  });

  const handleEditSetProductVariant = async (
    globalLabels: GlobalLabelsType,
  ): Promise<void> => {
    if (shoppingCartRentalDates) {
      const rentalDatesApiParams =
        DatesHelper.parseGlobalRentalDatesToAPIParams(shoppingCartRentalDates);

      const availableVariants =
        await ShoppingCartService.getSetGroupAvailability(
          partnerIdTheme,
          cartItems,
          cartItem.product.id,
          cartSetGroupSelection.setGroupId,
          rentalDatesApiParams.startDate as string,
          rentalDatesApiParams.endDate as string,
          globalLabels,
        );

      setEditSetProductVariant(true);
      setSelectedVariant(cartSetGroupSelection.variantId);
      setSetProductAvailableVariantsForEdit(availableVariants);
    }
  };

  const changeSetProductVariant = async (
    newCartItem: CartSetItemType,
    setGroup: CartSetItemSetGroup,
    newVariantId: string,
  ): Promise<void> => {
    if (!newVariantId) {
      setEditSetProductVariant(false);

      return;
    }

    const { availability, variantOptions } = setProductAvailableVariantsForEdit;

    const selectedProduct = variantOptions.find(
      (option: VariantSelectOptionType) =>
        option.id.toString() === newVariantId,
    );

    const selectedAvailability = availability.find(
      (option: VariantSelectOptionType) =>
        option.id.toString() === newVariantId,
    );

    const newData: SetProductVariantChangeType = {
      variantId: selectedAvailability.id,
      variantName: selectedProduct.variantName,
      variantShortName: selectedProduct.variantName,
      productId: selectedProduct.subProduct.id,
      productName: selectedProduct.subProduct.name,
      productImage: selectedProduct.subProduct.image,
    };

    if (changeCartSetProductVariant) {
      changeCartSetProductVariant(
        newCartItem.cartId,
        setGroup.cartSetGroupItemId,
        newData,
      );
    }

    setEditSetProductVariant(false);
  };

  return {
    selectedVariant,
    setSelectedVariant,
    changeSetProductVariant,
    itemVariantEditRef,
    editSetProductVariant,
    setProductAvailableVariantsForEdit,
    handleEditSetProductVariant,
    showSetProductEditButtonAlert,
    setShowSetProductEditButtonAlert,
  };
};

// eslint-disable-next-line import/no-default-export
export default useCartSetItemEditHook;
