import { Box, BoxProps, Text, useMultiStyleConfig } from "@chakra-ui/react";
import Link from "next/link";
import React, { FC } from "react";

interface Props extends BoxProps {
  categoryId: string | number;
  group: string;
  name: string | number;

  onClick(): void;
}

const MenuCategoryLink: FC<Props> = ({
  categoryId,
  name,
  group,
  onClick,
  ...other
}: Props) => {
  const styles = useMultiStyleConfig("Text");

  return (
    <Box as="header" display="inline-block" marginBottom={2.5} {...other}>
      <Link
        href={`/group/${group}/category/${categoryId}`}
        passHref
        prefetch={false}
      >
        <Text
          as="a"
          size="md"
          cursor="pointer"
          sx={styles.menuCategory}
          onClick={onClick}
          _hover={{
            borderBottom: "2px solid",
          }}
          _focus={{
            borderBottom: "2px solid",
            outline: "none",
          }}
        >
          {name}
        </Text>
      </Link>
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default MenuCategoryLink;
