const inputText = {
  parts: ["label", "input", "phoneInput"],
  baseStyle: {
    label: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    input: {
      fontFamily: "normal",
      fontWeight: "light",
      letterSpacing: "0.4px",
    },
    phoneInput: {
      fontFamily: "normal",
      fontWeight: "light",
      letterSpacing: "1px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default inputText;
