const linkButton = {
  baseStyle: {
    textTransform: "uppercase",
  },
  variants: {
    actionButtonXSmallPrimary: {
      fontSize: "xs",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default linkButton;
