const checkoutCheckbox = {
  baseStyle: {
    control: {
      w: "22px",
      h: "22px",
      borderRadius: "4px",
      border: "1px solid",
      bg: "white",
      _checked: {
        borderColor: "black",
        color: "black",
        bg: "white",
        fontWeight: "1px",
        _hover: {
          bg: "white",
        },
        _disabled: {
          borderColor: "gray.300",
        },
      },
      _disabled: {
        borderColor: "gray.300",
        color: "gray.300",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default checkoutCheckbox;
