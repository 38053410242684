/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpResponse from "./HttpResponse";
import RequestParams from "./RequestParams";

import Utils from "../../dep_helpers/Utils";
import { commonKeys } from "../../dep_whitelabel/commonKeys";

export const HttpRequestType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
} as const;

// eslint-disable-next-line no-redeclare
type HttpRequestType = (typeof HttpRequestType)[keyof typeof HttpRequestType];

const BASE_URL = commonKeys.API_URL;

// eslint-disable-next-line import/no-default-export
export default abstract class HttpRequest<T> {
  type: HttpRequestType;

  partnerId: string;

  protected constructor(type: HttpRequestType, partnerId: string) {
    this.type = type;
    this.partnerId = partnerId;
  }

  protected executeEndpoint = async (
    endpoint: string,
    params?: RequestParams<T>,
  ): Promise<HttpResponse> => {
    const url: URL = new URL(`${BASE_URL}${endpoint}`);

    if (params?.query) {
      url.search = new URLSearchParams(
        Utils.buildParams(params.query),
      ).toString();
    }

    return this.executeCall(url, params?.body)
      .then(async (res) => {
        const responseText = await res.text();
        let response = {};

        if (responseText.length) {
          if (
            // @ts-expect-error TODO: Investigate this
            res.headers
              .get("content-type")
              ?.toLowerCase()
              .indexOf("text/plain") >= 0
          ) {
            return new HttpResponse({
              error: true,
              errorMessage: responseText,
            });
          }
          response = JSON.parse(responseText);
        }
        const pagination = res.headers.has("pagination")
          ? JSON.parse(res.headers.get("pagination") as string)
          : null;

        //
        return new HttpResponse({
          status: res.status,
          data: response,
          error: res.status >= 400,
          pagination,
        });
      })
      .catch(() => new HttpResponse({ error: true }));
  };

  private executeCall = async (url: URL, body?: T): Promise<Response> =>
    fetch(url.toString(), {
      headers: {
        "Content-Type": "application/json",
        "x-partner-id": this.partnerId,
      } as any,
      body: JSON.stringify(body),
      method: this.type,
    });
}
