const customText = {
  baseStyle: {
    fontFamily: "normal",
    fontSize: {
      base: "14px",
      md: "16px",
    },
  },
  variants: {
    superlative: {},
    formTitle: {
      fontFamily: "normal",
      fontWeight: 500,
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "2px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    formSectionTitle: {},
    formHighlightTitle: {},
    formText: {},
    formSummaryValue: {
      fontFamily: "normal",
      fontWeight: 500,
      fontSize: "14px",
      color: "gray.500",
    },
    formDiscountKey: {},
    pdpProductDescription: {
      letterSpacing: "0.5px",
      lineHeight: "24px",
      fontSize: "15px",
      color: "gray.500",
    },
  },
  defaultProps: {},
};

// eslint-disable-next-line import/no-default-export
export default customText;
