const heading = {
  baseStyle: {
    fontFamily: "normal",
    letterSpacing: "0.8px",
    fontWeight: 400,
    fontSize: "25px",
    color: "gray.500",
  },
  variants: {
    productName: {
      fontFamily: "normal",
      fontWeight: 500,
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "20px",
      lineHeight: "30px",
      color: "gray.500",
    },
    pdpTotalPrice: {
      fontFamily: "normal",
      fontWeight: 500,
      fontSize: "26px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    accordionTitle: {
      textTransform: "uppercase",
      letterSpacing: "2px",
      fontSize: "20px",
    },
    orderConfirmationTitle: {
      fontSize: "30px",
      textTransform: "uppercase",
      letterSpacing: "2px",
      color: "white",
    },
    orderConfirmationSubtitle: {
      color: "white",
    },
    orderDetailsBoxTitle: {
      color: "gray.500",
    },
  },
  sizes: {
    hero_subHeading: {
      color: "white",
      fontSize: "25px",
      lineHeight: "36px",
      textAlign: "center",
      gridColumn: {
        base: "1 / span 12",
        md: "4 / span 6",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default heading;
