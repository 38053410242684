import { useRouter } from "next/router";
import { stringify } from "qs";

import { ColorSwatch, SearchProductType } from "@/dep_types";

import { getArrayFromQueryParam } from "../helpers/getArrayFromQueryParam";
/**
 * Get the link for the product card. This is determined with a
 * combination of the product slug, the selected swatch and the
 * filters that are applied in the url query.
 */
export function useGetProductCardLink(
  product: SearchProductType,
  swatch?: ColorSwatch,
) {
  const { query } = useRouter();

  /**
   * Get the params that are potentially on the
   * product slug
   */
  const slugArr = product.slug.split("?");
  const baseUrl = slugArr[0];
  const productParams = new URLSearchParams(slugArr[1]);

  /**
   * Get the condition param from the url query.
   * First we check if the conditions are an array or a string.
   * If it's an array, we map the conditions to lower case.
   * If it's a string, we convert it to an array.
   */
  const conditionsParams = new URLSearchParams();

  const { conditions } = query;
  const conditionsArr = getArrayFromQueryParam(conditions);

  if (conditionsArr?.includes("good")) {
    conditionsParams.append("c", "1");
  } else if (conditionsArr?.includes("fair")) {
    conditionsParams.append("c", "2");
  } else if (conditionsArr?.includes("bad")) {
    conditionsParams.append("c", "3");
  }

  const newQuery = {
    ...Object.fromEntries(productParams.entries()),
    ...Object.fromEntries(conditionsParams.entries()),
    ...(swatch && { color: swatch.color }),
  };

  const queryString = stringify(newQuery).replaceAll("%20", "+");
  const urlQuery = queryString ? `?${queryString}` : "";

  return `/products/${baseUrl}${urlQuery}`;
}
