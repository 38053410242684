const colorButton = {
  baseStyle: {
    display: "flex",
    position: "relative",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "transparent",
    opacity: 1,
    padding: 1,
    cursor: "pointer",
    _hover: {
      border: "1px solid",
      borderColor: "gray.300",
    },
  },
  variants: {
    selected: {
      borderColor: "secondary.600",
      _hover: {
        border: "1px solid",
        borderColor: "secondary.600",
      },
    },
    unavailable: {
      opacity: 0.6,
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default colorButton;
