import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import React, { FC } from "react";

import InformationIcon from "@/dep_components/icons/InformationIcon";

type Props = {
  label: React.ReactNode;
  dataTestId?: string;
};

const InfoPopover: FC<Props> = ({ label, dataTestId }: Props) => (
  <Popover>
    <PopoverTrigger>
      <Box ml={2} cursor="pointer" data-testid={dataTestId} color="black">
        <InformationIcon />
      </Box>
    </PopoverTrigger>
    <PopoverContent
      _focus={{
        boxShadow: "none",
        outline: "none",
      }}
    >
      <PopoverArrow bg="#0B2529" />
      <PopoverBody
        backgroundColor="#0B2529"
        color="white"
        fontFamily="normal"
        fontWeight="medium"
        borderRadius="3px"
        fontSize="14px"
      >
        {label}
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

InfoPopover.defaultProps = {
  dataTestId: undefined,
};
// eslint-disable-next-line import/no-default-export
export default InfoPopover;
