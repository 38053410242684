import APIFAQType from "@/dep_types/content/api/components/faq";
import { FAQType } from "@/dep_types/content/components";

import textMultipleMapper from "./textMultiple";

const faq = (apiFaqData: APIFAQType): FAQType => ({
  id: apiFaqData.id,
  question: apiFaqData.question,
  answer: apiFaqData.answer,
  categoriesEnabledFor:
    apiFaqData.categoriesEnabledFor?.map((category) =>
      textMultipleMapper(category),
    ) || [],
});

// eslint-disable-next-line import/no-default-export
export default faq;
