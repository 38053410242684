import { Box, ButtonProps } from "@chakra-ui/react";

import TrackingService from "@/dep_services/tracking/trackingService";
import { LinkedButtonType } from "@/dep_types/content/components";

import { CTAButton } from "@/dep_components/Commons/Buttons/CTAButton";
import DynamicIcon from "@/dep_components/icons/DynamicIcon";

export function LinkedCTAButton({
  variant,
  url,
  text,
  opensInNewWindow,
  trackingInfo,
  icon,
  iconWidth,
  sx,
  ...other
}: LinkedButtonType & ButtonProps) {
  const hasTracking = trackingInfo?.name && trackingInfo?.location;
  const onClick = () => {
    hasTracking &&
      TrackingService.trackButtonClick(
        trackingInfo?.name,
        trackingInfo?.location,
      );
  };

  return (
    <CTAButton
      {...other}
      href={url}
      onClick={onClick}
      variant={variant}
      target={opensInNewWindow ? "_blank" : "_self"}
      sx={sx}
    >
      {icon && (
        <Box
          as="span"
          w={iconWidth || "20px"}
          lineHeight="12px"
          mr={text ? 2 : 0}
        >
          <DynamicIcon source={icon.source} />
        </Box>
      )}
      {text}
    </CTAButton>
  );
}
