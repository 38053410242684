/* eslint-disable sonarjs/no-duplicate-string */
const brandPartnerShipHero = {
  parts: ["section", "brands", "title", "subtitle", "button"],
  baseStyle: {
    section: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "normal",
      color: "white",
      justifyContent: "center",
      paddingY: 14,
    },
    brands: {
      display: "flex",
      gridColumn: {
        base: "span 12",
        md: "2 / span 6",
        lg: "2 / span 4",
      },
    },
    title: {
      gridColumn: {
        base: "span 12",
        md: "2 / span 6",
        lg: "2 / span 4",
      },
      textAlign: "left",
      fontSize: 42,
      fontFamily: "normal",
      fontWeight: 700,
      lineHeight: "1.15",
      letterSpacing: "-.03em",
    },
    subtitle: {
      gridColumn: {
        base: "span 12",
        md: "2 / span 6",
        lg: "2 / span 4",
      },
      fontSize: 16,
      fontFamily: "normal",
      fontWeight: 400,
      textAlign: "left",
      lineHeight: "1.48",
      letterSpacing: "-.03em",
    },
    button: {
      gridColumn: {
        base: "span 12",
        md: "2 / span 6",
        lg: "2 / span 4",
      },
      width: { base: "100%", md: "330px" },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default brandPartnerShipHero;
