import {
  Box,
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalFooter as ChakraModalFooter,
  ModalHeader as ChakraModalHeader,
  ModalOverlay as ChakraModalOverlay,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import { Heading } from "../Heading";

export type ModalSizeType = "sm" | "md" | "lg" | "full";

export const sizeMapping: Record<ModalSizeType, string> = {
  sm: "md",
  md: "xl",
  lg: "4xl",
  full: "full",
};

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  size?: ModalSizeType;
};

export function Modal({
  isOpen,
  onClose,
  title,
  size = "md",
  children,
}: PropsWithChildren<ModalProps>) {
  const mappedSize = sizeMapping[size];

  return (
    <ChakraModal size={mappedSize} isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent>
        {title && (
          <ChakraModalHeader textAlign="center">
            <Heading as="h3">{title}</Heading>
          </ChakraModalHeader>
        )}
        <ChakraModalCloseButton />
        <ChakraModalBody>
          <Box>{children}</Box>
        </ChakraModalBody>
        <ChakraModalFooter />
      </ChakraModalContent>
    </ChakraModal>
  );
}
