import { Flex } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  onClick(): void;
};

const HamburgerButton: FC<Props> = ({ onClick }) => (
  <Flex as="button" type="button" onClick={onClick} marginRight="5">
    <svg
      fill="black"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Menu</title>
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default HamburgerButton;
