/* eslint-disable sonarjs/prefer-immediate-return */
import axios from "axios";

interface GenericCmsQueryResponse<T> {
  data: {
    attributes: T;
  }[];
}

export async function GenericCmsQuery<T>(
  path: string,
  query: string,
): Promise<Awaited<T>> {
  const response = await axios
    .get<GenericCmsQueryResponse<T>>(
      `${process.env.NEXT_PUBLIC_CMS_API_URL}/${path}?${query}`,
    )
    .then(({ data }) => data?.data?.[0]?.attributes)
    .catch((err) => {
      /**
       * TODO: We should throw an error to datadog whenever
       * we have that capability
       */
      console.error({
        err,
        path,
        message: "Something went wrong.",
      });

      throw Error(`Something went wrong fetch CMS ${path}.`);
    });

  return response;
}
