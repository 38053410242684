/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PartnerIds from "@/dep_constants/partnerIds";
import tShirtSizes from "@/dep_constants/productSizes";
import Utils from "@/dep_helpers/Utils";
import ProductImage from "@/dep_types/commerce-api/productImage";
import { capitalizeFirstLetter } from "@/helpers/capitalizeFirstLetter";

import { compareByWeightOrNumber } from "@/dep_components/Product/VariantSelection/ProductOptionsContainer.helpers";

import HttpResponse from "./http/HttpResponse";
import API from "./httpService";

import {
  ALL_COLLECTION_TYPES,
  conditionsSummary,
  conditionTypes,
  productSaleTypes as allProductSaleTypes,
  productType,
} from "../dep_constants";
import DatesHelper from "../dep_helpers/DatesHelper";
import ProductHelper from "../dep_helpers/ProductHelper";
import {
  BaseConditionSummary,
  ConditionKey,
  InitialSelectedGroupedVariant,
  PriceDecrementingConditionKey,
  PriceDecrementingTierConditionSummary,
  ProductType,
  RentalDatesType,
  SearchProductType,
  SetProductType,
  VariantType,
} from "../dep_types";
import {
  ApiParameterFacet,
  ApiResponse,
  ProductSearchApiResponse,
  ProductSearchWhitelabelResponse,
  WhitelabelParameterFacet,
} from "../dep_types/ApiTypes";
import {
  AvailabilityType,
  FetchProductsForCollectionParamsType,
  ProductCollectionInputAPI,
  ProductSaleTypes,
} from "../dep_types/ProductTypes";
import { commonKeys } from "../dep_whitelabel/commonKeys";

const includeUnpublishedSetItems = commonKeys.GET_UNPUBLISHED_SET_ITEMS;

// eslint-disable-next-line import/no-default-export
export default class ProductsService {
  static initializeSetForSizeSelection = (product: SetProductType): void => {
    // Sets
    product.initialSelectedVariants = [];

    const maxAmountOfPeople =
      ProductHelper.getMaxNumberOfSelectionsForSet(product);

    product.amountOfPeople = maxAmountOfPeople;

    // Initializing Empty object for Non Paginated sets Variant Selection
    const variantGroup: InitialSelectedGroupedVariant =
      ProductHelper.getInitialVariantGroupForSetProduct(product);

    product.initialSelectedVariants.push(variantGroup);

    // Initializing Empty object for Paginated sets Variant Selection
    for (let i = 0; i < maxAmountOfPeople; i++) {
      const vg: InitialSelectedGroupedVariant =
        ProductHelper.getInitialVariantGroupForSetProduct(product, true);

      product.initialSelectedVariants.push(vg);
    }

    product.hasVariantSelectionPages =
      product.initialSelectedVariants.length > 1;
  };

  static initializeResaleVariants(product: ProductType): void {
    const productConditionsSummary = { ...conditionsSummary };
    const resaleVariants: VariantType[] = [];

    const conditionKeys: ConditionKey[] = [
      conditionTypes.GOOD.key,
      conditionTypes.FAIR.key,
      conditionTypes.BAD.key,
    ];

    product.variants.forEach((variant) => {
      if (variant.conditions) {
        conditionKeys.forEach((conditionKey) => {
          const conditionValue = (variant.conditions as BaseConditionSummary)[
            conditionKey
          ];

          productConditionsSummary[conditionKey] += conditionValue;
          const selectedConditionType =
            ProductHelper.getConditionType(conditionKey);
          const priceDecrementingKey =
            selectedConditionType?.priceDecrementingKey as PriceDecrementingConditionKey;
          const originalPriceDecrementingAvailability = variant.conditions
            ? (variant.conditions as PriceDecrementingTierConditionSummary)[
                priceDecrementingKey
              ]
            : undefined;

          resaleVariants.push({
            ...variant,
            parameters: {
              ...variant.parameters,
              condition: conditionKey,
            },
            resaleMaxAvailability: conditionValue,
            resaleMaxAvailabilityPriceDecrementing:
              originalPriceDecrementingAvailability,
            originalPriceDecrementingAvailability,
          });
        });
      }
    });

    product.conditionsSummary = productConditionsSummary;
    product.resaleVariants = resaleVariants;
  }

  static async getProduct(
    partnerId: string,
    slug: string,
  ): Promise<ProductType | null> {
    const params = {};

    (params as any).getUnpublishedSetItems = !!includeUnpublishedSetItems;

    const response: ApiResponse = await API.get(
      partnerId,
      `/product/slug/${slug}`,
      params,
    ).then((r: HttpResponse) => r.get());

    if (response.error) {
      return null;
    }

    const product = response.data;

    ProductHelper.mapVariantsOptions(product);

    product.isSet = product.productType === productType.SET;

    if (product.isSet) {
      ProductsService.initializeSetForSizeSelection(product);
    } else {
      product.initialSelectedVariants = {};
    }

    if (product.productSaleType !== allProductSaleTypes.RENTAL) {
      ProductsService.initializeResaleVariants(product);
    }

    if (product.cloudinaryImages && product.cloudinaryImages.length > 0) {
      product.images = product.cloudinaryImages;
    }
    const defaultCarouselImages =
      ProductHelper.getProductDefaultImages(product);

    product.defaultCarouselImages = defaultCarouselImages;
    product.defaultMainImage =
      ProductHelper.getDefaultImage(defaultCarouselImages) ?? null;

    // For burton partner only. Put first video as second image. Any other video should go to the end.
    if (
      `${partnerId}` === PartnerIds.BURTON &&
      product.defaultCarouselImages.length > 0
    ) {
      //
      const all = product.defaultCarouselImages;
      const images = all.filter((i: ProductImage) => !i.url.endsWith("mp4"));
      const videos = all.filter((i: ProductImage) => i.url.endsWith("mp4"));

      product.defaultCarouselImages = [product.defaultMainImage];
      if (videos.length > 0) {
        product.defaultCarouselImages.push(videos[0]);
        // remove first video
        videos.shift();
        // remove first image
        images.shift();
        // Add all images
        product.defaultCarouselImages = [
          ...product.defaultCarouselImages,
          ...images,
          ...videos,
        ];
        product.images = product.defaultCarouselImages;
      }
    }

    if (product.cloudinaryImageSizeChart) {
      [product.sizeChart] = product.cloudinaryImageSizeChart;
    }

    return product;
  }

  static async getProductSlugByPartnerProductId(
    partnerId: string,
    partnerProductId: string,
  ): Promise<string | null> {
    const response: ApiResponse = await API.get(
      partnerId,
      `/product/${partnerProductId}/slug`,
    ).then((r: HttpResponse) => r.get());

    if (response.error) {
      return null;
    }

    return response.data.slug;
  }

  static async queryProducts(
    partnerId: string,
    params: any,
  ): Promise<ProductSearchApiResponse> {
    const response: ProductSearchApiResponse = await API.get(
      partnerId,
      "/search",
      params,
    ).then((r: HttpResponse) => r.get());

    return response;
  }

  static async getProductAvailability(
    partnerId: string,
    id: number | string,
    rentalDates: RentalDatesType,
  ): Promise<AvailabilityType[] | null> {
    const paramsRentalDates =
      DatesHelper.parseRentalDatesToAPIParamsOrDefault(rentalDates);

    const params: any = {};

    params.fromDate = paramsRentalDates.startDate;
    params.toDate = paramsRentalDates.endDate;

    const response: ApiResponse = await API.get(
      partnerId,
      `/Product/${id}/variant/availability`,
      params,
    ).then((r: HttpResponse) => r.get());

    if (!response.data || !response.data.length) {
      return null;
    }

    return response.data as AvailabilityType[];
  }

  static async getVariant(
    partnerId: string,
    id: number | string,
    variant: number,
  ): Promise<VariantType | null> {
    const response: ApiResponse = await API.get(
      partnerId,
      `/Product/${id}/variant/${variant}`,
    ).then((r: HttpResponse) => r.get());

    if (!response.data) {
      return null;
    }

    return response.data as VariantType;
  }

  /**
   * Deprecated. Will be replaced with search endpoint using opp = true
   * @param partnerId
   */
  static async getPopularProducts(
    partnerId: string,
  ): Promise<SearchProductType[]> {
    const response: ApiResponse = await API.get(
      partnerId,
      "/Product/popular",
    ).then((r: HttpResponse) => r.get());

    if (!response.data || !response.data.length) {
      return [];
    }

    return response.data;
  }

  static buildFilterForCollectionProducts(
    productConfig: FetchProductsForCollectionParamsType,
  ): ProductCollectionInputAPI {
    const {
      categoryId,
      collectionId,
      collectionIds,
      brandIds,
      productSaleTypes,
      onlyPopularProducts,
      conditions,
      sizeIds,
      genderIds,
      seasonIds,
      colorIds,
      capacityIds,
      sortBy,
      priceRange,
      excludeProducts,
      suggestedCollectionIds,
      facetsToInclude,
      includeVariants,
      rentalDates,
      weight,
      pageNumber = 1,
      pageSize = 24,
    } = productConfig;

    const params: ProductCollectionInputAPI = {
      PageSize: pageSize,
      PageNumber: pageNumber,
    };

    if (categoryId) {
      params.cat = categoryId;
    }

    if (collectionId && collectionId !== ALL_COLLECTION_TYPES) {
      params.cId = [collectionId];
    } else if (collectionIds) {
      params.cId = collectionIds;
    }

    if (brandIds) {
      params.bIds = brandIds;
    }

    if (onlyPopularProducts) {
      params.opp = onlyPopularProducts;
    }

    if (
      (sizeIds && sizeIds.length > 0) ||
      (genderIds && genderIds.length > 0) ||
      (colorIds && colorIds.length > 0) ||
      (capacityIds && capacityIds.length > 0)
    ) {
      params.p = JSON.stringify([
        ...(sizeIds?.map((sizeId) => ({ Size: sizeId })) || []),
        ...(genderIds?.map((genderId) => ({ Gender: genderId })) || []),
        ...(colorIds?.map((colorId) => ({ Color: colorId.toLowerCase() })) ||
          []),
        ...(capacityIds?.map((capacityId) => ({
          Capacity: capacityId.toLowerCase(),
        })) ?? []),
      ]);
    }

    if (seasonIds && seasonIds.length > 0) {
      params.sea = seasonIds;
    }

    if (priceRange && priceRange.lp && priceRange.up) {
      params.lp = priceRange.lp.toString();
      params.up = priceRange.up.toString();
    }
    if (productSaleTypes && Object.keys(productSaleTypes).length > 0) {
      params.pst =
        productSaleTypes.length > 1
          ? allProductSaleTypes.BOTH.toString()
          : ProductSaleTypes.find(
              (p) =>
                p.id.toString() === productSaleTypes[0] ||
                p.enumValue.toString() === productSaleTypes[0],
            )?.enumValue;
    }

    if (conditions) {
      params.con = conditions;
    }

    if (excludeProducts) {
      params.ep = true;
    }

    if (includeVariants) {
      params.iv = true;
    }

    if (suggestedCollectionIds) {
      params.scIds = suggestedCollectionIds;
    }

    if (facetsToInclude) {
      params.fs = facetsToInclude;
    }

    if (sortBy) {
      params.s = sortBy;
    }

    if (rentalDates) {
      params.f = rentalDates.startDate as string;
      params.t = rentalDates.endDate as string;
    }

    if (weight) {
      params.w = weight;
    }

    return params;
  }

  static mapApiFacetToWhitelabelFacet(
    parameterFacet?: ApiParameterFacet[],
  ): WhitelabelParameterFacet[] {
    return (
      parameterFacet?.map((item) => ({
        id: item.key,
        name: item.key,
        count: item.count,
      })) ?? []
    );
  }

  static mapToSizeFacet(
    partnerId: string,
    sizeFacets?: ApiParameterFacet[],
  ): WhitelabelParameterFacet[] {
    const whitelabelSizeFacets = this.mapApiFacetToWhitelabelFacet(sizeFacets);
    const sizeValues = whitelabelSizeFacets.map((sizeFacet) => ({
      ...sizeFacet,
      name:
        `${partnerId}` === PartnerIds.CITIZENRY
          ? capitalizeFirstLetter(sizeFacet.name)
          : Utils.capitalizeFirstCharOrCapitalizeAllIfWordIsLessThanFiveChars(
              sizeFacet.name,
            ),
    }));

    const clothSizesGroup = sizeValues.filter((item) =>
      tShirtSizes.includes(item.name.toString().toLowerCase()),
    );
    const numericSizesGroup = sizeValues.filter((item) =>
      Number.isInteger(parseInt(item.name[0], 10)),
    );
    const otherSizesGroup = sizeValues.filter(
      (item) => ![...clothSizesGroup, ...numericSizesGroup].includes(item),
    );

    clothSizesGroup.sort((a, b) => compareByWeightOrNumber(a.name, b.name));

    numericSizesGroup.sort((a, b) => compareByWeightOrNumber(a.name, b.name));

    otherSizesGroup.sort((a, b) => a.name.localeCompare(b.name));

    return [...clothSizesGroup, ...numericSizesGroup, ...otherSizesGroup];
  }

  static mapToParameterFacet(
    parametersFacets?: ApiParameterFacet[],
  ): WhitelabelParameterFacet[] {
    return (
      parametersFacets?.map((parametersFacet) => ({
        id: parametersFacet.key,
        name: capitalizeFirstLetter(parametersFacet.key),
        count: parametersFacet.count,
        metadata: parametersFacet.metadata,
      })) ?? []
    );
  }

  static async fetchProductsForCollection(
    productConfig: FetchProductsForCollectionParamsType,
  ): Promise<ProductSearchWhitelabelResponse> {
    const params =
      ProductsService.buildFilterForCollectionProducts(productConfig);
    const partnerId = productConfig.partnerId as string;
    const result = await ProductsService.queryProducts(partnerId, params);

    return {
      data: {
        products: result.data?.products ?? [],
        suggestedProducts: result.data?.suggestedProducts,
        facets: {
          brandFacets: result.data?.facets?.brandFacets ?? [],
          parametersFacet: {
            size: this.mapToSizeFacet(
              partnerId,
              result.data?.facets?.parametersFacet?.size,
            ),
            gender: this.mapToParameterFacet(
              result.data?.facets?.parametersFacet?.gender,
            ),
            season: this.mapToParameterFacet(
              result.data?.facets?.parametersFacet?.season,
            ),
            color: this.mapToParameterFacet(
              result.data?.facets?.parametersFacet?.color,
            ),
            capacity: this.mapToParameterFacet(
              result.data?.facets?.parametersFacet?.capacity,
            ),
          },
        },
      },
      pagination: result.pagination,
    } as ProductSearchWhitelabelResponse;
  }

  static getConditionKeyFromQueryParams(
    conditionParam?: string,
  ): string | null {
    if (!conditionParam) {
      return null;
    }

    return (
      Object.values(conditionTypes).find(
        (condition) => condition.tierNumber.toString() === conditionParam,
      )?.key ?? null
    );
  }
}
