/* eslint-disable sonarjs/no-duplicate-string */
import { SystemStyleObject } from "@chakra-ui/react";

const borderRadius = "10px";

const startOrEndDateStyles = (
  isEndDate: boolean,
  isRange = false,
): SystemStyleObject => {
  const rightBorderRadius = isEndDate && !isRange ? borderRadius : 0;
  const leftBorderRadius = isEndDate || isRange ? 0 : borderRadius;

  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "primary.50",
    color: "white",
    borderTopLeftRadius: leftBorderRadius,
    borderBottomLeftRadius: leftBorderRadius,
    borderTopRightRadius: rightBorderRadius,
    borderBottomRightRadius: rightBorderRadius,
    "& > abbr": {
      zIndex: 1,
    },
    _after: {
      content: '""',
      backgroundColor: "primary.600",
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius,
      width: "100%",
      height: "100%",
    },
    _disabled: {
      _after: {
        backgroundColor: "transparent",
      },
    },
  };
};

const startDateStyles = startOrEndDateStyles(false);
const endDateStyles = startOrEndDateStyles(true);
const activeRangeStyles = startOrEndDateStyles(false, true);

const activeStyles: SystemStyleObject = {
  backgroundColor: "primary.600",
  color: "white",
  borderRadius,
};

const disabledTextColor = "gray.300";

const neighboringMonthStyles: SystemStyleObject = {
  backgroundColor: "transparent",
  color: disabledTextColor,
};

const styles: SystemStyleObject = {
  ".react-calendar": {
    border: "none",
    fontFamily: "normal",
    fontWeight: "normal",
  },
  ".react-calendar--doubleView": {
    width: "100%",
  },
  ".react-calendar--doubleView .react-calendar__month-view__days__day--neighboringMonth":
    {
      visibility: "hidden",
    },
  ".react-calendar--doubleView .react-calendar__viewContainer, .react-calendar--doubleView .react-calendar__viewContainer > *":
    {
      margin: 0,
    },
  ".react-calendar--doubleView .react-calendar__viewContainer > :first-of-type":
    {
      marginRight: 10,
    },
  "abbr[title]": {
    textDecoration: "none",
  },
  ".react-calendar__month-view__weekdays__weekday": {
    fontWeight: "normal",
    fontSize: "12px",
    color: "gray.500",
    textTransform: "none",
  },
  ".react-calendar__month-view__weekdays": {
    marginBottom: 4,
  },
  ".react-calendar__month-view__days": {
    rowGap: "12px",
  },
  ".react-calendar__tile": {
    position: "relative",
    fontSize: "14px",
    fontWeight: "medium",
    color: "black",
    lineHeight: "auto",
    overflow: "visible !important",
  },
  ".react-calendar__tile--active, .react-calendar__tile--hover": {
    position: "relative",
    backgroundColor: "primary.50",
    color: "black",
  },
  ".react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--active":
    {
      base: {
        backgroundColor: "primary.50",
        color: "black",
      },
      md: neighboringMonthStyles,
    },
  ".react-calendar__tile--rangeStart": startDateStyles,
  ".react-calendar__tile--rangeEnd": endDateStyles,
  ".react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus":
    activeStyles,
  ".react-calendar__tile--range:enabled:hover, .react-calendar__tile--range:enabled:focus":
    activeRangeStyles,
  "..react-calendar__tile--hoverEnd.react-calendar__tile:enabled:hover, ..react-calendar__tile--hoverEnd.react-calendar__tile:enabled:focus":
    endDateStyles,
  ".react-calendar__tile--hasActive": activeStyles,
  ".react-calendar__tile--hoverStart": activeStyles,
  ".react-calendar__tile--hoverEnd": endDateStyles,
  ".react-calendar__tile--rangeEnd:enabled:hover, .react-calendar__tile--rangeEnd:enabled:focus":
    endDateStyles,
  ".react-calendar__tile--hoverStart:enabled:hover, .react-calendar__tile--hoverStart:enabled:focus":
    startDateStyles,
  ".react-calendar__tile--hoverEnd:enabled:hover, .react-calendar__tile--hoverEnd:enabled:focus":
    endDateStyles,
  ".react-calendar__tile--rangeStart:enabled:hover, .react-calendar__tile--rangeStart:enabled:focus":
    startDateStyles,
  ".react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--rangeEnd":
    {
      base: activeStyles,
      md: neighboringMonthStyles,
    },
  ".react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--rangeStart":
    {
      base: activeStyles,
      md: neighboringMonthStyles,
    },
  ".react-calendar__tile--rangeStart:enabled:focus, .react-calendar__tile--rangeEnd:enabled:focus":
    {
      outline: "5px auto",
      outlineOffset: "1px",
      outlineColor: "gray.500",
    },
  ".react-calendar__tile:disabled, .react-calendar__month-view__days__day--neighboringMonth":
    {
      backgroundColor: "transparent",
      color: disabledTextColor,
    },
  ".react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--hoverEnd":
    {
      _after: {
        content: "none",
      },
    },
  ".react-calendar__tile:disabled": {
    textDecoration: "line-through",
    cursor: "not-allowed",
  },
  ".react-calendar__tile--lessThanMinRange": {
    color: disabledTextColor,
    cursor: "default",
    position: "relative",
  },
  ".react-calendar__tile--lessThanMinRange:disabled": {
    textDecoration: "none",
  },
  ".react-calendar__tileInfoMessageTrigger:hover, .react-calendar__tileInfoMessageTrigger:focus":
    {
      outline: "none",
      border: "2px solid",
      borderColor: disabledTextColor,
      borderRadius,
      zIndex: 1,
    },
  ".react-calendar__tileInfoMessageTrigger:hover + .react-calendar__tileInfoMessage":
    {
      display: "flex",
    },
  ".react-calendar__tileInfoMessageTrigger:focus + .react-calendar__tileInfoMessage":
    {
      display: "flex",
    },
  ".react-calendar__tile--hoverStart:disabled.react-calendar__tile--lessThanMinRange, .react-calendar__tile--hoverEnd:disabled.react-calendar__tile--lessThanMinRange":
    {
      backgroundColor: "transparent",
      color: disabledTextColor,
      _after: {
        backgroundColor: "transparent",
      },
    },
};

// eslint-disable-next-line import/no-default-export
export default styles;
