import {
  Box,
  Grid,
  ResponsiveObject,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

import TrackingService from "@/dep_services/tracking/trackingService";
import { SearchProductType } from "@/dep_types";
import EventLocationType from "@/dep_types/EventLocationType";
import { usePartnerData } from "@/hooks/usePartnerData";

import { ProductCardBrandName } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardBrandName";
import { ProductCardButton } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardButton";
import { ProductCardImage } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardImage";
import { ProductCardOrginalPrice } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardOriginalPrice";
import { ProductCardPriceRange } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardPriceRange";
import { ProductCardProductName } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardProductName";
import { ProductCardSwatches } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardSwatches";
import { useProductCard } from "@/dep_components/Commons/Products/ProductCard/hooks/useProductCard";
import { ProductCardProvider } from "@/dep_components/Commons/Products/ProductCard/providers/ProductCardProvider";
import { useArriveContext } from "@/dep_components/context";

export type ProductCardVariants = "horizontal" | "vertical";
type ProductCardInternalsProps = Omit<ProductCardProps, "product">;

function ProductCardInternals({
  variation = "vertical",
  location,
  shouldShowPrice = true,
}: Readonly<ProductCardInternalsProps>) {
  const { siteRentalDates } = useArriveContext();

  const { data } = usePartnerData();
  const { asPath } = useRouter();
  const { product, availableSwatches } = useProductCard();

  const variant = useBreakpointValue(
    typeof variation === "string" ? { base: variation } : variation,
  );

  const { card } = useMultiStyleConfig("ProductCard", { variant });

  const maxCardWith = 310;
  const handleClick = () => {
    TrackingService.trackProductClick(
      location,
      product,
      siteRentalDates,
      asPath,
    );
  };

  return (
    <Grid
      gridTemplateColumns={
        variant === "vertical"
          ? "minmax(0, 1fr)"
          : "minmax(0, 1fr) minmax(0, 3fr)"
      }
      gap={variant === "horizontal" ? 3 : 0}
      maxWidth={variant === "vertical" ? maxCardWith : ""}
      color="black"
      sx={card}
    >
      <Box>
        <ProductCardImage width={maxCardWith} onClick={handleClick} />
      </Box>
      <Box>
        {availableSwatches?.length > 0 && (
          <Box mt={6}>
            <ProductCardSwatches />
          </Box>
        )}
        {data?.hasMultipleBrands && (
          <ProductCardBrandName>{product.brand.name}</ProductCardBrandName>
        )}
        <Box mt="2" mb="2.5">
          <ProductCardProductName variant={variant} />
        </Box>

        {variant === "horizontal" && (
          <ProductCardButton onClick={handleClick} />
        )}

        {shouldShowPrice && (
          <Box>
            <ProductCardOrginalPrice />
            <Box mt={1}>
              <ProductCardPriceRange />
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export type ProductCardProps = {
  readonly location: EventLocationType;
  readonly product: SearchProductType;
  readonly variation?:
    | ProductCardVariants
    | ResponsiveObject<ProductCardVariants>;
  readonly shouldShowPrice?: boolean;
};

export function ProductCard({ product, ...rest }: ProductCardProps) {
  return (
    <ProductCardProvider product={product}>
      <ProductCardInternals {...rest} />
    </ProductCardProvider>
  );
}
