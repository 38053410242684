import { Icon } from "@chakra-ui/react";

type Props = {
  fill?: string;
};

function SearchIcon({ fill = "#002B45" }: Props) {
  return (
    <Icon
      width="22"
      height="22"
      viewBox="0 0 18 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.0001 15.076L12.4719 10.5479C14.5428 7.41832 13.6851 3.19923 10.5478 1.12832C7.41826 -0.942585 3.19917 -0.0848582 1.12826 3.05241C-0.942647 6.18196 -0.0849192 10.401 3.05235 12.472C5.32417 13.9788 8.27599 13.9788 10.5555 12.472L15.0837 17.0001L17.0078 15.076H17.0001ZM1.92417 6.80014C1.92417 4.11105 4.10326 1.92423 6.80008 1.92423C9.4969 1.92423 11.676 4.10332 11.676 6.80014C11.676 9.49696 9.4969 11.6761 6.80008 11.6761C4.11099 11.6761 1.92417 9.49696 1.92417 6.80014Z" />
    </Icon>
  );
}

// eslint-disable-next-line import/no-default-export
export default SearchIcon;
