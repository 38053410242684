const faqValueProp = {
  parts: ["title", "question", "answer"],
  baseStyle: {
    title: {
      fontWeight: 700,
      fontSize: "30px",
      letterSpacing: "2px",
      lineHeight: "42px",
      color: "gray.500",
    },
    question: {
      fontWeight: 700,
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      textAlign: "left",
      color: "gray.500",
    },
    answer: {
      fontWeight: 400,
      fontSize: "20px",
      letterSpacing: 0,
      lineHeight: "30px",
      color: "gray.500",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqValueProp;
