const faqContactUs = {
  parts: ["title", "body"],
  baseStyle: {
    title: {},
    body: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default faqContactUs;
