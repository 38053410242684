import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

import { Icon } from "@/revive/components/Icon";

import { CartProductItemType } from "../../../../dep_types/CartTypes";
import Label from "../../../Commons/Titles/Label";

type Props = {
  isSet: boolean;
  cartItem: CartProductItemType;
  isReadonly?: boolean;
  addQuantity?(cartItem: CartProductItemType): void;
  reduceQuantity?(cartItem: CartProductItemType): void;
  showMaxReachedAlert?: boolean;
  setShowMaxReachedAlert?(value: boolean): void;
};

type ButtonProps = {
  children: ReactNode;
  onClick?(): void;
};

const ModifyQuantityButton: FC<ButtonProps> = ({
  onClick,
  children,
}: ButtonProps) => (
  <Flex
    onClick={onClick}
    height="33px"
    justifyContent="center"
    alignItems="center"
    px="7px"
    color="black"
    fontFamily="normal"
    fontWeight="medium"
    fontSize="22px"
    _hover={{
      cursor: "pointer",
      backgroundColor: "gray.200",
    }}
  >
    {children}
  </Flex>
);

ModifyQuantityButton.defaultProps = {
  onClick: undefined,
};

const NewQuantitySelector: FC<Props> = ({
  isSet,
  isReadonly,
  addQuantity,
  reduceQuantity,
  cartItem,
  showMaxReachedAlert,
  setShowMaxReachedAlert,
}) => {
  if (isSet) {
    return null;
  }

  const showEditButtons = addQuantity && reduceQuantity && !isReadonly;

  if (!showEditButtons) {
    return (
      <Label size="md" mt="4px">
        {cartItem.quantity} Qty
      </Label>
    );
  }

  return (
    <Flex
      direction="row"
      position="relative"
      border="1px solid"
      borderColor="#EDEDEF"
      minWidth="87px"
      height="33px"
      borderRadius="7px"
      justifyContent="space-between"
      maxWidth="85px"
    >
      <ModifyQuantityButton
        onClick={() => reduceQuantity && reduceQuantity(cartItem)}
      >
        <Icon name="minus" size="sm" />
      </ModifyQuantityButton>
      <Flex justifyContent="center" alignItems="center" pt="2px">
        <Label size="md">{cartItem.quantity}</Label>
      </Flex>
      <Popover
        returnFocusOnClose={false}
        isOpen={!!showMaxReachedAlert}
        onClose={() => setShowMaxReachedAlert && setShowMaxReachedAlert(false)}
        placement="top"
        closeOnBlur
        closeOnEsc
      >
        <PopoverTrigger>
          <Box
            role="button"
            onClick={() => addQuantity && addQuantity(cartItem)}
          >
            <ModifyQuantityButton>
              <Icon name="plus" size="sm" />
            </ModifyQuantityButton>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          backgroundColor="black"
          borderRadius="4px"
          px="15px"
          py="10px"
          color="white"
          fontFamily="normal"
          lineHeight="16px"
          fontSize="14px"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        >
          <PopoverArrow bg="black" />
          <PopoverBody>
            You’ve reached the maximum available stock for this product
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

NewQuantitySelector.defaultProps = {
  isReadonly: false,
  addQuantity: undefined,
  reduceQuantity: undefined,
  showMaxReachedAlert: false,
  setShowMaxReachedAlert: undefined,
};

// eslint-disable-next-line import/no-default-export
export default NewQuantitySelector;
