export const menuItem = {
  baseStyle: {
    textTransform: "uppercase",
    fontSize: "lg",
    fontFamily: "title",
    letterSpacing: "wider",
    color: "primary.600",
    fontWeight: "bold",
  },
};
