import APIFAQType from "@/dep_types/content/api/components/faq";
import APIFaqsType from "@/dep_types/content/api/components/sections/faqs";
import { FAQSectionType } from "@/dep_types/content/sections";

import faqDataMapper from "../components/faq";
import buttonDataMapper from "../components/linkedButton";

const faqsFunc = (apiFaqsData: APIFaqsType): FAQSectionType => {
  let faqs = null;

  if (apiFaqsData.faq_group && apiFaqsData.faq_group.data !== null) {
    faqs = apiFaqsData.faq_group.data.attributes.faqs.map((faq: APIFAQType) =>
      faqDataMapper(faq),
    );
  }

  return {
    id: apiFaqsData.id,
    componentKey: apiFaqsData.__component || "",
    title: apiFaqsData.title,
    faqs,
    button: apiFaqsData.button ? buttonDataMapper(apiFaqsData.button) : null,
  };
};

// eslint-disable-next-line import/no-default-export
export default faqsFunc;
