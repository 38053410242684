/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteRequest from "./http/DeleteRequest";
import GetRequest from "./http/GetRequest";
import HttpResponse from "./http/HttpResponse";
import PostRequest from "./http/PostRequest";
import PutRequest from "./http/PutRequest";
import { QueryParams } from "./http/RequestParams";
import TrackingService from "./tracking/trackingService";

import OrderHelper from "../dep_helpers/OrderHelper";
import Utils from "../dep_helpers/Utils";
import OrderLog from "../dep_helpers/order/OrderLog";
import { RentalDatesLabelsType } from "../dep_types";
import { ApiResponse } from "../dep_types/ApiTypes";
import { OrderCardDataType, OrderType } from "../dep_types/OrderTypes";

// eslint-disable-next-line import/no-default-export
export default class HttpService {
  static get = async (
    partnerId: string,
    url: string,
    params?: QueryParams,
  ): Promise<HttpResponse> => new GetRequest(partnerId).execute(url, params);

  static post = async (
    partnerId: string,
    url: string,
    bodyParams: any,
  ): Promise<HttpResponse> =>
    new PostRequest(partnerId).execute(url, bodyParams);

  static put = async (
    partnerId: string,
    url: string,
    bodyParams?: any,
    queryParams?: any,
  ): Promise<HttpResponse> =>
    new PutRequest(partnerId).execute(url, queryParams, bodyParams);

  static delete = async (
    partnerId: string,
    url: string,
    queryParams?: any,
  ): Promise<HttpResponse> =>
    new DeleteRequest(partnerId).execute(url, queryParams);

  // Orders
  static getOrder = async (
    partnerId: string,
    orderNumber: string,
    lastName: string,
    zipcode: string,
  ): Promise<ApiResponse> => {
    const request = HttpService.get(
      partnerId,
      `/order/${orderNumber.toUpperCase()}`,
      {
        lastName,
        zipcode,
      },
    );

    return request.then((res) => res.get());
  };

  static createOrder = async (
    partnerId: string,
    order: OrderType,
    orderLog: OrderLog,
  ): Promise<ApiResponse> => {
    const request = HttpService.post(partnerId, "/order/v2", order);

    return request.then((res) =>
      res
        .processOnSuccess(async (data: any) => {
          await TrackingService.trackConversion(
            partnerId,
            orderLog,
            data.rentalOrder?.id,
            data.resaleOrder?.id,
          );
        })
        .get(),
    );
  };

  static updateOrderItemVariant = async (
    partnerId: string,
    orderId: string,
    orderItemId: string,
    variantId: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/item/${orderItemId}`,
      variantId,
    );

    return request.then((res) =>
      res
        .processOnSuccess(() =>
          TrackingService.trackOrderEdited(orderId, "Order Item Variant"),
        )
        .get(),
    );
  };

  static updateOrderItemQuantity = async (
    partnerId: string,
    orderId: string,
    orderItemId: string,
    quantity: number,
    currentTotal: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/item/${orderItemId}/quantity`,
      null,
      {
        quantity,
      },
    );

    return request.then((res) =>
      res
        .failOn((data: any) => {
          if (Utils.booleanExistsAndIsFalse(data.isValid)) {
            return { error: true, errorMessage: "Is not valid" };
          }

          return {};
        })
        .processOnSuccess((data: any) => {
          const { valueChange, totalDifference } =
            OrderHelper.calculateOrderTotalDifferenceForLogs(
              data.total,
              currentTotal,
            );

          //
          TrackingService.trackOrderEdited(
            orderId,
            "Order Item Quantity",
            valueChange,
            totalDifference,
            data.total,
          );
        })
        .get(),
    );
  };

  static updateOrderSetItemVariant = async (
    partnerId: string,
    orderId: string,
    orderSetGroupItemId: number,
    variantId: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/setGroupItem/${orderSetGroupItemId}`,
      variantId,
    );

    return request.then((res) =>
      res
        .processOnSuccess(() =>
          TrackingService.trackOrderEdited(orderId, "Order Set Item Variant"),
        )
        .get(),
    );
  };

  static updateOrderPayment = async (
    partnerId: string,
    orderId: string,
    cardData: OrderCardDataType,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/payment`,
      cardData,
    );

    return request.then((res) =>
      res
        .processOnSuccess(() =>
          TrackingService.trackOrderEdited(orderId, "Order Item Payment Data"),
        )
        .get(),
    );
  };

  static updateOrderCustomer = async (
    partnerId: string,
    orderId: string,
    customer: any,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/customer`,
      customer,
    );

    return request.then((res) =>
      res
        .processOnSuccess(() =>
          TrackingService.trackOrderEdited(
            orderId,
            "Order Customer Information",
          ),
        )
        .get(),
    );
  };

  static updateOrderDates = async (
    partnerId: string,
    orderId: string,
    newDates: RentalDatesLabelsType,
    currentTotal: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.put(
      partnerId,
      `/order/${orderId}/dates`,
      newDates,
    );

    return request.then((res) =>
      res
        .processOnSuccess((data: any) => {
          const { valueChange, totalDifference } =
            OrderHelper.calculateOrderTotalDifferenceForLogs(
              data.total,
              currentTotal,
            );

          //
          TrackingService.trackOrderEdited(
            orderId,
            "Order Dates Updated",
            valueChange,
            totalDifference,
            data.total,
          );
        })
        .get(),
    );
  };

  static removeOrderSetItem = async (
    partnerId: string,
    orderId: string,
    orderSetItemId: number,
    currentTotal: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.delete(
      partnerId,
      `/order/${orderId}/setItem/${orderSetItemId}`,
    );

    return HttpService.removeOrderItem(
      request,
      "Order Set Item Removed",
      orderId,
      currentTotal,
    );
  };

  static removeOrderSimpleItem = async (
    partnerId: string,
    orderId: string,
    orderSetItemId: number,
    currentTotal: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.delete(
      partnerId,
      `/order/${orderId}/item/${orderSetItemId}`,
    );

    return HttpService.removeOrderItem(
      request,
      "Order Item Removed",
      orderId,
      currentTotal,
    );
  };

  static cancelOrder = async (
    partnerId: string,
    orderId: string,
    orderTotal: number,
  ): Promise<ApiResponse> => {
    const request = HttpService.delete(partnerId, `/order/${orderId}`);

    return request.then((res) =>
      res
        .processOnSuccess(() =>
          TrackingService.trackOrderCancelled(orderId, orderTotal),
        )
        .get(),
    );
  };

  // Products

  static getProduct = async (
    partnerId: string,
    productId: number,
  ): Promise<ApiResponse> =>
    HttpService.get(partnerId, `/Product/${productId}`).then(
      (r: HttpResponse) => r.get(),
    );

  static getProductAvailability = async (
    partnerId: string,
    productId: number,
    params: QueryParams,
  ): Promise<ApiResponse> => {
    const request = HttpService.get(
      partnerId,
      `/Product/${productId}/variant/availability`,
      params,
    );

    return request.then((res) =>
      res
        .transform((data: any) => ({
          availability: data,
          variantOptions: OrderHelper.mapOrderAvailabilityToOptions(data),
        }))
        .get(),
    );
  };

  static getProductSetAvailability = async (
    partnerId: string,
    productId: number,
    setGroupId: number,
    params: QueryParams,
  ): Promise<ApiResponse> => {
    const productResponse = HttpService.getProduct(partnerId, productId);
    const request = HttpService.get(
      partnerId,
      `/Product/${productId}/setGroup/${setGroupId}/availability`,
      params,
    );

    return request.then((res: HttpResponse) =>
      res
        .transform(async (data: any) => {
          const variantOptions = OrderHelper.mapOrderAvailabilityToOptions(
            data,
            (await productResponse).data,
          );

          return {
            availability: data,
            variantOptions,
          };
        })
        .get(),
    );
  };

  // Protected Order methods
  protected static removeOrderItem = async (
    request: Promise<HttpResponse>,
    action: string,
    orderId: string,
    currentTotal: number,
  ): Promise<ApiResponse> => {
    const r = request;

    return r.then((res) =>
      res
        .processOnSuccess((data: any) => {
          const { valueChange, totalDifference } =
            OrderHelper.calculateOrderTotalDifferenceForLogs(
              data.total,
              currentTotal,
            );

          //
          TrackingService.trackOrderEdited(
            orderId,
            action,
            valueChange,
            totalDifference,
            data.total,
          );
        })
        .get(),
    );
  };
}
