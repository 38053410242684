import { Flex, FlexProps } from "@chakra-ui/react";
import React, { FC } from "react";

import CalendarDateTooltip from "./CalendarDateTooltip";

const CalendarTooltipsContainer: FC<FlexProps> = ({ ...other }) => (
  <Flex {...other}>
    <CalendarDateTooltip
      text="Delivery Date"
      popover={{
        heading: "About delivery date",
        bodyText:
          "Your order will arrive by 8pm of your selected date or may arrive 1-3 days prior to your selected delivery date.",
        placement: "bottom-start",
      }}
      marginRight={2.5}
    />
    <CalendarDateTooltip
      text="Return Date"
      popover={{
        heading: "About return date",
        bodyText:
          "Drop off your box at any FedEx or a FedEx affiliated shipping location by 11:59PM on your return date.",
        placement: "bottom-end",
      }}
    />
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default CalendarTooltipsContainer;
