import APILinkType from "@/dep_types/content/api/components/link";
import { LinkType } from "@/dep_types/content/components";

const links = (apiLinkData: APILinkType): LinkType => ({
  id: apiLinkData.id,
  url: apiLinkData.url,
  text: apiLinkData.text,
  opensInNewWindow: apiLinkData.opensInNewWindow,
});

// eslint-disable-next-line import/no-default-export
export default links;
