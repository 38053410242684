/* eslint-disable sonarjs/no-duplicate-string */
const text = {
  parts: [
    "cartCheckoutExtraInfo",
    "link",
    "accordionTitle",
    "menuCategory",
    "orderDetailsPrice",
    "orderDetailsOneLineLeftTextTotal",
    "orderDetailsOneLineLeftText",
    "shoppingCartPrice",
    "checkoutFormSummarySectionTitle",
    "formHighlightTitle",
  ],
  baseStyle: {
    cartCheckoutExtraInfo: {
      textTransform: "uppercase",
      fontWeight: 900,
      fontSize: "18px",
      fontFamily: "title",
      letterSpacing: "0.08px",
      color: "secondary.600",
    },
    cartCheckoutSubtotal: {
      textTransform: "uppercase",
      fontWeight: 900,
      fontSize: "26px",
      fontFamily: "title",
      letterSpacing: "0.08px",
      color: "primary.600",
    },
    link: {
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: "title",
      letterSpacing: "0.08px",
      color: "primary.600",
    },
    accordionTitle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontSize: "14px",
      color: "primary.600",
    },
    menuCategory: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontSize: "14px",
      color: "primary.600",
    },
    orderDetailsOneLineLeftTextTotal: {
      fontWeight: "bold",
      fontSize: "14px",
      fontFamily: "normal",
      color: "primary.600",
    },
    orderDetailsOneLineLeftText: {
      fontWeight: 300,
      fontSize: "14px",
      fontFamily: "normal",
      color: "primary.600",
    },
    orderDetailsPrice: {
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: "title",
      letterSpacing: "0.05em",
      color: "primary.600",
    },
    shoppingCartPrice: {
      fontFamily: "title",
      fontWeight: 600,
      letterSpacing: "0.05em",
      color: "primary.600",
    },
    checkoutFormSummarySectionTitle: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      fontSize: "18px",
      color: "primary.600",
    },
    formHighlightTitle: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      fontSize: "18px",
      color: "primary.600",
    },
    sortByBoxTitle: {
      color: "gray.500",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default text;
