import {
  VariantType,
  ConditionKey,
  PriceDecrementingConditionKey,
  ConditionSummary,
  SalePrice,
} from "@/dep_types";

function addPricesForCondition(
  prices: number[],
  conditions: ConditionSummary,
  salePrices: SalePrice[],
  condition: ConditionKey,
) {
  if (conditions[condition] && conditions[condition] > 0) {
    const salePrice = salePrices?.find(
      (price) => price.condition === condition,
    );
    const conditionArrayKey =
      `${condition}Array` as PriceDecrementingConditionKey;

    if (salePrice?.priceArray && conditions[conditionArrayKey]) {
      salePrice.priceArray.forEach((price, index) => {
        if (conditions[conditionArrayKey][index] > 0) {
          prices.push(price);
        }
      });
    }
  }
}

export function getProductPriceRange(variants: VariantType[]) {
  const prices: number[] = [];

  for (const variant of variants) {
    const { conditions, salePrices } = variant;

    if (conditions && salePrices) {
      (["bad", "fair", "good"] as ConditionKey[]).forEach((condition) => {
        addPricesForCondition(prices, conditions, salePrices, condition);
      });
    }
  }

  const sortedPrices = [...prices].sort((a, b) => a - b);

  return Array.from(new Set(sortedPrices));
}
