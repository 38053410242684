import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
} from "@chakra-ui/react";
import React, { FC } from "react";

import DateBox from "./DateBox";

import { datesScope } from "../../dep_constants";
import DatesHelper from "../../dep_helpers/DatesHelper";
import { useArriveContext } from "../context";

const DateSyncModal: FC = () => {
  const {
    dateSyncModalOpen,
    closeDateSyncModal,
    siteRentalDates,
    shoppingCartRentalDates,
    syncRentalDatesAndAddProductToCart,
  } = useArriveContext();

  const siteRentalDatesLabels =
    DatesHelper.parseGlobalRentalDatesToDisplayDate(siteRentalDates);

  const shoppingCartRentalDatesLabels =
    DatesHelper.parseGlobalRentalDatesToDisplayDate(shoppingCartRentalDates);

  return (
    <>
      <Modal isOpen={dateSyncModalOpen} onClose={closeDateSyncModal} size="xl">
        <ModalOverlay />
        <ModalContent padding={{ md: "15px" }}>
          <ModalHeader>Oops!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              color="#4e4d5d"
              fontFamily="normal"
              fontWeight="medium"
              pb="20px"
            >
              Looks like you have two different date ranges selected for your
              trip. Choose one and re-add this item to your cart
            </Flex>
            <Flex direction="column" maxW="350px" m="auto">
              <Flex
                onClick={() =>
                  syncRentalDatesAndAddProductToCart(datesScope.CART)
                }
                borderTop="3px solid #72B2DE"
                backgroundColor="#F8F8F9"
                _hover={{
                  color: "black",
                  cursor: "pointer",
                  backgroundColor: "#e7e7e7",
                }}
              >
                <DateBox
                  text="Delivery Date"
                  dateLabel={shoppingCartRentalDatesLabels.startDate as string}
                />
                <DateBox
                  text="Return Date"
                  dateLabel={shoppingCartRentalDatesLabels.endDate as string}
                />
              </Flex>

              <Box
                p="10px"
                textAlign="center"
                fontFamily="normal"
                fontWeight="bold"
                fontSize="18px"
              >
                or
              </Box>
              <Flex
                onClick={() =>
                  syncRentalDatesAndAddProductToCart(datesScope.SITE)
                }
                borderTop="3px solid #72B2DE"
                backgroundColor="#F8F8F9"
                _hover={{
                  color: "black",
                  cursor: "pointer",
                  backgroundColor: "#e7e7e7",
                }}
              >
                <DateBox
                  text="Delivery Date"
                  dateLabel={siteRentalDatesLabels.startDate as string}
                />
                <DateBox
                  text="Return Date"
                  dateLabel={siteRentalDatesLabels.endDate as string}
                />
              </Flex>
            </Flex>
            <br />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default DateSyncModal;
