const orderStatusStep = {
  parts: ["line", "number", "text"],
  baseStyle: {
    line: {
      position: "absolute",
      width: {
        base: "28px",
        lg: "41px",
      },
      height: "2px",
      top: {
        base: "14px",
        lg: "19px",
      },
      backgroundColor: "gray.400",
    },
    number: {
      width: {
        base: "30px",
        lg: "40px",
      },
      height: {
        base: "30px",
        lg: "40px",
      },
      ml: {
        base: "25px",
        lg: "40px",
      },
      fontSize: {
        base: "12px",
        lg: "16px",
      },
      borderRadius: "10px",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "normal",
      fontWeight: "bold",
      _first: { marginLeft: 0 },
      backgroundColor: "gray.400",
      color: "white",
    },
    text: {
      position: "absolute",

      marginTop: {
        base: "35px",
        lg: "50px",
      },
      overflowWrap: "normal",
      width: {
        base: "55px",
        lg: "80px",
      },
      textAlign: "center",
      height: "2px",
      fontSize: {
        base: "9px",
        lg: "12px",
      },
      fontFamily: "normal",
      fontWeight: "medium",
    },
  },
  variants: {
    active: {
      line: {
        backgroundColor: "primary.600",
      },
      number: {
        backgroundColor: "primary.600",
        color: "white",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderStatusStep;
