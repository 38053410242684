import { Box, Flex, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import CalendarIcon from "@/dep_components/icons/CalendarIcon";

type Props = {
  text: string;
  dateLabel?: string;
};

const DateBox: FC<Props> = ({ text, dateLabel }: Props) => (
  <Flex flexDirection="column" textAlign="left" mr="20px" ml="20px" pt="10px">
    <Flex flexDirection="row" alignItems="center">
      <Box mr="15px" width={{ base: "14px", md: "18px" }}>
        <CalendarIcon />
      </Box>
      <Box
        pt="5px"
        fontSize={{ base: "14px" }}
        color="#4E4D5D"
        fontFamily="normal"
        fontWeight="medium"
      >
        {text}
      </Box>
    </Flex>
    <Text
      mt="5px"
      mb="8px"
      fontSize="14px"
      color="#4E4D5D"
      fontFamily="normal"
      fontWeight="bold"
    >
      {dateLabel || "add date"}
    </Text>
  </Flex>
);

DateBox.defaultProps = {
  dateLabel: undefined,
};
// eslint-disable-next-line import/no-default-export
export default DateBox;
