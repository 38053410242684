const checkoutLayout = {
  parts: ["confirmationContainer"],
  baseStyle: {
    confirmationContainer: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      py: 8,
      px: 12,
      backgroundColor: "primary.600",
      w: "100%",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default checkoutLayout;
