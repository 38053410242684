import { Button, Flex, useStyleConfig } from "@chakra-ui/react";
import React, { FC, MouseEventHandler } from "react";

import DatesHelper from "@/dep_helpers/DatesHelper";
import { RentalDatesType } from "@/dep_types";

import useIsMobile from "@/dep_hooks/useIsMobile";

import CalendarClearDatesBtn from "./CalendarClearDatesBtn";
import CalendarSelectedRangeText from "./CalendarSelectedRangeText";
import CalendarTooltipsContainer from "./CalendarTooltipsContainer";

type Props = {
  calendarDates: RentalDatesType;
  submitRentalDates(): void;
  clearCalendarDates(): void;
};

const CalendarFooter: FC<Props> = ({
  calendarDates,
  submitRentalDates,
  clearCalendarDates,
}: Props) => {
  const saveDatesBtnStyles = useStyleConfig("Button", {
    variant: "newPrimary",
  });

  const isValidRentalDate = DatesHelper.rentalDatesAreValid(calendarDates);
  const hasOneValidDate =
    DatesHelper.rentalDatesArePartiallyValid(calendarDates);

  const isMobile = useIsMobile();

  return (
    <Flex marginTop={5} direction="column">
      {isMobile && <CalendarTooltipsContainer justifyContent="space-between" />}
      <Flex
        marginTop={{
          base: 4,
          md: 0,
        }}
        justify={{
          base: "space-between",
          md: "flex-end",
        }}
      >
        {isMobile && (
          <CalendarSelectedRangeText calendarDates={calendarDates} />
        )}
        {!isMobile && hasOneValidDate && (
          <CalendarClearDatesBtn clearCalendarDates={clearCalendarDates} />
        )}
        <Button
          onClick={submitRentalDates as MouseEventHandler}
          sx={saveDatesBtnStyles}
          maxWidth="89px"
          fontFamily="normal"
          fontSize="16px"
          fontWeight="medium"
          lineHeight={1.48}
          letterSpacing="-.03em"
          isDisabled={!isValidRentalDate}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarFooter;
