import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC, ReactElement, useEffect, useRef } from "react";

import isElementInViewport from "@/dep_helpers/window/isElementInViewport";

type Props = {
  children: ReactElement;
  isOpen: boolean;
  submitRentalDates: () => void;
  toggleComponent: ReactElement;
};

const DesktopDatePicker: FC<Props> = ({
  children,
  isOpen,
  submitRentalDates,
  toggleComponent,
}: Props) => {
  const popoverContentContainer = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && popoverContentContainer.current !== null) {
      const isPopoverInViewport = isElementInViewport(
        popoverContentContainer.current,
      );

      if (!isPopoverInViewport) {
        popoverContentContainer.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [isOpen]);

  return (
    <Popover isOpen={isOpen} onClose={submitRentalDates}>
      <PopoverTrigger>{toggleComponent}</PopoverTrigger>
      <PopoverContent
        maxW={{ md: "736px", xl: "824px" }}
        width={{ md: "736px", xl: "824px" }}
        borderRadius="30px"
        _focus={{
          boxShadow: "none",
          outline: "none",
        }}
        ref={popoverContentContainer}
      >
        <PopoverArrow />
        <PopoverBody
          paddingX={{
            md: 10,
            xl: 16,
          }}
          paddingY={7}
        >
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

// eslint-disable-next-line import/no-default-export
export default DesktopDatePicker;
