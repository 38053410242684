/* eslint-disable @typescript-eslint/no-explicit-any */
import { cmsPartnerIds, partnerNames } from "@/dep_constants/partnerIds";
import getSWRCacheValue from "@/dep_helpers/cache/getSWRCacheValue";
import generateCmsQueryString from "@/dep_helpers/content/generateCmsQueryString";
import { performCmsFetchRequest } from "@/dep_helpers/content/performCmsFetchRequest";
import APIPartnerResponseType from "@/dep_types/content/api/response/partnerResponse";
import { ResponseErrorType } from "@/dep_types/content/error";
import { PartnerResponseType } from "@/dep_types/content/partner";

import handleContentError from "../handleContentError";
import partnerDataMapper from "../mappers/partner/partner";

const getPartnerData = async (
  partnerId: string,
  isPreviewMode: boolean,
): Promise<PartnerResponseType> => {
  const relationsToPopulate = [
    "darkLogo",
    "lightLogo",
    "favicon",
    "headerInfo.navigationItems.image",
    "headerInfo.backToSiteButton",
    "footerInfo",
    "footerInfo.image",
    "footerInfo.link",
    "footerInfo.buttons",
    "footerInfo.buttons.icon",
    "footerLinks",
    "footerLinks.links",
    "footerLinks.socialIcons",
    "footerLinks.socialIcons.icon",
    "globalLabels",
    "product",
    "logoSize",
  ];

  const cmsPartnerId = cmsPartnerIds[partnerId];
  const cacheKey = `ecomm-whitelabel-partner11-${cmsPartnerId}`;
  const query = generateCmsQueryString(
    partnerId,
    relationsToPopulate,
    false,
    isPreviewMode,
  );

  const path = `/partners?${query}`;
  const requestCallback = (): Promise<any> => performCmsFetchRequest(path);

  const partnerName = partnerNames[partnerId];
  const defaultErrorMessage =
    "No error was returned by the CMS API -- Unknown issue occurred.";
  const errorPrefix = `There was an error getting the CMS data for the partner's data for ${partnerName} with message`;

  const requestData: APIPartnerResponseType & ResponseErrorType =
    await getSWRCacheValue(
      cacheKey,
      requestCallback,
      errorPrefix,
      defaultErrorMessage,
      isPreviewMode,
    );

  // TryCatch in the event that requestData is not what we expect
  // This should never happen because of our getSWRCacheValue
  // implementation but let's handle it just in case
  try {
    const partnerData = requestData.data[0];

    return partnerDataMapper(partnerData, requestData.error);
  } catch (error) {
    return handleContentError(error);
  }
};

// eslint-disable-next-line import/no-default-export
export default getPartnerData;
