import APILinkedTileType from "@/dep_types/content/api/components/linkedTile";
import { LinkedTileType } from "@/dep_types/content/components";

import mediaDataMapper from "./media";

const linkedTile = (apiTileData: APILinkedTileType): LinkedTileType => ({
  title: apiTileData.title,
  url: apiTileData.url,
  image: apiTileData.image.data
    ? mediaDataMapper(apiTileData.image.data.attributes)
    : null,
});

// eslint-disable-next-line import/no-default-export
export default linkedTile;
