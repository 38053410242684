const priceContainer = {
  parts: ["originalLabel", "label", "price"],
  baseStyle: {
    originalLabel: {
      color: "gray.500",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "1.38",
      textDecoration: "line-through",
    },
    label: {},
    price: {
      fontSize: { xl: "21px" },
      lineHeight: { xl: "1.35" },
    },
    priceDescriptionPrefix: {
      fontSize: "12px",
      lineHeight: {
        base: "1.2",
        xl: "1.33",
      },
      letterSpacing: {
        base: "-.02em",
        xl: "-.04em",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default priceContainer;
