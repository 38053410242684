const richText = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "normal",
    "li:not(:last-child)": {
      marginBottom: 1,
    },
    "ol:not(:last-child)": {
      marginBottom: 4,
    },
    ol: {
      marginLeft: 4,
    },
    ul: {
      marginLeft: 4,
    },
    "ul:not(:last-child)": {
      marginBottom: 4,
    },
    "p:not(:last-child)": {
      marginBottom: 4,
    },
    a: {
      fontWeight: "medium",
      cursor: "pointer",
      textDecoration: "underline",
      _hover: {
        color: "gray.500",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default richText;
