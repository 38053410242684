const pdpToggleButton = {
  baseStyle: {
    borderColor: "secondary.50",
    letterSpacing: "0.8px", // TODO: this in not being appllied, investigate why
  },
  variants: {
    active: {
      borderColor: "secondary.50",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default pdpToggleButton;
