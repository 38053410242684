import { environment } from "../../dep_constants";

let overrides;

const local = {
  MEN_CATEGORY_ID: "83",
  WOMEN_CATEGORY_ID: "82",

  MEN_BOOTS_COLLECTION_CID: "233",
  MEN_BINDING_COLLECTION_CID: "232",
  MEN_SNOWBOARD_COLLECTION_CID: "231",

  WOMEN_BOOTS_COLLECTION_CID: "228",
  WOMEN_BINDING_COLLECTION_CID: "227",
  WOMEN_SNOWBOARD_COLLECTION_CID: "226",
};

const development = {
  MEN_CATEGORY_ID: "83",
  WOMEN_CATEGORY_ID: "82",

  MEN_BOOTS_COLLECTION_CID: "233",
  MEN_BINDING_COLLECTION_CID: "232",
  MEN_SNOWBOARD_COLLECTION_CID: "231",

  WOMEN_BOOTS_COLLECTION_CID: "228",
  WOMEN_BINDING_COLLECTION_CID: "227",
  WOMEN_SNOWBOARD_COLLECTION_CID: "226",
};

const production = {
  MEN_CATEGORY_ID: "71",
  WOMEN_CATEGORY_ID: "72",

  MEN_BOOTS_COLLECTION_CID: "233",
  MEN_BINDING_COLLECTION_CID: "232",
  MEN_SNOWBOARD_COLLECTION_CID: "231",

  WOMEN_BOOTS_COLLECTION_CID: "228",
  WOMEN_BINDING_COLLECTION_CID: "227",
  WOMEN_SNOWBOARD_COLLECTION_CID: "226",
};

switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
  case environment.PRODUCTION:
  case environment.STAGING:
    overrides = production;
    break;
  case environment.DEVELOPMENT:
    overrides = development;
    break;
  default:
    overrides = local;
    break;
}

const config = {
  MEN_CATEGORY_ID: overrides.MEN_CATEGORY_ID,
  WOMEN_CATEGORY_ID: overrides.WOMEN_CATEGORY_ID,

  MEN_BOOTS_COLLECTION_CID: overrides.MEN_BOOTS_COLLECTION_CID,
  MEN_BINDING_COLLECTION_CID: overrides.MEN_BINDING_COLLECTION_CID,
  MEN_SNOWBOARD_COLLECTION_CID: overrides.MEN_SNOWBOARD_COLLECTION_CID,

  WOMEN_BOOTS_COLLECTION_CID: overrides.WOMEN_BOOTS_COLLECTION_CID,
  WOMEN_BINDING_COLLECTION_CID: overrides.WOMEN_BINDING_COLLECTION_CID,
  WOMEN_SNOWBOARD_COLLECTION_CID: overrides.WOMEN_SNOWBOARD_COLLECTION_CID,
};

// eslint-disable-next-line import/no-default-export
export default config;
