/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";

import DatePicker from "./DatePicker";

const DatePickerWrapper: FC<any> = (props: any) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return <></>;
  }

  return <DatePicker {...props} />;
};

// eslint-disable-next-line import/no-default-export
export default DatePickerWrapper;
