export const topTextBanner = {
  parts: ["container", "link", "text"],
  baseStyle: {
    container: {
      position: "relative",
      backgroundColor: "announcement",
      color: "white",
      padding: 3,
      minHeight: 12,
    },
    text: {
      fontWeight: "medium",
      textAlign: "center",
      flex: 1,
    },
    link: {
      display: {
        base: "none",
        lg: "block",
      },
      position: "absolute",
      fontFamily: "normal",
      fontWeight: "medium",
      textDecoration: "underline",
      _hover: {
        color: "gray.500",
      },
    },
  },
};
