const title = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "black",
    letterSpacing: "1px",
  },
  sizes: {
    hero: {
      fontSize: {
        base: "36px",
        md: "64px",
      },
      lineHeight: {
        base: "48px",
        md: "75px",
      },
    },
  },
  variants: {
    hero: {
      fontFamily: "title",
      fontWeight: 300,
      letterSpacing: "0.4px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default title;
