export type OrderAddressType = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  addressType?: string;
};

export type OrderCustomerType = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  shippingAddress: OrderAddressType;
  billingAddress: OrderAddressType;
  joinedMarketingList?: boolean;
};

export type OrderCardDataType = {
  stripeId: string;
  lastFour: string;
  brand: string;
  expMonth: number;
  expYear: number;
};

export type OrderCartItemType = {
  variantId: string | number;
  quantity: string | number;
  isResale: boolean;
  condition: string;
};

type OrderCartSetItemType = {
  productId: number;
  cartSetGroups: [
    {
      setGroupId: string;
      variantIds: string[] | number[];
    },
  ];
};

export type OrderCartType = {
  dateFrom?: string;
  dateTo?: string;
  cartItems?: OrderCartItemType[];
  cartSetItems?: OrderCartSetItemType[];
  discountCode?: string;
};

export type OrderType = {
  customer?: OrderCustomerType;
  shippingAddress?: OrderAddressType;
  billingAddress?: OrderAddressType;
  cardData?: OrderCardDataType;
  cart?: OrderCartType;
  attributionId?: string;
};

export type OrderStepType = {
  number: number;
  status: string;
  text: string;
};

const OrderFulfillmentType = {
  RENTAL: "RENTAL",
  RESALE: "RESALE",
} as const;

// eslint-disable-next-line no-redeclare
type OrderFulfillmentType =
  (typeof OrderFulfillmentType)[keyof typeof OrderFulfillmentType];

// eslint-disable-next-line import/no-default-export
export default OrderFulfillmentType;
