import { Box, useStyleConfig } from "@chakra-ui/react";
import { ResponsiveValue, ThemeTypings } from "@chakra-ui/styled-system";
import * as CSS from "csstype";
import React, { FC, ReactNode } from "react";

declare type Token<
  CSSType,
  ThemeKey = unknown,
> = ThemeKey extends keyof ThemeTypings
  ? ResponsiveValue<CSSType | ThemeTypings[ThemeKey]>
  : ResponsiveValue<CSSType>;

type Props = {
  children: ReactNode;
  variant?: string;
  textDecoration?: Token<CSS.Property.TextDecoration | number>;
};
type OneTrustType = {
  ToggleInfoDisplay: () => void;
};

declare const OneTrust: OneTrustType;

const OneTrustCookieLink: FC<Props> = ({
  children,
  variant,
  textDecoration,
}) => {
  const styles = useStyleConfig("SiteLink", { variant });

  return (
    <Box
      as="a"
      sx={styles}
      textDecoration={textDecoration}
      onClick={() => OneTrust.ToggleInfoDisplay()}
    >
      {children}
    </Box>
  );
};

OneTrustCookieLink.defaultProps = {
  variant: undefined,
  textDecoration: undefined,
};

// eslint-disable-next-line import/no-default-export
export default OneTrustCookieLink;
