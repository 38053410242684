const richText = {
  baseStyle: {
    a: {
      fontWeight: "medium",
      cursor: "pointer",
      textDecoration: "underline",
      _hover: {
        color: "gray.400",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default richText;
