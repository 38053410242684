/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ConditionsHelper from "@/dep_helpers/ConditionsHelper";
import {
  ColorSwatch,
  ConditionFilters,
  ConditionKey,
  ConditionsDiscounts,
  ConditionType,
  DiscountAndStockByCondition,
  InitialSelectedGroupedVariant,
  PriceDecrementingConditionKey,
  PricingType,
  ProductImage,
  ProductType,
  RentalDatesType,
  SearchProductType,
  SetGroup,
  SetProductType,
  VariantParametersType,
  VariantType,
} from "@/dep_types";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemType,
} from "@/dep_types/CartTypes";
import { AvailabilityType, PriceType } from "@/dep_types/ProductTypes";
import { GlobalLabelsType } from "@/dep_types/content/api/components/partner/globalLabels";
import { capitalizeFirstLetter } from "@/helpers/capitalizeFirstLetter";

import DatesHelper from "./DatesHelper";

import { parameterKeyIsSize } from "../dep_components/Product/VariantSelection/ProductOptionsContainer.helpers";
import { conditionTypes, productType } from "../dep_constants";

// eslint-disable-next-line import/no-default-export
export default class ProductHelper {
  static mapVariantToOption(variant: VariantType): any {
    return {
      id: variant.id,
      name: ProductHelper.parametersToVariantLabel(variant.parameters),
      capitalizedName: ProductHelper.parametersToVariantLabel(
        variant.parameters,
        undefined,
        true,
      ),
    };
  }

  static generateSimpleProductCartId = (
    productId: number,
    variantId: number,
    isResale?: boolean,
    condition?: string,
  ): string => {
    if (isResale) {
      return `resale-${productId}-${variantId}-${condition}`;
    }

    return `${productId}-${variantId}`;
  };

  static parametersToVariantLabel(
    params: VariantParametersType,
    globalLabels?: GlobalLabelsType,
    capitalized?: boolean,
    ignoreSize?: boolean,
  ): string {
    return Object.keys(params)
      .map((key) => {
        if (parameterKeyIsSize(key) && ignoreSize) {
          return null;
        }
        const paramLabel = ConditionsHelper.getTranslation(
          params[key],
          globalLabels,
        );

        return `${
          capitalized ? capitalizeFirstLetter(key) : key
        }: ${paramLabel}`;
      })
      .filter((item) => !!item)
      .join(" | ");
  }

  static mapVariantsOptions(product: ProductType): void {
    if (product.productType === productType.PRODUCT) {
      product.variantOptions = product.variants.map(
        ProductHelper.mapVariantToOption,
      );
    } else {
      (product as SetProductType).setGroups.forEach((setGroup) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define,no-return-assign
        setGroup.products.forEach(
          (p) =>
            (p.variantOptions = p.variants.map((variant: VariantType) =>
              /**
               * TODO: Fix this. This is due to a circular dependecy
               */
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              mapVariantToSetOption(p, variant),
            )),
        );
      });
    }
  }

  // TODO RESALE: Move this one to Shopping Cart Helper?
  static calculatePriceForRentalShoppingCartItem(
    product: ProductType,
    rentalDates: RentalDatesType,
    quantity?: number,
  ): number {
    const nightsCount = DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      rentalDates.startDate as Date,
      rentalDates.endDate as Date,
    );

    const pricesArray = product.price;

    let pricePerNight = pricesArray[nightsCount - 1];

    if (!pricePerNight) {
      pricePerNight = pricesArray[pricesArray.length - 1];
    }

    return pricePerNight * (quantity || 1);
  }

  static calculatePrices(
    product: ProductType | SearchProductType,
    rentalDates: RentalDatesType,
    selectedVariant?: VariantType | null,
  ): PriceType {
    const pricesArray = product.price;
    const variantRetailPrice = selectedVariant?.retailPrice;

    if (product.pricingType === PricingType.Fixed) {
      const retailPrice = variantRetailPrice || product.retailPrice;

      return {
        price: pricesArray.length > 0 ? pricesArray[0] : retailPrice,
        basePricePerNight: 0,
        pricePerNight: 0,
        total: retailPrice,
      };
    }
    const nightsCount = DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      rentalDates.startDate as Date,
      rentalDates.endDate as Date,
    );

    const total =
      nightsCount < pricesArray.length
        ? pricesArray[nightsCount - 1]
        : pricesArray[pricesArray.length - 1];
    const calculatedPricePerNight = total / nightsCount;

    // price has been set to use "base price"
    // because the calculated price per night
    // was deemed confusing on collection pages.
    /////
    // TODO: How do we display discounts, etc.

    return {
      price: pricesArray[0],
      basePricePerNight: pricesArray[0],
      pricePerNight: Math.round(calculatedPricePerNight * 100) / 100,
      total,
    };
  }

  static isProductAvailable(availability: AvailabilityType[]): any {
    return (
      availability &&
      availability.length > 0 &&
      availability.some((variant) => variant.maxAvailable > 0)
    );
  }

  static isVariantAvailable(
    availability: AvailabilityType[],
    selectedVariant: number | string,
  ): boolean {
    if (!availability || availability.length <= 0) {
      return false;
    }

    const variant = availability.find(
      (v) => v.id.toString() === selectedVariant.toString(),
    );

    if (!variant) {
      return false;
    }

    return variant.maxAvailable > 0;
  }

  static getInitialVariantGroupForSetProduct(
    product: SetProductType,
    isGrouped: boolean = false,
  ): InitialSelectedGroupedVariant {
    const variantGroup: InitialSelectedGroupedVariant = {};

    product.setGroups
      .filter((setGroup: SetGroup) =>
        isGrouped
          ? setGroup.numberOfSelections > 1
          : setGroup.numberOfSelections === 1,
      )
      .forEach((setGroup: SetGroup) => {
        variantGroup[setGroup.id] = {};

        const hasSingleProductWithSingleVariant =
          setGroup.products.length === 1 &&
          setGroup.products[0].variants.length === 1;

        if (hasSingleProductWithSingleVariant) {
          variantGroup[setGroup.id] = {
            variant: setGroup.products[0].variants[0],
            product: setGroup.products[0],
            variantOption: setGroup.products[0].variantOptions[0],
            subProduct: {
              id: setGroup.products[0].id,
              name: setGroup.products[0].name,
              image: setGroup.products[0].image,
            },
            selectedSetGroup: {
              id: setGroup.id,
              name: setGroup.name,
            },
          };
        }
      });

    return variantGroup;
  }

  static getInitialVariantForSimpleProduct(
    product: ProductType,
  ): VariantType | object {
    if (product.variants.length === 1) {
      return product.variants[0];
    }

    return {};
  }

  static getMaxNumberOfSelectionsForSet(product: SetProductType): number {
    if (!product.setGroups || product.setGroups.length === 0) {
      return 1;
    }

    const numberOfSelectionsArray = product.setGroups.map(
      (setGroup: SetGroup) => setGroup.numberOfSelections,
    );

    return Math.max(...numberOfSelectionsArray);
  }

  static getVariantUnitsInShoppingCart(
    cartItems: CartItemType[],
    variantAvailabilityItem: AvailabilityType,
  ): number {
    let variantInCartQuantity = 0;

    cartItems.forEach((cartItem) => {
      if (!cartItem.isSet) {
        if (
          (cartItem as CartProductItemType).variant ===
          variantAvailabilityItem.id
        ) {
          variantInCartQuantity += (cartItem as CartProductItemType).quantity;
        }
      } else {
        (cartItem as CartSetItemType).cartSetGroups.forEach((cartSetGroup) => {
          cartSetGroup.forEach((cartSetGroupItem) => {
            if (
              cartSetGroupItem.variantId.toString() ===
              variantAvailabilityItem.id.toString()
            ) {
              variantInCartQuantity += 1;
            }
          });
        });
      }
    });

    return variantInCartQuantity;
  }

  static filterAvailableItems(
    cartItems: CartItemType[],
    variantAvailabilityList: AvailabilityType[],
  ): AvailabilityType[] {
    return variantAvailabilityList.filter((variantAvailabilityItem) => {
      const variantInCartQuantity = ProductHelper.getVariantUnitsInShoppingCart(
        cartItems,
        variantAvailabilityItem,
      );

      return variantAvailabilityItem.maxAvailable - variantInCartQuantity > 0;
    });
  }

  static mapSetProductAvailabilityItemToVariantOptions(
    variantAvailabilityItem: AvailabilityType,
    globalLabels: GlobalLabelsType,
    product?: any,
  ): any {
    let foundProduct: SetProductType | undefined;

    product.setGroups.some((setGroup: any) => {
      foundProduct = setGroup.products.find((prod: any) =>
        prod.variants.some(
          (variant: any) => variant.id === variantAvailabilityItem.id,
        ),
      );

      return !!foundProduct;
    });

    const variantName = ProductHelper.parametersToVariantLabel(
      variantAvailabilityItem.parameters,
      globalLabels,
    );

    return {
      id: variantAvailabilityItem.id.toString(),
      variantName,
      name: `${foundProduct?.name} ${variantName}`,
      subProduct: {
        id: foundProduct?.id,
        name: foundProduct?.name,
        image: foundProduct?.image,
      },
    };
  }

  static filterPossibleVariantsForSimpleProducts(
    cartItems: CartProductItemType[],
    availability: AvailabilityType[],
    selectedVariantId: number,
  ): AvailabilityType[] {
    const newAvailabilityItems = availability.filter(
      (availabilityItem) => availabilityItem.id !== selectedVariantId,
    );
    const selectedSimpleProductVariants = cartItems
      .filter((cartItem) => !cartItem.isSet)
      .map((cartItem: CartProductItemType) => cartItem.variant);

    return newAvailabilityItems.filter(
      (availabilityItem) =>
        !selectedSimpleProductVariants.includes(availabilityItem.id),
    );
  }

  static getRecommendedCollectionIdsFromProductCollectionIds(
    productCollectionIds: number[],
    partnerRecommendedCollectionIds: any,
  ): string[] | null {
    if (!partnerRecommendedCollectionIds) {
      return null;
    }

    return productCollectionIds
      .map((pcId: number) => partnerRecommendedCollectionIds[pcId] || null)
      .flat()
      .filter((v: number) => v !== null);
  }

  static getProductDefaultImages = (product: ProductType): ProductImage[] => {
    const imageWithColor = product.images?.find(
      (image) => image.parameters?.Color || image.parameters?.color,
    );

    if (imageWithColor) {
      const defaultColorValue =
        imageWithColor.parameters?.Color || imageWithColor.parameters?.color;

      return ProductHelper.filterImagesByVariantParameters(
        product,
        defaultColorValue as string,
      );
    }

    return product.images;
  };

  static filterImagesByVariantParameters = (
    product: ProductType,
    colorValue: string,
    sizeValue?: string | null,
  ): ProductImage[] => {
    let filteredImages = product.images.filter((image: ProductImage) => {
      const color = image.parameters?.color || image.parameters?.Color;

      if (sizeValue) {
        const size = image.parameters?.size || image.parameters?.Size;

        return color === colorValue && size === sizeValue;
      }

      return color === colorValue;
    });

    if (filteredImages.length === 0) {
      filteredImages = product.images.filter((image: ProductImage) => {
        const color = image.parameters?.color || image.parameters?.Color;

        return color === colorValue;
      });
    }

    return filteredImages;
  };

  static getDefaultImage = (images: ProductImage[]): ProductImage =>
    images.find((image) => image.isDefault) || images[0];

  static getSizeChartPublicId = (product: ProductType): string | undefined => {
    if (product.cloudinaryImageSizeCharts) {
      return product.cloudinaryImageSizeCharts[0]?.publicId;
    }

    return product.sizeChart?.publicId;
  };

  static filterAvailableSetGroupProducts = (
    setGroup: SetGroup,
  ): ProductType[] =>
    setGroup.products.filter(
      (setGroupProduct: ProductType) =>
        setGroupProduct.availableVariants.length > 0,
    );

  // TODO: We should not call this directly. We should call "getRegularSalePriceOrPriceDecrementingTierPrice"
  // TODO: Refactor, send only the SalePrices instead of the whole product
  static getSalePrice = (
    product: ProductType,
    condition: string,
    variant?: VariantType | null,
  ): number | undefined => {
    if (variant?.salePrices && condition) {
      const variantSalePrice = variant.salePrices.find(
        (salePrice) =>
          salePrice?.condition?.toLowerCase() === condition.toLowerCase(),
      );

      if (variantSalePrice) {
        // For now, we return the last price, which is the one without price decrementing tiers
        return variantSalePrice.priceArray[
          variantSalePrice.priceArray.length - 1
        ];
      }
    }
    // if no variant or variant has no sale prices, we use the product sale prices
    const productSalePrice = product.salePrices.find(
      (salePrice) =>
        salePrice?.condition?.toLowerCase() === condition?.toLowerCase(),
    );

    if (!productSalePrice) {
      return undefined;
    }

    // For now, we return the last price, which is the one without price decrementing tiers
    return productSalePrice.priceArray[productSalePrice.priceArray.length - 1];
  };

  static getRegularSalePriceOrPriceDecrementingTierPrice = (
    isPriceDecrementingEnabled: boolean,
    conditionKey: ConditionKey,
    productData: ProductType,
    priceDecrementing: ConditionsDiscounts | null,
    selectedVariant: VariantType | null,
  ): number | undefined => {
    const currentConditionPriceDecrementing = priceDecrementing?.[conditionKey];

    let salePrice;

    if (
      isPriceDecrementingEnabled &&
      currentConditionPriceDecrementing &&
      currentConditionPriceDecrementing?.stock > 0
    ) {
      salePrice = currentConditionPriceDecrementing?.price;
    } else {
      salePrice = ProductHelper.getSalePrice(
        productData,
        conditionKey,
        selectedVariant,
      );
    }

    return salePrice;
  };

  static calculateResaleTotalForCartItem(
    product: ProductType,
    condition: string,
    quantity: number,
  ): number {
    const cartItemResalePrice = ProductHelper.getSalePrice(
      product,
      condition,
    ) as number;

    return cartItemResalePrice * quantity;
  }

  static getMinResalePrice = (
    product: ProductType | SearchProductType,
  ): number =>
    Math.min(...product.salePrices.map((salePrice) => salePrice.price));

  static getMinAvailableResalePrice = (product: ProductType): number =>
    Math.min(
      ...product.salePrices
        .filter(
          (salePrice) =>
            product.conditionsSummary &&
            product.conditionsSummary[salePrice.condition] > 0,
        )
        .map((salePrice) => salePrice.price),
    );

  static getMinAvailableOrLowestResalePrice = (
    product: ProductType,
  ): number => {
    if (
      product.conditionsSummary &&
      (product.conditionsSummary[conditionTypes.GOOD.key] > 0 ||
        product.conditionsSummary[conditionTypes.FAIR.key] > 0 ||
        product.conditionsSummary[conditionTypes.BAD.key] > 0)
    ) {
      return ProductHelper.getMinAvailableResalePrice(product);
    }

    return ProductHelper.getMinResalePrice(product);
  };

  static getMaxResalePrice = (
    product: ProductType | SearchProductType,
  ): number =>
    Math.max(...product.salePrices.map((salePrice) => salePrice.price));

  static getSlugParameters = (
    productSlug: string,
    appliedConditionFilters: ConditionFilters | undefined,
    selectedSwatch: ColorSwatch | undefined,
  ): string => {
    const searchParams = new URLSearchParams();

    const slugParts = productSlug.split("?");

    const baseSlug = slugParts[0];

    if (slugParts.length > 1) {
      // Note: If some day the productSlug from the API comes with more than one parameter, we should do a loop here
      // instead of assuming just one more value
      const keyValue = slugParts[1].split("=");
      const key = keyValue[0];
      const value = keyValue[1];

      searchParams.append(key, value);
    }

    if (appliedConditionFilters) {
      if (
        appliedConditionFilters[capitalizeFirstLetter(conditionTypes.GOOD.key)]
      ) {
        searchParams.append("c", "1");
      } else if (
        appliedConditionFilters[capitalizeFirstLetter(conditionTypes.FAIR.key)]
      ) {
        searchParams.append("c", "2");
      } else if (
        appliedConditionFilters[capitalizeFirstLetter(conditionTypes.BAD.key)]
      ) {
        searchParams.append("c", "3");
      }
    }
    if (selectedSwatch) {
      searchParams.append("color", selectedSwatch.color);
    }

    const url = `/products/${baseSlug}?${searchParams.toString()}`;

    return url;
  };

  static isValidCondition = (condition: ConditionKey): boolean => {
    const conditionKeys = Object.entries(conditionTypes).map(
      ([, value]) => value.key,
    );

    return conditionKeys.includes(condition);
  };

  static getConditionPriceDecrementingKey = (
    conditionKey: ConditionKey,
  ): PriceDecrementingConditionKey | null => {
    if (!conditionKey) {
      return null;
    }
    const conditionType = Object.values(conditionTypes).find(
      (condition) => condition.key === conditionKey,
    );

    return conditionType?.priceDecrementingKey || null;
  };

  static getConditionType = (
    conditionKey: ConditionKey,
  ): ConditionType | null =>
    Object.values(conditionTypes).find(
      (condition) => condition.key === conditionKey,
    ) || null;

  static getDiscountDataByConditionAndSelectedVariant = (
    selectedVariant: VariantType,
    conditionType: ConditionType,
    resaleVariants: VariantType[],
  ): DiscountAndStockByCondition => {
    const condition = conditionType?.key;
    const currentProductId = selectedVariant.id;
    const resaleVariant = resaleVariants.find(
      (variant) =>
        variant.id === currentProductId &&
        variant.parameters.condition === condition,
    );
    const resaleVariantSalePrices = resaleVariant?.salePrices;
    const stocks =
      resaleVariant?.conditions?.[conditionType?.priceDecrementingKey];
    const availIndex = stocks?.findIndex((value: number) => value > 0);
    const indexDefined = availIndex !== undefined;
    const stock = indexDefined && stocks?.[availIndex];
    const price =
      resaleVariantSalePrices && indexDefined
        ? resaleVariantSalePrices.find(
            (priceObj) => priceObj.condition === condition,
          )?.priceArray[availIndex]
        : undefined;
    const isLastPriceLevel = indexDefined && availIndex + 1 === stocks?.length;
    const hasDiscount = !isLastPriceLevel && availIndex !== -1;
    const totalTiersAmount = stocks?.length || 0;

    return {
      condition,
      hasDiscount,
      stock: stock || 0,
      price: price || 0,
      conditionPriceDecrementingTierStockIndex: indexDefined ? availIndex : -1,
      totalTiersAmount,
    };
  };

  static getConditionsDiscountsDataBySelectedVariant = (
    selectedVariant: VariantType,
    resaleVariants: VariantType[],
  ): ConditionsDiscounts => {
    const selectedConditionType = ProductHelper.getConditionType(
      selectedVariant.parameters.condition as ConditionKey,
    );

    const goodPriceDecrementing =
      ProductHelper.getDiscountDataByConditionAndSelectedVariant(
        selectedVariant,
        conditionTypes.GOOD,
        resaleVariants,
      );
    const fairPriceDecrementing =
      ProductHelper.getDiscountDataByConditionAndSelectedVariant(
        selectedVariant,
        conditionTypes.FAIR,
        resaleVariants,
      );
    const badPriceDecrementing =
      ProductHelper.getDiscountDataByConditionAndSelectedVariant(
        selectedVariant,
        conditionTypes.BAD,
        resaleVariants,
      );

    return {
      currentConditionType: selectedConditionType,
      good: goodPriceDecrementing,
      fair: fairPriceDecrementing,
      bad: badPriceDecrementing,
    };
  };

  static getStockLeftInPriceDecrementingTiers = (
    stockTiers: number[],
    stockInCart: number,
  ): { stockLeftAvailable: number; tierIndex: number } => {
    let stockInCartCounter = stockInCart;
    let tierIndex = -1;
    let stockLeftAvailable = 0;

    for (let i = 0; i < stockTiers.length; i++) {
      const hasStockAvailable = stockTiers[i] - stockInCartCounter > 0;

      if (!hasStockAvailable) {
        stockInCartCounter -= stockTiers[i];
      } else {
        stockLeftAvailable = stockTiers[i] - stockInCartCounter;
        tierIndex = i;
        break;
      }
    }

    return {
      stockLeftAvailable,
      tierIndex,
    };
  };

  static getPriceDecrementingTiersWithoutCartStock = (
    stockTiers: number[],
    stockInCart: number,
  ): number[] => {
    const newStockTiers = [...stockTiers];
    let stockInCartCounter = stockInCart;

    for (let i = 0; i < newStockTiers.length; i++) {
      const hasStockAvailable = stockTiers[i] - stockInCartCounter > 0;

      if (!hasStockAvailable) {
        stockInCartCounter -= newStockTiers[i];
        newStockTiers[i] = 0;
      } else {
        newStockTiers[i] -= stockInCartCounter;
        break;
      }
    }

    return newStockTiers;
  };

  static getPriceDecrementingTiersByCartStock = (
    stockTiers: number[],
    requestedStock: number,
  ): number[] => {
    const newStockTiers = [];
    let stockLeftCounter = requestedStock;

    for (let i = 0; i < stockTiers.length; i++) {
      const hasStockAvailable = stockLeftCounter - stockTiers[i] > 0;

      if (hasStockAvailable) {
        newStockTiers.push(stockTiers[i]);
        stockLeftCounter -= stockTiers[i];
      } else {
        newStockTiers.push(stockLeftCounter);
        break;
      }
    }

    return newStockTiers;
  };

  static getRemainingStockFromPriceDecrementingStockArray = (
    stockArray: number[],
  ): number =>
    stockArray.reduce((partialSum, newValue) => partialSum + newValue, 0);

  // TODO: maybe move to shopping cart helper
  static recalculatePriceDecrementingCartItems = (
    stockArray: number[],
    priceArray: number[],
    requestedStock: number,
    unavailableUnitsCount?: number,
    includeUnavailable?: boolean,
  ): {
    tierStockIndex: number;
    quantity: number;
    price: number;
  }[] => {
    const newRequestedStockTiers =
      ProductHelper.getPriceDecrementingTiersByCartStock(
        stockArray,
        requestedStock,
      );

    const priceDecrementingItems = [];

    for (let i = 0; i < newRequestedStockTiers.length; i++) {
      const quantity = newRequestedStockTiers[i];

      if (quantity > 0) {
        priceDecrementingItems.push({
          tierStockIndex: i + 1,
          quantity,
          price: priceArray[i],
        });
      }
    }
    if (
      !!unavailableUnitsCount &&
      unavailableUnitsCount > 0 &&
      includeUnavailable
    ) {
      const lastTierStock =
        newRequestedStockTiers[newRequestedStockTiers.length - 1];

      // if the last tier had stock, it means we need to add the remaining unavailable stock to that price breakkdown line
      // else: we push a new object with the "unavailableUnitsCount" with the last tier of prices
      if (lastTierStock > 0) {
        priceDecrementingItems[priceDecrementingItems.length - 1].quantity +=
          unavailableUnitsCount;
      } else {
        priceDecrementingItems.push({
          tierStockIndex: newRequestedStockTiers.length,
          quantity: unavailableUnitsCount,
          price: priceArray[priceArray.length - 1],
        });
      }
    }

    return priceDecrementingItems;
  };

  // TODO: maybe move to shopping cart helper
  static calculatePriceDecrementingTotalPrice = (
    existentCartItem: CartItemType,
  ): number =>
    existentCartItem.priceDecrementingItems?.reduce(
      (totalPrice, item) => totalPrice + item.price * item.quantity,
      0,
    ) || 0;

  static getDefaultColorSwatch = (
    product: SearchProductType,
  ): ColorSwatch | undefined => {
    let matchedSwatch;

    if (product.searchTerm) {
      const searchTerm = product.searchTerm.toUpperCase();

      matchedSwatch = product.swatches?.find((swatch) =>
        swatch.color.toUpperCase().includes(searchTerm),
      );
    }
    if (matchedSwatch) {
      return matchedSwatch;
    }
    if (product.swatches && product.swatches.length > 0) {
      return product.swatches[0];
    }

    return undefined;
  };
}

const mapVariantToSetOption = (
  product: SetProductType,
  variant: VariantType,
): any => {
  const paramsLabel = ProductHelper.parametersToVariantLabel(
    variant.parameters,
  );

  return {
    id: variant.id,
    shortName: paramsLabel,
    name: `${product.name} | ${paramsLabel}`,
  };
};
