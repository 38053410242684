/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex } from "@chakra-ui/react";
import React, { FC } from "react";

import UnavailablePill from "@/dep_components/ShoppingCart/UnavailablePill/UnavailablePill";

import CartEditVariantButton from "./Edit/CartEditVariantButton";
import ProductThumbnail from "./ProductThumbnail";

import useCartSetItemEditHook from "../../../dep_hooks/useCartSetItemEditHook";
import { DropDownListItemType } from "../../../dep_types";
import {
  CartSetItemSetGroup,
  CartSetItemType,
} from "../../../dep_types/CartTypes";
import DropDownList from "../../Commons/FormFilterElements/DropDownList";

type Props = {
  isNotLast: boolean;
  cartSetGroupSelection: CartSetItemSetGroup;
  cartItem: CartSetItemType;
  isReadonly?: boolean;
};

const SetChildrenItem: FC<Props> = ({
  isNotLast,
  cartSetGroupSelection,
  cartItem,
  isReadonly,
}: Props) => {
  const {
    selectedVariant,
    itemVariantEditRef,
    editSetProductVariant,
    setProductAvailableVariantsForEdit,
    handleEditSetProductVariant,
    showSetProductEditButtonAlert,
    setShowSetProductEditButtonAlert,
    changeSetProductVariant,
  } = useCartSetItemEditHook(cartItem, cartSetGroupSelection);

  return (
    <Flex
      direction="row"
      pt="20px"
      pb="20px"
      borderBottom={isNotLast ? "4px solid" : 0}
      borderColor="#f5f5f5"
    >
      <Flex flex={1} direction="column">
        <Flex flex={1} direction="row">
          <Box maxWidth={70}>
            <ProductThumbnail
              image={cartSetGroupSelection.productImage}
              width={70}
              height={70}
              containerHeight={70}
              minWidth={70}
              isUnavailable={cartSetGroupSelection.unavailable}
            />
          </Box>
          <Flex flex={1} direction="column">
            <Box
              textAlign="right"
              color="black"
              fontSize={{ base: "14px", md: "16px" }}
              fontFamily="normal"
              fontWeight="bold"
            >
              {cartSetGroupSelection.productName}
            </Box>
            <Flex
              direction="row"
              justifyContent="flex-end"
              textAlign="right"
              fontSize={{ base: "14px", md: "15px" }}
            >
              <Box as="span" paddingRight={2}>
                {cartSetGroupSelection.variantShortName}
              </Box>
              <CartEditVariantButton
                editVariant={editSetProductVariant}
                isReadonly={isReadonly}
                showEditButtonAlert={showSetProductEditButtonAlert}
                setShowEditButtonAlert={setShowSetProductEditButtonAlert}
                handleEditVariant={handleEditSetProductVariant}
              />
            </Flex>
            <Flex direction="row" justifyContent="flex-end" mt="5px">
              {cartSetGroupSelection.unavailable && <UnavailablePill />}
            </Flex>
            {editSetProductVariant && (
              <Flex justifyContent="flex-end">
                {setProductAvailableVariantsForEdit &&
                  setProductAvailableVariantsForEdit.variantOptions && (
                    <Box ref={itemVariantEditRef as any}>
                      <DropDownList
                        id="variant"
                        placeholder="- Select a new variant -"
                        selected={selectedVariant || 0}
                        items={
                          setProductAvailableVariantsForEdit.variantOptions as DropDownListItemType[]
                        }
                        onChange={(event) => {
                          const variantId = event.target.value;

                          changeSetProductVariant(
                            cartItem,
                            cartSetGroupSelection,
                            variantId,
                          );
                        }}
                      />
                    </Box>
                  )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

SetChildrenItem.defaultProps = {
  isReadonly: false,
};

// eslint-disable-next-line import/no-default-export
export default SetChildrenItem;
