import { Flex, FlexProps, List, ListItem } from "@chakra-ui/react";
import { useState } from "react";

import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { CategorySubmenu } from "./CategorySubmenu";

import { CategoryType } from "../../../dep_types";
import { MenuItem } from "../MenuItem";

interface NavigationItemsProps extends FlexProps {
  categoryGroups: Record<string, CategoryType[]>;
  navigationItems?: NavigationItem[];
}

export function NavigationItems({
  categoryGroups,
  navigationItems,
  ...other
}: NavigationItemsProps) {
  const [openMenuItem, setOpenMenuItem] = useState("");
  const { headerInfo, globalLabels } = useWhitelabelContext();

  const closeMenuItem = (): void => setOpenMenuItem("");
  const findYourOrderTitle =
    globalLabels?.navBarFindYourOrderTitle || "Find your Order";
  const helpCenterTitle = globalLabels?.navBarHelpCenterTitle || "Help Center";

  return (
    <Flex
      as="nav"
      height="100%"
      justifyContent="center"
      onMouseLeave={closeMenuItem}
      {...other}
    >
      <List
        display="flex"
        justifyContent="center"
        alignItems="center"
        columnGap={8}
      >
        {headerInfo &&
          headerInfo.navigationItems.map((item) => {
            const categoryGroup = categoryGroups[item.categoryGroup];
            const isOpen = openMenuItem === item.categoryGroup;

            const mouseEnterHandler = (): void =>
              setOpenMenuItem(item.categoryGroup);

            if (categoryGroup) {
              return (
                <ListItem key={item.categoryGroup} height="100%">
                  <MenuItem onMouseEnter={mouseEnterHandler}>
                    {item.name}
                  </MenuItem>
                  <CategorySubmenu
                    groupKey={item.categoryGroup}
                    groupName={item.name}
                    categories={categoryGroups[item.categoryGroup]}
                    isOpen={isOpen}
                    menuItemName={item.categoryGroup}
                    setOpenMenuItem={setOpenMenuItem}
                    image={item.image}
                  />
                </ListItem>
              );
            }

            return null;
          })}
        {navigationItems?.map((item) => (
          <ListItem key={item.label} height="100%">
            <MenuItem href={item.href} onMouseEnter={closeMenuItem}>
              {item.label}
            </MenuItem>
          </ListItem>
        ))}
        <ListItem height="100%">
          <MenuItem href="/findmyorder" onMouseEnter={closeMenuItem}>
            {findYourOrderTitle}
          </MenuItem>
        </ListItem>
        <ListItem height="100%">
          <MenuItem href="/faq" onMouseEnter={closeMenuItem}>
            {helpCenterTitle}
          </MenuItem>
        </ListItem>
      </List>
    </Flex>
  );
}
