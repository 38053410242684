/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-default-export
export default class LocalStorageService {
  static save(key: string, value: any): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static get(key: string): any {
    const value: string | null = localStorage.getItem(key);

    return value && JSON.parse(value);
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }

  static clearAll(): void {
    localStorage.clear();
  }
}
