import { Flex } from "@chakra-ui/react";
import React, { FC } from "react";

const SetPill: FC = () => (
  <Flex
    pb={{ base: 0, md: "3px" }}
    pt={{ base: "4px", md: "5px" }}
    px={{ base: "5px", md: "10px" }}
    ml="10px"
    fontSize={{ base: "10px", md: "13px" }}
    borderRadius="5px"
    textTransform="uppercase"
    backgroundColor="#f1e3ff"
  >
    SET
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default SetPill;
