const searchResults = {
  parts: ["noResults", "title"],
  baseStyle: {
    noResults: {
      fontFamily: "normal",
      fontWeight: "400",
      fontSize: {
        base: "21px",
        xl: "24px",
      },
      lineHeight: {
        base: "28px",
        xl: "29px",
      },
      marginBottom: "4",
      color: "black",
    },
    title: {
      fontFamily: "normal",
      fontWeight: "400",
      fontSize: "14px",
      marginBottom: "4",
      color: "gray.500",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default searchResults;
