import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

interface Props extends BoxProps {
  onClick(): void;
  variant?: "actionButtonXSmallPrimary" | "newLinkButton";
  dataTestId?: string;
  text?: string | ReactNode;
  children?: ReactNode;
}

const LinkButton: FC<Props> = ({
  onClick,
  variant,
  text,
  dataTestId,
  children,
  ...other
}) => {
  const styles = useStyleConfig("LinkButton", { variant });

  return (
    <Box
      as="button"
      type="button"
      onClick={onClick}
      sx={styles}
      data-testid={dataTestId}
      {...other}
    >
      {text}
      {children}
    </Box>
  );
};

LinkButton.defaultProps = {
  variant: undefined,
  text: undefined,
  children: undefined,
  dataTestId: undefined,
};

// eslint-disable-next-line import/no-default-export
export default LinkButton;
