import HttpResponse from "./http/HttpResponse";
import API from "./httpService";

import { APICollectionDataResponse } from "../dep_types";
import { ApiResponse } from "../dep_types/ApiTypes";

// eslint-disable-next-line import/no-default-export
export default class CollectionsService {
  static async fetchCollections(
    partnerId: string,
  ): Promise<APICollectionDataResponse[]> {
    const response: ApiResponse = await API.get(partnerId, "/collection").then(
      (r: HttpResponse) => r.get(),
    );

    if (!response.data || !response.data.length) {
      return [];
    }
    const collections: APICollectionDataResponse[] = response.data;

    collections.forEach((collection) => {
      if (!collection.group) {
        collection.group = "collection";
      }
    });

    return collections;
  }
}
