import { Flex } from "@chakra-ui/react";
import { addDays, subDays } from "date-fns";
import React, { ReactElement, FC, useState } from "react";
import Calendar, {
  CalendarTileProperties,
  OnChangeDateRangeCallback,
} from "react-calendar";

import isLessThanMinRentalRange from "@/dep_components/DatePicker/utils/isLessThanMinRentalRange";

import useIsMobile from "@/dep_hooks/useIsMobile";

import CalendarFooter from "./CalendarFooter";
import CalendarHeader from "./CalendarHeader";
import CalendarMonthToggle from "./CalendarMonthToggle";
import CalendarTileContent from "./CalendarTileContent";
import styles from "./styles";

import { demoProductMaxRentalDuration } from "../../../../dep_constants";
import DatesHelper from "../../../../dep_helpers/DatesHelper";
import {
  PricingType,
  ProductType,
  RentalDatesType,
} from "../../../../dep_types";

type Props = {
  handleDateRangeChange: OnChangeDateRangeCallback;
  submitRentalDates(): void;
  calendarDates: RentalDatesType;
  clearCalendarDates(): void;
  products: ProductType[];
};

const weekdayLabels: Record<number, string> = {
  0: "Su",
  1: "Mo",
  2: "Tu",
  3: "We",
  4: "Th",
  5: "Fr",
  6: "Sa",
};

const CalendarContent: FC<Props> = ({
  handleDateRangeChange,
  submitRentalDates,
  calendarDates,
  clearCalendarDates,
  products,
}: Props) => {
  const hasOnlyDemoProducts = products
    ? products.every((product) => product.pricingType === PricingType.Fixed)
    : false;
  const maximumRentalDuration = hasOnlyDemoProducts
    ? demoProductMaxRentalDuration
    : DatesHelper.maxRentalDaysRange;

  const defaultShownDate =
    (calendarDates.startDate as Date) || DatesHelper.minEndRentalDate;
  const [shownDate, setShownDate] = useState(defaultShownDate);

  const diffBetweenMinRentalAndStartDate = Math.abs(
    DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      calendarDates.startDate as Date,
      DatesHelper.minEndRentalDate,
    ),
  );

  const isActiveMinHigher =
    diffBetweenMinRentalAndStartDate >= maximumRentalDuration;

  const minDate = isActiveMinHigher
    ? subDays(calendarDates.startDate as Date, maximumRentalDuration)
    : DatesHelper.minEndRentalDate;

  const getTileClassName = ({ date }: CalendarTileProperties): string => {
    const dateIsLessThanMinRange = isLessThanMinRentalRange(
      date,
      calendarDates,
      minDate,
    );

    return dateIsLessThanMinRange
      ? "react-calendar__tile--lessThanMinRange"
      : "";
  };

  const getTileContent = ({
    date,
  }: CalendarTileProperties): ReactElement | null => {
    const dateIsLessThanMinRange = isLessThanMinRentalRange(
      date,
      calendarDates,
      minDate,
    );

    return dateIsLessThanMinRange ? (
      <CalendarTileContent
        message={`${DatesHelper.minRentalDaysRange}-day minimum`}
      />
    ) : null;
  };

  const getTileDisabled = ({ date }: CalendarTileProperties): boolean =>
    isLessThanMinRentalRange(date, calendarDates, minDate);

  const getWeekdayLabel = (_locale: string, date: Date): string =>
    weekdayLabels[date.getDay()];

  const calendarValue = [
    calendarDates.startDate as Date,
    calendarDates.endDate as Date,
  ] as [Date | null, Date | null];

  const isMobile = useIsMobile();

  const burtonSeasonEnd = new Date("2024-05-01");
  const minCalendarDate = addDays(new Date(), 10);

  return (
    <Flex direction="column" fontFamily="normal" sx={styles}>
      <CalendarHeader
        calendarDates={calendarDates}
        clearCalendarDates={clearCalendarDates}
        submitRentalDates={submitRentalDates}
      />
      <CalendarMonthToggle shownDate={shownDate} setShownDate={setShownDate} />
      <Calendar
        activeStartDate={shownDate}
        allowPartialRange
        formatShortWeekday={getWeekdayLabel}
        minDate={minCalendarDate}
        maxDate={burtonSeasonEnd}
        onChange={handleDateRangeChange}
        showNavigation={false}
        selectRange
        showDoubleView={!isMobile}
        showFixedNumberOfWeeks
        tileClassName={getTileClassName}
        tileContent={getTileContent}
        tileDisabled={getTileDisabled}
        value={calendarValue}
        view="month"
      />
      <CalendarFooter
        calendarDates={calendarDates}
        submitRentalDates={submitRentalDates}
        clearCalendarDates={clearCalendarDates}
      />
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarContent;
