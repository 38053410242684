/* eslint-disable sonarjs/prefer-immediate-return */
import qs from "qs";

import { GenericCmsQuery } from "@/dep_queries/cms/GenericCmsQuery";

export interface FeatureFlags {
  hideDiscountOnCheckout: boolean;
  hideProductPageSizeSelector: boolean;
  showGearFinder: boolean;
  collectionsSearchFilters: boolean;
  autoSelectProductOptions: boolean;
  hideResaleRange: boolean;
  showCollectionPageCategoriesDropdown: boolean;
  collectionsProductSwatches: boolean;
  enableCollectionColorFilter: boolean;
  enablePriceDecrementing: boolean;
  enableTakeBackProgram: boolean;
}

interface FeatureFlagQueryResponse {
  production: { id?: string } & FeatureFlags;
}

export async function FetchFeatureFlags(
  partner: string,
): Promise<FeatureFlags> {
  const query = qs.stringify({
    filters: {
      partner: {
        partner_id: {
          $eq: partner.toLowerCase(),
        },
      },
    },
    populate: ["production"],
  });

  const featureFlags = await GenericCmsQuery<FeatureFlagQueryResponse>(
    "feature-flag-partners",
    query,
  ).then(({ production }) => {
    if (production?.id) {
      delete production.id;
    }

    return production;
  });

  return featureFlags;
}
