const siteLink = {
  baseStyle: {},
  variants: {
    footer: {
      color: "gray.500",
      _hover: {
        color: "gray.500",
        textDecoration: "none",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default siteLink;
