import { ReviveTheme } from "@/revive/theme";

export const yeti: ReviveTheme = {
  partnerId: 7,
  partnerName: "yeti",
  colors: {
    announcement: "#002B45",
    confirmation: "#28B761",
    error: "#EA4335",
    black: "#002B45",
    primary: {
      50: "#002B45",
      600: "#002B45",
    },
    secondary: {
      50: "#0070AD",
      600: "#F8683D",
    },
    tertiary: {
      50: "#CBB485",
      600: "#CBB485",
    },
    gray: {
      50: "#F1F1F1",
      100: "#DDDDDD",
      200: "#DDDDDD",
      300: "#DDDDDD",
      400: "#555555",
      500: "#242424",
    },
  },
  components: {
    Heading: {
      variants: {
        h2: {
          fontWeight: "semibold",
          textTransform: "uppercase",
          letterSpacing: "wide",
        },
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          textTransform: "uppercase",
        },
      },
    },
  },
  icons: {
    cart: {
      viewBox: "0 0 23 23",
      fill: "none",
      paths: [
        {
          d: "M0 8.53499L1.3111 23H20.9262L22.4516 8.53499H0ZM19.3838 21.2861H2.87928L1.87668 10.2488H20.5492L19.3838 21.2861Z",
        },
        {
          d: "M4.3618 7.2839H6.07566V6.42697H16.3588V7.2839H18.0727V4.97019C17.9956 2.21088 15.7418 0 12.9825 0H9.4691C6.7612 0 4.52461 2.12519 4.3875 4.83309H4.37037V7.2839H4.3618ZM9.46053 1.71386H12.9739C14.6964 1.71386 16.136 3.00782 16.3331 4.71311H6.10136C6.29846 3.00782 7.74667 1.71386 9.4691 1.71386H9.46053Z",
        },
      ],
    },
  },
};
