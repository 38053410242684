import { Flex, chakra } from "@chakra-ui/react";
import { Image } from "cloudinary-react";

import { useProductCard } from "@/dep_components/Commons/Products/ProductCard/hooks/useProductCard";

type ProductCardImageProps = {
  readonly width: number;
  readonly onClick?: () => void;
};

export function ProductCardImage({ width, onClick }: ProductCardImageProps) {
  const { product, selectedImageId, productUrl } = useProductCard();

  return (
    <Flex
      width={width}
      maxWidth="100%"
      aspectRatio="155/192"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
    >
      <chakra.a
        href={productUrl}
        onClick={onClick}
        display="block"
        transition="transform 200ms ease-in-out"
        _hover={{ transform: "scale(1.1)" }}
      >
        <Image
          width={width}
          publicId={selectedImageId}
          alt={product.name}
          fetchFormat="auto"
          crop="fit"
          dpr={1}
          radius="5"
          style={{
            width: `${width}px`,
            objectFit: "contain",
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />
      </chakra.a>
    </Flex>
  );
}
