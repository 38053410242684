import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import merge from "lodash/merge";
import { PropsWithChildren } from "react";

import { ReviveTheme, reviveTheme } from "@/revive/theme";

type ThemeProviderProps = {
  /**
   * The theme that will be used.
   */
  theme?: ReviveTheme;
};

export function ThemeProvider({
  theme,
  children,
}: PropsWithChildren<ThemeProviderProps>) {
  const mergedTheme = extendTheme(merge({}, reviveTheme, theme));

  return (
    <>
      {mergedTheme?.fontFaces && <Global styles={mergedTheme?.fontFaces} />}
      <ChakraProvider theme={mergedTheme}>{children}</ChakraProvider>
    </>
  );
}
