import { chakra } from "@chakra-ui/react";

import { Icon } from "@/revive/components/Icon";

type ProductCardSliderButtonProps = {
  readonly direction: "next" | "prev";
  readonly disabled?: boolean;
  readonly onClick: () => void;
};

export function ProductCardSliderButton({
  direction,
  disabled,
  onClick,
}: ProductCardSliderButtonProps) {
  return (
    <chakra.button
      onClick={onClick}
      disabled={disabled}
      height={{
        base: "7",
        md: "10",
        lg: "8",
        xl: "10",
      }}
      aspectRatio="1/1"
      border="1px"
      borderColor="gray.200"
      transition="all 150ms"
      borderRadius="full"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      _hover={{ borderColor: "black" }}
      _disabled={{
        cursor: "not-allowed",
        pointerEvents: "none",
        borderColor: "gray.50",
        color: "gray.300",
      }}
    >
      <Icon
        size="sm"
        name={direction === "next" ? "chevronRight" : "chevronLeft"}
      />
    </chakra.button>
  );
}
