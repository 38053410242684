import { Heading as ChakraHeading } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type HeadingStyles = "h1" | "h2" | "h3";

type HeadingProps = {
  as?: HeadingStyles;
  style?: HeadingStyles;
};

export function Heading({
  as = "h2",
  style,
  children,
}: PropsWithChildren<HeadingProps>) {
  return (
    <ChakraHeading
      as={as}
      variant={style ?? as}
      fontFamily="title"
      lineHeight="short"
    >
      {children}
    </ChakraHeading>
  );
}
