import DatesHelper from "@/dep_helpers/DatesHelper";
import { RentalDatesType } from "@/dep_types";

const isLessThanMinRentalRange = (
  date: Date,
  calendarDates: RentalDatesType,
  minDate: Date,
): boolean => {
  const hasEndDate = Boolean(calendarDates.endDate);
  const hasStartDate = Boolean(calendarDates.startDate);

  const diffBetweenDateAndMinDate =
    DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(minDate, date);

  const dateIsNotValid = diffBetweenDateAndMinDate < 0;

  if (hasEndDate || !hasStartDate || dateIsNotValid) {
    return false;
  }

  const difference = Math.abs(
    DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      calendarDates.startDate as Date,
      date,
    ),
  );

  return difference > 0 && difference < DatesHelper.minRentalDaysRange;
};

// eslint-disable-next-line import/no-default-export
export default isLessThanMinRentalRange;
