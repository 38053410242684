const breadcrumb = {
  parts: ["text"],
  baseStyle: {
    text: {
      textTransform: "uppercase",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default breadcrumb;
