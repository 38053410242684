/* eslint-disable @typescript-eslint/no-explicit-any */
import APIMediaValuePropType from "@/dep_types/content/api/components/sections/mediaValueProp";
import { MediaValuePropType } from "@/dep_types/content/sections";

import buttonDataMapper from "../components/linkedButton";
import mediaDataMapper from "../components/media";
import textMultipleDataMapper from "../components/textMultiple";

const mediaValueProp = (
  apiMediaValuePropData: APIMediaValuePropType,
): MediaValuePropType => ({
  id: apiMediaValuePropData.id,
  componentKey: apiMediaValuePropData.__component,
  mediaItem: apiMediaValuePropData.mediaItem.data
    ? mediaDataMapper(apiMediaValuePropData.mediaItem.data.attributes)
    : null,
  mediaPlacement: apiMediaValuePropData.mediaPlacement,
  title: apiMediaValuePropData.title,
  description: apiMediaValuePropData.description,
  checkListItems: apiMediaValuePropData.checkedListItem.map((item: any) => ({
    id: item.id,
    text: item.text,
  })),
  button: apiMediaValuePropData.button
    ? buttonDataMapper(apiMediaValuePropData.button)
    : null,
  categoriesEnabledFor: apiMediaValuePropData.categoriesEnabledFor
    ? apiMediaValuePropData.categoriesEnabledFor.map((category) =>
        textMultipleDataMapper(category),
      )
    : [],
});

// eslint-disable-next-line import/no-default-export
export default mediaValueProp;
