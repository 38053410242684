import { useQuery } from "@tanstack/react-query";

import { GetPartnerData } from "@/api/cms/GetPartnerData";
import { prefetchQueryClient } from "@/providers/QueryProvider";
import { useTheme } from "@/revive/hooks/useTheme";

export const usePartnerDataKey = "partner";

const queryContent = (partnerName: string) => ({
  queryKey: [usePartnerDataKey, partnerName],
  queryFn: () => GetPartnerData(`${partnerName}`),
});

export async function prefetchUseProductBySlug(partnerName: string) {
  await prefetchQueryClient.prefetchQuery(queryContent(partnerName));

  return prefetchQueryClient;
}

export function usePartnerData() {
  const { partnerName } = useTheme();
  const { data, isLoading } = useQuery({
    ...queryContent(partnerName),
    ...{
      enabled: Boolean(partnerName),
    },
  });

  return {
    data,
    isLoading,
  };
}
