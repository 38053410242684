import APIMediaType from "@/dep_types/content/api/media/media";
import { MediaType } from "@/dep_types/content/components";

const media = (apiMediaData: APIMediaType): MediaType => ({
  cloudinaryId: apiMediaData.provider_metadata.public_id,
  altText: apiMediaData.alternativeText,
  type: apiMediaData.provider_metadata.resource_type,
  extension: apiMediaData.ext,
  url: apiMediaData.url,
});

// eslint-disable-next-line import/no-default-export
export default media;
