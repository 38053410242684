const modalContent = {
  parts: ["title", "bodyText"],
  baseStyle: {
    title: {
      pb: "0",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      fontSize: "32px",
      color: "primary.600",
    },
    bodyText: {
      fontFamily: "normal",
      letterSpacing: "0.08px",
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default modalContent;
