import React, { FC } from "react";

import AlertPill from "@/dep_components/Commons/Pills/AlertPill/AlertPill";

type Props = {
  stockAvailable?: number;
  stockRequested?: number;
};

export const unavailablePillStates = {
  OUT_OF_STOCK: "outOfStock",
  LESS_AVAILABLE: "lessAvailability",
};

const UnavailablePill: FC<Props> = ({ stockAvailable, stockRequested }) => {
  let variant = unavailablePillStates.OUT_OF_STOCK;
  let text = "Out of Stock";

  if (stockRequested && stockAvailable !== undefined && stockAvailable > 0) {
    variant = unavailablePillStates.LESS_AVAILABLE;
    text = `Only ${stockAvailable} of ${stockRequested} units available`;
  }

  return <AlertPill text={text} variant={variant} />;
};

UnavailablePill.defaultProps = {
  stockAvailable: undefined,
  stockRequested: undefined,
};

// eslint-disable-next-line import/no-default-export
export default UnavailablePill;
