export const brandValueProp = {
  parts: ["section", "title", "body", "button", "icon"],
  baseStyle: {
    section: {
      fontFamily: "normal",
      color: "black",
      backgroundColor: "white",
    },
    button: {
      marginTop: {
        base: 5,
        md: 8,
        xl: 12,
      },
    },
    cta: {
      mx: "auto",
    },
    icon: {
      mb: 8,
      svg: {
        width: {
          base: 29,
          md: 34,
        },
        height: "auto",
      },
    },
  },
  variants: {
    secondary: {
      title: {
        marginBottom: {
          base: 7,
          xl: 0,
        },
      },
      button: {
        marginTop: {
          base: 8,
          xl: 5,
        },
        marginX: 0,
      },
    },
  },
};
