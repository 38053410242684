import { parts } from "@/dep_theme/common/components/productCard";

export const productCard = {
  parts,
  baseStyle: {},
  variants: {
    vertical: {},
    horizontal: {},
  },
};
