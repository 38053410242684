/* eslint-disable @typescript-eslint/no-explicit-any */
import APISectionType from "@/dep_types/content/api/components/sections";
import { DynamicContentType } from "@/dep_types/content/dynamic-content";

import categoryTilesDataMapper from "./categoryTiles";
import faqsDataMapper from "./faqs";
import howItWorksDataMapper from "./howItWorks";
import mediaValuePropDataMapper from "./mediaValueProp";
import recommendedProductsDataMapper from "./recommendedProducts";
import valuePropDataMapper from "./valueProp";

import brandPartnershipHeroMapper from "../components/brandPartnershipHero";
import heroDataMapper from "../components/hero";

type SectionMappersType = {
  [key: string]: any;
};

const sectionMappers: SectionMappersType = {
  "sections.recommended-products": recommendedProductsDataMapper,
  "sections.brand-value-prop": valuePropDataMapper,
  "sections.category-tiles": categoryTilesDataMapper,
  "sections.how-it-works": howItWorksDataMapper,
  "sections.media-value-prop": mediaValuePropDataMapper,
  "sections.brand-partnership-hero": brandPartnershipHeroMapper,
  "sections.fa-qs": faqsDataMapper,
  "sections.hero": heroDataMapper,
};

const sectionMappersFunc = (
  apiSectionsData: APISectionType[],
): DynamicContentType[] =>
  apiSectionsData.reduce((response: DynamicContentType[], apiSectionData) => {
    const componentKey = apiSectionData.__component;
    const mapper = sectionMappers[componentKey] || null;

    if (mapper !== null) {
      const mappedData = mapper(apiSectionData);

      response.push(mappedData);
    }

    return response;
  }, []);

// eslint-disable-next-line import/no-default-export
export default sectionMappersFunc;
