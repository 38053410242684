const searchResults = {
  parts: ["noResults", "title"],
  baseStyle: {
    title: {
      fontFamily: "title",
      fontWeight: "700",
      fontSize: "14px",
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default searchResults;
