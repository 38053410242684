const orderNumber = {
  parts: ["numberContainer"],
  baseStyle: {
    numberContainer: {
      ml: 4,
      px: 4,
      py: 1,
      color: "white",
      backgroundColor: "primary.600",
      fontSize: "sm",
      fontFamily: "normal",
      fontWeight: "medium",
      borderRadius: 4,
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderNumber;
