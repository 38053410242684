/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from "../../dep_types/ApiTypes";

// eslint-disable-next-line import/no-default-export
export default class HttpResponse {
  response: ApiResponse;

  constructor(response: ApiResponse) {
    this.response = response;
  }

  get = (): ApiResponse => this.response;

  transform = (doTransform: any): HttpResponse => {
    const { data } = this.response;

    if (data) {
      this.response.data = doTransform(data);
    }

    return this;
  };

  //
  process = (callback: any): HttpResponse => {
    if (callback !== undefined) {
      callback();
    }

    return this;
  };

  processOnSuccess = (callback: any): HttpResponse => {
    if (callback !== undefined && this.response.data && !this.response.error) {
      callback(this.response.data);
    }

    return this;
  };

  processOnError = (callback: any): HttpResponse => {
    if (callback !== undefined && this.response.error) {
      callback(this.response.error);
    }

    return this;
  };

  //
  failOn = (callback: any): HttpResponse => {
    if (callback !== undefined && this.response.data) {
      const { error, errorMessage } = callback(this.response.data);

      if (error) {
        this.response.error = error;
        this.response.errorMessage = errorMessage;
      }
    }

    return this;
  };
}
