import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { keys } = accordionAnatomy;
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(keys);

export const Accordion = defineMultiStyleConfig({
  baseStyle: {
    button: {
      fontWeight: "bold",
      fontFamily: "title",
      textTransform: "none",
    },
  },
});
