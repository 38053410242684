import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

import { SearchProductType } from "@/dep_types";
import EventLocationType from "@/dep_types/EventLocationType";

import { ProductCard } from "@/dep_components/Commons/Products/ProductCard/ProductCard";
import Container from "@/dep_components/Layout/Container";
import { useSearchContext } from "@/dep_components/searchContext";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type Props = {
  popularProducts: SearchProductType[];
};

const SearchResults: FC<Props> = ({ popularProducts }) => {
  const { isYeti } = useWhitelabelContext();
  const { searchResults, lastSearchedTerm } = useSearchContext();
  const styles = useMultiStyleConfig("SearchResults", {});

  const numberOfResults = searchResults.length;
  const hasResults = numberOfResults > 0;
  // eslint-disable-next-line no-nested-ternary
  const productsHeadingText = hasResults
    ? `${numberOfResults} Results`
    : isYeti
    ? "Most popular gear"
    : "Most popular products";
  const products = hasResults ? searchResults : popularProducts;
  const [displayNoResultsMessage, setDisplayNoResultsMessage] = useState(false);

  useEffect(() => {
    const hasSearchTermAndNoResults = Boolean(lastSearchedTerm) && !hasResults;

    if (hasSearchTermAndNoResults !== displayNoResultsMessage) {
      setDisplayNoResultsMessage(hasSearchTermAndNoResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  return (
    <Container py="7" backgroundColor="gray.50">
      {displayNoResultsMessage && (
        <Heading as="h3" sx={styles.noResults}>
          {`Hmm, no results for "${lastSearchedTerm}", try a new search.`}
        </Heading>
      )}
      {products?.length > 0 && (
        <>
          <Heading as="h3" sx={styles.title}>
            {productsHeadingText}
          </Heading>
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
              xl: 5,
              "2xl": 6,
            }}
            w="100%"
            justifyContent="center"
            columnGap="8"
            rowGap="5"
          >
            {products.map((product) => (
              <GridItem
                key={`search-result-${product.slug}-${product.searchTerm}`}
              >
                <Box>
                  <ProductCard
                    location={EventLocationType.SEARCH}
                    product={product}
                    variation={{
                      base: "vertical",
                      sm: "horizontal",
                      xl: "vertical",
                    }}
                    shouldShowPrice={false}
                  />
                </Box>
              </GridItem>
            ))}
          </SimpleGrid>
        </>
      )}
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchResults;
