const footer = {
  parts: ["container", "title", "body", "copyright", "assistiveText"],
  baseStyle: {
    container: {
      fontFamily: "normal",
      fontWeight: "medium",
      color: "white",
      backgroundColor: "black",
      py: {
        base: 12,
        md: 16,
      },
    },
    title: {
      fontSize: "16px",
      fontFamily: "normal",
      fontWeight: "medium",
      my: 3,
    },
    body: {
      fontSize: "14px",
      fontFamily: "normal",
    },
    copyright: {
      fontFamily: "normal",
    },
    assistiveText: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default footer;
