/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoriesType, CategoryType, GroupCategoriesType } from "@/dep_types";
import { ProductPriceRangeType } from "@/dep_types/ProductTypes";

import Utils from "./Utils";

import { productSaleTypes } from "../dep_constants";

// eslint-disable-next-line import/no-default-export
export default class CollectionsHelper {
  static groupCategoriesByCategoryGroup(collections: any): any {
    return Utils.groupBy(collections, "group");
  }

  static groupCollectionByCategory(
    groupedCategories: any,
  ): GroupCategoriesType {
    const categoryMainMenuGroupKeys = Object.keys(groupedCategories);

    // eslint-disable-next-line no-return-assign
    categoryMainMenuGroupKeys.forEach(
      (groupKey: string) =>
        (groupedCategories[groupKey] = groupedCategories[groupKey].reduce(
          (categories: any, collection: any) => {
            const arrIndex = collection.collectionCategory.id;

            if (categories[arrIndex]) {
              categories[arrIndex].collections.push(collection);
            } else {
              categories[arrIndex] = {
                collectionCategory: collection.collectionCategory,
                collections: [collection],
              };
            }

            return categories;
          },
          {},
        )),
    );

    return groupedCategories;
  }

  static sortCategories(groupedCategories: CategoryType[]): CategoryType[] {
    return [...groupedCategories].sort(
      (a, b) =>
        a.collectionCategory.sortWeight - b.collectionCategory.sortWeight,
    );
  }

  static getCategoryAndCollectionNamesFromFilters(
    categories: CategoriesType,
    categoryId: string,
    collectionIds: string[],
  ): {
    categoryName: string;
    collectionNames: string;
  } {
    let collectionNames = "";

    const category = categoryId && categories[categoryId]?.collectionCategory;
    const categoryName = category && category.name;

    if (categoryId && collectionIds.length > 0) {
      const collections = categories[categoryId]?.collections.filter(
        (collection) => collectionIds.includes(collection.id.toString()),
      );

      if (collections && collections.length > 0) {
        collectionNames = collections
          .map((collection) => collection.name)
          .join(", ");
      }
    }

    return {
      categoryName,
      collectionNames,
    };
  }

  static getStringArrayFromParam(valueParam?: string | string[]): string[] {
    if (valueParam) {
      if (Array.isArray(valueParam)) {
        return valueParam;
      }

      return [valueParam.toString()];
    }

    return [];
  }

  static getPriceRangeFromParams(
    lp?: string | string[],
    up?: string | string[],
  ): ProductPriceRangeType {
    if (!lp || !up) {
      return {};
    }

    const numericLp = parseInt(lp as string, 10);

    const numericUp = parseInt(up as string, 10);

    const valuesAreNumber =
      Number.isInteger(numericLp) && Number.isInteger(numericUp);

    if (!valuesAreNumber) {
      return {};
    }

    if (numericLp > numericUp) {
      return {};
    }

    return {
      lp: lp.toString(),
      up: up.toString(),
    };
  }

  static getCollectionSaleTypesFilterForAmplitudeTrack(saleTypeFilters: {
    [key: number]: boolean;
  }): string[] {
    const saleTypes: string[] = [];

    if (!saleTypeFilters) {
      return saleTypes;
    }
    if (saleTypeFilters[1]) {
      saleTypes.push(productSaleTypes.RENTAL);
    }
    if (saleTypeFilters[2]) {
      saleTypes.push(productSaleTypes.RESALE);
    }

    return saleTypes;
  }
}
