/* eslint-disable @typescript-eslint/no-explicit-any */
import contentErrorDataMapper from "@/dep_helpers/content/mappers/error";
import { ContentResponseType } from "@/dep_types/content/response";
// TODO: Something similar with DataDog, hopefully?
// Sentry.captureException(error);
const handleContentError = (error: any): ContentResponseType => {
  const data = {
    data: null,
    error: contentErrorDataMapper(
      error,
      "We are currently experiencing technical problems. Our engineers have been notified of the issue and are working on a fix.",
    ),
  };

  // eslint-disable-next-line no-console
  console.error(data);

  return data;
};

// eslint-disable-next-line import/no-default-export
export default handleContentError;
