const textStyles = {
  "2xs": {
    fontSize: "10px",
    lineHeight: 1.6,
  },
  xs: {
    fontSize: "12px",
    lineHeight: 1.33,
  },
  sm: {
    fontSize: "13px",
    lineHeight: 1.2,
  },
  md: {
    fontSize: "14px",
    lineHeight: 1.4,
  },
  lg: {
    fontSize: "16px",
    lineHeight: 1.48,
  },
  xl: {
    fontSize: "18px",
    lineHeight: 1.35,
  },
};

// eslint-disable-next-line import/no-default-export
export default textStyles;
