/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-collapsible-if */
import amplitude, { AmplitudeClient } from "amplitude-js";

import partnerIds from "@/dep_constants/partnerIds";
import CartHelper from "@/dep_helpers/CartHelper";
import appendSetChoices from "@/dep_helpers/tracking/appendSetChoices";

import TrackingStrategy from "./trackingStrategy";

import { conditionTypes, productSaleTypes } from "../../../dep_constants";
import DatesHelper from "../../../dep_helpers/DatesHelper";
import ProductHelper from "../../../dep_helpers/ProductHelper";
import OrderLog from "../../../dep_helpers/order/OrderLog";
import {
  ProductType,
  RentalDatesType,
  SearchProductType,
} from "../../../dep_types";
import { CartItemType, CartSetItemType } from "../../../dep_types/CartTypes";
import EventLocationType from "../../../dep_types/EventLocationType";
import resolveConfigurations from "../../../dep_whitelabel";
import ShoppingCartService from "../../shoppingCartService";

const events = {
  VIEWED_PAGE: "viewed page",
  SCROLL_DEPTH: "Scroll Depth",
  VIEWED_PRODUCT: "viewed product",
  PRODUCT_OUT_OF_STOCK: "Product Out of Stock",
  CLICKED_PRODUCT: "clicked product",
  CALENDAR_OPENED: "Calendar Opened",
  CALENDAR_DATES_CLEARED: "Calendar Dates Cleared",
  CALENDAR_DATES_CONFIRMED: "Calendar Dates Confirmed",
  NAVIGATION_CLICK: "Navigation Click",
  VIEWED_COLLECTION: "viewed collection",
  ADDED_PRODUCT: "added product",
  INITIATED_CHECKOUT: "initiated checkout",
  PLACED_RESERVATION: "placed reservation",
  PURCHASED_ITEM: "purchased item",
  EDITED_ORDER: "edited order",
  CANCELLED_ORDER: " cancelled order",
  REVENUE: "revenue",
};

// eslint-disable-next-line import/no-default-export
export default class AmplitudeTrackingStrategy extends TrackingStrategy {
  private amplitudeClient: AmplitudeClient | undefined = undefined;

  initialized(): boolean {
    if (typeof window === "undefined") return false;
    if (!TrackingStrategy.partnerId) return false;

    const configurations = resolveConfigurations(TrackingStrategy.partnerId);
    const key = configurations.keys.AMPLITUDE_KEY;

    if (!key) {
      return false;
    }

    if (!this.amplitudeClient) {
      this.amplitudeClient = amplitude.getInstance();
      if (this.amplitudeClient) {
        this.amplitudeClient.init(key as string, undefined, {
          includeUtm: true,
          includeGclid: true,
          includeReferrer: true,
          sameSiteCookie: "Strict",
          cookieExpiration: 365,
        });
      } else {
        return false;
      }
    }

    return true;
  }

  trackAddToCart(newCartItem: CartItemType | CartSetItemType): void {
    const { product } = newCartItem;
    const setChoices: string[] = [];

    appendSetChoices(newCartItem, setChoices);

    const properties = {
      product_name: product.name,
      product_category: product.category,
      product_brand: product.brand.name,
      set_choices: setChoices,
      item_type: newCartItem.isResale
        ? productSaleTypes.RESALE
        : productSaleTypes.RENTAL,
      condition: newCartItem.condition,
    };

    this.trackEvent(events.ADDED_PRODUCT, properties);
  }

  trackConversion(
    _partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void {
    const cartId = ShoppingCartService.getCartId();

    const {
      cartItems,
      cartTotalPrice,
      shoppingCartRentalDates,
      logRentalDates,
      rentalShippingCost,
      rentalTotal,
      resaleShippingCost,
      resaleTotal,
      tripDuration,
      paymentMethodName,
      customerData,
    } = orderLog;

    const conversionType = CartHelper.getConversionType(cartItems);

    const shippingCost = rentalShippingCost + resaleShippingCost;
    const revenue = rentalTotal + resaleTotal;
    const taxes = revenue - cartTotalPrice - shippingCost;
    const totalUnits = cartItems
      .map((c) => c.quantity)
      .reduce((partialSum: number, a: number) => partialSum + a, 0);
    const proratedTaxCost = taxes / cartItems.length;
    const proratedShippingCost = shippingCost / cartItems.length;

    const productNames: string[] = [];
    const variantNames: string[] = [];
    const categoryNames: string[] = [];
    const collectionCategoryNamesSet = new Set<string>();
    const collectionCategoryNames: string[] = [];
    const collectionNames: string[] = [];
    const brandNames: string[] = [];
    const conditions: string[] = [];
    const colors: string[] = [];
    const sizes: string[] = [];
    const skus: string[] = [];
    const setChoices: string[] = [];

    cartItems.forEach((cartItem) => {
      const variantCollectionNames: string[] = [];

      productNames.push(cartItem.product.name);
      categoryNames.push(cartItem.product.category);
      if (cartItem.product.productCollections) {
        cartItem.product.productCollections.forEach((c) => {
          collectionNames.push(c.name);
          variantCollectionNames.push(c.name);
          if (c.collectionCategory) {
            collectionCategoryNamesSet.add(c.collectionCategory.name);
          }
        });
      }
      if (cartItem.condition) {
        conditions.push(cartItem.condition);
      }
      brandNames.push(cartItem.product.brand.name);

      const variant = cartItem.product?.variants?.find(
        (v) => v.id === cartItem.variant,
      );

      if (variant) {
        if (variant.displayName) {
          variantNames.push(variant.displayName);

          if (_partnerId === partnerIds.YETI) {
            if (variant.displayName?.toLowerCase().indexOf("wheeled") >= 0) {
              const wheeledCategory = "wheeled coolers";

              // This category is created dynamically by the search engine, so we need to hardcode it here to send it to amplitude
              collectionCategoryNamesSet.clear();
              collectionCategoryNamesSet.add(wheeledCategory);
            }
          }
        }
        skus.push(variant.sku);
        colors.push(variant.parameters.Color);
        sizes.push(variant.parameters.Size);

        const variantCollectionCategoryNames: string[] = [];

        collectionCategoryNamesSet.forEach((c) => {
          variantCollectionCategoryNames.push(c);
        });

        const variantProperties = {
          cart_id: cartId,
          order_id: rentalOrderId ?? resaleOrderId,
          product_name: cartItem.product.name,
          product_color: variant.parameters.Color,
          variant_name: variant.displayName,
          product_size: variant.parameters.Size,
          product_sku: variant.sku,
          product_condition: cartItem.condition,
          category_name: cartItem.product.category,
          collection_category_names: variantCollectionCategoryNames,
          collection_names: variantCollectionNames,
          brand_name: cartItem.product.brand.name,
          quantity: cartItem.quantity,
          price: cartItem.totalPrice,
          total_price: cartItem.totalPrice,
          revenue: cartItem.totalPrice + proratedTaxCost + proratedShippingCost,
          total_cart_size: totalUnits,
          delivery_date: logRentalDates.startDate,
          return_date: logRentalDates.endDate,
          item_type: cartItem.isResale
            ? productSaleTypes.RESALE
            : productSaleTypes.RENTAL,
          // Shipping info
          shipping_city: customerData.shippingAddress.city,
          shipping_state: customerData.shippingAddress.state,
          trip_duration: tripDuration,
          total_cart_value: cartTotalPrice,
        };

        this.trackEvent(events.PURCHASED_ITEM, variantProperties);
      }

      appendSetChoices(cartItem, setChoices);
    });
    collectionCategoryNamesSet.forEach((c) => {
      collectionCategoryNames.push(c);
    });

    const daysInAdvance = shoppingCartRentalDates?.startDate
      ? DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
          new Date(),
          shoppingCartRentalDates.startDate as Date,
        )
      : 0;

    const conditionGood = cartItems.filter(
      (item) => item.condition === conditionTypes.GOOD.key,
    ).length;
    const conditionFair = cartItems.filter(
      (item) => item.condition === conditionTypes.FAIR.key,
    ).length;
    const conditionBad = cartItems.filter(
      (item) => item.condition === conditionTypes.BAD.key,
    ).length;

    const reservationProperties = {
      cart_id: cartId,
      total_cart_value: cartTotalPrice,
      total_cart_size: totalUnits,
      order_id: rentalOrderId || resaleOrderId,
      delivery_date: logRentalDates.startDate,
      return_date: logRentalDates.endDate,
      total_shipping: shippingCost,
      shipping_city: customerData.shippingAddress.city,
      shipping_state: customerData.shippingAddress.state,
      billing_city: customerData.billingAddress.city,
      billing_state: customerData.billingAddress.state,
      order_total: rentalTotal + resaleTotal,
      order_total_rental: rentalTotal,
      order_total_resale: resaleTotal,
      product_names: productNames,
      product_colors: colors,
      variant_names: variantNames,
      product_sizes: sizes,
      product_skus: skus,
      product_conditions: conditions,
      set_choices: setChoices,
      category_names: categoryNames,
      collection_category_names: collectionCategoryNames,
      collection_names: collectionNames,
      brand_names: brandNames,
      trip_duration: tripDuration,
      payment_method: paymentMethodName,
      days_in_advance: daysInAdvance,
      // Revenue properties
      quantity: cartItems.length,
      price: cartTotalPrice,
      revenue,
      conversion_type: conversionType,
      condition_good: conditionGood,
      condition_fair: conditionFair,
      condition_bad: conditionBad,
    };

    const revenueProperties = {
      revenue: rentalTotal + resaleTotal,
    };

    this.trackEvent(events.PLACED_RESERVATION, reservationProperties);

    this.trackEvent(events.REVENUE, revenueProperties);
  }

  trackEvent(eventName: string, data?: object): void {
    if (!this.amplitudeClient) return;
    this.amplitudeClient.logEvent(eventName, data);
  }

  trackPageView(pageCategory?: string): void {
    this.trackEvent(events.VIEWED_PAGE, { pageCategory });
  }

  trackScrollDepth(
    scrollDepth: number,
    pageCategory: string,
    url: string,
  ): void {
    this.trackEvent(events.SCROLL_DEPTH, {
      scrollDepth,
      pageCategory,
      url,
    });
  }

  trackProductClick(
    location: EventLocationType,
    product: ProductType | SearchProductType,
    siteRentalDates: RentalDatesType,
    fromUrl: string,
  ): void {
    // TODO RESALE: ask for resale modifications

    const { pricePerNight } = ProductHelper.calculatePrices(
      product,
      siteRentalDates,
    );

    this.trackEvent(events.CLICKED_PRODUCT, {
      location,
      product_id: product.id,
      product_name: product.name,
      product_category: product.category,
      product_brand: product.brand.name,
      product_daily_price: pricePerNight,
      previous_page: fromUrl,
    });
  }

  trackCalendarOpened(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
  ): void {
    this.trackEvent(events.CALENDAR_OPENED, {
      location,
      product_names: productNames,
      product_categories: categoryNames,
    });
  }

  trackCalendarDatesCleared(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
  ): void {
    this.trackEvent(events.CALENDAR_DATES_CLEARED, {
      location,
      product_names: productNames,
      product_categories: categoryNames,
    });
  }

  trackCalendarDatesConfirmed(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
    startDate: string,
    endDate: string,
    tripDuration: number,
    months: string[],
    startDay: string,
  ): void {
    this.trackEvent(events.CALENDAR_DATES_CONFIRMED, {
      location,
      product_names: productNames,
      product_categories: categoryNames,
      start_date: startDate,
      end_date: endDate,
      trip_duration: tripDuration,
      months,
      start_day: startDay,
    });
  }

  trackNavigationClick(
    menuItem: string,
    primaryCategory: string,
    secondaryCategory: string,
    pageCategory: string,
    url: string,
  ): void {
    this.trackEvent(events.NAVIGATION_CLICK, {
      menuItem,
      primaryCategory,
      secondaryCategory,
      pageCategory,
      url,
    });
  }

  trackProductPageView(product: ProductType): void {
    // TODO RESALE: ask for resale modifications

    const properties = {
      product_name: product.name,
      product_category: product.category,
      product_brand: product.brand.name,
    };

    this.trackEvent(events.VIEWED_PRODUCT, properties);
  }

  trackProductOutOfStock(
    product: ProductType,
    siteRentalDates: RentalDatesType,
    url: string,
  ): void {
    const properties = {
      product_name: product.name,
      product_category: product.category,
      product_brand: product.brand.name,
      delivery_date: siteRentalDates.startDate,
      return_date: siteRentalDates.endDate,
      url,
    };

    this.trackEvent(events.PRODUCT_OUT_OF_STOCK, properties);
  }

  trackCollectionPageView(
    category: string,
    collections: string,
    saleTypes: string[],
  ): void {
    this.trackEvent(events.VIEWED_COLLECTION, {
      collection_names: collections,
      product_category: category,
      collection_type: saleTypes,
    });
  }

  trackStartCheckout(cartItems: CartItemType[], cartTotalPrice: number): void {
    const cartId = ShoppingCartService.getCartId();

    this.trackEvent(events.INITIATED_CHECKOUT, {
      cart_id: cartId,
      total_cart_value: cartTotalPrice,
      total_cart_size: cartItems.length,
    });
  }

  trackOrderEdited(
    orderId: string,
    editing: string,
    valueChange?: string,
    totalDifference?: number,
    newTotal?: number,
  ): void {
    // TODO RESALE: ask for resale modifications

    this.trackEvent(events.EDITED_ORDER, {
      order_id: orderId,
      editing,
      value_change: valueChange,
      total_difference: totalDifference,
      new_total: newTotal,
    });
  }

  trackOrderCancelled(orderId: string, value: number): void {
    const properties = {
      order_id: orderId,
      order_total: value,
    };

    this.trackEvent(events.CANCELLED_ORDER, properties);
  }
}
