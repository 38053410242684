const inputText = {
  baseStyle: {
    label: {
      color: "gray.500",
      fontWeight: 500,
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    input: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default inputText;
