import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

import DatesHelper from "@/dep_helpers/DatesHelper";
import ShoppingCartService from "@/dep_services/shoppingCartService";
import { RentalDatesType } from "@/dep_types";

export type RentalDateConfig = {
  minEndRentalDate: string;
  minRentalDays: number;
};

export const getRentalDateConfig = async (
  partnerId: string,
): Promise<RentalDateConfig> => {
  const firstAvailableDate =
    await ShoppingCartService.getRentalMinDate(partnerId);
  const date = DatesHelper.parseFromServerDate(firstAvailableDate);

  return {
    minEndRentalDate: firstAvailableDate,
    minRentalDays: DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      new Date(),
      date,
    ),
  };
};

const tripDiscountThresholds = [
  {
    minimumDuration: 3,
    stickerContent: (
      <Box as="span" textTransform="uppercase">
        Tip!
      </Box>
    ),
    textContent: <>Extend your trip to 5 days for 25% off!</>,
  },
  {
    minimumDuration: 5,
    stickerContent: <>25% Off</>,
    textContent: (
      <>
        <Box as="span" fontWeight="medium">
          Discount!
        </Box>
        {" Your trip is longer than 5 days so you get a 25% trip discount!"}
      </>
    ),
  },
  {
    minimumDuration: 10,
    stickerContent: <>50% Off</>,
    textContent: (
      <>
        <Box as="span" fontWeight="medium">
          Discount!
        </Box>
        {" Your trip is longer than 10 days so you get a 50% trip discount!"}
      </>
    ),
  },
];

export type TripDiscountTagType = {
  stickerContent: ReactElement;
  textContent: ReactElement;
};

export const getTripDiscountTag = (
  siteRentalDates: RentalDatesType,
): TripDiscountTagType | null => {
  let tripDiscountTag: TripDiscountTagType | null = null;

  const areDatesValid = DatesHelper.rentalDatesAreValid(siteRentalDates);

  const tripDuration = areDatesValid
    ? DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
        siteRentalDates.startDate as Date,
        siteRentalDates.endDate as Date,
      )
    : 0;

  tripDiscountThresholds.forEach((discountThreshold) => {
    const meetsMinimumDuration =
      tripDuration >= discountThreshold.minimumDuration;

    if (meetsMinimumDuration) {
      tripDiscountTag = {
        stickerContent: discountThreshold.stickerContent,
        textContent: discountThreshold.textContent,
      };
    }
  });

  return tripDiscountTag;
};
