const richText = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "normal",
    letterSpacing: "0.16px",
    a: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default richText;
