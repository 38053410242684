const title = {
  baseStyle: {
    textTransform: "uppercase",
    fontFamily: "normal",
    fontWeight: 700,
    color: "gray.500",
  },
  variants: {
    findMyOrder: {
      fontSize: "30px",
      lineHeight: "42px",
      letterSpacing: "2px",
    },
    hero: {
      fontSize: "30px",
      mt: {
        base: "34px",
        md: 0,
      },
      textAlign: "center",
      fontWeight: 700,
      letterSpacing: "2px",
      lineHeight: "42px",
      textTransform: "uppercase",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default title;
