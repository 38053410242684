import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React, { FC } from "react";

import PartnerFooterInfo from "@/dep_components/Footer/PartnerFooterInfo";
import Container from "@/dep_components/Layout/Container";
import ThemeToggle from "@/dep_components/Storybook/ThemeToggle";

import CookieBanner from "./CookieBanner";
import PartnerFooterLinks from "./PartnerFooterLinks";

import RecGov from "../RecGov/RecGov";

const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === "local";
const showThemeToggle = isLocal;

const Footer: FC = () => {
  const styles = useMultiStyleConfig("Footer");

  return (
    <>
      <Container sx={styles.container}>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          flexGrow={1}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          marginBottom={{ base: 0, md: 14 }}
        >
          <PartnerFooterInfo />
        </Flex>
        <Flex
          flexGrow={1}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          marginBottom={15}
        >
          <PartnerFooterLinks />
        </Flex>
        <RecGov />
      </Container>
      {showThemeToggle && <ThemeToggle />}
      <CookieBanner />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Footer;
