import { useRouter } from "next/router";
import { useState, useCallback, useMemo } from "react";

import { componentModalContent } from "@/features/shared/Modals/ModalContent/componentModalContent";
import { ModalSizeType } from "@/revive/components/Modal/Modal";

import { useModalsCms } from "./useModalsCms";

export type ModalContent = {
  modalId: string;
  size?: ModalSizeType;
  title?: string;
  content?: string;
  component?: React.ComponentType;
};

export function useModalManager() {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string | undefined>();

  const getPath = useMemo(() => {
    if (!router) return { pathname: "", query: {} };
    const { pathname, query } = router;
    const newQuery = { ...query };

    delete newQuery.modal;

    return {
      pathname,
      query: newQuery,
    };
  }, [router]);

  const openModal = useCallback(
    (id: string) => {
      setModalId(id);
      setIsOpen(true);
      router?.replace(
        { ...getPath, query: { ...getPath.query, modal: id } },
        undefined,
        {
          shallow: true,
        },
      );
    },
    [getPath, router],
  );

  const closeModal = useCallback((): Promise<void> => {
    return new Promise((resolve) => {
      setModalId(undefined);
      setIsOpen(false);
      router?.replace(getPath, undefined, { shallow: true }).then(() => {
        resolve();
      });
    });
  }, [getPath, router]);

  const modalActions = useMemo(
    () => ({
      openModal,
      closeModal,
      isOpen,
    }),
    [openModal, closeModal, isOpen],
  );

  const { data } = useModalsCms({ openModal });

  const modalContent = useMemo(() => {
    // Modal content from the CMS
    const cmsModalContent: ModalContent[] =
      data?.map((item: { attributes: ModalContent }) => item.attributes) ?? [];

    return [...cmsModalContent, ...componentModalContent];
  }, [data]);

  const matchingModal = modalContent.find((modal) => modal.modalId === modalId);

  return {
    modalActions,
    matchingModal,
  };
}
