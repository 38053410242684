import {
  ConditionKey,
  ConditionSummaryType,
  ConditionTypes,
  PriceDecrementingConditionKey,
} from "@/dep_types";

export const filterGroups = {
  CATEGORIES: "categories",
  COLLECTIONS: "collections",
  BRANDS: "brands",
  PRODUCT_SALE_TYPES: "productSaleTypes",
  CONDITIONS: "conditions",
  COLORS: "colors",
  SIZES: "sizes",
  CAPACITIES: "capacities",
  GENDERS: "genders",
  SEASONS: "seasons",
  PRICE_RANGE: "priceRange",
};

export const ALL_COLLECTION_TYPES = "all";

export const datesScope = {
  CART: "cartDates",
  SITE: "siteDates",
};

export const productType = {
  PRODUCT: "product",
  SET: "set",
};

export const shoppingCartErrorTypes = {
  NO_DATES: "nodates",
  INVALID_DATE_RANGE: "daterange",
  ITEMS_UNAVAILABLE: "unavailable",
  PRICE_DECREMENTING_MISMATCH: "priceDecrementingMismatch",
};

export const stateAbbreviations = {
  ALASKA: "ak",
  HAWAII: "hi",
};

export const itemQuantityChangeAction = {
  ADD: "add",
  REDUCE: "reduce",
};

export const environment = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
};

export const productSaleTypes = {
  RENTAL: "rental",
  RESALE: "resale",
  BOTH: "both",
};

export const conditionTypes: ConditionTypes = {
  GOOD: {
    tierNumber: 1,
    key: "good" as ConditionKey,
    priceDecrementingKey: "goodArray" as PriceDecrementingConditionKey,
    description: "Like New",
  },
  FAIR: {
    tierNumber: 2,
    key: "fair" as ConditionKey,
    priceDecrementingKey: "fairArray" as PriceDecrementingConditionKey,
    description: "Great",
  },
  BAD: {
    tierNumber: 3,
    key: "bad" as ConditionKey,
    priceDecrementingKey: "badArray" as PriceDecrementingConditionKey,
    description: "Good",
  },
};

export const conditionsSummary: ConditionSummaryType = {
  [conditionTypes.BAD.key]: 0,
  [conditionTypes.FAIR.key]: 0,
  [conditionTypes.GOOD.key]: 0,
};

export const variantParameterKeys = {
  SIZE: "Size",
  COLOR: "Color",
  CONDITION: "condition",
  SIZE_CATEGORY: "Size Category",
};

export const sizeCategoryRegularValues = ["regular"];

export const allProductsCollectionSubstring = "All";
export const demoProductMaxRentalDuration = 14;
export const productSaleTypeResale = 2;

export const yetiVSeries = "v series";
