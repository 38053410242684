/* eslint-disable sonarjs/no-duplicate-string */
const faqValueProp = {
  parts: ["title", "question", "answer"],
  baseStyle: {
    title: {
      color: "primary.600",
      letterSpacing: "3px",
      fontWeight: "bold",
      fontFamily: "title",
    },
    question: {
      color: "primary.600",
      fontFamily: "title",
      textTransform: "uppercase",
      textAlign: "left",
    },
    answer: {
      fontFamily: "normal",
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqValueProp;
