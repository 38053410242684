import { useQuery } from "@tanstack/react-query";

import { FetchFeatureFlags } from "@/dep_queries/cms/FetchFeatureFlags";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import type { FeatureFlags } from "@/dep_queries/cms/FetchFeatureFlags";

interface UseFeatureFlags {
  onSuccess?: (data: FeatureFlags) => void;
}

export function useFeatureFlags({ onSuccess }: UseFeatureFlags = {}) {
  const { name } = useWhitelabelContext();

  const { data, error, isLoading } = useQuery<FeatureFlags, Error>({
    queryKey: ["feature-flags", name.toLowerCase()],
    queryFn: () => FetchFeatureFlags(name.toLowerCase().replace(/ /g, "-")),
    ...{
      enabled: Boolean(name),
      onSuccess,
    },
  });

  return {
    data,
    error,
    isLoading,
  };
}
