import { Box, CloseButton, Flex } from "@chakra-ui/react";
import React, { FC, ReactNodeArray, useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";

import localStorageKeys from "@/dep_constants/localStorageKeys";

import SiteLink from "@/dep_components/Commons/SiteLink";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import localStorageService from "../../dep_services/localStorageService";

const CookieBanner: FC = () => {
  const { privacyPolicyLink, termsOfUseLink, keys, globalLabels } =
    useWhitelabelContext();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookieAcceptanceState = localStorageService.get(
      localStorageKeys.COOKIE_BANNER_ID,
    );
    const hasCookieBanner = !cookieAcceptanceState && !keys.ONE_TRUST_SCRIPT_ID;

    setShowCookieBanner(hasCookieBanner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAcceptCookies = (): void => {
    setShowCookieBanner(false);
    localStorageService.save(localStorageKeys.COOKIE_BANNER_ID, "accepted");
  };

  if (showCookieBanner) {
    const renderCookieBannerText = (): ReactNodeArray => {
      const bannerText =
        globalLabels?.cookiesBannerText ||
        "We use cookies to improve your experience on our site. By continuing to use this site, you agree to the use of " +
          "cookies, privacy policy and terms of use. You can change your browser settings to remove or reject cookies at any time.";
      let finalBannerText = reactStringReplace(
        bannerText,
        "privacy policy",
        () => (
          <SiteLink url={privacyPolicyLink} opensInNewWindow variant="cookie">
            privacy policy
          </SiteLink>
        ),
      );

      finalBannerText = reactStringReplace(
        finalBannerText,
        "terms of use",
        () => (
          <SiteLink url={termsOfUseLink} opensInNewWindow variant="cookie">
            terms of use
          </SiteLink>
        ),
      );

      return finalBannerText;
    };

    return (
      <Flex
        as="dialog"
        open={showCookieBanner}
        position="fixed"
        justifyContent="center"
        bottom={0}
        width="100%"
        maxWidth="100%"
        fontFamily="normal"
        py={{
          base: 3.5,
          xl: 8,
        }}
        px={10}
        backgroundColor="rgba(0,0,0,0.8)"
        justify="center"
        zIndex={999999}
      >
        <CloseButton
          color="white"
          position="absolute"
          top="1.5"
          right="1.5"
          _hover={{
            background: "gray.500",
          }}
          onClick={onAcceptCookies}
        />
        <Box
          textAlign={{
            base: "center",
            md: "left",
          }}
          fontFamily="normal"
          fontWeight="normal"
          as="p"
          color="white"
          fontSize="12px"
          maxWidth="1400px"
        >
          {renderCookieBannerText()}
        </Box>
      </Flex>
    );
  }

  return <></>;
};

// eslint-disable-next-line import/no-default-export
export default CookieBanner;
