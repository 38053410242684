import { createContext } from "react";

import { useTheme } from "@/revive/hooks/useTheme";

import { GenericIcon } from "./components/GenericIcon";
import { icons } from "./utils/icons";

export type IconName = keyof typeof icons;

type IconProps = {
  readonly name: IconName;
  readonly size?: "lg" | "md" | "sm" | "xs";
};

const sizeMap = {
  lg: 24,
  md: 20,
  sm: 16,
  xs: 12,
};

export const IconContext = createContext<number>(sizeMap.md);

export function Icon({ name, size = "md" }: IconProps) {
  const { icons: themeIcons } = useTheme();

  const themeIcon = themeIcons?.[name];

  const iconSize = sizeMap[size];

  if (themeIcon) {
    return <GenericIcon name={name} size={iconSize} icon={themeIcon} />;
  }

  const ReactIcon = icons[name];

  return (
    <IconContext.Provider value={iconSize}>
      <ReactIcon size={iconSize} data-testid={`icon-${name}`} />
    </IconContext.Provider>
  );
}
