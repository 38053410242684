/* eslint-disable @typescript-eslint/no-explicit-any */
import Utils from "./Utils";

import { productSaleTypes } from "../dep_constants";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemSetGroup,
  CartSetItemType,
} from "../dep_types/CartTypes";

// eslint-disable-next-line import/no-default-export
export default class CartHelper {
  static mapCartToOrder(items: CartItemType[]): any {
    const cartItems = items
      .filter((cartItem) => !cartItem.isSet)
      .map((cartItemProduct: CartItemType) => ({
        variantId: (cartItemProduct as CartProductItemType).variant,
        quantity: (cartItemProduct as CartProductItemType).quantity,
        condition: (cartItemProduct as CartProductItemType).condition,
        isResale: (cartItemProduct as CartProductItemType).isResale,
        price: (cartItemProduct as CartProductItemType)
          .priceDecrementingTotalPrice,
      }));

    const cartSetItems = items
      .filter((cartItem) => cartItem.isSet)
      .map((cartItemSet: CartItemType) => {
        const productId = (cartItemSet as CartSetItemType).product.id;
        const allSelections = (
          cartItemSet as CartSetItemType
        ).cartSetGroups.map((setGroup) =>
          (setGroup as CartSetItemSetGroup[]).map((inner) => ({
            setGroupId: inner.setGroupId,
            variantId: inner.variantId,
          })),
        );

        const variantsGroupedBySetGroupId = Utils.groupBy(
          allSelections.flat(),
          "setGroupId",
        );

        const cartSetGroups = Object.keys(variantsGroupedBySetGroupId).map(
          (key) => {
            const variantIds = variantsGroupedBySetGroupId[key].map(
              ({ variantId }: any) => variantId,
            );

            return {
              setGroupId: key,
              variantIds,
            };
          },
        );

        return {
          productId,
          cartSetGroups,
        };
      });

    return {
      cartItems,
      cartSetItems,
    };
  }

  static getTotal(cartItems: CartItemType[]): number {
    if (!cartItems || cartItems.length === 0) {
      return 0;
    }

    return cartItems
      .map(
        (cartItem) =>
          cartItem.priceDecrementingTotalPrice || cartItem.totalPrice || 0,
      )
      .reduce((sum, price) => sum + price);
  }

  static setInitialItemsStatusToAvailable(cartItems: CartItemType[]): void {
    cartItems.forEach((item) => {
      item.unavailable = false;
      if (item.isSet) {
        (item as CartSetItemType).cartSetGroups.forEach((setGroupSelections) =>
          setGroupSelections.forEach((variantSelection) => {
            variantSelection.unavailable = false;
          }),
        );
      }
    });
  }

  static filterResaleItems = (
    cartItems: CartItemType[],
  ): CartProductItemType[] =>
    (cartItems as CartProductItemType[]).filter(
      (cartItem) => cartItem.isResale,
    );

  static filterRentalItems = (
    cartItems: CartItemType[],
  ): CartProductItemType[] =>
    (cartItems as CartProductItemType[]).filter(
      (cartItem) => !cartItem.isResale,
    );

  static hasRentalItems = (cartItems: CartItemType[]): boolean =>
    cartItems.some((cartItem) => !(cartItem as CartProductItemType).isResale);

  static hasResaleItems = (cartItems: CartItemType[]): boolean =>
    cartItems.some((cartItem) => (cartItem as CartProductItemType).isResale);

  static getConversionType = (cartItems: CartItemType[]): string => {
    const hasRentalItems = CartHelper.hasRentalItems(cartItems);
    const hasResaleItems = CartHelper.hasResaleItems(cartItems);

    if (hasRentalItems && hasResaleItems) {
      return productSaleTypes.BOTH;
    }
    if (hasResaleItems) {
      return productSaleTypes.RESALE;
    }

    return productSaleTypes.RENTAL;
  };

  static hasUnavailableRentalItems = (cartItems: CartItemType[]): boolean =>
    cartItems.some(
      (cartItem) =>
        !(cartItem as CartProductItemType).isResale && cartItem.unavailable,
    );

  static hasUnavailableResaleItems = (cartItems: CartItemType[]): boolean =>
    cartItems.some(
      (cartItem) =>
        (cartItem as CartProductItemType).isResale && cartItem.unavailable,
    );
}
