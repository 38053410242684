import APIValuePropType from "@/dep_types/content/api/components/sections/valueProp";
import { ValuePropType } from "@/dep_types/content/sections";

import iconDataMapper from "../components/icon";
import buttonDataMapper from "../components/linkedButton";
import colorDataMapper from "../miscellaneous/color";

const valueProp = (apiValuePropData: APIValuePropType): ValuePropType => ({
  id: apiValuePropData.id,
  componentKey: apiValuePropData.__component || "",
  icon: apiValuePropData.icon?.data
    ? iconDataMapper(apiValuePropData.icon.data.attributes)
    : null,
  title: apiValuePropData.title,
  description: apiValuePropData.description,
  variant: apiValuePropData.variant,
  backgroundColor: colorDataMapper(apiValuePropData.backgroundColor),
  button: apiValuePropData.button
    ? buttonDataMapper(apiValuePropData.button)
    : null,
});

// eslint-disable-next-line import/no-default-export
export default valueProp;
