export const topTextBanner = {
  parts: ["container", "link"],
  baseStyle: {
    container: {
      color: "white",
    },
    link: {
      fontSize: "15px",
      letterSpacing: "0.5px",
      fontWeight: 400,
      _hover: {
        color: "white",
      },
    },
  },
};
