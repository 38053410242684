import { defineStyleConfig } from "@chakra-ui/react";

export const Heading = defineStyleConfig({
  variants: {
    h1: {
      fontSize: "5xl",
    },
    h2: {
      fontSize: "4xl",
      fontWeight: "medium",
      textTransform: "none",
      letterSpacing: "tighter",
    },
    h3: {
      fontSize: "2xl",
      textTransform: "uppercase",
    },
  },
});
