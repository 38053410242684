const select = {
  baseStyle: {
    field: {
      fontFamily: "normal",
      fontWeight: "medium",
    },
    icon: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default select;
