/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from "@datadog/browser-rum";
import merge from "lodash/merge";
import { NextPageContext } from "next";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import React, { ReactElement } from "react";
import "../styles/globals.css";
import "react-calendar/dist/Calendar.css";
import "keen-slider/keen-slider.min.css";

import { getCurrentPartnerFromHost } from "@/dep_constants/partnerIds";
import CollectionsHelper from "@/dep_helpers/CollectionsHelper";
import {
  getRentalDateConfig,
  RentalDateConfig,
} from "@/dep_helpers/RentalDatesHelper";
import extendSectionsData from "@/dep_helpers/content/extendSectionsData";
import getNotFoundPageData from "@/dep_helpers/content/pages/notFoundPage/getNotFoundPageData";
import getPartnerData from "@/dep_helpers/content/partner/getPartnerData";
import reportContentError from "@/dep_helpers/content/reportContentError";
import {
  FetchNavigationItems,
  NavigationItem,
} from "@/dep_queries/cms/FetchNavigationItems";
import CollectionsService from "@/dep_services/collectionsService";
import TrackingStrategy from "@/dep_services/tracking/stratagies/trackingStrategy";
import resolveTheme from "@/dep_theme";
import { GroupCategoriesType } from "@/dep_types";
import EventLocationType from "@/dep_types/EventLocationType";
import { ContentErrorType } from "@/dep_types/content/error";
import {
  NotFoundPageSectionType,
  NotFoundPageType,
} from "@/dep_types/content/notFoundPage";
import { PartnerType } from "@/dep_types/content/partner";
import { buildPartnerTheme } from "@/helpers/buildPartnerTheme";
import { prefetchUseModalsCms } from "@/hooks/useModalsCms";
import { ModalProvider } from "@/providers/ModalProvider";
import { WhitelabelProvider } from "@/providers/WhitelabelProvider";
import { ThemeProvider } from "@/revive/providers/ThemeProvider";
import { ReviveTheme } from "@/revive/theme";

import ContentError from "@/dep_components/Commons/ContentError";
import { Layout, LayoutWrapper } from "@/dep_components/Layout";

import DatesHelper from "../dep_helpers/DatesHelper";

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DD_APP_ID as string,
  clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
  site: "datadoghq.com",
  service: "ecomm-whitelabel",
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

interface CustomAppInitialProps extends AppInitialProps {
  partnerId: string;
  partnerTheme?: ReviveTheme;
  partnerData: PartnerType | null;
  canonicalDomain: string;
  menuCategories: GroupCategoriesType;
  navigationItems?: NavigationItem[];
  initialMinRentalDates: RentalDateConfig;
  contentError?: ContentErrorType;
  notFoundPageData?: NotFoundPageType | null;
}

export type AllPageProps = AppProps & CustomAppInitialProps;

const MyApp = ({
  Component,
  pageProps,
  partnerId,
  canonicalDomain,
  menuCategories,
  navigationItems,
  partnerData,
  initialMinRentalDates,
  notFoundPageData,
  partnerTheme,
}: AllPageProps): ReactElement => {
  const resolvedTheme = resolveTheme(partnerId);
  const theme = merge({}, resolvedTheme, partnerTheme);

  if (!partnerId || notFoundPageData) {
    // For error page that is generated in build time.
    return (
      <Component
        {...pageProps}
        partnerId={partnerId}
        menuCategories={menuCategories}
        partnerData={partnerData}
        theme={theme}
        {...notFoundPageData}
      />
    );
  }
  TrackingStrategy.partnerId = pageProps.partnerId;
  DatesHelper.minEndRentalDate = DatesHelper.datesStringToDate(
    initialMinRentalDates.minEndRentalDate,
  );
  DatesHelper.minRentalDays = initialMinRentalDates.minRentalDays;
  const LayoutComponent = (Component as any).Layout || Layout;

  const hasContentError = Boolean(pageProps.contentError);
  const hasContentErrorMessage = Boolean(pageProps.contentError?.userMessage);

  if (hasContentError) {
    reportContentError(pageProps.contentError.errorObject);
  }

  return (
    <ThemeProvider theme={theme}>
      <WhitelabelProvider {...pageProps}>
        <LayoutWrapper
          partnerId={pageProps.partnerId}
          partnerData={partnerData}
        >
          <ModalProvider>
            <LayoutComponent
              categoryGroups={menuCategories}
              navigationItems={navigationItems}
            >
              {hasContentErrorMessage ? (
                <ContentError
                  eventLocation={EventLocationType.HOME}
                  userMessage={pageProps.contentError.userMessage}
                />
              ) : (
                <Component {...pageProps} canonicalDomain={canonicalDomain} />
              )}
            </LayoutComponent>
          </ModalProvider>
        </LayoutWrapper>
      </WhitelabelProvider>
    </ThemeProvider>
  );
};

interface CustomNextPageContext extends NextPageContext {
  partnerData: PartnerType | null;
  partnerId: string;
}

interface CustomAppContext extends AppContext {
  ctx: CustomNextPageContext;
}

MyApp.getInitialProps = async (
  appContext: CustomAppContext,
): Promise<CustomAppInitialProps> => {
  const appProps = await App.getInitialProps(appContext);

  const { ctx } = appContext;
  const { req, pathname } = ctx;

  const host = req?.headers.host || window?.location?.host;
  const { partnerId, canonicalDomain } = getCurrentPartnerFromHost(host);

  const isServerSide = typeof req !== "undefined";

  let partnerData;

  // Because getInitialProps runs on both the server and the client, we need to provide
  // the ability to get the data for a partner in both environments
  if (isServerSide) {
    partnerData = await getPartnerData(partnerId, false);
  } else {
    const partnerResponse = await fetch(`/content/partner/${partnerId}`);

    partnerData = await partnerResponse.json();
  }

  const collections = await CollectionsService.fetchCollections(partnerId);
  const groupedCategories =
    CollectionsHelper.groupCategoriesByCategoryGroup(collections);
  const menuCategories =
    CollectionsHelper.groupCollectionByCategory(groupedCategories);

  const navigationItems = await FetchNavigationItems(
    partnerData?.data?.name?.toLowerCase(),
  );

  ctx.partnerData = partnerData?.data;
  ctx.partnerId = partnerId;

  const initialMinRentalDates = await getRentalDateConfig(partnerId);

  let notFoundPageData;
  const pageNotFound = pathname === "/404";

  const partnerTheme = await buildPartnerTheme(partnerId);

  if (pageNotFound) {
    // Because getInitialProps runs on both the server and the client, we need to provide
    // the ability to get the data for the Not Found Page in both environments
    if (isServerSide) {
      notFoundPageData = await getNotFoundPageData(partnerId, false);
    } else {
      const notFoundPageResponse = await fetch(
        `/content/not-found-page/${partnerId}`,
      );

      notFoundPageData = await notFoundPageResponse.json();
    }

    if (notFoundPageData.data) {
      notFoundPageData.data.sections = (await extendSectionsData(
        partnerId,
        notFoundPageData.data.sections,
      )) as NotFoundPageSectionType[];
    }
  }

  const { cmsPartnerName } = getCurrentPartnerFromHost(host);

  await prefetchUseModalsCms(cmsPartnerName);

  return {
    ...appProps,
    partnerTheme,
    partnerId,
    canonicalDomain,
    menuCategories,
    navigationItems,
    partnerData: partnerData?.data,
    initialMinRentalDates,
    notFoundPageData: notFoundPageData?.data,
  };
};

MyApp.defaultProps = {
  contentError: null,
  notFoundPageData: null,
  navigationItems: undefined,
};

export default MyApp;
