const cartItem = {
  parts: ["brandName", "productName", "variantText", "priceContainer"],
  baseStyle: {
    priceContainer: {
      mt: "3px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default cartItem;
