const dateBox = {
  parts: ["container", "header", "icon", "label", "date"],
  baseStyle: {
    container: {
      flexDirection: "column",
      textAlign: "left",
      paddingX: 5,
      paddingY: 2.5,
    },
    header: {
      marginBottom: 1.5,
      fontSize: "12px",
      fontFamily: "normal",
      fontWeight: "medium",
      flexDirection: { base: "column", md: "row" },
      alignItems: { base: "flex-start", md: "center" },
    },
    icon: {
      align: "center",
      marginRight: 2.5,
      color: "black",
    },
    date: {
      fontSize: "14px",
      fontFamily: "normal",
      fontWeight: "bold",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default dateBox;
