const mediaValueProp = {
  parts: ["title", "body", "listItem"],
  baseStyle: {
    title: {
      fontSize: "30px",
      letterSpacing: "2px",
      lineHeight: "42px",
      fontWeight: 700,
      color: "gray.500",
    },
    body: {
      fontWeight: 400,
      fontSize: "20px",
      letterSpacing: 0,
      lineHeight: "30px",
      color: "gray.500",
    },
    listItem: {
      fontWeight: 400,
      fontSize: "20px",
      letterSpacing: 0,
      lineHeight: "30px",
      color: "gray.500",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default mediaValueProp;
