/* eslint-disable sonarjs/no-duplicate-string */
const brandPartnershipHero = {
  parts: ["section", "brands", "title", "subtitle", "button"],
  baseStyle: {
    section: {},
    brands: {},
    title: {
      gridColumn: {
        base: "span 12",
        md: "4 / span 6",
        lg: "4 / span 6",
      },
      fontFamily: "normal",
      letterSpacing: "2px",
      lineHeight: "42px",
      fontSize: "30px",
      fontWeight: "700",
      textTransform: "uppercase",
      color: "white",
      textAlign: "center",
    },
    subtitle: {
      gridColumn: {
        base: "span 12",
        md: "4 / span 6",
        lg: "4 / span 6",
      },
      fontFamily: "normal",
      fontSize: "20px",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "30px",
      color: "white",
      textAlign: "center",
    },
    button: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default brandPartnershipHero;
