import { BoxProps, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Image } from "cloudinary-react";
import Link from "next/link";
import React, { FC, FocusEventHandler } from "react";

import { useWhitelabelContext } from "../whitelabelContext";

interface Props extends BoxProps {
  isLight?: boolean;
  onFocus?: FocusEventHandler;
}

const Logo: FC<Props> = ({ isLight, onFocus, ...other }: Props) => {
  const { darkLogo, lightLogo, logoSize } = useWhitelabelContext();

  const dpr = 3;

  const baseWidth = logoSize?.mobileWidth || 80;
  const smWidth = logoSize?.tabletWidth || 80;
  const lgWidth = logoSize?.desktopWidth || 100;

  const imageWidth = useBreakpointValue(
    {
      base: baseWidth,
      sm: smWidth,
      lg: lgWidth,
    },
    "base",
  );

  const imageHeight = useBreakpointValue(
    {
      base: 46,
      md: 54,
      xl: 62,
    },
    "base",
  );

  const currentLogo = isLight ? lightLogo : darkLogo;

  return (
    <Link href="/" passHref prefetch={false}>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        as="a"
        onFocus={onFocus}
        {...other}
      >
        <Image
          width={imageWidth}
          height={imageHeight}
          publicId={currentLogo?.cloudinaryId}
          alt={currentLogo?.altText || "Logo"}
          fetchFormat="auto"
          crop="mfit"
          dpr={dpr}
        />
      </Flex>
    </Link>
  );
};

Logo.defaultProps = {
  isLight: false,
  onFocus: undefined,
};

// eslint-disable-next-line import/no-default-export
export default Logo;
