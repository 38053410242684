/* eslint-disable sonarjs/no-duplicate-string */
const heading = {
  baseStyle: {
    textTransform: "uppercase",
    fontFamily: "title",
    letterSpacing: "0.08px",
    fontWeight: 600,
  },
  variants: {
    orderDetailsOrderNumber: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.05em",
      fontWeight: 600,
      color: "primary.600",
    },
    orderDetailsDate: {
      fontSize: {
        base: "sm",
        lg: "lg",
      },
      letterSpacing: 0,
      textTransform: "none",
      fontWeight: 300,
    },
    orderDetailsBoxTitle: {
      fontSize: "lg",
      fontFamily: "normal",
      fontWeight: 500,
      letterSpacing: "-0.03em",
      textTransform: "uppercase",
    },
    orderConfirmationTitle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
    },
    orderConfirmationSubtitle: {
      fontFamily: "normal",
      letterSpacing: "0.08px",
    },
    paymentOrderDetailsBoxTitle: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.05em",
      fontWeight: 600,
      color: "primary.600",
    },
    hero: {
      fontFamily: "title",
      lineHeight: {
        base: "148%",
      },
    },
    faqTitle: {
      fontSize: "46px",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      color: "primary.600",
    },
    faqQuestion: {
      fontSize: "32px",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      color: "primary.600",
    },
  },
  sizes: {
    hero_subHeading: {
      fontSize: {
        base: "16px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default heading;
