export const brandValueProp = {
  parts: ["section", "title", "body", "button", "icon"],
  variants: {
    primary: {
      title: {
        fontFamily: "title",
        letterSpacing: "3px",
        fontWeight: "600",
        textTransform: "uppercase",
        color: "white",
      },
      body: {
        fontFamily: "normal",
        fontSize: "16px",
        fontWeight: "normal",
        color: "white",
      },
      button: {
        display: "flex",
        justifyContent: {
          base: "left",
          md: "center",
        },
      },
      icon: {
        svg: {
          color: "white",
        },
      },
    },
    secondary: {
      title: {
        color: "primary.600",
        fontFamily: "title",
        letterSpacing: "3px",
        fontWeight: "600",
      },
      body: {
        fontFamily: "normal",
      },
      button: {},
    },
  },
};
