import { keyValue } from "@/dep_types";

const colorMap: keyValue = {
  gray50: "gray.50",
  gray100: "gray.100",
  gray200: "gray.200",
  gray300: "gray.300",
  gray400: "gray.400",
};

const colors = (apiColorData: string): string =>
  colorMap[apiColorData] || apiColorData;

// eslint-disable-next-line import/no-default-export
export default colors;
