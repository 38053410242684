import OrderLog from "../../../dep_helpers/order/OrderLog";
import { ProductType, RentalDatesType } from "../../../dep_types";
import { CartItemType } from "../../../dep_types/CartTypes";
import EventLocationType from "../../../dep_types/EventLocationType";

// eslint-disable-next-line import/no-default-export
export default abstract class TrackingStrategy {
  static partnerId: string | undefined = undefined;

  abstract initialized(): boolean;

  abstract trackPageView(pageCategory?: string): void;

  abstract trackScrollDepth(
    scrollDepth: number,
    pageCategory: string,
    url: string,
  ): void;

  abstract trackCollectionPageView(
    category: string,
    collections: string,
    saleTypes: string[],
  ): void;

  abstract trackProductPageView(product: ProductType): void;

  abstract trackProductOutOfStock(
    product: ProductType,
    siteRentalDates: RentalDatesType,
    url: string,
  ): void;

  abstract trackProductClick(
    location: EventLocationType,
    product: ProductType,
    siteRentalDates: RentalDatesType,
    fromUrl: string,
  ): void;

  abstract trackCalendarOpened(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
  ): void;

  abstract trackCalendarDatesCleared(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
  ): void;

  abstract trackCalendarDatesConfirmed(
    location: EventLocationType,
    productNames: string[],
    categoryNames: string[],
    startDate: string,
    endDate: string,
    tripDuration: number,
    months: string[],
    startDay: string,
  ): void;

  abstract trackNavigationClick(
    menuItem: string,
    primaryCategory: string,
    secondaryCategory: string,
    pageCategory: string,
    url: string,
  ): void;

  abstract trackAddToCart(newCartItem: CartItemType): void;

  abstract trackConversion(
    partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void;

  abstract trackStartCheckout(
    cartItems: CartItemType[],
    cartTotalPrice: number,
  ): void;

  abstract trackOrderEdited(
    orderId: string,
    editing: string,
    valueChange?: string,
    totalDifference?: number,
    newTotal?: number,
  ): void;

  abstract trackOrderCancelled(orderId: string, value: number): void;

  abstract trackEvent(eventName: string, data?: object): void;
}
