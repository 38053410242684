const containerPadding = {
  base: 5,
  md: 10,
  xl: 16,
};

const sectionPadding = {
  base: 8,
  md: 10,
  xl: 14,
};

const desktopHeaderHeight = 88;

const styles = {
  containerPadding,
  sectionPadding,
  desktopHeaderHeight,
};

// eslint-disable-next-line import/no-default-export
export default styles;
