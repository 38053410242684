const drawer = {
  variants: {
    search: {
      closeButton: {
        display: "block",
        zIndex: "docked",
        width: "unset",
        height: "unset",
        top: "unset",
        right: "unset",
        position: "relative",
        borderRadius: "0",
      },
      dialog: {
        bottom: "auto !important",
        maxWidth: "unset",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default drawer;
