const orderNumber = {
  parts: ["numberContainer"],
  baseStyle: {
    numberContainer: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderNumber;
