import { Flex } from "@chakra-ui/react";
import React, { FC, useState } from "react";

import ExpandSetButton from "./ExpandSetButton";
import ProductThumbnail from "./ProductThumbnail";
import SetChildrenItem from "./SetChildrenItem";

import { SetProductType } from "../../../dep_types";
import {
  CartSetItemSetGroup,
  CartSetItemType,
} from "../../../dep_types/CartTypes";

type Props = {
  product: SetProductType;
  cartSetGroups: CartSetItemSetGroup[][];
  cartItem: CartSetItemType;
  isReadonly?: boolean;
};

const SetChildrenSection: FC<Props> = ({
  product,
  cartSetGroups,
  cartItem,
  isReadonly,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Flex direction="row" mt="20px">
        <Flex mr="10px" flexGrow={1}>
          {!isExpanded &&
            product.setGroups.map((setGroup) => (
              <Flex
                mr="10px"
                border="3px solid"
                borderColor="#f1f1f1"
                key={setGroup.id}
              >
                <ProductThumbnail
                  product={setGroup.products[0]}
                  width={50}
                  height={50}
                />
              </Flex>
            ))}
        </Flex>
        <ExpandSetButton
          isExpanded={isExpanded}
          toggleExpand={() => setIsExpanded(!isExpanded)}
        />
      </Flex>
      {isExpanded &&
        cartSetGroups.flat().map((cartSetGroupSelection, i) => {
          const isNotLast =
            i + 1 !== (cartSetGroups ? cartSetGroups.flat().length : 0);

          return (
            <SetChildrenItem
              key={`set_children_${cartSetGroupSelection.setGroupId}`}
              isNotLast={isNotLast}
              cartSetGroupSelection={cartSetGroupSelection}
              cartItem={cartItem}
              isReadonly={isReadonly}
            />
          );
        })}
    </>
  );
};

SetChildrenSection.defaultProps = {
  isReadonly: false,
};
// eslint-disable-next-line import/no-default-export
export default SetChildrenSection;
