import React, {
  ChangeEvent,
  ChangeEventHandler,
  createContext,
  FC,
  MouseEventHandler,
  ReactNode,
  useContext,
  useState,
} from "react";

import { resalePartnerIds } from "@/dep_constants/partnerIds";
import getPageCategory from "@/dep_helpers/tracking/getPageCategory";
import ProductsService from "@/dep_services/productService";
import TrackingService from "@/dep_services/tracking/trackingService";
import { SearchProductType } from "@/dep_types";
import productSaleType from "@/dep_types/commerce-api/productSaleType";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import { useArriveContext } from "./context";

export const SearchContext = createContext<SearchContextType>({
  onSearchOpen: () => {},
  onSearchChange: () => {},
  onSearchKeyUp: () => {},
  onSearchClear: () => {},
  isSearchOpen: false,
  setIsSearchOpen: () => {},
  searchTerm: "",
  lastSearchedTerm: "",
  searchResults: [],
  bannerHeight: 0,
});

interface SearchContextType {
  onSearchOpen: MouseEventHandler;
  onSearchChange: ChangeEventHandler;
  onSearchKeyUp: () => void;
  onSearchClear: MouseEventHandler;
  isSearchOpen: boolean;
  setIsSearchOpen(val: boolean): void;
  searchTerm: string;
  lastSearchedTerm: string;
  searchResults: SearchProductType[];
  bannerHeight: number;
}

const updateBannerHeight = (
  currentHeight: number,
  setHeightHandler: (height: number) => void,
): void => {
  const bannerElement = document.getElementById(commonKeys.TEXT_BANNER_ID);

  if (bannerElement) {
    let newBannerHeight = bannerElement.offsetHeight;

    const bannerTopPosition = bannerElement.getBoundingClientRect().top;
    const isBannerOutOfView = bannerTopPosition < 0;
    const bannerHeightOffsetByTop = newBannerHeight + bannerTopPosition;
    const isBannerCompletelyOutOfView =
      isBannerOutOfView && bannerHeightOffsetByTop < 0;

    if (isBannerOutOfView && isBannerCompletelyOutOfView) {
      newBannerHeight = 0;
    } else if (isBannerOutOfView) {
      newBannerHeight = bannerHeightOffsetByTop;
    }

    if (newBannerHeight !== currentHeight) {
      setHeightHandler(newBannerHeight);
    }
  }
};

type Props = {
  children: ReactNode;
};

export const SearchContextProvider: FC<Props> = ({ children }) => {
  const { partnerIdTheme } = useArriveContext();
  const [bannerHeight, setBannerHeight] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearchedTerm, setLastSearchedTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Array<SearchProductType>>(
    [],
  );

  const onSearchOpen = (): void => {
    updateBannerHeight(bannerHeight, setBannerHeight);

    const url = window.location.href;
    const pageCategory = getPageCategory();

    TrackingService.trackEvent("Internal Search Opened", {
      pageCategory,
      url,
    });

    setIsSearchOpen(true);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setSearchTerm(event.target.value);

  const onSearchKeyUp = (): void => {
    const asyncFunction = async (): Promise<void> => {
      setLastSearchedTerm(searchTerm);

      if (searchTerm) {
        const pst =
          resalePartnerIds.indexOf(partnerIdTheme) >= 0
            ? productSaleType.RESALE
            : productSaleType.BOTH;
        const productsResponse = await ProductsService.queryProducts(
          partnerIdTheme,
          {
            q: searchTerm,
            pst,
          },
        );

        // Copies searchTerm into the product so we can render it with the correct color swatch defaulted in the search results
        if (productsResponse.data?.products) {
          for (let i = 0; i < productsResponse.data.products.length; i++) {
            productsResponse.data.products[i].searchTerm = searchTerm;
          }
        }

        setSearchResults(productsResponse.data?.products || []);

        TrackingService.trackEvent("Internal Search Completed", {
          term: searchTerm,
        });
      } else {
        setSearchResults([]);
      }
    };

    asyncFunction();
  };

  const onSearchClear = (): void => {
    setSearchTerm("");
    setLastSearchedTerm("");
    setSearchResults([]);
  };

  const searchContextValue: SearchContextType = {
    onSearchOpen,
    onSearchChange,
    onSearchKeyUp,
    onSearchClear,
    isSearchOpen,
    setIsSearchOpen,
    searchTerm,
    lastSearchedTerm,
    searchResults,
    bannerHeight,
  };

  return (
    <SearchContext.Provider value={searchContextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider",
    );
  }

  return context;
};
