const inputText = {
  baseStyle: {
    label: {
      fontFamily: "title",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "0.08px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default inputText;
