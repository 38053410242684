/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, useStyleConfig } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  text?: string;
  variant?: string;
};

const AlertPill: FC<Props> = ({ text, variant }) => {
  const styles: any = useStyleConfig("UnavailablePill", { variant });

  return <Flex sx={styles}>{text}</Flex>;
};

AlertPill.defaultProps = {
  text: undefined,
  variant: undefined,
};

// eslint-disable-next-line import/no-default-export
export default AlertPill;
