import { Flex, Text, useMultiStyleConfig } from "@chakra-ui/react";
import React, { FC } from "react";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { shoppingCartErrorTypes } from "../../dep_constants";
import CartHelper from "../../dep_helpers/CartHelper";
import Utils from "../../dep_helpers/Utils";
import { CartItemType } from "../../dep_types/CartTypes";
import { CTAButton } from "../Commons/Buttons/CTAButton";

type Props = {
  cartItems: CartItemType[];
  handleReserveCart(): void;
  cartError?: string | null | undefined;
};

const Footer: FC<Props> = ({
  cartItems,
  handleReserveCart,
  cartError,
}: Props) => {
  const subtotal = CartHelper.getTotal(cartItems);
  const { globalLabels, isYeti } = useWhitelabelContext();
  const styles = useMultiStyleConfig("Text", {});

  // If there is no cart error or the error is a price mismatch,
  // the cart is valid because the user can continue to checkout.
  // Price mismatch will result in an updated cart item price.
  const validCart =
    !cartError ||
    cartError === shoppingCartErrorTypes.PRICE_DECREMENTING_MISMATCH;

  return (
    <Flex
      flex={1}
      direction="column"
      py={{ base: "10px", md: "15px" }}
      px={{ base: "10px", md: "50px" }}
      pb={{ base: "20px", sm: "10px" }}
    >
      {validCart && (
        <>
          <Flex direction="row" marginBottom={3.5}>
            <Flex justifyContent="flex-start" flexGrow={1}>
              <Flex
                fontSize={{ base: "22px", md: "28px" }}
                direction="column"
                fontFamily="normal"
                fontWeight="bold"
              >
                <Text sx={styles.cartCheckoutSubtotal}>Subtotal</Text>
              </Flex>
              <Flex
                direction="column"
                fontSize={{ base: "22px", md: "28px" }}
                fontFamily="normal"
                fontWeight="bold"
                pl={2}
              >
                <Text sx={styles.cartCheckoutSubtotal}>
                  $ {Utils.toCurrency(subtotal)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
      <Flex direction="row" mb={2}>
        <CTAButton
          variant="reservation"
          onClick={handleReserveCart}
          isDisabled={!validCart}
        >
          {validCart && "Checkout"}
          {cartError &&
            cartError === shoppingCartErrorTypes.NO_DATES &&
            "SET YOUR RENTAL DATES"}
          {cartError &&
            cartError === shoppingCartErrorTypes.ITEMS_UNAVAILABLE &&
            (isYeti
              ? "ITEMS NOT AVAILABLE IN YOUR BAG"
              : "ITEMS NOT AVAILABLE IN YOUR CART")}
          {cartError &&
            cartError === shoppingCartErrorTypes.INVALID_DATE_RANGE &&
            "AMOUNT OF NIGHTS SHOULD BE BETWEEN 2 AND 21"}
        </CTAButton>
      </Flex>
      {globalLabels && globalLabels.cartAndCheckoutExtraInfo && (
        <Flex direction="row" justifyContent="center" mb={2}>
          <Text sx={styles.cartCheckoutExtraInfo}>
            {globalLabels.cartAndCheckoutExtraInfo}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

Footer.defaultProps = {
  cartError: undefined,
};

// eslint-disable-next-line import/no-default-export
export default Footer;
