import {
  Box,
  Flex,
  Grid,
  GridItem,
  keyframes,
  List,
  ListItem,
} from "@chakra-ui/react";
import { Image } from "cloudinary-react";
import Link from "next/link";
import { KeyboardEvent, useEffect, useRef, useState } from "react";

import TrackingService from "@/dep_services/tracking/trackingService";
import { MediaType } from "@/dep_types/content/components";

import Container from "@/dep_components/Layout/Container";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import MenuCategoryLink from "./MenuCategoryLink";
import MenuCollectionLink from "./MenuCollectionLink";

import { allProductsCollectionSubstring } from "../../../dep_constants";
import CollectionsHelper from "../../../dep_helpers/CollectionsHelper";
import { CategoryType, CollectionType } from "../../../dep_types";

type CategorySubmenuProps = {
  groupKey: string;
  groupName: string;
  categories: CategoryType[];
  isOpen: boolean;
  menuItemName: string;
  setOpenMenuItem(menuItem: string): void;
  image: MediaType | null;
};

export function CategorySubmenu({
  groupKey,
  groupName,
  categories,
  isOpen,
  menuItemName,
  setOpenMenuItem,
  image,
}: CategorySubmenuProps) {
  const { isYeti } = useWhitelabelContext();
  const [focusableElements, setFocusableElements] = useState<
    HTMLAnchorElement[]
  >([]);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const itemCategories = categories.filter(
    (category) =>
      !category.collectionCategory.name
        .toLowerCase()
        .includes(allProductsCollectionSubstring.toLowerCase()),
  );
  const allProductsCategory = categories.find((category) =>
    category.collectionCategory.name
      .toLowerCase()
      .includes(allProductsCollectionSubstring.toLowerCase()),
  );
  const sortedCategories = CollectionsHelper.sortCategories(itemCategories);

  const animation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

  useEffect(() => {
    if (containerRef.current) {
      const newFocusableElements = Array.prototype.slice.call(
        containerRef.current.querySelectorAll("a[href]"),
      );

      setFocusableElements(newFocusableElements);
    }
  }, [categories, containerRef]);

  const mouseEnterHandler = (): void => setOpenMenuItem(menuItemName);
  const mouseLeaveHandler = (): void => setOpenMenuItem("");
  const keyDownHandler = (event: KeyboardEvent<HTMLDivElement>): void => {
    const targetElement = event.target;
    const currentTargetElement = event.currentTarget as HTMLElement;
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    if (event.key === "Escape") {
      event.preventDefault();
      setOpenMenuItem("");

      const menuItemBtn =
        currentTargetElement &&
        (currentTargetElement.previousSibling as HTMLElement);

      if (menuItemBtn) {
        menuItemBtn.focus();
      }
    } else if (event.key === "Tab" && targetElement === lastFocusableElement) {
      setOpenMenuItem("");
    }
  };

  return (
    <Container
      ref={containerRef}
      flex={1}
      py="20px"
      width="100%"
      position="absolute"
      top="calc(100% + 1px)"
      left={0}
      zIndex={10}
      backgroundColor="white"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onKeyDown={keyDownHandler}
      display={isOpen ? "block" : "none"}
      animation={`${animation} .15s ease-in-out forwards`}
    >
      <Grid templateColumns="repeat(12, 1fr)" columnGap={5} rowGap={2}>
        {allProductsCategory && (
          <GridItem gridColumn="10 / span 3">
            <Link
              href={`/group/${groupKey}/category/${allProductsCategory.collectionCategory.id}`}
              passHref
              prefetch={false}
            >
              <Box as="a" display="inline-block" marginBottom={5}>
                {image && (
                  <Image
                    width={332}
                    height={224}
                    publicId={image?.cloudinaryId}
                    alt="Shop all products"
                    fetchFormat="auto"
                    crop="fill"
                    radius={5}
                    dpr={2.0}
                  />
                )}
              </Box>
            </Link>
            <Flex as="nav" flexDirection="column">
              <MenuCategoryLink
                categoryId={allProductsCategory.collectionCategory.id}
                name={
                  isYeti
                    ? "SHOP ALL"
                    : allProductsCategory.collectionCategory.name
                }
                onClick={() =>
                  TrackingService.trackNavigationClick(
                    groupName,
                    isYeti
                      ? "SHOP ALL"
                      : allProductsCategory.collectionCategory.name,
                    "",
                  )
                }
                group={groupKey}
                marginBottom={0.5}
              />
              <List>
                {!isYeti &&
                  allProductsCategory.collections.map(
                    (collection: CollectionType) => (
                      <ListItem key={collection.id} marginBottom={0.5}>
                        <MenuCollectionLink
                          categoryId={allProductsCategory.collectionCategory.id}
                          id={collection.id}
                          name={collection.name}
                          onClick={() =>
                            TrackingService.trackNavigationClick(
                              groupName,
                              allProductsCategory.collectionCategory.name,
                              collection.name,
                            )
                          }
                          group={groupKey}
                        />
                      </ListItem>
                    ),
                  )}
              </List>
            </Flex>
          </GridItem>
        )}
        <GridItem
          gridColumn="2 / span 8"
          display="grid"
          gridTemplateColumns="repeat(8, 1fr)"
          gridColumnGap={5}
          gridRowGap={2}
          order={-1}
        >
          {sortedCategories.map((category: CategoryType) => {
            const sortedCollection = [...category.collections].sort(
              (a, b) => a.sortWeight - b.sortWeight,
            );

            return (
              <GridItem
                key={category.collectionCategory.id}
                as="nav"
                flexDirection="column"
                gridColumn="span 2"
              >
                <MenuCategoryLink
                  categoryId={category.collectionCategory.id}
                  name={category.collectionCategory.name}
                  onClick={() =>
                    TrackingService.trackNavigationClick(
                      groupName,
                      category.collectionCategory.name,
                      "",
                    )
                  }
                  group={groupKey}
                />
                <List>
                  {sortedCollection.map((collection: CollectionType) => (
                    <ListItem key={collection.id} marginBottom={1}>
                      <MenuCollectionLink
                        categoryId={category.collectionCategory.id}
                        id={collection.id}
                        name={collection.name}
                        onClick={() =>
                          TrackingService.trackNavigationClick(
                            groupName,
                            category.collectionCategory.name,
                            collection.name,
                          )
                        }
                        group={groupKey}
                      />
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            );
          })}
        </GridItem>
      </Grid>
    </Container>
  );
}
