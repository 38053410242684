import APIRecommendedProductsType from "@/dep_types/content/api/components/sections/recommendedProducts";
import { RecommendedProductsType } from "@/dep_types/content/sections";

import numberDataMapper from "../components/number";

const recommendedProducts = (
  apiRecommendedProductsData: APIRecommendedProductsType,
): RecommendedProductsType => ({
  id: apiRecommendedProductsData.id,
  componentKey: apiRecommendedProductsData.__component,
  title: apiRecommendedProductsData.title,
  titleLink: apiRecommendedProductsData.titleLink,
  description: apiRecommendedProductsData.description,
  productType: apiRecommendedProductsData.productType,
  variant: apiRecommendedProductsData.variant,
  collectionIds: apiRecommendedProductsData.collectionIds?.map((apiNumber) =>
    numberDataMapper(apiNumber).toString(),
  ),
});

// eslint-disable-next-line import/no-default-export
export default recommendedProducts;
