/* eslint-disable @typescript-eslint/no-explicit-any */
import { WhitelabelParameterFacet } from "@/dep_types/ApiTypes";

// eslint-disable-next-line import/no-default-export
export default class Utils {
  static groupBy(array: any, key: any): any {
    return array.reduce((hash: any, obj: any) => {
      if (obj[key] === undefined) return hash;

      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  /**
   * This function flat a set of arrays using one element of each collection
   * and starting again from the original collection once on element for all the collections has been used
   *
   * Input: [[1, 2, 3], [10, 11, 12], [100, 101, 102]]
   * Output: [1,10,100, 2,11,101, 3,12,102]
   *
   * Input: [[1], [10, 11, 12], [100, 101]]
   * Output: [1,10,100, 11,101, 12]
   *
   * @param set
   */
  static flatSetOfArrays<T>(set: Set<T[]>): T[] {
    const result: T[] = [];

    let index = 0;

    set.forEach(() => {
      set.forEach((value: T[]) => {
        if (value.length > index) {
          result.push(value[index]);
        }
      });
      index++;
    });

    return result;
  }

  static toCurrency(
    value: string | number | undefined,
    hasTrailingZeros: boolean = true,
  ): string {
    const defaultText = hasTrailingZeros ? "0.00" : "0";
    let price = value
      ? Number.parseFloat(value.toString()).toFixed(2)
      : defaultText;

    if (!hasTrailingZeros && price.endsWith(".00")) {
      price = price.replace(".00", "");
    }

    return price;
  }

  static toCurrencyRounded(value: string | number | undefined): string {
    const defaultText = "0";

    return value
      ? Math.round(Number.parseFloat(value.toString())).toFixed()
      : defaultText;
  }

  static capitalizeFirstCharOrCapitalizeAllIfWordIsLessThanFiveChars(
    stringValue: string,
  ): string {
    return stringValue.length > 4
      ? stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
      : stringValue.toUpperCase();
  }

  static sizeComparator(
    a: WhitelabelParameterFacet,
    b: WhitelabelParameterFacet,
  ): number {
    // Sorts numbers first
    if (Number.isInteger(a.name)) {
      if (Number.isInteger(b.name)) {
        return parseInt(a.name, 10) > parseInt(b.name, 10) ? -1 : 1;
      }

      return -1;
    }
    if (Number.isInteger(b.name)) {
      return 1;
    }

    // Then sorts alphabetically
    return a.name.localeCompare(b.name);
  }

  static booleanExistsAndIsFalse(variable?: boolean): boolean {
    return typeof variable === "boolean" && !variable;
  }

  static isNull(variable: any): boolean {
    return variable == null;
  }

  static buildParams(paramsObject: any): URLSearchParams {
    const params = new URLSearchParams();

    Object.keys(paramsObject).forEach((key) => {
      const filter = paramsObject[key];

      if (Array.isArray(filter)) {
        filter.forEach((item) => params.append(key, item));
      } else {
        params.append(key, filter);
      }
    });

    return params;
  }

  static arrayUnique(array: any): any {
    return [...(new Set(array) as any)];
  }

  static sumBy(array: any, key: any): any {
    return array
      .map((item: any) => item[key])
      .reduce((acum: any, itemProp: any) => acum + itemProp);
  }

  static parseName(input: string): any {
    const fullName = input || "";
    const result = {
      firstName: fullName,
      lastName: fullName,
    };

    if (fullName.length > 0) {
      const nameTokens = fullName.split(" ");

      [result.firstName, result.lastName] = nameTokens;

      if (nameTokens.length > 2) {
        result.lastName = nameTokens[nameTokens.length - 1];
      }
    }

    return result;
  }

  static isExternalLink(linkUrl: string): boolean {
    return (
      linkUrl.startsWith("http") ||
      linkUrl.startsWith("mailto") ||
      linkUrl.startsWith("tel")
    );
  }

  static generateRandomKey = (): string =>
    Math.random().toString(36).substring(7);

  /**
   * This function expects a number and return the ordinal suffix for that number
   */
  static getOrdinal = (n: number): string => {
    let ord = "th";

    if (n % 10 === 1 && n % 100 !== 11) {
      ord = "st";
    } else if (n % 10 === 2 && n % 100 !== 12) {
      ord = "nd";
    } else if (n % 10 === 3 && n % 100 !== 13) {
      ord = "rd";
    }

    return ord;
  };
}
