import { useMediaQuery } from "@uidotdev/usehooks";

interface UseBreakpoint {
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

/**
 * Chakra breakpoints
 * sm: "30em", 478px
 * md: "48em", 765
 * lg: "62em", 988.29
 * xl: "80em",
 * 2xl: "96em",
 */

export function useBreakpoint(): UseBreakpoint {
  const isPhone = useMediaQuery("only screen and (max-width : 478px)");
  const isTablet = useMediaQuery(
    "only screen and (min-width : 479px) and (max-width : 988px)",
  );
  const isDesktop = useMediaQuery("only screen and (min-width : 989px)");

  return {
    isPhone,
    isTablet,
    isDesktop,
  };
}
