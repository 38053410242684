/* eslint-disable @typescript-eslint/no-explicit-any */
import { RentalDatesType } from "@/dep_types";
import { CartProductItemType } from "@/dep_types/CartTypes";
import {
  OrderDetailsOrderSetGroupItem,
  OrderDetailsType,
} from "@/dep_types/OrderDetailsTypes";
import {
  AvailabilityType,
  VariantSelectOptionType,
} from "@/dep_types/ProductTypes";

import CartHelper from "./CartHelper";
import DatesHelper from "./DatesHelper";
import ProductHelper from "./ProductHelper";
import Utils from "./Utils";

import OrderFulfillmentType, {
  OrderCardDataType,
  OrderCartType,
  OrderCustomerType,
  OrderStepType,
} from "../dep_types/OrderTypes";

// eslint-disable-next-line import/no-default-export
export default class OrderHelper {
  static steps = (fulfillmentType: OrderFulfillmentType): OrderStepType[] => {
    let steps = [
      {
        number: 1,
        status: "placed",
        text: "Order Placed",
      },
      {
        number: 2,
        status: "paymentProcessed",
        text: "Payment Accepted",
      },
      {
        number: 3,
        status: "shipped",
        text: "Order Shipped",
      },
      {
        number: 4,
        status: "delivered",
        text: "Order Delivered",
      },
    ];

    if (OrderFulfillmentType.RENTAL === fulfillmentType) {
      steps = steps.concat([
        {
          number: 5,
          status: "returned",
          text: "Order Returned",
        },
        {
          number: 6,
          status: "received",
          text: "Order Received",
        },
      ]);
    }

    return steps;
  };

  static mapOrderAvailabilityToOptions(
    variantsList: AvailabilityType[],
    product?: any,
  ): VariantSelectOptionType[] {
    const variantToOptions: VariantSelectOptionType[] = variantsList
      .filter((variant) => variant.maxAvailable > 0)
      .map(ProductHelper.mapVariantToOption);

    if (product && product.setGroups) {
      variantToOptions.forEach((variantOption) => {
        let foundProduct: any;

        product.setGroups.some((setGroup: any) => {
          foundProduct = setGroup.products.find((prod: any) =>
            prod.variants.some(
              (variant: any) => variant.id.toString() === variantOption.id,
            ),
          );

          return !!foundProduct;
        });

        variantOption.name = `${foundProduct?.name} ${variantOption.name}`;
      });
    }

    return variantToOptions;
  }

  static mapDatesErrorByUnavailableVariantsResponseToErrorText = (
    order: OrderDetailsType,
    orderEditResponse: {
      error: boolean;
      response: { isValid: boolean; variantsUnavailable: number[] };
    },
  ): any => {
    const unavailableList = orderEditResponse.response.variantsUnavailable.map(
      (unavailableVariantId) => {
        const foundOrderItem = order.orderItems.find(
          (orderItem) => unavailableVariantId === orderItem.variant.id,
        );

        if (foundOrderItem) {
          const variantLabel = ProductHelper.parametersToVariantLabel(
            foundOrderItem.variant.parameters,
          );

          return `${foundOrderItem.product.name} (${variantLabel})`;
        }

        let foundOrderSetGroupItem;
        const foundOrderSetItem = order.orderSetItems.find((orderSetItem) => {
          const foundVariant = orderSetItem.orderSetGroups.find(
            (orderSetGroup) => {
              const setGroupItem = orderSetGroup.orderSetGroupItems.find(
                (orderSetGroupItem) =>
                  orderSetGroupItem.variant.id === unavailableVariantId,
              );

              foundOrderSetGroupItem = setGroupItem;

              return setGroupItem;
            },
          );

          return !!foundVariant;
        });

        if (foundOrderSetItem) {
          const variantLabel =
            foundOrderSetGroupItem &&
            ProductHelper.parametersToVariantLabel(
              (foundOrderSetGroupItem as OrderDetailsOrderSetGroupItem).variant
                .parameters,
            );

          return `[SET] ${foundOrderSetItem.product.name} <br/> Item: ${
            foundOrderSetGroupItem &&
            (foundOrderSetGroupItem as OrderDetailsOrderSetGroupItem).product
              .name
          } (${variantLabel})`;
        }

        return "- Not specified -";
      },
    );

    const itemList = unavailableList.map(
      (unavailableItemText) => `<li>- ${unavailableItemText}</li>`,
    );

    return `<p>The following Items in your order are not available for the selected dates</p><br/><ul>${itemList}</ul>
    <br/><p>Remove these items from your order first and try again.</p>`;
  };

  static calculateOrderTotalDifferenceForLogs(
    newTotal: number,
    currentTotal: number,
  ): { valueChange: string; totalDifference: number } {
    let valueChange;
    let totalDifference;

    if (newTotal === currentTotal) {
      valueChange = "equal";
      totalDifference = 0;
    } else if (newTotal > currentTotal) {
      valueChange = "increase";
      totalDifference = newTotal - currentTotal;
    } else {
      valueChange = "decrease";
      totalDifference = currentTotal - newTotal;
    }

    return {
      valueChange,
      totalDifference,
    };
  }

  static mapWalletDataToCustomer(data: any): OrderCustomerType {
    const shippingAddress = {
      address1: data.shippingAddress.addressLine[0],
      address2:
        data.shippingAddress.addressLine.length > 1
          ? data.shippingAddress.addressLine[1]
          : null,
      city: data.shippingAddress.city,
      state: data.shippingAddress.region,
      zipcode: data.shippingAddress.postalCode,
      addressType: "HOME", // Can't get this from Wallet UI
    };

    const billingAddress = {
      address1: data.paymentMethod.billing_details.address.line1,
      address2: data.paymentMethod.billing_details.address.line2,
      city: data.paymentMethod.billing_details.address.city,
      state: data.paymentMethod.billing_details.address.state, // This comes in long name version
      zipcode: data.paymentMethod.billing_details.address.postal_code,
    };
    const name = Utils.parseName(data.payerName);

    return {
      firstName: name.firstName,
      lastName: name.lastName,
      emailAddress: data.payerEmail,
      phoneNumber: data.payerPhone, // could be null
      shippingAddress,
      billingAddress,
      joinedMarketingList: false, // This is override in the ui=
    } as OrderCustomerType;
  }

  static mapFormDataToCustomer(data: any): OrderCustomerType {
    const shippingAddress = {
      address1: data.shipping_address1,
      address2: data.shipping_address2,
      city: data.shipping_city,
      state: data.shipping_state,
      zipcode: data.shipping_zipcode,
      addressType: data.shipping_isPrimaryAddress
        ? "home"
        : data.shipping_addressType,
    };

    const billingAddress = data.useShippingAsBillingAddress
      ? shippingAddress
      : {
          address1: data.billing_address1,
          address2: data.billing_address2,
          city: data.billing_city,
          state: data.billing_state,
          zipcode: data.billing_zipcode,
        };

    return {
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      shippingAddress,
      billingAddress,
      joinedMarketingList: data.acceptJoinEmailList,
    } as OrderCustomerType;
  }

  static mapFormDataToCardData(payload: any): OrderCardDataType {
    return {
      stripeId: payload.paymentMethod.id,
      lastFour: payload.paymentMethod.card.last4,
      brand: payload.paymentMethod.card.brand,
      expMonth: payload.paymentMethod.card.exp_month,
      expYear: payload.paymentMethod.card.exp_year,
    } as OrderCardDataType;
  }

  static mapItemsToCart(
    cartItems: CartProductItemType[],
    shoppingCartRentalDates: RentalDatesType,
    discountCode: string | undefined,
  ): any {
    const cartItemsOrder = CartHelper.mapCartToOrder(cartItems);
    const formattedDates = DatesHelper.parseGlobalRentalDatesToAPIParams(
      shoppingCartRentalDates,
    );

    return {
      dateFrom: formattedDates.startDate,
      dateTo: formattedDates.endDate,
      discountCode,
      ...cartItemsOrder,
    } as OrderCartType;
  }

  static getMethodName(walletMethod: any): string {
    let method = walletMethod.methodName;

    if (walletMethod.methodName === "apple-pay") {
      method = "Apple Pay";
    } else if (walletMethod.methodName === "google-pay") {
      method = "Google Pay";
    } else if (walletMethod.methodName?.length === 0) {
      method = "Wallet Pay";
    }

    return method;
  }
}
