const formSectionHeading = {
  parts: ["container", "stepNumber"],
  baseStyle: {
    container: {
      alignItems: "center",
      fontWeight: "medium",
      color: "black",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    stepNumber: {
      width: "43px",
      height: "43px",
      borderRadius: "30px",
      border: "1px solid",
      borderColor: "gray.200",
      justifyContent: "center",
      alignItems: "center",
      mr: 4,
    },
  },
  variants: {
    disabled: {
      container: {
        color: "gray.400",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default formSectionHeading;
