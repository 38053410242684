import { Box, chakra, useStyleConfig } from "@chakra-ui/react";
import { Image } from "cloudinary-react";

import { ColorSwatch as TColorSwatch } from "@/dep_types";

type ColorSwatchProps = {
  readonly swatch: TColorSwatch;
  readonly isActive?: boolean;
  readonly onClick: (swatch: TColorSwatch) => void;
  readonly onMouseEnter: (swatch: TColorSwatch) => void;
  readonly onMouseLeave: () => void;
};

export function ColorSwatch({
  swatch,
  isActive,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ColorSwatchProps) {
  const imageToShow = swatch?.swatchImage ?? swatch?.image;

  const styles = useStyleConfig("colorSwatch", {
    variant: isActive && "active",
  });

  const handleClick = () => onClick(swatch);
  const handleMouseEnter = () => onMouseEnter(swatch);

  return (
    <chakra.button
      sx={styles}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      height={{
        base: "7",
        md: "10",
        lg: "8",
        xl: "10",
      }}
      padding={{
        base: "1",
        sm: "0.5",
        md: "1",
        lg: "0.5",
        xl: "1",
      }}
      borderRadius="full"
      aspectRatio="1/1"
    >
      {swatch?.swatchHex ? (
        <Box
          width="100%"
          height="100%"
          background={swatch?.swatchHex}
          borderRadius="full"
        />
      ) : (
        <Image
          alt={`Color: ${swatch?.color}`}
          publicId={imageToShow?.publicId}
          crop="fill"
          radius="max"
          width={30}
          height={30}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
    </chakra.button>
  );
}
