const variantSelector = {
  parts: ["optionButton"],
  baseStyle: {
    optionButton: {
      letterSpacing: "0.8px",
    },
  },
  variants: {
    selected: {
      optionButton: {
        color: "secondary.50",
        backgroundColor: "white",
        borderColor: "secondary.50",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default variantSelector;
