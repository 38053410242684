const primary600 = "primary.600";
const heading = {
  baseStyle: {
    fontFamily: "title",
    letterSpacing: "0.4px",
    fontWeight: 300,
  },
  variants: {
    productName: {
      fontFamily: "product-title",
      letterSpacing: "0.4px",
      fontWeight: 400,
      textTransform: "none",
    },
    orderDetailsOrderNumber: {
      fontWeight: 400,
      fontFamily: "product-title",
      textTransform: "none",
      fontSize: "36px",
    },
    orderDetailsDate: {
      letterSpacing: 0,
      fontWeight: 500,
      fontFamily: "normal",
      fontSize: "16px",
      textTransform: "uppercase",
    },
    orderDetailsBoxTitle: {
      fontSize: "15px",
      fontFamily: "normal",
      fontWeight: 500,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    orderConfirmationTitle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.4px",
    },
    orderConfirmationSubtitle: {
      fontFamily: "normal",
      letterSpacing: "0.16px",
      textTransform: "uppercase",
    },
    paymentOrderDetailsBoxTitle: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.4px",
      fontWeight: 300,
      color: primary600,
    },
    hero: {
      fontFamily: "normal",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "1px",
      textTransform: "none",
      lineHeight: {
        base: "148%",
      },
    },
    faqTitle: {
      fontSize: "46px",
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.4px",
      fontWeight: 300,
      color: primary600,
    },
    faqQuestion: {
      fontSize: "32px",
      fontFamily: "title",
      letterSpacing: "0.4px",
      fontWeight: 300,
      color: primary600,
      textTransform: "uppercase",
    },
    accordionTitle: {
      fontSize: "16px",
      fontFamily: "normal",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: "medium",
    },
    pdpTotalPrice: {
      fontSize: "18px",
      fontFamily: "normal",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: "medium",
    },
  },
  sizes: {
    hero_subHeading: {
      fontSize: {
        base: "16px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default heading;
