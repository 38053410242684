const pdpToggleButton = {
  baseStyle: {
    borderWidth: "1px",
    borderColor: "gray.200",
    borderRadius: "10px",
    paddingY: 6,
    paddingX: {
      base: 5,
      md: 9,
    },
    alignItems: "center",
    justifyContent: "space-between",
  },
  variants: {
    active: {
      borderWidth: "2px",
      borderColor: "secondary.600",
    },
    unavailable: {
      borderStyle: "none",
      textColor: "gray.300",
      backgroundImage:
        "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' " +
        "xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'" +
        " rx='10' ry='10' stroke='%23E3E8EFFF' stroke-width='1' stroke-dasharray='10' " +
        "stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e\")",
      borderRadius: "10px",
    },
    disabled: {
      backgroundColor: "gray.50",
      border: 0,
      color: "gray.400",
    },
    activeWithDiscount: {
      borderWidth: "2px",
      borderColor: "error",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default pdpToggleButton;
