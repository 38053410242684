import { useContext } from "react";

import { ProductCardContext } from "../providers/ProductCardProvider";

/**
 * This hook is used only to provide the context of the ProductCardProvider.
 * The state of the ProductCardProvider is managed in the useProductCardContext hook.
 */
export function useProductCard() {
  const context = useContext(ProductCardContext);

  if (!context) {
    throw new Error("useProductCard must be used within a ProductCardProvider");
  }

  return context;
}
