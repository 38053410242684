import APINavigationItemType from "@/dep_types/content/api/components/navigationItem";
import { NavigationItemType } from "@/dep_types/content/components";

import mediaDataMapper from "./media";

const navigationItem = (
  apiNavigationItemData: APINavigationItemType,
): NavigationItemType => ({
  name: apiNavigationItemData.name,
  categoryGroup: apiNavigationItemData.categoryGroup,
  image: apiNavigationItemData.image?.data
    ? mediaDataMapper(apiNavigationItemData.image.data.attributes)
    : null,
});

// eslint-disable-next-line import/no-default-export
export default navigationItem;
