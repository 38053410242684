import { parts } from "@/dep_theme/common/components/productCard";

export const productCard = {
  parts,
  baseStyle: {
    productName: {
      fontWeight: "bold",
      fontSize: "md",
      textTransform: "uppercase",
      letterSpacing: "wider",
    },
    productPrice: {
      fontSize: "sm",
      fontWeight: "bold",
      letterSpacing: "wide",
      color: "gray.500",
    },
    productPricePrefix: {
      color: "gray.500",
      letterSpacing: "wider",
    },
  },
  variants: {
    vertical: {},
    horizontal: {
      productName: {
        fontWeight: "bold",
        fontSize: "md",
      },
    },
  },
};
