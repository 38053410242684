const tripDiscountTag = {
  parts: ["tag", "text"],
  baseStyle: {
    tag: {
      flexShrink: 0,
      backgroundColor: "secondary.600",
      borderRadius: "4px",
      paddingX: 3,
      paddingY: 1.5,
      color: "white",
      fontWeight: "medium",
      textTransform: "uppercase",
    },
    text: {
      marginLeft: 5,
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default tripDiscountTag;
