import { Flex, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { PropsWithChildren, useEffect } from "react";

import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import { Header } from "@/dep_components/Header";
import { TopTextBanner } from "@/dep_components/Header/TopTextBanner";

import ShoppingCartService from "../../dep_services/shoppingCartService";
import { CategoryType, GroupCategoriesType } from "../../dep_types";
import DateSyncModal from "../DateSyncModal";
import Footer from "../Footer";
import ShoppingCart from "../ShoppingCart";

interface LayoutProps {
  categoryGroups: GroupCategoriesType;
  navigationItems?: NavigationItem[];
}

function Layout({
  categoryGroups,
  navigationItems,
  children,
}: PropsWithChildren<LayoutProps>) {
  const router = useRouter();
  const previewModeToast = useToast();
  const previewToastId = "preview-toast";

  if (router.isPreview && !previewModeToast.isActive(previewToastId)) {
    previewModeToast({
      id: previewToastId,
      title: "Preview Mode",
      description: "Close this dialog box to exit Preview Mode.",
      status: "info",
      position: "bottom-right",
      isClosable: true,
      duration: null,
      onCloseComplete: () => window.location.replace("/exit-preview"),
    });
  }

  useEffect(() => {
    if (router.query.irclickid) {
      ShoppingCartService.saveImpactId(router.query.irclickid as string);
    }
  }, [router.query.irclickid]);

  if (!categoryGroups) {
    return <div />;
  }

  const groupedCategories: Record<string, CategoryType[]> = {};

  Object.keys(categoryGroups).forEach((groupKey) => {
    const categories = categoryGroups[groupKey];

    groupedCategories[groupKey] = categories
      ? Object.keys(categories).map((key) => categories[key])
      : [];
  });

  return (
    <>
      <ShoppingCart />
      <DateSyncModal />
      <Flex direction="column" minHeight="100vh">
        <TopTextBanner />
        <Header
          categoryGroups={groupedCategories}
          navigationItems={navigationItems}
        />

        <Flex direction="column" flex={1} justifyContent="center">
          {children}
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default Layout;
