import { chakra } from "@chakra-ui/react";

import { useProductCard } from "@/dep_components/Commons/Products/ProductCard/hooks/useProductCard";

type ProductCardButtonProps = {
  readonly onClick?: () => void;
};

export function ProductCardButton({ onClick }: ProductCardButtonProps) {
  const { productUrl } = useProductCard();

  return (
    <chakra.a
      href={productUrl}
      onClick={onClick}
      display="block"
      padding="2.5"
      borderColor="gray.200"
      borderRadius="base"
      borderWidth="1px"
      textAlign="center"
      transition="all 200ms ease-in-out"
      fontWeight="normal"
      _hover={{
        backgroundColor: "black",
        color: "white",
      }}
    >
      Shop Now
    </chakra.a>
  );
}
