import { ContentErrorType, ErrorObjectType } from "@/dep_types/content/error";

const errorFunc = (
  errorData: ErrorObjectType,
  userMessage?: string,
): ContentErrorType => {
  const errorObject = {
    message: errorData.message || "An unknown error occured.",
    name: errorData.name || "UnknownError",
  };

  return {
    errorObject,
    userMessage: userMessage || null,
  };
};

// eslint-disable-next-line import/no-default-export
export default errorFunc;
