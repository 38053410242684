import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

const CloseIcon: FC<IconProps> = ({ ...other }) => (
  <Icon
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    fill="none"
    stroke="#B8BEC3"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...other}
  >
    <path d="M12.5 12.25L1 1.25" />
    <path d="M1.25 12.5L12.25 1" />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default CloseIcon;
