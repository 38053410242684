/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { parseISO } from "date-fns";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import localStorageKeys from "@/dep_constants/localStorageKeys";
import DatesHelper from "@/dep_helpers/DatesHelper";
import localStorageService from "@/dep_services/localStorageService";

import {
  ADD_TO_CART,
  CLEAR_CART,
  CLOSE_DATE_SYNC_MODAL,
  CLOSE_SHOPPING_CART,
  INIT_CART,
  OPEN_DATE_SYNC_MODAL,
  OPEN_SHOPPING_CART,
  REMOVE_FROM_CART,
  SET_PARTNER_ID_THEME,
  SET_SHOPPING_CART_RENTAL_DATES,
  SET_SITE_RENTAL_DATES,
  UPDATE_CART_ITEMS,
} from "./contextActions";
import shoppingCartReducer from "./contextReducer";

import DatesContextService from "../dep_contextServices/datesContextService";
import ShoppingCartContextService from "../dep_contextServices/shoppingCartContextService";
import ShoppingCartService from "../dep_services/shoppingCartService";
import { RentalDatesType } from "../dep_types";
import {
  CartItemType,
  CartProductItemType,
  CartSetItemType,
} from "../dep_types/CartTypes";
import {
  SetProductVariantChangeType,
  VariantSelectOptionType,
} from "../dep_types/ProductTypes";
import { commonKeys } from "../dep_whitelabel/commonKeys";

const keepCart = commonKeys.KEEP_CART_AFTER_CHECKOUT_ORDER;

export interface State {
  siteRentalDates: RentalDatesType;
  addedCartItem: CartItemType;
  shoppingCartRentalDates: {
    startDate: string;
    endDate: string;
  };
  cartItems: [];
  dateSyncModalOpen: boolean;
  shoppingCartOpened: boolean;
  cartInitialized: boolean;
  confirmOrder: (orderNumber: number) => void;
  openShoppingCart: () => void;
  closeShoppingCart: () => void;
  cartItemToSave: CartItemType;
}

const initialState = {
  siteRentalDates: {},
  addedCartItem: {},
  shoppingCartRentalDates: {},
  cartItems: [],
  dateSyncModalOpen: false,
  shoppingCartOpened: false,
  cartInitialized: false,
  partnerIdTheme: "1", // by default
  confirmOrder: () => {},
  openShoppingCart: () => {},
  closeShoppingCart: () => {},
};

export const ArriveRentalContext = createContext<State | any>(initialState);

export const ArriveRentalProvider: FC<any> = (props: any) => {
  const { addedCartItem, partnerId: partnerIdTheme } = props;
  const [state, dispatch] = useReducer(shoppingCartReducer, {
    ...initialState,
    addedCartItem,
    partnerIdTheme,
  });

  const dispatchSetSiteRentalDates = (
    siteRentalDates: RentalDatesType,
  ): void => {
    dispatch({
      type: SET_SITE_RENTAL_DATES,
      siteRentalDates,
    });
  };

  const setSiteRentalDates = (siteRentalDates: RentalDatesType): void => {
    localStorageService.save(
      localStorageKeys.SITEWIDE_RENTAL_DATES,
      siteRentalDates,
    );

    dispatchSetSiteRentalDates(siteRentalDates);
  };

  const dispatchSetShoppingCartRentalDates = (
    shoppingCartRentalDates: RentalDatesType,
  ): void =>
    dispatch({
      type: SET_SHOPPING_CART_RENTAL_DATES,
      shoppingCartRentalDates,
    });

  const dispatchInitCart = (cartInitialItems: CartItemType[]): void =>
    dispatch({
      type: INIT_CART,
      cartInitialItems,
    });

  useEffect(() => {
    const loadCartState = (): void => {
      ShoppingCartService.removeOldNonCompatibleItems();

      const localStorageCartState = ShoppingCartService.getCartItems();
      const localStorageCartDates = ShoppingCartService.getCartDates();

      if (localStorageCartDates) {
        dispatchSetShoppingCartRentalDates(localStorageCartDates);
      }

      const cartInitialItems = localStorageCartState || [];

      dispatchInitCart(cartInitialItems);
    };

    const loadUserSiteRentalDates = (): void => {
      const localStorageSiteRentalDates = localStorageService.get(
        localStorageKeys.SITEWIDE_RENTAL_DATES,
      );
      const areValidDates = DatesHelper.rentalDatesAreValid(
        localStorageSiteRentalDates,
      );

      if (areValidDates) {
        const localStartDate = parseISO(localStorageSiteRentalDates.startDate);
        const localEndDate = parseISO(localStorageSiteRentalDates.endDate);

        const minDateAndStartDateDifference =
          DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
            DatesHelper.minEndRentalDate,
            localStartDate,
          );

        if (minDateAndStartDateDifference >= 0) {
          dispatchSetSiteRentalDates({
            startDate: localStartDate,
            endDate: localEndDate,
          });
        } else {
          localStorageService.remove(localStorageKeys.SITEWIDE_RENTAL_DATES);
        }
      }
    };

    // eslint-disable-next-line react/destructuring-assignment
    if (props.initialize) {
      ShoppingCartService.initializeCartId();
      loadCartState();
      loadUserSiteRentalDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatchCloseDateSyncModal = (): void => {
    dispatch({ type: CLOSE_DATE_SYNC_MODAL });
  };

  const openShoppingCart = (): void =>
    dispatch({
      type: OPEN_SHOPPING_CART,
    });

  const closeShoppingCart = (): void =>
    dispatch({
      type: CLOSE_SHOPPING_CART,
    });

  const dispatchRemoveFromCart = (cartId: string): void => {
    dispatch({
      type: REMOVE_FROM_CART,
      cartId,
    });
  };

  const dispatchUpdateCartItems = (updatedCartItems: CartItemType[]): void => {
    dispatch({
      type: UPDATE_CART_ITEMS,
      updatedCartItems,
    });
  };

  const dispatchAddToCart = (
    cartItem: CartItemType | null,
    newCartItems: CartItemType[],
  ): void => {
    dispatch({
      type: ADD_TO_CART,
      addedCartItem: cartItem,
      newCartItems,
      addedToCartFlow: "OPEN_CART",
    });
  };

  const dispatchClearCart = (): void => {
    dispatch({
      type: CLEAR_CART,
    });
  };

  const dispatchSetPartnerIdTheme = (partnerId: string): void => {
    dispatch({
      type: SET_PARTNER_ID_THEME,
      partnerIdTheme: partnerId,
    });
  };

  const dispatchOpenDateSyncModal = (cartItem: CartItemType): void => {
    dispatch({
      type: OPEN_DATE_SYNC_MODAL,
      cartItem,
    });
  };

  const setShoppingCartRentalDates = (
    shoppingCartRentalDates: RentalDatesType,
  ): void =>
    DatesContextService.setShoppingCartRentalDates(
      state,
      shoppingCartRentalDates,
      dispatchSetShoppingCartRentalDates,
      dispatchUpdateCartItems,
    );

  const removeFromCart = (cartItem: CartItemType): void =>
    ShoppingCartContextService.removeFromCart(
      cartItem.cartId,
      dispatchRemoveFromCart,
    );

  const addCartItemQuantity = (cartItemId: string): void =>
    ShoppingCartContextService.addCartItemQuantity(
      state,
      cartItemId,
      dispatchUpdateCartItems,
    );

  const changeCartSimpleProductVariant = (
    cartItem: CartProductItemType,
    newVariantOption: VariantSelectOptionType,
  ): void =>
    ShoppingCartContextService.changeCartSimpleProductVariant(
      state,
      cartItem,
      newVariantOption,
      dispatchUpdateCartItems,
    );

  const changeCartSetProductVariant = (
    cartItemId: string,
    cartSetGroupItemId: string,
    updatedVariantData: SetProductVariantChangeType,
  ): void =>
    ShoppingCartContextService.changeCartSetProductVariant(
      state,
      cartItemId,
      cartSetGroupItemId,
      updatedVariantData,
      dispatchUpdateCartItems,
    );

  const reduceCartItemQuantity = (cartItemId: string): void =>
    ShoppingCartContextService.reduceCartItemQuantity(
      state,
      cartItemId,
      dispatchUpdateCartItems,
    );

  const addToCart = async (
    cartItem: CartProductItemType | CartSetItemType,
  ): Promise<void> => {
    if ((cartItem as CartProductItemType).isResale) {
      return ShoppingCartContextService.addToCartResaleItem(
        state,
        cartItem,
        dispatchAddToCart,
      );
    }

    return ShoppingCartContextService.addToCart(
      state,
      cartItem,
      dispatchAddToCart,
      dispatchSetSiteRentalDates,
      dispatchSetShoppingCartRentalDates,
      dispatchOpenDateSyncModal,
    );
  };

  const addGearFinderItemsToCart = async (
    cartItems: CartProductItemType[],
  ): Promise<void> => {
    const newCartItems = ShoppingCartContextService.addGearFinderItemsToCart(
      state,
      cartItems,
      dispatchSetSiteRentalDates,
      dispatchSetShoppingCartRentalDates,
    );

    dispatchAddToCart(null, newCartItems as CartItemType[]);
  };

  const syncRentalDatesAndAddProductToCart = useCallback(
    (scope: string): void =>
      ShoppingCartContextService.syncRentalDatesAndAddProductToCart(
        state,
        scope,
        dispatchAddToCart,
        dispatchSetSiteRentalDates,
        dispatchCloseDateSyncModal,
        dispatchSetShoppingCartRentalDates,
      ),
    [dispatchCloseDateSyncModal, state],
  );

  const changePartnerTheme = (partnerId: string): void =>
    dispatchSetPartnerIdTheme(partnerId);

  const clearCart = (): void =>
    ShoppingCartContextService.clearCart(keepCart, dispatchClearCart);

  const value = useMemo(
    () => ({
      ...state,
      siteRentalDates: state.siteRentalDates,
      addedCartItem: state.addedCartItem,
      cartItems: state.cartItems,
      rentalCartItems: state.cartItems
        ? state.cartItems.filter(
            (cartItem: CartProductItemType) => !cartItem.isResale,
          )
        : [],
      resaleCartItems: state.cartItems
        ? state.cartItems.filter(
            (cartItem: CartProductItemType) => cartItem.isResale,
          )
        : [],
      shoppingCartRentalDates: state.shoppingCartRentalDates,
      dateSyncModalOpen: state.dateSyncModalOpen,
      shoppingCartOpened: state.shoppingCartOpened,
      cartInitialized: state.cartInitialized,
      savedOrder: state.order,
      partnerIdTheme: state.partnerIdTheme,

      // THEME SERVICE
      changePartnerTheme,

      // DATES SERVICE
      setSiteRentalDates,
      setShoppingCartRentalDates,
      closeDateSyncModal: dispatchCloseDateSyncModal,

      // CART SERVICE
      addToCart,
      addManyToCart: addGearFinderItemsToCart,
      syncRentalDatesAndAddProductToCart,
      openShoppingCart,
      closeShoppingCart,
      removeFromCart,
      clearCart,
      addCartItemQuantity,
      reduceCartItemQuantity,
      changeCartSimpleProductVariant,
      changeCartSetProductVariant,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return <ArriveRentalContext.Provider value={value} {...props} />;
};

export const useArriveContext = (): any => {
  const context = useContext(ArriveRentalContext);

  if (context === undefined) {
    throw new Error(
      "useArriveContext must be used within a ArriveRentalProvider",
    );
  }

  return context;
};

// eslint-disable-next-line react/prop-types
export const ManagedArriveRentalContext: FC<any> = ({
  partnerId,
  children,
}) => (
  <ArriveRentalProvider initialize partnerId={partnerId}>
    {children}
  </ArriveRentalProvider>
);

type TestContextProps = {
  children: ReactNode;
};

export const TestArriveRentalContext: FC<TestContextProps> = ({ children }) => (
  <ArriveRentalProvider>{children}</ArriveRentalProvider>
);
