import { Box, Flex, VisuallyHidden } from "@chakra-ui/react";
import { addMonths, format } from "date-fns";
import React, { FC } from "react";

import ChevronIcon from "@/dep_components/icons/ChevronIcon";

import useIsMobile from "@/dep_hooks/useIsMobile";

type Props = {
  shownDate: Date;
  setShownDate(date: Date): void;
};

const CalendarMonthToggle: FC<Props> = ({ shownDate, setShownDate }) => {
  const monthFormat = "LLLL yyyy";

  const formattedCurrentMonth = format(shownDate, monthFormat);

  const nextMonth = addMonths(shownDate, 1);
  const formattedNextMonth = format(nextMonth, monthFormat);

  const isMobile = useIsMobile();

  const updateShownDate = (isPrevious: boolean): void => {
    const newDate = new Date(shownDate);
    const monthDifference = isPrevious ? -1 : 1;
    const newMonth = newDate.getMonth() + monthDifference;

    newDate.setMonth(newMonth);
    setShownDate(newDate);
  };

  return (
    <Flex
      align="center"
      justify="space-around"
      position="relative"
      textStyle="lg"
      fontWeight="medium"
      marginBottom={7}
      marginX={{
        base: 0,
        md: 3,
      }}
    >
      <Flex
        as="button"
        type="button"
        justify="flex-start"
        align="center"
        width="40px"
        height="40px"
        position="absolute"
        left={0}
        onClick={() => updateShownDate(true)}
      >
        <VisuallyHidden>Navigate to the previous month</VisuallyHidden>
        <ChevronIcon direction="left" />
      </Flex>
      <Box as="span">{formattedCurrentMonth}</Box>
      {!isMobile && <Box as="span">{formattedNextMonth}</Box>}
      <Flex
        as="button"
        type="button"
        justify="flex-end"
        align="center"
        width="40px"
        height="40px"
        position="absolute"
        right={0}
        onClick={() => updateShownDate(false)}
      >
        <VisuallyHidden>Navigate to the next month</VisuallyHidden>
        <ChevronIcon direction="right" />
      </Flex>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarMonthToggle;
