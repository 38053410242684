import { environment } from "@/dep_constants/index";

const partnerIds = {
  ARRIVE_OUTDOORS: "1",
  BURTON: "5",
  CITIZENRY: "6",
  YETI: "7",
  BLANQI: "8",
};

type PartnerPrefixes = {
  [key: string]: string;
};

const partnerIdsByFeaturePrefix: PartnerPrefixes = {
  ao: partnerIds.ARRIVE_OUTDOORS,
  burton: partnerIds.BURTON,
  citizenry: partnerIds.CITIZENRY,
  yeti: partnerIds.YETI,
  blanqi: partnerIds.BLANQI,
};

export const partnerUrls = {
  [partnerIds.ARRIVE_OUTDOORS]: [
    "localhost:3000",
    "local.arriveoutdoors.com:3001",
    "dev-ao.arriveoutdoors.com",
    "staging.arriveoutdoors.com",
    "www.arriveoudoors.com",
  ],
  [partnerIds.BURTON]: [
    "local-burton.arriveoutdoors.com:3005",
    "dev-burton.arriveoutdoors.com",
    "staging-burton.arriveoutdoors.com",
    "rentals.burton.com",
    "passalong.burton.com",
  ],
  [partnerIds.CITIZENRY]: [
    "local-citizenry.arriveoutdoors.com:3006",
    "dev-citizenry.arriveoutdoors.com",
    "staging-citizenry.arriveoutdoors.com",
    "secondhome.the-citizenry.com",
  ],
  [partnerIds.YETI]: [
    "local-yeti.arriveoutdoors.com:3007",
    "dev-yeti.arriveoutdoors.com",
    "staging-yeti.arriveoutdoors.com",
    "rescues.yeti.com",
  ],
  [partnerIds.BLANQI]: [
    "local-blanqi.arriveoutdoors.com:3008",
    "dev-blanqi.arriveoutdoors.com",
    "staging-blanqi.arriveoutdoors.com",
    "renewed.blanqi.com",
  ],
};

export const resalePartnerIds = [
  partnerIds.CITIZENRY,
  partnerIds.YETI,
  partnerIds.BLANQI,
];

export const cmsPartnerIds = {
  [partnerIds.ARRIVE_OUTDOORS]: "arrive-outdoors",
  [partnerIds.BURTON]: "burton",
  [partnerIds.CITIZENRY]: "the-citizenry",
  [partnerIds.YETI]: "yeti",
  [partnerIds.BLANQI]: "blanqi",
};

export const partnerNames = {
  [partnerIds.ARRIVE_OUTDOORS]: "Arrive Outdoors",
  [partnerIds.BURTON]: "Burton",
  [partnerIds.CITIZENRY]: "Citizenry",
  [partnerIds.YETI]: "Yeti",
  [partnerIds.BLANQI]: "Blanqi",
};

export const getCurrentPartnerFromHost = (
  host = "www.arriveoutdoors.com",
): { partnerId: string; cmsPartnerName: string; canonicalDomain: string } => {
  const canonicalDomain = `https://${host}`;

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT &&
    !host.startsWith("dev-") &&
    !host.startsWith("dev.")
  ) {
    const isLocalHost = host.startsWith("local");

    // Dev is using in feature deployment so we now use prefix to detect partner
    if (!isLocalHost) {
      const partnerHostPrefix = host.split("-")[0];
      const featurePartnerId =
        partnerIdsByFeaturePrefix[partnerHostPrefix] ||
        partnerIds.ARRIVE_OUTDOORS;

      // eslint-disable-next-line no-console
      console.log(`Selected partnerId for feature branch: ${featurePartnerId}`);

      return {
        partnerId: featurePartnerId,
        cmsPartnerName: cmsPartnerIds[featurePartnerId],
        canonicalDomain,
      };
    }
  }
  const swapped = Object.fromEntries(
    Object.entries(partnerUrls).map((a) => a.reverse()),
  );
  const urlKey: string | undefined = Object.keys(swapped).find(
    (k: string) => k.indexOf(host) >= 0,
  );
  const partnerId = urlKey
    ? swapped[urlKey] || partnerIds.ARRIVE_OUTDOORS
    : partnerIds.ARRIVE_OUTDOORS;

  // eslint-disable-next-line no-console
  return {
    partnerId,
    cmsPartnerName: cmsPartnerIds[partnerId],
    canonicalDomain,
  };
};

// eslint-disable-next-line import/no-default-export
export default partnerIds;
