import Utils from "@/dep_helpers/Utils";
import {
  CartProductItemType,
  PriceBreakdownItemType,
} from "@/dep_types/CartTypes";

// eslint-disable-next-line import/no-default-export
export default class CartItemHelper {
  static getPriceBreakdownItems(
    cartItem: CartProductItemType,
  ): PriceBreakdownItemType[] | undefined {
    let priceBreakdownItems;

    if ((cartItem as CartProductItemType)?.priceDecrementingItems) {
      const itemsMap = new Map();

      cartItem?.priceDecrementingItems?.forEach((priceDecrementingItem) => {
        const key = priceDecrementingItem.price.toString();

        if (itemsMap.has(key)) {
          // If item with same price already exists, update its quantity
          const existingItem = itemsMap.get(key);

          existingItem.quantity += priceDecrementingItem.quantity;
        } else {
          itemsMap.set(key, {
            label: `${itemsMap.size + 1}${Utils.getOrdinal(
              itemsMap.size + 1,
            )} Promotional Price`,
            price: priceDecrementingItem.price,
            quantity: priceDecrementingItem.quantity,
          });
        }
      });

      priceBreakdownItems = Array.from(itemsMap.values());
    }

    return priceBreakdownItems;
  }
}
