import { Box, Flex, VisuallyHidden } from "@chakra-ui/react";
import React, { FC } from "react";

import CloseIcon from "@/dep_components/icons/CloseIcon";

import useIsMobile from "@/dep_hooks/useIsMobile";

import CalendarClearDatesBtn from "./CalendarClearDatesBtn";
import CalendarSelectedRangeText from "./CalendarSelectedRangeText";
import CalendarTooltipsContainer from "./CalendarTooltipsContainer";

import { RentalDatesType } from "../../../../dep_types";

type Props = {
  calendarDates: RentalDatesType;
  clearCalendarDates(): void;
  submitRentalDates(): void;
};

const CalendarHeader: FC<Props> = ({
  calendarDates,
  clearCalendarDates,
  submitRentalDates,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      marginBottom={9}
      marginX={{
        base: 0,
        md: 3,
      }}
      align="center"
      justify="space-between"
    >
      {!isMobile && <CalendarSelectedRangeText calendarDates={calendarDates} />}
      {!isMobile && <CalendarTooltipsContainer width="340px" />}
      {isMobile && (
        <Box as="button" type="button" onClick={() => submitRentalDates()}>
          <VisuallyHidden>Close</VisuallyHidden>
          <CloseIcon stroke="black" />
        </Box>
      )}
      {isMobile && (
        <CalendarClearDatesBtn clearCalendarDates={clearCalendarDates} />
      )}
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarHeader;
