const checkbox = {
  parts: ["container", "control", "label", "icon"],
  baseStyle: {
    label: {
      textTransform: "uppercase",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default checkbox;
