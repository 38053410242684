const faqCategorySection = {
  parts: ["title", "subtitle"],
  baseStyle: {
    title: {
      fontFamily: "normal",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: 1.4,
      letterSpacing: "-.02em",
      marginBottom: 4,
    },
    subtitle: {
      fontFamily: "normal",
      fontSize: {
        base: "26px",
        md: "48px",
      },
      fontWeight: "medium",
      lineHeight: 1.25,
      letterSpacing: "-.04em",
      marginBottom: {
        base: 10,
        md: 16,
      },
    },
    card: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingY: {
        base: 8,
        md: 12,
      },
      paddingX: {
        base: 4,
        md: 12,
      },
      fontFamily: "normal",
      fontSize: "18px",
      fontWeight: "medium",
      backgroundColor: "gray.100",
      lineHeight: 1.5,

      letterSpacing: "-.03em",
      _hover: {
        backgroundColor: "gray.200",
      },
      borderRadius: "14px",
      transition: ".2s ease background-color",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqCategorySection;
