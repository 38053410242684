import QueryString from "qs";

import { cmsPartnerIds } from "@/dep_constants/partnerIds";

type StrapiEqFilter = {
  [key: string]: {
    $eq: string;
  };
};

export type ExtraFilterData = {
  fieldKey: string;
  fieldValue: string;
};

const generateCmsQueryString = (
  partnerId: string | null,
  relations: string[],
  isChild: boolean,
  isPreviewMode: boolean,
  extraFilterData?: ExtraFilterData[],
): string => {
  const additionalFilters: StrapiEqFilter = {};

  if (extraFilterData && extraFilterData.length > 0) {
    extraFilterData.forEach((extraFilter) => {
      additionalFilters[extraFilter.fieldKey] = {
        $eq: extraFilter.fieldValue,
      };
    });
  }

  const filters = {
    ...additionalFilters,
  };

  if (partnerId !== null) {
    const cmsPartnerId = cmsPartnerIds[partnerId];

    const partnerFilter = {
      partner_id: {
        $eq: cmsPartnerId,
      },
    };

    const childFilter = {
      partner: partnerFilter,
    };

    const mainFilters = isChild ? childFilter : partnerFilter;

    Object.assign(filters, mainFilters);
  }

  return QueryString.stringify({
    filters,
    populate: relations,
    publicationState: isPreviewMode ? "preview" : undefined,
  });
};

// eslint-disable-next-line import/no-default-export
export default generateCmsQueryString;
