const modalContent = {
  parts: ["title", "bodyText"],
  baseStyle: {
    title: {
      pt: "10px",
      pb: "15px",
      textAlign: "center",
      fontSize: "24px",
      fontFamily: "normal",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    bodyText: {
      textAlign: "center",
      fontWeight: "medium",
      color: "black",
      fontFamily: "normal",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default modalContent;
