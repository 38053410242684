import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useState } from "react";

import ProductsService from "@/dep_services/productService";
import { SearchProductType } from "@/dep_types";

import { useArriveContext } from "@/dep_components/context";
import SearchYetiIcon from "@/dep_components/icons/Yeti/SearchIcon";
import { useSearchContext } from "@/dep_components/searchContext";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import SearchForm from "./SearchForm";
import SearchIcon from "./SearchIcon";
import SearchResults from "./SearchResults";

const SearchModule: FC = () => {
  const { partnerIdTheme } = useArriveContext();
  const { onSearchOpen, isSearchOpen, setIsSearchOpen, bannerHeight } =
    useSearchContext();

  const { isYeti } = useWhitelabelContext();

  const [popularProducts, setPopularProducts] = useState<
    Array<SearchProductType>
  >([]);

  useEffect(() => {
    const fetchPopularProducts = async (): Promise<void> => {
      if (isYeti) {
        const productConfig = {
          partnerId: partnerIdTheme,
          onlyPopularProducts: true,
          pageNumber: 1,
          pageSize: 6,
        };
        const collectionProducts =
          await ProductsService.fetchProductsForCollection(productConfig);

        setPopularProducts(collectionProducts.data?.products || []);
      } else {
        const popularProductsResponse =
          await ProductsService.getPopularProducts(partnerIdTheme);

        setPopularProducts(popularProductsResponse);
      }
    };

    fetchPopularProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawerContentContainerRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        // Hack because the Chakra Drawer component does not give us the
        // ability to update props on the DrawerContent's container element
        const contentContainerChild = node.firstElementChild as HTMLElement;

        contentContainerChild.style.height = `calc(100vh - ${bannerHeight}px)`;
        contentContainerChild.style.top = `${bannerHeight}px`;
      }
    },
    [bannerHeight],
  );

  return (
    <>
      <Flex
        as="button"
        type="button"
        display="flex"
        alignItems="center"
        paddingX={3}
        marginRight={{
          base: "2",
          md: "0",
        }}
        height="100%"
        onClick={onSearchOpen}
      >
        {isYeti ? <SearchYetiIcon fill="secondary.600" /> : <SearchIcon />}
      </Flex>
      <Drawer
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        placement="right"
        variant="search"
      >
        <DrawerOverlay
          height={`calc(100% - ${bannerHeight}px)`}
          top={`${bannerHeight}px`}
        />
        <Box ref={drawerContentContainerRef}>
          <DrawerContent
            maxHeight={`calc(100% - ${bannerHeight}px)`}
            top={`${bannerHeight}px !important`}
          >
            <VisuallyHidden>
              <Heading>Search</Heading>
            </VisuallyHidden>
            <SearchForm />
            <SearchResults popularProducts={popularProducts} />
          </DrawerContent>
        </Box>
      </Drawer>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchModule;
