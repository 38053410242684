const label = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "medium",
  },
  sizes: {
    xl: {
      fontSize: { base: "18px", md: "20px" },
    },
    lg: {
      fontSize: { base: "14px", md: "16px" },
    },
    md: {
      fontSize: { base: "12px", md: "14px" },
    },
    sm: {
      fontSize: { base: "12px", md: "12px" },
    },
    xs: {
      fontSize: "10px",
    },
  },
  variants: {
    productBrand: {
      fontSize: "12px",
      fontFamily: "normal",
      fontWeight: "bold",
      color: "#F08C77",
    },
    inverted: {
      color: "white",
    },
    bold: {
      fontWeight: "bold",
    },
    loveItKeepIt: {
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
  defaultProps: {
    size: "lg",
  },
};

// eslint-disable-next-line import/no-default-export
export default label;
