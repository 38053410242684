const text = {
  parts: [
    "accordionTitle",
    "cartCheckoutExtraInfo",
    "cartPaymentTotalRowTitle",
    "checkoutFormSummarySectionTitle",
    "formHighlightTitle",
    "link",
    "menuCategory",
    "orderDetailsPrice",
    "orderDetailsOneLineLeftTextTotal",
    "orderDetailsOneLineLeftText",
    "orderDetailsTotalRow",
    "productOptionLabelParameterSelection",
    "shoppingCartPrice",
    "variantParametersGroupText",
    "primaryResidenceCheckboxText",
    "priceWithDiscount",
  ],
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "normal",
    letterSpacing: "-.02em",
    link: {
      fontWeight: "medium",
      textDecoration: "underline",
      cursor: "pointer",
      fontSize: "16px",
    },
    variantParametersGroupText: {
      fontWeight: "medium",
      fontSize: "16px",
    },
    cartCheckoutExtraInfo: {},
    accordionTitle: {},
    menuCategory: {
      fontWeight: "medium",
    },
    shoppingCartPrice: {
      fontFamily: "normal",
      fontWeight: "bold",
    },
    checkoutFormSummarySectionTitle: {
      fontSize: "21px",
      fontWeight: "medium",
      color: "black",
    },
    cartPaymentTotalRowTitle: {
      fontSize: 14,
      fontFamily: "normal",
      fontWeight: 500,
    },
    orderDetailsTotalRow: {
      direction: "row",
      fontFamily: "normal",
      fontWeight: "medium",
      fontSize: {
        base: "xl",
        md: "2xl",
        lg: "3xl",
      },
    },
    priceWithDiscount: {
      fontWeight: "bold",
      color: "error",
    },
  },
  sizes: {
    "2xs": {
      fontSize: "10px",
      lineHeight: 1.6,
    },
    xs: {
      fontSize: "12px",
      lineHeight: 1.33,
    },
    sm: {
      fontSize: "13px",
      lineHeight: 1.2,
    },
    md: {
      fontSize: "14px",
      lineHeight: 1.4,
    },
    lg: {
      fontSize: "16px",
      lineHeight: 1.48,
    },
    xl: {
      fontSize: "18px",
      lineHeight: 1.35,
    },
  },
  variants: {
    orderDetailsItem: {
      fontSize: "xs",
      color: "gray.500",
    },
    formHighlightTitle: {
      fontSize: "16px",
      fontWeight: "medium",
      color: "primary.600",
    },
    formInfoSubtitle: {
      fontSize: "14px",
      fontWeight: "medium",
      color: "gray.500",
    },
    primaryResidenceCheckboxText: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default text;
