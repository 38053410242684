const modalHeader = {
  parts: ["header"],
  baseStyle: {
    header: {
      pb: "0",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default modalHeader;
