/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex } from "@chakra-ui/react";
import { Image, Transformation } from "cloudinary-react";
import React, { FC } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies

import { commonKeys } from "@/dep_whitelabel/commonKeys";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { useBreakpoint } from "@/dep_hooks/useBreakpoint";

import { ProductImage, ProductType } from "../../../dep_types";
import { OrderDetailProduct } from "../../../dep_types/OrderDetailsTypes";

type SimplifiedSubProductSelectedFromSet = {
  id: string;
  name: string;
  image?: any;
  images?: any;
  defaultMainImage?: ProductImage;
  defaultCarouselImages?: ProductImage[];
};

type Props = {
  product?:
    | ProductType
    | SimplifiedSubProductSelectedFromSet
    | OrderDetailProduct;
  image?: ProductImage;
  width?: number;
  height?: number;
  containerHeight?: number;
  minWidth?: number;
  isUnavailable?: boolean;
  variantImageCloudinaryPublicId?: string;
};

const ProductThumbnail: FC<Props> = ({
  product,
  image,
  width,
  height,
  containerHeight,
  minWidth,
  isUnavailable,
  variantImageCloudinaryPublicId,
}: Props) => {
  const { isPhone } = useBreakpoint();
  const { keys } = useWhitelabelContext();

  const fallbackImage =
    keys?.DEFAULT_PRODUCT_IMAGE_ID || commonKeys.DEFAULT_PRODUCT_IMAGE_ID;

  // TODO: Refactor this when BE team tells that "images" field is no longer used: Thank you PLatini.
  let imagePublicId;

  if (variantImageCloudinaryPublicId) {
    imagePublicId = variantImageCloudinaryPublicId;
  } else {
    imagePublicId =
      image?.publicId ||
      product?.defaultMainImage?.publicId ||
      (product?.image && product.image.publicId) ||
      (product?.images &&
        product?.images.length > 0 &&
        product?.images[0]?.publicId) ||
      (product as ProductType)?.cloudinaryImage?.publicId ||
      fallbackImage;
  }
  if (!width) {
    width = 300;
  }

  return (
    <Flex
      flex={1}
      height={containerHeight}
      justifyContent="center"
      alignItems="center"
      minWidth={minWidth || "auto"}
    >
      <Image
        height={height || 100}
        width={width || "auto"}
        publicId={imagePublicId}
        fetchFormat="auto"
        crop="pad"
        dpr={isPhone ? 2 : 1}
        alt={product?.name}
      >
        {isUnavailable && <Transformation effect="grayscale" />}
      </Image>
    </Flex>
  );
};

ProductThumbnail.defaultProps = {
  product: undefined,
  image: undefined,
  height: undefined,
  width: undefined,
  minWidth: undefined,
  containerHeight: undefined,
  isUnavailable: false,
  variantImageCloudinaryPublicId: undefined,
};

// eslint-disable-next-line import/no-default-export
export default ProductThumbnail;
