import { environment } from "../../dep_constants";

let overrides;

const local = {};

const development = {};

// Enabling amplitude in staging to allow Adam play with dashboards.
const staging = {};

const production = { AMPLITUDE_KEY: "1abfea98e691ed68e3e592abbae675b2" };

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

const configurations = {
  ...overrides,
  // TODO: Change it
  // DEFAULT_PRODUCT_IMAGE_ID: "ecomm-cms-prod/primary_black_b82c174443",
};

// eslint-disable-next-line import/no-default-export
export default configurations;
