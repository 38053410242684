/* eslint-disable class-methods-use-this */
import OrderLog from "@/dep_helpers/order/OrderLog";
import * as gtag from "@/lib/gtag";

import TrackingStrategy from "./trackingStrategy";

// eslint-disable-next-line import/no-default-export
export default class GTagTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    typeof window !== "undefined" && Boolean(window.gtag);

  trackPageView(): void {
    gtag.event({
      action: "conversion",
      allow_custom_scripts: true,
      send_to: "DC-13515713/unive0/unive0+standard",
    });
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductPageView(): void {
    // do not track
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackAddToCart = (): void => {
    // do not track
  };

  trackConversion = (
    _partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void => {
    const rentalTotal = orderLog.rentalTotal;
    const resaleTotal = orderLog.resaleTotal;

    if (rentalTotal > 0) {
      gtag.event({
        action: "conversion",
        category: "purchase",
        label: "rental",
        value: rentalTotal,
      });

      // DoubleClick Floodlight conversion event
      gtag.event({
        allow_custom_scripts: true,
        action: "conversion",
        category: "purchase",
        label: rentalOrderId ?? "",
        value: rentalTotal,
        send_to: "DC-13515713/conve000/conve0+transactions",
      });
    }

    if (resaleTotal > 0) {
      gtag.event({
        action: "conversion",
        category: "purchase",
        label: "resale",
        value: resaleTotal,
      });

      // DoubleClick Floodlight conversion event
      gtag.event({
        allow_custom_scripts: true,
        action: "conversion",
        category: "purchase",
        label: resaleOrderId ?? "",
        value: resaleTotal,
        send_to: "DC-13515713/conve000/conve0+transactions",
      });
    }
  };

  trackEvent = (): void => {
    // do not track
  };

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }

  trackStartCheckout(): void {
    // do not track
  }
}
