const linkButton = {
  baseStyle: {
    color: "primary.600",
    fontFamily: "normal",
    fontWeight: "bold",
    fontSize: { base: "13px", md: "15px" },
    transition: "filter .15s ease",
    _hover: {
      filter: "brightness(1.5)",
      cursor: "pointer",
    },
  },
  variants: {
    actionButtonXSmallPrimary: {
      fontSize: "xs",
    },
    newLinkButton: {
      fontSize: "md",
      fontWeight: "bold",
      variant: "text",
      color: "black",
      textTransform: "none",
      padding: 0,
      textDecoration: "underline",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default linkButton;
