const siteLink = {
  variants: {
    footer: {
      fontSize: "14px",
      fontWeight: "500",
      color: "inherit",
      textTransform: "uppercase",
      _hover: {
        color: "white",
        textDecoration: "underline",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default siteLink;
