const iconValueProp = {
  parts: ["container", "textContainer", "title", "description", "icon"],
  baseStyle: {
    title: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    description: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
    icon: {
      marginBottom: {
        base: 7,
        md: 10,
      },
      width: {
        base: "28px",
        md: "35px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default iconValueProp;
