import { ParameterSummaryType } from "@/dep_types";

import { variantParameterKeys } from "../../../dep_constants";

type ParametersListType = [string, string[]][];

const parameterKeysByWeight: {
  [key: string]: number;
} = {
  // Golf
  hand: 0,
  loft: 1,
  flex: 2,
  shaft: 3,
  // Apparel
  "size category": 0,
  size: 1,
  color: 2,
};

const parameterValuesByWeight: {
  [key: string]: number;
} = {
  // Sizes
  xxs: 0,
  xs: 1,
  s: 2,
  m: 3,
  l: 4,
  lg: 4,
  xl: 5,
  xxl: 6,
  "2xl": 6,
  xxxl: 7,
  regular: 0,
  standard: 0,
  long: 1,
  petite: 1,
  "women's petite": 1,
  tall: 2,
  "women's tall": 2,
  plus: 3,
  "women's plus": 3,
  "x-small": 0,
  "extra small": 0,
  small: 1,
  medium: 2,
  "large otg": 3,
  large: 3,
  "x-large": 4,
  "extra large": 4,
};

const parameterKeyIsSize = (parameterKey: string): boolean =>
  parameterKey.toLowerCase() === variantParameterKeys.SIZE.toLowerCase();
const parameterKeyIsSizeCategory = (parameterKey: string): boolean =>
  parameterKey.toLowerCase() ===
  variantParameterKeys.SIZE_CATEGORY.toLowerCase();
const parameterKeyIsColor = (parameterKey: string): boolean =>
  parameterKey.toLowerCase() === variantParameterKeys.COLOR.toLowerCase();
const parameterKeyIsCondition = (parameterKey: string): boolean =>
  parameterKey.toLowerCase() === variantParameterKeys.CONDITION.toLowerCase();

const compareByWeightOrNumber = (
  parameterValue1: string,
  parameterValue2: string,
): number => {
  const parameter1Weight =
    parameterValuesByWeight[parameterValue1.toLowerCase()];
  const parameter2Weight =
    parameterValuesByWeight[parameterValue2.toLowerCase()];

  if (parameter1Weight !== undefined && parameter2Weight !== undefined) {
    return parameter1Weight - parameter2Weight;
  }

  const parameterValue1AsFloat = parseFloat(parameterValue1);
  const parameterValue2AsFloat = parseFloat(parameterValue2);

  // If the values are both numbers then we can sort them based on their numerical value
  if (
    !Number.isNaN(parameterValue1AsFloat) &&
    !Number.isNaN(parameterValue2AsFloat)
  ) {
    // This accounts for fraction-based strings like '8 1/2' or '30/32'
    // which will resolve as 8 or 30, respectively. Using the second number
    // in the string allows '8 1/2' to be sorted after '8' and '30/32' to be
    // sorted before '30/34', ensuring the correct sorting of these complex
    // number-based strings
    if (parameterValue1AsFloat === parameterValue2AsFloat) {
      const parameter1Matches = parameterValue1.match(/\d+/g) || [];
      const parameter2Matches = parameterValue2.match(/\d+/g) || [];

      const parameter1SecondNumber = parseInt(parameter1Matches[1], 10) || 0;

      const parameter2SecondNumber = parseInt(parameter2Matches[1], 10) || 0;

      return parameter1SecondNumber - parameter2SecondNumber;
    }

    return parameterValue1AsFloat - parameterValue2AsFloat;
  }

  // If we have nothing to base our sorting off of then just maintain the order
  return 0;
};

const getSortedParametersList = (
  parameterSummary: ParameterSummaryType,
): ParametersListType => {
  const parametersList = Object.entries(parameterSummary || {}).sort(
    (parameterListItem1, parameterListItem2) => {
      const parameterKey1Weight =
        parameterKeysByWeight[parameterListItem1[0].toLowerCase()];
      const parameterKey2Weight =
        parameterKeysByWeight[parameterListItem2[0].toLowerCase()];

      if (
        parameterKey1Weight !== undefined &&
        parameterKey2Weight !== undefined
      ) {
        return parameterKey1Weight - parameterKey2Weight;
      }

      return 0;
    },
  );

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  parametersList.forEach(([_parameter, parameterValues]) => {
    parameterValues.sort(compareByWeightOrNumber);
  });

  return parametersList;
};

const compareAlphabetically = (
  parameterValue1: string,
  parameterValue2: string,
): number => {
  if (parameterValue1 < parameterValue2) {
    return -1;
  }

  if (parameterValue1 > parameterValue2) {
    return 1;
  }

  return 0;
};

const getSortedValuesByNumberAndAlphabeticalOrder = (
  parameterValues: string[],
): string[] => {
  const numericValues = parameterValues.filter((parameterValue) => {
    const parameterValueAsFloat = parseFloat(parameterValue);

    return !Number.isNaN(parameterValueAsFloat);
  });
  const nonNumericValues = parameterValues.filter((parameterValue) => {
    const parameterValueAsFloat = parseFloat(parameterValue);

    return Number.isNaN(parameterValueAsFloat);
  });

  return [
    ...numericValues.sort(compareByWeightOrNumber),
    ...nonNumericValues.sort(compareAlphabetically),
  ];
};

export {
  compareByWeightOrNumber,
  getSortedParametersList,
  parameterKeyIsSize,
  parameterKeyIsColor,
  parameterKeyIsCondition,
  parameterKeyIsSizeCategory,
  getSortedValuesByNumberAndAlphabeticalOrder,
};
