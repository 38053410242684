import { CartItemType, CartSetItemType } from "@/dep_types/CartTypes";

const appendSetChoices = (
  cartItem: CartItemType | CartSetItemType,
  setChoices: string[],
): void => {
  if (cartItem.isSet) {
    const cartItemAsSet = cartItem as CartSetItemType;

    cartItemAsSet.cartSetGroups.forEach((setGroup) => {
      setGroup.forEach((item) => {
        setChoices.push(item.productName);
      });
    });
  }
};

// eslint-disable-next-line import/no-default-export
export default appendSetChoices;
