import { Flex } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  message: string;
};

const CalendarTileContent: FC<Props> = ({ message }) => {
  const arrowHeight = "5px";

  return (
    <>
      <Flex
        className="react-calendar__tileInfoMessageTrigger"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        tabIndex={0}
      />
      <Flex
        className="react-calendar__tileInfoMessage"
        display="none"
        position="absolute"
        justifyContent="center"
        backgroundColor="black"
        fontSize="12px"
        color="white"
        paddingX={3}
        paddingY={3}
        borderRadius="10px"
        top="calc(100% + 8px)"
        left="calc(50% - 20px)"
        width="max-content"
        zIndex={1}
        _before={{
          content: '""',
          position: "absolute",
          top: `-${arrowHeight}`,
          left: `calc(10px + ${arrowHeight})`,
          width: 0,
          height: 0,
          borderLeft: `${arrowHeight} solid transparent`,
          borderRight: `${arrowHeight} solid transparent`,
          borderBottom: `${arrowHeight} solid`,
          borderBottomColor: "black",
        }}
        role="status"
      >
        {message}
      </Flex>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarTileContent;
