import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";

import PartnerIds from "@/dep_constants/partnerIds";
import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import BackToSiteButton from "./BackToSiteButton";
import SiteNavigationCategory from "./SiteNavigationCategory";
import SiteNavigationLink, {
  SiteNavigationLinkType,
} from "./SiteNavigationLink";

import { CategoryType } from "../../../dep_types";

interface MobileNavigationProps {
  categoryGroups: Record<string, CategoryType[]>;
  navigationItems?: NavigationItem[];
  onCloseDrawer: () => void;
}

export function MobileNavigation({
  categoryGroups,
  navigationItems,
  onCloseDrawer,
}: MobileNavigationProps) {
  const { partnerIdTheme, headerInfo, isYeti } = useWhitelabelContext();

  let findMyOrderLabel = "";

  switch (partnerIdTheme) {
    case PartnerIds.YETI:
      findMyOrderLabel = "Track Your Order";
      break;
    case PartnerIds.BURTON:
      findMyOrderLabel = "Find Your Order";
      break;
    case PartnerIds.CITIZENRY:
      findMyOrderLabel = "VIEW YOUR ORDER";
      break;
    default:
      findMyOrderLabel = "Find your Order";
      break;
  }

  let helpCenterLabel = "";

  switch (partnerIdTheme) {
    case PartnerIds.YETI:
      helpCenterLabel = "Help";
      break;
    case PartnerIds.CITIZENRY:
      helpCenterLabel = "FAQ";
      break;
    default:
      helpCenterLabel = "Help Center";
      break;
  }

  const navigationLinks: SiteNavigationLinkType[] = [
    {
      children: findMyOrderLabel,
      link: "/findmyorder",
      menuItem: "Find Order",
      primaryCategory: "",
      secondaryCategory: "",
      onCloseDrawer,
    },
    {
      children: helpCenterLabel,
      link: "/faq",
      menuItem: "FAQ",
      primaryCategory: "",
      secondaryCategory: "",
      onCloseDrawer,
    },
  ];

  return (
    <Box as="nav" textTransform={isYeti ? "uppercase" : "inherit"}>
      <Text
        as="h2"
        size="md"
        color="gray.500"
        marginBottom={7}
        letterSpacing={isYeti ? 0.03 : "auto"}
      >
        Menu
      </Text>
      <UnorderedList listStyleType="none" margin="0">
        {headerInfo.navigationItems.map((item) => (
          <ListItem
            key={item.name}
            borderTop="1px solid"
            borderColor="gray.100"
          >
            <SiteNavigationCategory
              name={`${item.name}`}
              categoryGroupKey={item.categoryGroup}
              subcategories={categoryGroups[item.categoryGroup]}
              onCloseDrawer={onCloseDrawer}
            />
          </ListItem>
        ))}
        {navigationItems?.map((item) => (
          <ListItem
            key={item.label}
            borderTop="1px solid"
            borderColor="gray.100"
            _last={{
              borderBottom: "1px solid",
              borderColor: "gray.100",
            }}
          >
            <SiteNavigationLink
              link={item.href}
              menuItem={item.label}
              primaryCategory=""
              secondaryCategory=""
              onCloseDrawer={onCloseDrawer}
            >
              {item.label}
            </SiteNavigationLink>
          </ListItem>
        ))}
        {navigationLinks.map((navItem) => (
          <ListItem
            key={navItem.link}
            borderTop="1px solid"
            borderColor="gray.100"
            _last={{
              borderBottom: "1px solid",
              borderColor: "gray.100",
            }}
          >
            <SiteNavigationLink {...navItem} />
          </ListItem>
        ))}
        {headerInfo.backToSiteButton && (
          <ListItem
            borderTop="1px solid"
            borderColor="gray.100"
            _last={{
              borderBottom: "1px solid",
              borderColor: "gray.100",
            }}
          >
            <BackToSiteButton {...headerInfo.backToSiteButton} />
          </ListItem>
        )}
      </UnorderedList>
    </Box>
  );
}
