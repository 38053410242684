import qs from "qs";

import { CmsClient } from "@/api/CmsClient";

type PartnerFontStyle = {
  source: string;
  style: string;
  weight: string;
};

type PartnerFont = {
  name: string;
  family: string;
  fallbacks?: string;
  styles: PartnerFontStyle[];
};

export async function GetPartnerFonts(
  partnerName: string,
): Promise<PartnerFont[]> {
  const query = qs.stringify({
    filters: {
      partner_id: {
        $eq: partnerName,
      },
    },
    populate: "fonts.styles",
  });

  try {
    return await CmsClient()
      .get(`/partners?${query}`)
      .then(({ data }) => data?.data[0]?.attributes?.fonts);
  } catch {
    throw Error(`Something went wrong getting partner fonts`);
  }
}
