const formSectionHeading = {
  parts: ["container", "stepNumber"],
  baseStyle: {
    container: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      fontSize: "18px",
    },
    stepNumber: {},
  },
  variants: {},
};

// eslint-disable-next-line import/no-default-export
export default formSectionHeading;
