import dynamic from "next/dynamic";

import { IconSkeleton } from "../components/IconSkeleton";

const dynamicIconConfig = {
  ssr: false,
  loading: IconSkeleton,
};

export const icons = {
  chevronRight: dynamic(
    () =>
      import("@react-icons/all-files/fi/FiChevronRight").then(
        (mod) => mod.FiChevronRight,
      ),
    dynamicIconConfig,
  ),
  chevronLeft: dynamic(
    () =>
      import("@react-icons/all-files/fi/FiChevronLeft").then(
        (mod) => mod.FiChevronLeft,
      ),
    dynamicIconConfig,
  ),
  chevronDown: dynamic(
    () =>
      import("@react-icons/all-files/fi/FiChevronDown").then(
        (mod) => mod.FiChevronDown,
      ),
    dynamicIconConfig,
  ),
  cart: dynamic(
    () =>
      import("@react-icons/all-files/fi/FiShoppingCart").then(
        (mod) => mod.FiShoppingCart,
      ),
    dynamicIconConfig,
  ),
  info: dynamic(
    () => import("@react-icons/all-files/fi/FiInfo").then((mod) => mod.FiInfo),
    dynamicIconConfig,
  ),
  x: dynamic(
    () => import("@react-icons/all-files/fi/FiX").then((mod) => mod.FiX),
    dynamicIconConfig,
  ),
  plus: dynamic(
    () => import("@react-icons/all-files/fi/FiPlus").then((mod) => mod.FiPlus),
    dynamicIconConfig,
  ),
  minus: dynamic(
    () =>
      import("@react-icons/all-files/fi/FiMinus").then((mod) => mod.FiMinus),
    dynamicIconConfig,
  ),
};
