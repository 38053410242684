// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

const RecGov: FC = () => {
  const [showReferralText, setShowReferralText] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (
      searchParams.get("utm_source") === "Recreation.gov" &&
      searchParams.get("utm_medium") === "referral" &&
      !sessionStorage.getItem("referralModalShown")
    ) {
      setShowReferralText(true);
    }
  }, []);

  if (!showReferralText) return null;

  return (
    <Text
      fontSize="12px"
      fontFamily="normal"
      opacity={0.6}
      pr={{ base: 0, md: 20 }}
      textAlign="left"
    >
      Recreation.gov is your source for inspiration and reservations at national
      parks, forests, and other federal recreation sites across the country. To
      help deliver a complete travel planning experience, Recreation.gov has
      provided a referral link to this site for gear rental. This resource is
      offered for visitor convenience and does not indicate an endorsement,
      recommendation, or favoring by Recreation.gov or the United States
      Government. Reserve gear for free with Arrive Outdoors today, so you can
      start enjoying your time outdoors and bring home a story!
    </Text>
  );
};

// eslint-disable-next-line import/no-default-export
export default RecGov;
