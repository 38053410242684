import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function ProductCardPricePrefix({
  children,
}: Readonly<PropsWithChildren>) {
  const { productPricePrefix } = useMultiStyleConfig("ProductCard");

  return (
    <Box
      sx={productPricePrefix}
      fontSize="xs"
      fontWeight="normal"
      fontFamily="body"
    >
      {children}
    </Box>
  );
}
