import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import React, { FC } from "react";

import Utils from "@/dep_helpers/Utils";
import { CartProductItemType, PriceBreakdownTier } from "@/dep_types/CartTypes";

type Props = {
  priceTiers: PriceBreakdownTier[];
  cartItem: CartProductItemType;
};

const PriceBreakdownSection: FC<Props> = ({ priceTiers, cartItem }: Props) => (
  <Accordion allowMultiple>
    <AccordionItem
      sx={{
        border: 0,
      }}
    >
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left" color="gray.500">
            Price Breakdown
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {priceTiers.map((priceTier) => (
          <Flex
            key={`${cartItem.variant}-${priceTier.price}`}
            direction="row"
            mb={2}
          >
            <Flex flex={1} direction="row" alignItems="center">
              {priceTier.label}
            </Flex>
            <Flex flex={1} direction="row" justifyContent="flex-end">
              $ {Utils.toCurrency(priceTier.price)} x {priceTier.quantity} Qty
            </Flex>
          </Flex>
        ))}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);

// eslint-disable-next-line import/no-default-export
export default PriceBreakdownSection;
