/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import React, { ChangeEvent, FC } from "react";

import { DropDownListItemType } from "../../../dep_types";
import ErrorMessageText from "../FormInputElements/ErrorMessageText";

type Props = {
  id: string;
  autocomplete?: string;
  placeholder?: string;
  selected?: number | string;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
  label?: string;
  items: DropDownListItemType[];
  dataTestId?: string;
  variant?: string;
  errors?: any;
  isDisabled?: boolean;
  name?: string;
};

const DropDownList: FC<Props> = ({
  id,
  autocomplete,
  label,
  placeholder,
  selected,
  items,
  onChange,
  dataTestId,
  variant,
  errors,
  isDisabled,
  name,
}: Props) => {
  const sortedItems = items
    ? [...items].sort((a, b) => (a.sortWeight || 0) - (b.sortWeight || 0))
    : [];
  const styles = useMultiStyleConfig("DropDownList", { variant });

  return (
    <FormControl>
      <Box id={id}>
        {label && <FormLabel sx={styles.label}>{label}</FormLabel>}
        <Select
          autoComplete={autocomplete}
          placeholder={placeholder}
          value={selected}
          onChange={onChange}
          data-testid={dataTestId}
          sx={styles.select}
          isDisabled={isDisabled}
          name={name}
        >
          {sortedItems &&
            sortedItems.map((item) => (
              <option key={item.id} value={item.id} disabled={item.disabled}>
                {item.name}
              </option>
            ))}
        </Select>
      </Box>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={`${name ?? dataTestId}`}
          render={({ message }) => (
            <ErrorMessageText>{message}</ErrorMessageText>
          )}
        />
      )}
    </FormControl>
  );
};

DropDownList.defaultProps = {
  autocomplete: undefined,
  placeholder: undefined,
  label: undefined,
  variant: undefined,
  errors: undefined,
  dataTestId: undefined,
  selected: undefined,
  isDisabled: false,
  name: undefined,
};

// eslint-disable-next-line import/no-default-export
export default DropDownList;
