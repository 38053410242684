import { Box, useMultiStyleConfig } from "@chakra-ui/react";

import { useProductCard } from "@/dep_components/Commons/Products/ProductCard/hooks/useProductCard";

import { ProductCardVariants } from "../ProductCard";

export type ProductCardNameProps = {
  readonly variant?: ProductCardVariants;
};

export function ProductCardProductName({ variant }: ProductCardNameProps) {
  const { product } = useProductCard();
  const { productName } = useMultiStyleConfig("ProductCard", { variant });

  return (
    <Box sx={productName} fontSize="lg">
      {product.name}
    </Box>
  );
}
