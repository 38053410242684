import { Flex } from "@chakra-ui/react";

import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import Container from "@/dep_components/Layout/Container";
import Logo from "@/dep_components/Logo/Logo";

import { NavigationItems } from "./NavigationItems";

import layout from "../../../dep_constants/layout";
import { CategoryType } from "../../../dep_types";
import MenuItemShoppingCart from "../MenuItemShoppingCart";
import SearchModule from "../Search/SearchModule";

interface DesktopHeaderProps {
  categoryGroups: Record<string, CategoryType[]>;
  navigationItems?: NavigationItem[];
}

export function DesktopHeader({
  categoryGroups,
  navigationItems,
}: DesktopHeaderProps) {
  return (
    <Container
      display={{ base: "none", lg: "flex" }}
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      height={`${layout.desktopHeaderHeight}px`}
    >
      <Flex flex={1} alignItems="center">
        <Logo
          sx={{
            img: {
              display: "inline-block",
            },
          }}
        />
      </Flex>
      <NavigationItems
        categoryGroups={categoryGroups}
        navigationItems={navigationItems}
        flex={3}
      />
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
      >
        <SearchModule />
        <MenuItemShoppingCart />
      </Flex>
    </Container>
  );
}
