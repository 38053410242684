const faqCategorySection = {
  parts: ["title", "subtitle", "card"],
  baseStyle: {
    title: {
      fontFamily: "normal",
      fontWeight: "normal",
      letterSpacing: "0.16px",
    },
    subtitle: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    card: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqCategorySection;
