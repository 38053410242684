const mediaValueProp = {
  parts: ["title", "body", "listItem"],
  baseStyle: {
    title: {
      marginTop: {
        xl: 8,
      },
      marginBottom: {
        base: 6,
        md: 8,
      },
    },
    body: {
      marginBottom: {
        base: 6,
        md: 8,
      },
    },
    listItem: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default mediaValueProp;
