const accordion = {
  baseStyle: {
    button: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
  },
  variants: {
    menuItem: {
      button: {
        pl: 0,
        backgroundColor: "#ffffff",
        _hover: {
          backgroundColor: "#ffffff",
        },
      },
    },
    collectionFiltersMenuItem: {
      button: {
        pl: 0,
        backgroundColor: "#ffffff",
        _hover: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default accordion;
