import {
  QueryClientProvider,
  Hydrate,
  QueryClient,
  DehydratedState,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren, useState } from "react";

interface QueryProviderProps {
  dehydratedState?: DehydratedState[];
}

export const prefetchQueryClient = new QueryClient();

export function QueryProvider({
  dehydratedState,
  children,
}: PropsWithChildren<QueryProviderProps>) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}
