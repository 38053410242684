/* eslint-disable @typescript-eslint/no-explicit-any */
import { WhitelabelParameterFacet } from "@/dep_types/ApiTypes";
import {
  BrandType,
  CollectionFilter,
  ProductPriceRangeType,
  ProductSaleType,
} from "@/dep_types/ProductTypes";

import { CartSetItemSetGroupVariantSelection } from "./CartTypes";
import APIImage from "./commerce-api/image";
import APIPricingType from "./commerce-api/pricingType";
import APIProductImage from "./commerce-api/productImage";
import APIProductSaleType from "./commerce-api/productSaleType";
import APIProductType from "./commerce-api/productType";
import APIResalePrice from "./commerce-api/resalePrice";
import APISizeChart from "./commerce-api/sizeChart";

export type DropDownListItemType = {
  id: number | string;
  name: string;
  disabled?: boolean;
  sortWeight?: number;
};

export type NavMenuItemType = {
  group: string;
  name: string;
};

export type BreadcrumbType = {
  link?: string;
  name: string;
};

export type CategoryFilterItemType = {
  id: number;
  name: string;
  sortWeight: number;
};

export interface FiltersType {
  categories?: CategoryFilterItemType[];
  collections?: CollectionType[];
  brands?: BrandType[];
  productSaleTypes?: ProductSaleType[];
  conditions?: ConditionFilter[];
  colors?: WhitelabelParameterFacet[];
  sizes?: WhitelabelParameterFacet[];
  capacities?: WhitelabelParameterFacet[];
  genders?: WhitelabelParameterFacet[];
  seasons?: WhitelabelParameterFacet[];
  conditionsTooltip?: string | null;
}

export type ConditionFilter = {
  id: string;
  name: string;
  count: number;
};

export type ConditionTypes = {
  [key in "GOOD" | "FAIR" | "BAD"]: ConditionType;
};
export type ConditionType = {
  tierNumber: number;
  key: ConditionKey;
  priceDecrementingKey: PriceDecrementingConditionKey;
  description: string;
};

export type DiscountAndStockByCondition = {
  condition: ConditionKey;
  stock: number;
  hasDiscount: boolean;
  price: number;
  conditionPriceDecrementingTierStockIndex: number;
  totalTiersAmount: number;
};

export type ConditionsDiscounts = {
  currentConditionType: ConditionType | null;
  good: DiscountAndStockByCondition;
  fair: DiscountAndStockByCondition;
  bad: DiscountAndStockByCondition;
};

export type VariantParametersType = {
  [key: string]: string;
};

export type VariantOptionType = {
  id?: number;
  name?: string;
  shortName?: string;
};

export type ParameterSummaryType = {
  [key: string]: string[];
};

export type ConditionSummaryType = {
  [key: string]: number;
};

export type ConditionKey = "good" | "fair" | "bad";
export type PriceDecrementingConditionKey =
  | "goodArray"
  | "fairArray"
  | "badArray";

export type BaseConditionSummary = {
  [key in ConditionKey]: number;
};

export type PriceDecrementingTierConditionSummary = {
  [key in PriceDecrementingConditionKey]: number[];
};

export type ConditionSummary = BaseConditionSummary &
  PriceDecrementingTierConditionSummary;

export type VariantSummaryType = {
  parameterSummary: ParameterSummaryType | null;
  conditionSummary: ConditionSummaryType;
};

export type APIVariantSummaryType = {
  parameterSummary: ParameterSummaryType | null;
  conditionSummary: string[];
  rentalInStock: boolean;
  resaleInStock: boolean;
};

export type VariantType = {
  id: number;
  parameters: VariantParametersType;
  productId: number;
  sku: string;
  thumbnail: {
    id: number;
    publicId: string;
    url: string;
  };
  conditions?: ConditionSummary;
  resaleMaxAvailability?: number;
  resaleMaxAvailabilityPriceDecrementing?: number[];
  originalPriceDecrementingAvailability?: number[];
  stock: number;
  stockBuffer: number;
  displayName?: string | null;
  specs?: string;
  description?: string;
  retailPrice?: number;
  salePrices?: SalePrice[];
};

export type InitialSelectedGroupedVariant = {
  [key: string]: object;
};

export interface ProductImage {
  id: number;
  url: string;
  publicId: string;
  isDefault: boolean;
  productId: number;
  parameters?: VariantParametersType;
}

export type ProductSuperlativeTagType = {
  id: string;
  name: string;
  color: string;
};

export type CloudinaryImage = {
  id: number;
  publicId: string;
  url: string;
};

export type ColorSwatch = {
  id: number;
  color: string;
  image?: CloudinaryImage;
  displayImage: CloudinaryImage | null;
  swatchImage: CloudinaryImage | null;
  swatchHex: string | null;
  variantSku?: string;
};

export type ProductCollection = {
  id: number;
  name: string;
  products: any[];
  collectionCategoryId: number;
  collectionCategory: {
    id: number;
    name: string;
    productCollections: any[];
  };
};

type ProductActivityType = {
  id: number;
  name: string;
  products: any[];
};

type ProductFeature = {
  id: number;
  name: string;
  products: any[];
};

export interface SalePrice {
  condition: string;
  price: number;
  priceArray: number[];
}

export interface ProductType {
  id: number;
  isSet: boolean;
  productType: "product" | "set";
  category: string;
  productSaleType: "rental" | "resale" | "both";
  name: string;
  colorSwatches: ColorSwatch[];
  // This will be deprecated
  sizeChart?: {
    publicId: string;
  };
  // We will use only this array eventually
  cloudinaryImageSizeCharts?: {
    publicId: string;
  }[];
  description: string;
  specs: string;
  slug: string;
  descriptionPlainText: string;
  pricingType: PricingType;
  displayPrice: number;
  retailPrice: number;
  resalePrice: number; // TODO RESALE: match with api price
  brand: {
    id: number;
    name: string;
    products: any[];
  };
  brandId: number;
  partner: {
    id: number;
    name: string;
    products: any[];
  };
  partnerId: number;
  seasons: string; // TODO: Type as constant values
  temperatureRating: number;
  weight: number;
  variants: VariantType[];
  // NEW TODO: This needs to be optional as it is not always there
  resaleVariants: VariantType[];
  availableVariants: VariantType[];
  availableResaleVariants: VariantType[];
  variantOptions: VariantOptionType[];
  variantSummary: APIVariantSummaryType;
  conditionsSummary?: ConditionSummaryType;
  amountOfPeople?: number;
  activityTypes: ProductActivityType[];
  features: ProductFeature[];
  productCollections: ProductCollection[];
  superlativeTag: ProductSuperlativeTagType;
  // TODO: Refactor Images arrays
  // cloudinaryImages has all the images for the product
  cloudinaryImages?: ProductImage[];
  // Images and Images should be deleted when Backend team gives the ok.
  images: ProductImage[];
  image: ProductImage;
  cloudinaryImage?: ProductImage;
  // Default images are set here to filter these just once and get them from the product
  defaultCarouselImages: ProductImage[];
  defaultMainImage: ProductImage;
  price: number[];
  salePrices: SalePrice[];
  isPublished: boolean;
  recentlyOrdered: boolean;

  // YETI Optional fields for default values
  variantCustomDescription?: string;
  variantCustomSpecs?: string;
  variantCustomRetailPrice?: number;

  uniqueSizes?: string[];

  showAllSizes?: boolean;
}

export type SearchProductType = {
  id: number;
  name: string;
  slug: string;
  brand: BrandType;
  retailPrice: number;
  displayPrice: number;
  conditions?: ConditionSummaryType;
  productType: APIProductType;
  productSaleType: APIProductSaleType;
  category: string;
  image: APIImage;
  cloudinaryImage: APIProductImage;
  price: number[];
  isPublished: boolean;
  inStock: boolean;
  publishToggleAvailable: boolean;
  sizeChart: APISizeChart;
  pricingType: APIPricingType;
  salePrices: APIResalePrice[];
  swatches: ColorSwatch[];
  rentalInStock: boolean;
  resaleInStock: boolean;
  searchTerm?: string;
  variants?: VariantType[];
};

export interface SetProductType extends ProductType {
  initialSelectedVariants: InitialSelectedGroupedVariant[];
  hasVariantSelectionPages: boolean;
  setGroups: SetGroup[];
}

export interface SetGroup {
  id: number;
  name: string;
  numberOfSelections: number;
  description: string;
  productId: number;
  products: any[];
  substitutions: any[];
}

export type APICollectionDataResponse = {
  id: number;
  name: string;
  group: string;
  collectionCategory: {
    id: number;
    name: string;
  };
};

export type APIBrandDataResponse = BrandType;

export type CollectionCategoryType = {
  id: number;
  name: string;
  sortWeight: number;
};

export interface CollectionType extends CollectionFilter {
  collectionCategory: CollectionCategoryType;
  sortWeight: number;
}

export interface CategoryType {
  collectionCategory: CollectionCategoryType;
  collections: CollectionType[];
}

export type GroupCategoriesType = {
  [key: string]: Record<string, CategoryType>;
};

export type CategoriesType = {
  [key: string]: CategoryType;
};

export type APICategoriesType = {
  [key: string]: CategoryType;
};

export type ConditionFilters = { [key: string]: boolean };

export type AppliedFiltersType = {
  category: string;
  collection: string;
  brands: { [key: string]: boolean };
  productSaleTypes: { [key: string]: boolean };
  conditions: ConditionFilters;
  categoryGroup: string;
  colors: { [key: string]: boolean };
  sizes: { [key: string]: boolean };
  capacities: { [key: string]: boolean };
  genders: { [key: string]: boolean };
  seasons: { [key: string]: boolean };
  priceRange: ProductPriceRangeType;
};

// DATES
export type RentalDatesType = {
  startDate?: Date | null | string;
  endDate?: Date | null | string;
};

export type RentalDatesLabelsType = {
  startDate?: string | null;
  endDate?: string | null;
};

// Product Details Variant Selection
export type ProductSetGroupSelection = {
  [key: string]: CartSetItemSetGroupVariantSelection;
};

export type UnifiedTaxInfo = {
  rentalTaxInfo: TaxInfo;
  resaleTaxInfo: TaxInfo;
};

export type TaxInfo = {
  salesTax?: number;
  taxRate?: number;
};

export enum PricingType {
  Percentage = "percentage",
  Fixed = "fixed",
}

export type keyValues = { [key: string]: string[] | null };
export type keyValue = { [key: string]: string | null };
export type StringMap = {
  [key: string]: string;
};
export type BooleanMap = {
  [key: string]: boolean;
};
