const menuItemDate = {
  parts: ["contentContainer", "title", "subtitle"],
  baseStyle: {
    contentContainer: {
      fontFamily: "normal",
      fontWeight: "medium",
      display: "block",
      pt: "10px",
    },
    title: {
      lineHeight: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
    },
    subtitle: {
      fontSize: "13px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default menuItemDate;
