import { GlobalLabelsType } from "@/dep_types/content/api/components/partner/globalLabels";

import { conditionTypes } from "../dep_constants";

// eslint-disable-next-line import/no-default-export
export default class ConditionsHelper {
  static getTranslation(key: string, globalLabels?: GlobalLabelsType): string {
    if (!key) throw new Error("Please specify a condition key");
    switch (key.toLowerCase()) {
      case conditionTypes.GOOD.key:
        return (
          globalLabels?.resaleConditionTierOne ||
          conditionTypes.GOOD.description
        );
      case conditionTypes.FAIR.key:
        return (
          globalLabels?.resaleConditionTierTwo ||
          conditionTypes.FAIR.description
        );
      case conditionTypes.BAD.key:
        return (
          globalLabels?.resaleConditionTierThree ||
          conditionTypes.BAD.description
        );
      default:
        return key;
    }
  }

  static getConditionGuideDescriptionTranslation(
    key: string,
    globalLabels: GlobalLabelsType,
  ): string {
    if (!key) throw new Error("Please specify a condition key");
    switch (key.toLowerCase()) {
      case conditionTypes.GOOD.key:
        return globalLabels?.resaleConditionTierOneDescription || "";
      case conditionTypes.FAIR.key:
        return globalLabels?.resaleConditionTierTwoDescription || "";
      case conditionTypes.BAD.key:
        return globalLabels?.resaleConditionTierThreeDescription || "";
      default:
        return key;
    }
  }

  static getConditionsTooltip = (
    globalLabels: GlobalLabelsType,
  ): string | null => {
    let text = "";
    const separator = globalLabels.resaleConditionGuideSeparator || "\n\n";

    if (globalLabels.resaleConditionTierOneEnabled) {
      const text1 = `${ConditionsHelper.getTranslation(
        conditionTypes.GOOD.key,
        globalLabels,
      )}${separator}${ConditionsHelper.getConditionGuideDescriptionTranslation(
        conditionTypes.GOOD.key,
        globalLabels,
      )}`;

      text += `${text1}`;
    }

    if (globalLabels.resaleConditionTierTwoEnabled) {
      const text2 = `${ConditionsHelper.getTranslation(
        conditionTypes.FAIR.key,
        globalLabels,
      )}${separator}${ConditionsHelper.getConditionGuideDescriptionTranslation(
        conditionTypes.FAIR.key,
        globalLabels,
      )}`;

      text += `\n\n\n${text2}`;
    }

    if (globalLabels.resaleConditionTierThreeEnabled) {
      const text3 = `${ConditionsHelper.getTranslation(
        conditionTypes.BAD.key,
        globalLabels,
      )}${separator}${ConditionsHelper.getConditionGuideDescriptionTranslation(
        conditionTypes.BAD.key,
        globalLabels,
      )}`;

      text += `\n\n\n${text3}`;
    }

    return text !== "" ? `${text}` : null;
  };
}
