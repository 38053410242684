import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

interface Props extends IconProps {}

const InformationIcon: FC<Props> = ({ ...other }) => (
  <Icon width="11px" height="12px" viewBox="0 0 11 12" fill="black" {...other}>
    <path d="M5.5 0C2.46265 0 0 2.46724 0 5.50827C0 8.55108 2.46265 11.0165 5.5 11.0165C8.53735 11.0165 11 8.55108 11 5.50827C11 2.46724 8.53735 0 5.5 0ZM5.5 2.44318C6.01443 2.44318 6.43145 2.86084 6.43145 3.37604C6.43145 3.89124 6.01443 4.30889 5.5 4.30889C4.98557 4.30889 4.56855 3.89124 4.56855 3.37604C4.56855 2.86084 4.98557 2.44318 5.5 2.44318ZM6.74194 8.08472C6.74194 8.23191 6.62278 8.35125 6.47581 8.35125H4.52419C4.37722 8.35125 4.25806 8.23191 4.25806 8.08472V7.55166C4.25806 7.40447 4.37722 7.28513 4.52419 7.28513H4.79032V5.86364H4.52419C4.37722 5.86364 4.25806 5.7443 4.25806 5.59711V5.06405C4.25806 4.91686 4.37722 4.79753 4.52419 4.79753H5.94355C6.09052 4.79753 6.20968 4.91686 6.20968 5.06405V7.28513H6.47581C6.62278 7.28513 6.74194 7.40447 6.74194 7.55166V8.08472Z" />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default InformationIcon;
