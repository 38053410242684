import { Box, BoxProps, Link, useStyleConfig } from "@chakra-ui/react";
import { sanitize } from "isomorphic-dompurify";
import ReactMarkdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import Utils from "@/dep_helpers/Utils";
import { ModalLink } from "@/features/shared/Modals/ModalLink";
import { Text } from "@/revive/components/Text";

import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

interface MarkdownProps {
  text: string;
  openExternalLinksInNewTab: boolean;
}

const markdownComponentMap: Components = {
  p: (props) => <Text {...props} />,
  a: (props) => {
    if (!props?.href) {
      return <span {...props} />;
    }

    if (props?.href.includes("modal=")) {
      return <ModalLink href={props?.href}>{props?.children[0]}</ModalLink>;
    }

    return <Link {...props} />;
  },
};

function Markdown({ text, openExternalLinksInNewTab }: MarkdownProps) {
  return (
    <ReactMarkdown
      components={markdownComponentMap}
      className="react-markdown"
      rehypePlugins={[rehypeRaw, remarkGfm]}
      linkTarget={(href) =>
        openExternalLinksInNewTab && Utils.isExternalLink(href)
          ? "_blank"
          : "_self"
      }
    >
      {text}
    </ReactMarkdown>
  );
}

interface RichTextProps extends BoxProps {
  isHTML?: boolean;
  text: string;
}

export function RichText({ isHTML, text, sx, ...other }: RichTextProps) {
  const whiteLabelContext = useWhitelabelContext();
  const styles = useStyleConfig("RichText", {});

  return (
    <Box sx={{ ...styles, ...sx }} {...other}>
      {isHTML ? (
        <div dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
      ) : (
        <Markdown
          text={text}
          openExternalLinksInNewTab={
            whiteLabelContext?.openExternalLinksInNewTab
          }
        />
      )}
    </Box>
  );
}
