import { environment } from "../../dep_constants";

let overrides;

const local = {
  SHOP_ALL_CATEGORY_ID: "103",
};

const production = {
  SHOP_ALL_CATEGORY_ID: "111",
};

switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
  case environment.PRODUCTION:
  case environment.STAGING:
    overrides = production;
    break;
  default:
    overrides = local;
    break;
}

const config = {
  SHOP_ALL_CATEGORY_ID: overrides.SHOP_ALL_CATEGORY_ID,
};

// eslint-disable-next-line import/no-default-export
export default config;
