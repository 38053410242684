import { Box, Stack, useMultiStyleConfig } from "@chakra-ui/react";

import { ValuePropType } from "@/dep_types/content/sections";
import { Container } from "@/revive/components/Container";
import { Heading } from "@/revive/components/Heading";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import { RichText } from "@/dep_components/Commons/RichText";
import Section from "@/dep_components/Layout/Section";
import DynamicIcon from "@/dep_components/icons/DynamicIcon";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type BrandValuePropProps = Omit<ValuePropType, "componentKey" | "id">;

export function BrandValueProp({
  icon,
  title,
  description,
  button,
  variant,
  backgroundColor,
}: Readonly<BrandValuePropProps>) {
  const { isYeti } = useWhitelabelContext();

  const styles = useMultiStyleConfig("brandValueProp", { variant });

  const buttonYetiStyles = isYeti &&
    title === "Find Your Order" && {
      color: "white",
      borderColor: "white",
      _hover: {
        backgroundColor: "#002B45",
        borderColor: "#002B45",
      },
      _active: {
        backgroundColor: "#002B45",
        borderColor: "#002B45",
      },
    };

  const isYetiTrackYourOrder =
    isYeti && title.toLowerCase() === "track your order";

  return (
    <Section
      sx={{
        ...styles.section,
        backgroundColor,
      }}
      background={
        isYetiTrackYourOrder
          ? "url('https://res.cloudinary.com/arrive-outdoors/image/upload/v1671473310/ecomm-cms-prod/YETI_Rescues_Arrive_Microsite_FAQ_Find" +
            "_Your_Order_Launch_198f149dce.png?updated_at=2022-12-19T18:08:31.350Z')"
          : undefined
      }
      backgroundPosition={isYetiTrackYourOrder ? "center" : undefined}
      backgroundSize={isYetiTrackYourOrder ? "cover" : undefined}
      backgroundBlendMode={isYetiTrackYourOrder ? "soft-light" : undefined}
    >
      <Container>
        <Stack
          direction={{
            base: "column",
            ...(variant === "secondary" && { xl: "row" }),
          }}
          alignItems="center"
          gap={{
            base: 5,
            ...(variant === "secondary" && { xl: 12 }),
          }}
          textAlign={{
            base: "center",
            ...(variant === "secondary" && { xl: "left" }),
          }}
          color={isYetiTrackYourOrder && "white"}
        >
          <Box flex="1">
            {icon && (
              <Box sx={styles.icon}>
                <DynamicIcon source={icon.source} />
              </Box>
            )}
            <Heading style="h1">{title}</Heading>
          </Box>
          <Box flex="1">
            <RichText text={description} />
            {button && (
              <Box sx={styles.button}>
                <LinkedCTAButton
                  {...button}
                  sx={{ ...styles.cta, ...buttonYetiStyles }}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Container>
    </Section>
  );
}
