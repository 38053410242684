import { Flex, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import DatesHelper from "../../../../dep_helpers/DatesHelper";
import { RentalDatesType } from "../../../../dep_types";

type Props = {
  calendarDates: RentalDatesType;
};

const CalendarSelectedRangeText: FC<Props> = ({ calendarDates }: Props) => {
  const daysRented = DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
    calendarDates.startDate as Date,
    calendarDates.endDate as Date,
  );
  const dayOrDays = daysRented > 1 ? "days" : "day";
  const datesInNewShoppingCartFormat =
    DatesHelper.parseGlobalRentalDatesToDisplayDate(calendarDates);

  const isValidRentalDate = DatesHelper.rentalDatesAreValid(calendarDates);

  const primaryText = isValidRentalDate
    ? `${daysRented} ${dayOrDays}`
    : "Reservation Dates";
  const secondaryText = isValidRentalDate
    ? `${datesInNewShoppingCartFormat.startDate} - ${datesInNewShoppingCartFormat.endDate}`
    : "When are you using this product?";

  return (
    <Flex direction="column">
      <Text as="span" size="xl" fontWeight="medium" marginBottom={1}>
        {primaryText}
      </Text>
      <Text as="span" size="md" color="gray.500">
        {secondaryText}
      </Text>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarSelectedRangeText;
