import { ReviveTheme } from "@/revive/theme";

export const blanqi: ReviveTheme = {
  partnerId: 8,
  partnerName: "blanqi",
  colors: {
    announcement: "#3C3C3C",
    confirmation: "#7A7145",
    error: "#FC826B",
    black: "#414141",
    primary: {
      50: "#7A7145",
      100: "#F2F2F2",
      600: "#7A7145",
    },
    secondary: {
      50: "#70797C",
      600: "#70797C",
    },
    gray: {
      50: "#FAFAFA",
      100: "#F2F2F2",
      200: "#E2E2E2",
      300: "#C8C8C8",
      400: "#828282",
      500: "#6E6E6E",
    },
  },
  components: {
    Heading: {
      variants: {
        h1: {
          fontSize: "7xl",
        },
        h2: {
          fontWeight: "normal",
          letterSpacing: "normal",
        },
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          fontWeight: "medium",
          textTransform: "uppercase",
          fontFamily: "body",
        },
      },
    },
  },
};
