const dropDownList = {
  parts: ["label", "select"],
  baseStyle: {
    label: {
      fontFamily: "normal",
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    select: {
      fontFamily: "normal",
      fontWeight: "normal",
      letterSpacing: "0.16px",
    },
  },
  variants: {
    form: {
      label: {
        fontFamily: "normal",
        fontWeight: "light",
        letterSpacing: "0.16px",
      },
      select: {
        fontFamily: "normal",
        fontWeight: "light",
        letterSpacing: "0.16px",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default dropDownList;
