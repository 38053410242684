import { datadogRum } from "@datadog/browser-rum";

import { ErrorObjectType } from "@/dep_types/content/error";

const reportContentError = (errorObject: ErrorObjectType): void => {
  const error = new Error(errorObject.message);

  error.name = errorObject.name;

  datadogRum.addError(error);
};

// eslint-disable-next-line import/no-default-export
export default reportContentError;
