/* eslint-disable @typescript-eslint/no-explicit-any */
import { useOutsideClick } from "@chakra-ui/react";
import { useRef, useState } from "react";

import { useArriveContext } from "../dep_components/context";
import DatesHelper from "../dep_helpers/DatesHelper";
import ShoppingCartService from "../dep_services/shoppingCartService";
import { RentalDatesType } from "../dep_types";
import { CartProductItemType } from "../dep_types/CartTypes";
import { VariantSelectOptionType } from "../dep_types/ProductTypes";

export interface State {
  selectedVariant: number | undefined;
  setSelectedVariant: (number: number) => void;
  changeSimpleProductVariant: (newVariantId: number) => Promise<void>;
  itemVariantEditRef: any;
  editSimpleProductVariant: boolean;
  simpleProductAvailableVariantsForEdit: any;
  handleEditSimpleProductVariant: () => Promise<void>;
  showSimpleProductEditButtonAlert: boolean;
  setShowSimpleProductEditButtonAlert: (show: boolean) => void;
}

const useCartItemEditHook = (
  cartItem: CartProductItemType,
  shoppingCartRentalDates?: RentalDatesType,
  changeCartSimpleProductVariant?: (
    changedCartItem: CartProductItemType,
    selectedOption: VariantSelectOptionType,
  ) => void,
): State => {
  const { cartItems, partnerIdTheme } = useArriveContext();

  const [editSimpleProductVariant, setEditSimpleProductVariant] =
    useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<number | undefined>();
  const [
    showSimpleProductEditButtonAlert,
    setShowSimpleProductEditButtonAlert,
  ] = useState<boolean>(false);

  const [
    simpleProductAvailableVariantsForEdit,
    setSimpleProductAvailableVariantsForEdit,
  ] = useState<any>();

  const itemVariantEditRef = useRef();

  useOutsideClick({
    ref: itemVariantEditRef as any,
    handler: () => setEditSimpleProductVariant(false),
  });

  const handleEditSimpleProductVariant = async (): Promise<void> => {
    if (shoppingCartRentalDates) {
      const rentalDatesApiParams =
        DatesHelper.parseGlobalRentalDatesToAPIParams(shoppingCartRentalDates);

      const availableVariants =
        await ShoppingCartService.getSimpleProductAvailability(
          partnerIdTheme,
          cartItems,
          cartItem.variant,
          cartItem.product.id,
          rentalDatesApiParams.startDate as string,
          rentalDatesApiParams.endDate as string,
        );

      if (
        !availableVariants ||
        !availableVariants.variantOptions ||
        availableVariants.variantOptions.length === 0
      ) {
        setShowSimpleProductEditButtonAlert(true);

        return;
      }

      setEditSimpleProductVariant(true);
      setSelectedVariant(cartItem.variant);
      setSimpleProductAvailableVariantsForEdit(
        availableVariants.variantOptions,
      );
    }
  };

  const changeSimpleProductVariant = async (
    newVariantId: number,
  ): Promise<void> => {
    if (!newVariantId) {
      setEditSimpleProductVariant(false);

      return;
    }

    const selectedOption = simpleProductAvailableVariantsForEdit.find(
      (option: VariantSelectOptionType) => option.id === newVariantId,
    );

    if (changeCartSimpleProductVariant) {
      changeCartSimpleProductVariant(cartItem, selectedOption);
    }

    setEditSimpleProductVariant(false);
  };

  return {
    selectedVariant,
    setSelectedVariant,
    changeSimpleProductVariant,
    itemVariantEditRef,
    editSimpleProductVariant,
    simpleProductAvailableVariantsForEdit,
    handleEditSimpleProductVariant,
    showSimpleProductEditButtonAlert,
    setShowSimpleProductEditButtonAlert,
  };
};

// eslint-disable-next-line import/no-default-export
export default useCartItemEditHook;
