/* eslint-disable sonarjs/no-duplicate-string */
const productSlider = {
  parts: [
    "section",
    "navigation",
    "toggleButtons",
    "linkedTitle",
    "slider",
    "title",
    "description",
  ],
  baseStyle: {
    section: {
      color: "black",
      overflow: "hidden",
    },
    navigation: {
      gridColumn: "1 / span 12",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    toggleButtons: {
      display: { base: "none", xl: "flex" },
    },
    linkedTitle: {
      flexBasis: { base: "100%", xl: "unset" },
      justifyContent: { base: "space-between", xl: "flex-start" },
      alignItems: "center",
      transition: "color .25s ease",
    },
    valueProp: {
      display: "flex",
      gridColumn: {
        base: "span 12",
        lg: "span 4",
        xl: "span 3",
      },
      flexDirection: "column",
      alignItems: "flex-start",
      alignSelf: "flex-start",
      marginTop: {
        base: 0,
        xl: 5,
      },
    },
    sliderContainer: {
      gridColumn: "1 / span 12",
    },
    slider: {
      overflow: "visible !important",
    },
    title: {
      fontSize: "16px",
      lineHeight: "1.48",
      letterSpacing: "-.03em",
      marginRight: { base: 0, xl: 16 },
      _groupHover: { color: "gray.500" },
    },
  },
  variants: {
    value_prop: {
      navigation: {
        justifyContent: "flex-end",
      },
      toggleButtons: {
        display: { base: "none", lg: "flex" },
      },
      sliderContainer: {
        gridColumn: {
          base: "span 11",
          lg: "span 8",
          xl: "span 9",
        },
      },
      slider: {
        clipPath: "inset( -100vw -100vw -100vw 0 )",
      },
      title: {
        marginRight: 0,
        marginBottom: {
          base: 0,
          md: 4,
          lg: 8,
        },
        _groupHover: null,
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default productSlider;
