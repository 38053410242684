import { Flex, Skeleton } from "@chakra-ui/react";

import { usePartnerData } from "@/hooks/usePartnerData";

import { useProductCard } from "@/dep_components/Commons/Products/ProductCard/hooks/useProductCard";

export function ProductCardOrginalPrice() {
  const { data, isLoading } = usePartnerData();
  const { originalPriceLabel } = data?.globalLabels ?? {};

  const { product } = useProductCard();

  return (
    <Flex
      as={Skeleton}
      isLoaded={!isLoading}
      gap={2}
      fontSize="xs"
      textDecoration="line-through"
      color="gray.500"
    >
      {originalPriceLabel || "Original Price"} ${product.displayPrice}
    </Flex>
  );
}
