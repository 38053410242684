/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@chakra-ui/react";
import { isValid } from "date-fns";
import React, { FC, ReactNode, useEffect, useState } from "react";

import TrackingService from "@/dep_services/tracking/trackingService";
import EventLocationType from "@/dep_types/EventLocationType";

import useIsMobile from "@/dep_hooks/useIsMobile";

import CalendarContent from "./partials/CalendarContent/CalendarContent";
import DesktopDatePicker from "./partials/DesktopDatePicker";
import MobileDatePicker from "./partials/MobileDatePicker";

import DatesHelper from "../../dep_helpers/DatesHelper";
import useDatePickerValidationHook from "../../dep_hooks/useDatePickerValidationHook";
import { ProductType, RentalDatesType } from "../../dep_types";
import { useArriveContext } from "../context";

type Props = {
  children: ReactNode;
  location: EventLocationType;
  productsForTracking: ProductType[];
  productsForCalendar?: ProductType[];
  isOpen?: boolean;
  setIsOpen?(): void;
  cartDates?: boolean;
  siteDates?: boolean;
  fullWidth?: boolean;
};

const DatePicker: FC<Props> = ({
  children,
  location,
  productsForTracking,
  productsForCalendar,
  isOpen,
  setIsOpen,
  cartDates,
  siteDates,
  fullWidth,
}: Props) => {
  // Allowing this component to be controlled, if desired
  // (e.g. the Add to Cart CTA needs to trigger the DatePicker to open on PDPs)
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const isDatePickerOpen = isOpen === undefined ? uncontrolledIsOpen : isOpen;
  const setIsDatePickerOpen =
    setIsOpen === undefined ? setUncontrolledIsOpen : setIsOpen;

  useEffect(() => {
    if (isDatePickerOpen) {
      TrackingService.trackCalendarOpened(location, productsForTracking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatePickerOpen]);

  const {
    checkRangeSelectedIsValid,
    handleOnRangeFocusChange,
    rangeSelectionComplete,
    rangeSelectionIsValid,
  } = useDatePickerValidationHook();

  const {
    siteRentalDates,
    shoppingCartRentalDates,
    setSiteRentalDates,
    setShoppingCartRentalDates,
  } = useArriveContext();

  const initialDates: RentalDatesType = DatesHelper.getInitialDates(
    siteRentalDates,
    shoppingCartRentalDates,
    cartDates,
    siteDates,
  );

  const [calendarDates, setCalendarDates] = useState({
    startDate: initialDates.startDate,
    endDate: initialDates.endDate,
  });

  useEffect(() => {
    if (
      cartDates &&
      shoppingCartRentalDates &&
      isValid(shoppingCartRentalDates.startDate)
    ) {
      setCalendarDates({
        startDate: shoppingCartRentalDates.startDate,
        endDate: shoppingCartRentalDates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCartRentalDates]);

  useEffect(() => {
    if (siteDates && siteRentalDates && isValid(siteRentalDates.startDate)) {
      setCalendarDates({
        startDate: siteRentalDates.startDate,
        endDate: siteRentalDates.endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteRentalDates]);

  const handleDateRangeChange = (item: any): void => {
    const startDate = item[0];
    const endDate = item[1];
    const hasStartDate = Boolean(startDate);
    const hasEndDate = Boolean(endDate);

    let firstRangeSelection = hasStartDate ? 0 : 1;
    let secondRangeSelection = hasEndDate ? 0 : 1;

    const dateRangeData = {
      startDate,
      endDate: endDate ? DatesHelper.setHoursToZero(endDate) : undefined,
    };

    if (hasStartDate && hasEndDate) {
      const rentalLength = DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
        startDate,
        endDate,
      );

      if (rentalLength === 0) {
        firstRangeSelection = 1;
        secondRangeSelection = 1;
        dateRangeData.startDate = undefined;
        dateRangeData.endDate = undefined;
      }
    }

    handleOnRangeFocusChange([firstRangeSelection, secondRangeSelection]);
    setCalendarDates(dateRangeData);
    checkRangeSelectedIsValid(dateRangeData);
  };

  const submitRentalDates = (): void => {
    setIsDatePickerOpen(false);

    if (isValid(calendarDates.startDate) && isValid(calendarDates.endDate)) {
      if (!rangeSelectionComplete || !rangeSelectionIsValid) {
        return;
      }

      TrackingService.trackCalendarDatesConfirmed(
        location,
        productsForTracking,
        calendarDates,
      );

      if (cartDates) {
        setShoppingCartRentalDates({
          startDate: calendarDates.startDate,
          endDate: calendarDates.endDate,
        });
      } else if (siteDates) {
        setSiteRentalDates({
          startDate: calendarDates.startDate,
          endDate: calendarDates.endDate,
        });
      }
    }
  };

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children, {
        rentalDates: DatesHelper.getInitialRentalDatesValues(
          siteRentalDates,
          shoppingCartRentalDates,
          cartDates,
          siteDates,
        ),
      } as never)
    : children;

  const isMobile = useIsMobile();

  const DatePickerToggle = (
    <Box
      as="button"
      type="button"
      width={fullWidth ? "100%" : "auto"}
      onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
    >
      {childrenWithProps}
    </Box>
  );

  const datePickerProps = {
    isOpen: isDatePickerOpen,
    submitRentalDates,
    toggleComponent: DatePickerToggle,
  };

  const clearCalendarDates = (): void => {
    TrackingService.trackCalendarDatesCleared(location, productsForTracking);
    setCalendarDates({
      startDate: null,
      endDate: null,
    });
  };

  const CalendarContentComponent = (
    <CalendarContent
      handleDateRangeChange={handleDateRangeChange}
      calendarDates={calendarDates}
      clearCalendarDates={clearCalendarDates}
      submitRentalDates={submitRentalDates}
      products={productsForCalendar || productsForTracking}
    />
  );

  return isMobile ? (
    <MobileDatePicker {...datePickerProps}>
      {CalendarContentComponent}
    </MobileDatePicker>
  ) : (
    <DesktopDatePicker {...datePickerProps}>
      {CalendarContentComponent}
    </DesktopDatePicker>
  );
};

DatePicker.defaultProps = {
  cartDates: false,
  siteDates: false,
  fullWidth: false,
  isOpen: undefined,
  setIsOpen: undefined,
  productsForCalendar: undefined,
};

// eslint-disable-next-line import/no-default-export
export default DatePicker;
