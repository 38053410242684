import { useTheme } from "@/revive/hooks/useTheme";
import { ReviveIconAttributes } from "@/revive/theme";

type GenericIconProps = {
  readonly name: string;
  readonly size: number;
  readonly icon: ReviveIconAttributes;
};

export function GenericIcon({ name, size, icon, ...rest }: GenericIconProps) {
  const { partnerName } = useTheme();

  return (
    <svg
      {...rest}
      width={size}
      height={size}
      viewBox={icon.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`icon-${name}-${partnerName}`}
    >
      <title>{name}</title>
      <g>{icon?.paths?.map((path) => <path key={path.d} {...path} />)}</g>
    </svg>
  );
}
