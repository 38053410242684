const unavailablePill = {
  baseStyle: {
    pb: "2px",
    pt: "2px",
    px: "13px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "4px",
    backgroundColor: "error",
    color: "white",
    fontFamily: "normal",
  },
  variants: {
    outOfStock: {
      // TODO: we can't just change to grays without knowing that every partner have these grays
      backgroundColor: "#E3E8EF", // TODO: maybe gray.200
      color: "#A5A4B1", // TODO: maybe gray.400
    },
    lessAvailability: {
      backgroundColor: "error",
      color: "white",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default unavailablePill;
