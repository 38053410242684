/* eslint-disable sonarjs/no-duplicate-string */
const faqCategorySection = {
  parts: ["title", "subtitle", "card"],
  baseStyle: {
    title: {
      color: "primary.600",
      letterSpacing: "0.08px",
      fontFamily: "normal",
      mb: 1,
    },
    subtitle: {
      color: "primary.600",
      fontWeight: "bold",
      fontFamily: "title",
      letterSpacing: "3px",
      textTransform: "uppercase",
    },
    card: {
      fontFamily: "title",
      fontSize: "18px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "primary.600",
      backgroundColor: "white",
      letterSpacing: "-.02em",
      border: "1px solid",
      borderColor: "gray.200",
      borderRadius: "4px",
      _hover: {
        backgroundColor: "gray.50",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqCategorySection;
