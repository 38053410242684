import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

import { NavigationItem } from "@/dep_queries/cms/FetchNavigationItems";

import Container from "@/dep_components/Layout/Container";

import HamburgerButton from "./HamburgerButton";
import { MobileNavigation } from "./MobileNavigation";

import { CategoryType } from "../../../dep_types";
import Logo from "../../Logo/Logo";
import MenuItemShoppingCart from "../MenuItemShoppingCart";
import SearchModule from "../Search/SearchModule";

interface MobileHeaderProps {
  categoryGroups: Record<string, CategoryType[]>;
  navigationItems?: NavigationItem[];
}

export function MobileHeader({
  categoryGroups,
  navigationItems,
}: MobileHeaderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Container
        display={{ base: "flex", lg: "none" }}
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
        color="black"
      >
        <Flex align="center">
          <HamburgerButton onClick={onOpen} />
          <Logo />
        </Flex>
        <Flex align="center">
          <SearchModule />
          <MenuItemShoppingCart />
        </Flex>
      </Container>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent height="100%">
          <DrawerCloseButton zIndex="overlay" />
          <DrawerBody paddingLeft={5} paddingRight={8} paddingY={8}>
            <MobileNavigation
              categoryGroups={categoryGroups}
              navigationItems={navigationItems}
              onCloseDrawer={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
