import { Flex, Text } from "@chakra-ui/react";
import Link from "next/link";
import React, { FC } from "react";

import TrackingService from "../../../dep_services/tracking/trackingService";

type Props = {
  menuItem: string;
  link: string;
  shopAllLabel: string;
  onCloseDrawer: () => void;
};

const ShopAllProductsLink: FC<Props> = ({
  menuItem,
  link,
  shopAllLabel,
  onCloseDrawer,
}) => (
  <Link href={link} passHref prefetch={false}>
    <Flex
      as="a"
      direction="column"
      onClick={() => {
        onCloseDrawer();

        TrackingService.trackNavigationClick(menuItem, "Shop all products", "");
      }}
    >
      <Text size="md" fontWeight="medium">
        {shopAllLabel}
      </Text>
    </Flex>
  </Link>
);

// eslint-disable-next-line import/no-default-export
export default ShopAllProductsLink;
