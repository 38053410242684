import linkedButtonDataMapper from "@/dep_helpers/content/mappers/components/linkedButton";
import mediaDataMapper from "@/dep_helpers/content/mappers/components/media";
import APIBrandPartnershipHeroType from "@/dep_types/content/api/components/brandPartnershipHero";
import { BrandPartnershipHeroType } from "@/dep_types/content/sections";

import textMultipleMapper from "./textMultiple";

const brandPartnershipHero = (
  apiBrandPartnershipHeroData: APIBrandPartnershipHeroType,
): BrandPartnershipHeroType => ({
  componentKey:
    apiBrandPartnershipHeroData.__component || "brandPartnershipHero",
  id: apiBrandPartnershipHeroData.id,
  title: apiBrandPartnershipHeroData.title,
  description: apiBrandPartnershipHeroData.description || null,
  button: apiBrandPartnershipHeroData.button
    ? linkedButtonDataMapper(apiBrandPartnershipHeroData.button)
    : null,
  image: {
    mobile: apiBrandPartnershipHeroData.image.mobileImage.data
      ? mediaDataMapper(
          apiBrandPartnershipHeroData.image.mobileImage.data.attributes,
        )
      : null,
    tablet: apiBrandPartnershipHeroData.image.tabletImage.data
      ? mediaDataMapper(
          apiBrandPartnershipHeroData.image.tabletImage.data.attributes,
        )
      : null,
    desktop: apiBrandPartnershipHeroData.image.desktopImage.data
      ? mediaDataMapper(
          apiBrandPartnershipHeroData.image.desktopImage.data.attributes,
        )
      : null,
  },
  brandImages: apiBrandPartnershipHeroData.brandImages?.data
    ? apiBrandPartnershipHeroData.brandImages.data.map((image) =>
        mediaDataMapper(image.attributes),
      )
    : [],
  categoriesEnabledFor: apiBrandPartnershipHeroData.categoriesEnabledFor
    ? apiBrandPartnershipHeroData.categoriesEnabledFor.map((category) =>
        textMultipleMapper(category),
      )
    : [],
  active: true,
});

// eslint-disable-next-line import/no-default-export
export default brandPartnershipHero;
