import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  clearCalendarDates(): void;
};

const CalendarClearDatesBtn: FC<Props> = ({ clearCalendarDates }: Props) => (
  <Box
    as="button"
    type="button"
    onClick={() => clearCalendarDates()}
    marginRight={{
      base: 0,
      md: 6,
    }}
    fontFamily="normal"
    fontSize="14px"
    fontWeight="medium"
    letterSpacing="-.02em"
  >
    Clear Dates
  </Box>
);

// eslint-disable-next-line import/no-default-export
export default CalendarClearDatesBtn;
