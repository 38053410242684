const priceContainer = {
  parts: ["originalLabel"],
  baseStyle: {
    originalLabel: {
      fontWeight: "normal",
      letterSpacing: "0.4px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default priceContainer;
