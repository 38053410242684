const popover = {
  variants: {
    fullWidth: {
      popper: {
        maxWidth: "unset",
        width: "unset",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default popover;
