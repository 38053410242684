import { Flex } from "@chakra-ui/react";
import React, { FC } from "react";

import ShoppingCartIcon from "@/dep_components/icons/ShoppingCartIcon";
import ShoppingCartYetiIcon from "@/dep_components/icons/Yeti/ShoppingCartIcon";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { useArriveContext } from "../context";

const MenuItemShoppingCart: FC = () => {
  const { cartItems, openShoppingCart } = useArriveContext();

  const { isYeti } = useWhitelabelContext();
  const totalQuantity = cartItems.reduce(
    (qty: number, item: { quantity: number }) => qty + item.quantity,
    0,
  );

  return (
    <Flex
      as="button"
      onClick={openShoppingCart}
      position="relative"
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      marginLeft={{
        base: 3,
        md: 5,
      }}
      cursor="pointer"
      transition="color .25s ease"
      _hover={{
        color: "gray.500",
      }}
    >
      {isYeti ? (
        <>
          <ShoppingCartYetiIcon />
          <Flex
            position="absolute"
            color="primary.600"
            fontSize="xs"
            fontWeight="bold"
            fontFamily="title"
            left="50%"
            top="50%"
            translateX="-50%"
            translateY="-25%"
            transform="auto"
          >
            {totalQuantity}
          </Flex>
        </>
      ) : (
        <>
          <ShoppingCartIcon />
          <Flex
            position="absolute"
            justifyContent="center"
            alignItems="center"
            top="calc(50% - 13px)"
            right="calc(50% - 18px)"
            width="14px"
            height="14px"
            backgroundColor="primary.600"
            borderRadius="50%"
            color="white"
            fontSize="8px"
            fontWeight="medium"
            fontFamily="normal"
          >
            {totalQuantity}
          </Flex>
        </>
      )}
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default MenuItemShoppingCart;
