const heading = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "medium",
    letterSpacing: "-.04em",
  },
  sizes: {
    hero_subHeading: {
      fontSize: {
        base: "16px",
        md: "18px",
      },
      lineHeight: {
        base: "26px",
        md: "28px",
      },
    },
    xl: {
      fontSize: ["42px", null, "96px"],
      lineHeight: [1.15, null, 1],
    },
    lg: {
      fontSize: ["26px", null, "36px", "48px"],
      lineHeight: [1.25, null, 1.32, 1.25],
    },
    md: {
      fontSize: ["18px", null, "24px", "42px"],
      lineHeight: [1.57, null, 1.15],
    },
    sm: {
      fontSize: ["21px", null, "36px"],
      lineHeight: [1.35, null, 1.32],
    },
    xs: {
      fontSize: ["16px", null, "26px"],
      lineHeight: [1.48, null, 1.25],
    },
  },
  variants: {
    black: {
      fontWeight: "black",
    },
    bold: {
      fontWeight: "bold",
    },
    medium: {
      fontWeight: "medium",
    },
    accordionTitle: {
      fontWeight: "bold",
    },
    faqTitle: {
      fontSize: "18px",
      fontFamily: "normal",
      fontWeight: "bold",
    },
    faqQuestion: {
      fontSize: "15px",
      fontFamily: "normal",
      fontWeight: "bold",
    },
    orderConfirmationTitle: {
      fontWeight: {
        base: "bold",
        md: "bold",
      },
      fontSize: {
        base: "2xl",
        md: "5xl",
      },
    },
    orderConfirmationSubtitle: {
      fontWeight: {
        base: "medium",
        md: "medium",
      },
      fontSize: "lg",
    },
    orderConfirmationFedexTitle: {
      fontWeight: {
        base: "medium",
        md: "medium",
      },
      fontSize: {
        base: "sm",
        md: "md",
      },
    },
    orderConfirmationShippedToTitle: {
      fontWeight: {
        base: "medium",
        md: "medium",
      },
      fontSize: {
        base: "xs",
        md: "sm",
      },
      color: "monoSecondaryText1",
    },
    orderDetailsOrderNumber: {
      fontWeight: {
        base: "bold",
        md: "medium",
      },
      fontSize: {
        base: "2xl",
        lg: "3xl",
      },
    },
    orderDetailsHeading: {
      pb: {
        base: 4,
        md: 6,
      },
      fontSize: {
        base: "xl",
        lg: "2xl",
      },
    },
    orderDetailsDate: {
      fontSize: {
        base: "sm",
        lg: "xl",
      },
    },
    orderDetailsBoxTitle: {
      fontSize: "xl",
    },
    orderDetailsItem: {
      fontSize: "md",
    },
  },
  defaultProps: {
    size: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default heading;
