const inputText = {
  parts: ["label", "input", "phoneInput"],
  baseStyle: {
    label: {
      fontSize: "14px",
      color: "black",
      fontFamily: "normal",
      fontWeight: "medium",
    },
    input: {
      borderRadius: "4px",
      fontSize: "14px",
      height: "48px",
      fontFamily: "normal",
      fontWeight: "light",
      _placeholder: { color: "gray.500" },
    },
    phoneInput: {
      borderColor: "gray.200",
      borderRadius: "4px",
      fontSize: "14px",
      height: "48px",
      fontFamily: "normal",
      fontWeight: "light",
      _placeholder: { color: "gray.500" },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default inputText;
