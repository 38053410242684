export const brandValueProp = {
  parts: ["section", "title", "body", "button", "icon"],
  baseStyle: {
    section: {
      color: "gray.500",
    },
  },
  variants: {
    primary: {
      title: {
        letterSpacing: "2px",
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "42px",
        textTransform: "uppercase",
      },
      body: {
        fontFamily: "normal",
        fontSize: "20px",
        letterSpacing: 0,
        lineHeight: "30px",
        fontWeight: "normal",
      },
      button: {
        display: "flex",
        justifyContent: {
          base: "left",
          md: "center",
        },
      },
      icon: {
        svg: {
          color: "white",
        },
      },
    },
    secondary: {
      title: {
        letterSpacing: "2px",
        fontWeight: "700",
        fontSize: "30px",
        lineHeight: "42px",
        textTransform: "uppercase",
      },
      body: {
        fontFamily: "normal",
        fontSize: "20px",
        letterSpacing: 0,
        lineHeight: "30px",
        fontWeight: "normal",
      },
      button: {},
    },
  },
};
