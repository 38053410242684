import { ClearCartModalContent } from "@/features/shared/Modals/ModalContent/ClearCartModalContent";
import { ModalContent } from "@/hooks/useModalManager";

export const componentModalContent: ModalContent[] = [
  {
    modalId: "clear-cart",
    size: "md",
    component: ClearCartModalContent,
  },
];
