/* eslint-disable @typescript-eslint/no-explicit-any */
import { createClient, RedisClientType } from "redis";

let cacheClient: RedisClientType;

const createRedisClient = (): RedisClientType =>
  createClient({
    url: `redis://${process.env.REDIS_HOST}:${process.env.REDIS_PORT}`,
  }) as RedisClientType;

const getRedisClient = async (): Promise<any> => {
  if (!cacheClient) {
    cacheClient = createRedisClient();
  }

  if (!cacheClient.isOpen) {
    await cacheClient.connect();
  }

  return cacheClient;
};

// eslint-disable-next-line import/no-default-export
export default getRedisClient;
