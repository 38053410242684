import {
  addDays,
  differenceInDays,
  parse,
  format,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";

import { RentalDatesLabelsType, RentalDatesType } from "@/dep_types";

const formatDate = (
  rentalDates: RentalDatesType,
  formatString: string,
): RentalDatesLabelsType => {
  const startDate =
    rentalDates.startDate &&
    format(rentalDates.startDate as Date, formatString);
  const endDate =
    rentalDates.endDate && format(rentalDates.endDate as Date, formatString);

  return {
    startDate,
    endDate,
  };
};

const defaultStartDate = addDays(new Date(), 10);
const defaultEndDate = addDays(new Date(), 12);
const defaultRentalDates = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
};

const maxEndRentalDate = addDays(new Date(), 365);

// eslint-disable-next-line import/no-default-export
export default class DatesHelper {
  static minRentalDays: number | undefined = undefined;

  static defaultStartDate = defaultStartDate;

  static defaultEndDate = defaultEndDate;

  static minEndRentalDate = addDays(new Date(), DatesHelper.minRentalDays || 0);

  static maxEndRentalDate = maxEndRentalDate;

  static minRentalDaysRange = 2;

  static maxRentalDaysRange = Infinity;

  static parseFromServerDate = (date: string): Date =>
    parse(date, "yyyy-MM-dd", new Date());

  static parseGlobalRentalDatesToOrderAmplitudeLogDates(
    rentalDates: RentalDatesType,
  ): RentalDatesType {
    return formatDate(rentalDates, "MM.dd.yyyy");
  }

  static parseGlobalRentalDatesToCollectionDates(
    rentalDates: RentalDatesType,
  ): RentalDatesType {
    return formatDate(rentalDates, "MM/dd/yyyy");
  }

  static parseGlobalRentalDatesToDisplayDate(
    rentalDates: RentalDatesType,
  ): RentalDatesLabelsType {
    return formatDate(rentalDates, "MMMM dd, yyyy");
  }

  static parseDateToAPIParam(date: Date): string | null {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month > 9 ? month : `0${month}`;
    const formattedDay = day > 9 ? day : `0${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  static parseGlobalRentalDatesToAPIParams(
    rentalDates: RentalDatesType,
  ): RentalDatesLabelsType {
    return {
      startDate: DatesHelper.parseDateToAPIParam(rentalDates.startDate as Date),
      endDate: DatesHelper.parseDateToAPIParam(rentalDates.endDate as Date),
    };
  }

  static getInitialDates(
    siteRentalDates: RentalDatesType,
    shoppingCartRentalDates: RentalDatesType,
    cartDates?: boolean,
    siteDates?: boolean,
  ): RentalDatesType {
    const initialDates: RentalDatesType = {};

    if (cartDates) {
      initialDates.startDate = shoppingCartRentalDates
        ? shoppingCartRentalDates.startDate
        : null;
      initialDates.endDate = shoppingCartRentalDates
        ? shoppingCartRentalDates.endDate
        : null;
    } else if (siteDates) {
      initialDates.startDate = siteRentalDates
        ? siteRentalDates.startDate
        : null;
      initialDates.endDate = siteRentalDates ? siteRentalDates.endDate : null;
    }

    return initialDates;
  }

  static getInitialRentalDatesValues(
    siteRentalDates: RentalDatesType,
    shoppingCartRentalDates: RentalDatesType,
    cartDates?: boolean,
    siteDates?: boolean,
    productDates?: boolean,
  ): RentalDatesType | null {
    if (cartDates) {
      return shoppingCartRentalDates;
    }
    if (siteDates) {
      if (productDates && (!siteRentalDates || !siteRentalDates.startDate)) {
        return defaultRentalDates;
      }

      return siteRentalDates;
    }

    return null;
  }

  static rentalDatesAreEqual(
    rentalDatesLeft: RentalDatesType,
    rentalDatesRight: RentalDatesType,
  ): boolean {
    const left = formatDate(rentalDatesLeft, "MM-dd-yyyy");
    const right = formatDate(rentalDatesRight, "MM-dd-yyyy");

    return left.startDate === right.startDate && left.endDate === right.endDate;
  }

  static getDifferenceBetweenDatesInDaysOrDefault(
    start: Date,
    end: Date,
  ): number {
    return differenceInDays(end || defaultEndDate, start || defaultStartDate);
  }

  static datesStringToRentalDates(rentalDateString: RentalDatesLabelsType): {
    startDate: Date;
    endDate: Date;
  } {
    return {
      startDate: parseISO(rentalDateString.startDate as string),
      endDate: parseISO(rentalDateString.endDate as string),
    };
  }

  static datesStringToDate(dateString: string): Date {
    return parseISO(dateString);
  }

  static parseSingleDateStringToShoppingCartDate(date: Date): string {
    return format(date, "MMMM dd, yyyy");
  }

  static parseRentalDatesToAPIParamsOrDefault(
    rentalDates: RentalDatesType,
  ): RentalDatesLabelsType {
    const currentDate =
      rentalDates && rentalDates.startDate ? rentalDates : defaultRentalDates;

    return DatesHelper.parseGlobalRentalDatesToAPIParams(currentDate);
  }

  static checkRangeSelectedIsValid = (
    startDate: Date,
    endDate: Date,
  ): boolean => {
    const datesAreValid = isValid(startDate) && isValid(endDate);

    if (!datesAreValid) {
      return false;
    }

    const rangeInDays = DatesHelper.getDifferenceBetweenDatesInDaysOrDefault(
      startDate,
      endDate,
    );

    return (
      rangeInDays >= DatesHelper.minRentalDaysRange &&
      rangeInDays <= DatesHelper.maxRentalDaysRange
    );
  };

  static rentalDatesAreValid(rentalDate: RentalDatesType): boolean {
    return (
      Boolean(rentalDate) &&
      Boolean(rentalDate.startDate) &&
      Boolean(rentalDate.endDate)
    );
  }

  static rentalDatesArePartiallyValid(rentalDate: RentalDatesType): boolean {
    return (
      Boolean(rentalDate) &&
      (Boolean(rentalDate.startDate) || Boolean(rentalDate.endDate))
    );
  }

  static setHoursToZero(dateTime: Date): Date {
    return setSeconds(setMinutes(setHours(dateTime, 0), 0), 0);
  }
}
