import PartnerBrandPartnershipHero from "./components/BrandPartnershipHero";
import PartnerButton from "./components/Button";
import PartnerCartItem from "./components/CartItem";
import PartnerCategoryTiles from "./components/CategoryTiles";
import PartnerColorButton from "./components/ColorButton";
import PartnerCustomText from "./components/CustomText";
import PartnerDropDownList from "./components/DropDownList";
import PartnerFAQCategorySection from "./components/FAQCategorySection";
import PartnerFAQContactUs from "./components/FAQContactUs";
import PartnerFAQValueProp from "./components/FAQValueProp";
import PartnerFooter from "./components/Footer";
import PartnerFormSectionHeading from "./components/FormSectionHeading";
import PartnerHeading from "./components/Heading";
import PartnerIconValueProp from "./components/IconValueProp";
import PartnerInputText from "./components/InputText";
import PartnerLinkButton from "./components/LinkButton";
import PartnerMediaValueProp from "./components/MediaValueProp";
import { menuItem } from "./components/MenuItem";
import PartnerModalContent from "./components/ModalContent";
import PartnerOrderStatusStep from "./components/OrderStatusStep";
import PartnerPDPToggleButton from "./components/PDPToggleButton";
import PartnerPriceContainer from "./components/PriceContainer";
import PartnerSelect from "./components/Select";
import PartnerSiteLink from "./components/SiteLink";
import PartnerText from "./components/Text";
import PartnerTitle from "./components/Title";
import PartnerVariantSelector from "./components/VariantSelector";
import { brandValueProp } from "./components/brandValueProp";
import { productCard } from "./components/productCard";
import { topTextBanner } from "./components/topTextBanner";

const citizenryComponents = {
  PartnerButton,
  brandValueProp,
  PartnerBrandPartnershipHero,
  PartnerCategoryTiles,
  PartnerCartItem,
  PartnerColorButton,
  PartnerCustomText,
  PartnerDropDownList,
  PartnerFAQCategorySection,
  PartnerFAQContactUs,
  PartnerFAQValueProp,
  PartnerFormSectionHeading,
  PartnerFooter,
  PartnerHeading,
  PartnerInputText,
  PartnerIconValueProp,
  PartnerLinkButton,
  PartnerMediaValueProp,
  PartnerMenuItem: menuItem,
  PartnerModalContent,
  PartnerOrderStatusStep,
  PartnerPDPToggleButton,
  productCard,
  PartnerPriceContainer,
  PartnerSelect,
  PartnerSiteLink,
  PartnerText,
  PartnerTitle,
  topTextBanner,
  PartnerVariantSelector,
};

// eslint-disable-next-line import/no-default-export
export default citizenryComponents;
