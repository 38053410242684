const productConfigHeader = {
  parts: ["tooltipLabel"],
  baseStyle: {
    tooltipLabel: {
      textDecoration: "line-through",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default productConfigHeader;
