const title = {
  baseStyle: {
    fontFamily: "normal",
    fontWeight: "black",
    color: "black",
  },
  sizes: {
    hero: {
      fontSize: {
        base: "42px",
        md: "96px",
      },
      lineHeight: {
        base: "48px",
        md: "96px",
      },
    },
    xl: {
      fontSize: {
        base: "45px",
        lg: "64px",
      },
    },
    lg: {
      fontSize: {
        base: "45px",
        lg: "64px",
      },
    },
    md: {
      fontSize: {
        base: "32px",
        lg: "45px",
      },
      lineHeight: "50px",
    },
    sm: {
      fontSize: {
        base: "28px",
        lg: "36px",
      },
    },
  },
  variants: {
    hero: {
      fontWeight: "medium",
      color: "white",
      letterSpacing: "-.03em",
    },
    collection: {
      fontSize: {
        base: "28px",
        lg: "40px",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};

// eslint-disable-next-line import/no-default-export
export default title;
