export const parts = ["productName", "productPrice", "productPricePrefix"];

export const productCard = {
  parts,
  baseStyle: {
    productPrice: {
      fontSize: "xl",
    },
  },
  variants: {
    vertical: {},
    horizontal: {
      productName: {
        fontWeight: "normal",
        fontSize: "sm",
      },
    },
  },
};
