import { resalePartnerIds } from "@/dep_constants/partnerIds";
import ProductsService from "@/dep_services/productService";
import productSaleType from "@/dep_types/commerce-api/productSaleType";
import { RecommendedProductsType } from "@/dep_types/content/sections";
import RecommendedProductTypeEnum from "@/dep_types/content/sections/recommendedProductType";

const extendRecommendedProductsSection = async (
  partnerId: string,
  section: RecommendedProductsType,
): Promise<RecommendedProductsType> => {
  if (section.collectionIds?.length > 0) {
    const productConfig = {
      partnerId,
      collectionIds: section.collectionIds,
      pageNumber: 1,
      pageSize: 6,
      productSaleTypes:
        resalePartnerIds.indexOf(partnerId) >= 0
          ? [productSaleType.RESALE]
          : [productSaleType.BOTH],
    };
    const collectionProducts =
      await ProductsService.fetchProductsForCollection(productConfig);

    section.products = collectionProducts.data?.products;
  } else if (section.productType !== RecommendedProductTypeEnum.CROSS_SELL) {
    const popularProducts = await ProductsService.getPopularProducts(partnerId);

    section.products = popularProducts;
  }

  return section;
};

// eslint-disable-next-line import/no-default-export
export default extendRecommendedProductsSection;
