/* eslint-disable sonarjs/no-duplicate-string */
const orderStatusStep = {
  parts: ["line", "number", "text"],
  baseStyle: {
    line: {},
    number: {
      fontFamily: "normal",
      fontWeight: "bold",
      letterSpacing: "1px",
      borderRadius: "4px",
    },
    text: {
      fontFamily: "normal",
      fontWeight: 300,
      letterSpacing: "1px",
    },
  },
  variants: {
    inactive: {
      line: {
        backgroundColor: "primary.600",
      },
      number: {
        backgroundColor: "#fff",
        border: "1px solid",
        borderColor: "primary.600",
        color: "primary.600",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default orderStatusStep;
