const iconValueProp = {
  parts: ["container", "textContainer", "title", "description", "icon"],
  baseStyle: {
    container: {},
    title: {
      fontFamily: "normal",
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    description: {
      fontFamily: "normal",
      fontWeight: "normal",
      letterSpacing: "0.16px",
    },
    icon: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default iconValueProp;
