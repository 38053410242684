/* eslint-disable class-methods-use-this */
import TrackingStrategy from "./trackingStrategy";

import OrderLog from "../../../dep_helpers/order/OrderLog";

type AwinType = {
  Tracking: {
    Sale: {
      amount: string;
      channel: string;
      orderRef?: string;
      parts: string;
      currency: string;
      voucher: string;
      test: string;
    };
    run: () => void;
  };
};

declare const AWIN: AwinType;
const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT === "local" || "development";

// eslint-disable-next-line import/no-default-export
export default class AwinTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    typeof window !== "undefined" &&
    typeof AWIN !== "undefined" &&
    Boolean(AWIN);

  trackAddToCart(): void {
    // do not track
  }

  trackConversion(
    _partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void {
    const { rentalTotal, resaleTotal } = orderLog;

    AWIN.Tracking.Sale = {} as AwinType["Tracking"]["Sale"];
    AWIN.Tracking.Sale.amount = (rentalTotal + resaleTotal).toFixed(2);
    AWIN.Tracking.Sale.channel = "aw";
    AWIN.Tracking.Sale.orderRef = rentalOrderId || resaleOrderId;
    AWIN.Tracking.Sale.parts = `DEFAULT:${(rentalTotal + resaleTotal).toFixed(
      2,
    )}`;
    AWIN.Tracking.Sale.currency = "USD";
    AWIN.Tracking.Sale.voucher = "";
    //
    // test should be populated with "0" when tracking is in live mode or alternatively "1" if in test mode.
    // When set to the latter, the incoming tracking requests will not be processed
    AWIN.Tracking.Sale.test = isDev ? "1" : "0";
    AWIN.Tracking.run();
  }

  trackEvent(): void {
    // do not track
  }

  trackPageView(): void {
    // do not track
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackProductPageView(): void {
    // do not track
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackStartCheckout(): void {
    // do not track
  }

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }
}
