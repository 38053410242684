/* eslint-disable sonarjs/no-duplicate-string */
const mediaValueProp = {
  parts: ["title", "body", "listItem"],
  baseStyle: {
    title: {
      fontFamily: "title",
      letterSpacing: "3px",
      fontWeight: "bold",
      color: "primary.600",
    },
    body: {
      fontFamily: "normal",
      color: "primary.600",
    },
    listItem: {
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default mediaValueProp;
