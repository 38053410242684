import { Text } from "@chakra-ui/react";
import Link from "next/link";
import React, { FC } from "react";

type Props = {
  id: string | number;
  categoryId: string | number;
  name: string;
  group: string;
  onClick(): void;
};

const MenuCollectionLink: FC<Props> = ({
  categoryId,
  id,
  name,
  group,
  onClick,
}: Props) => (
  <Link
    href={`/group/${group}/category/${categoryId}?collections=${id}`}
    passHref
    prefetch={false}
  >
    <Text
      as="a"
      size="md"
      cursor="pointer"
      onClick={onClick}
      _hover={{
        borderBottom: "1px solid",
      }}
      _focus={{
        borderBottom: "1px solid",
        outline: "none",
      }}
    >
      {name}
    </Text>
  </Link>
);

// eslint-disable-next-line import/no-default-export
export default MenuCollectionLink;
