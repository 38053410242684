/* eslint-disable class-methods-use-this */
import TrackingStrategy from "./trackingStrategy";

import OrderLog from "../../../dep_helpers/order/OrderLog";

export class CriteoTrackingStrategy extends TrackingStrategy {
  initialized = (): boolean =>
    // @ts-expect-error TODO: Investigate this
    typeof window !== "undefined" && Boolean(window.criteo_q);

  trackPageView(): void {
    // do not track
  }

  trackScrollDepth(): void {
    // do not track
  }

  trackProductPageView(): void {
    // @ts-expect-error TODO: Investigate this
    window.criteo_q = window.criteo_q || [];

    const deviceType = /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent,
        )
      ? "m"
      : "d";

    // @ts-expect-error TODO: Investigate this
    window.criteo_q.push(
      { event: "setSiteType", type: deviceType },

      { event: "viewItem", item: 1 },
    );
  }

  trackProductOutOfStock(): void {
    // do not track
  }

  trackCollectionPageView(): void {
    // do not track
  }

  trackProductClick(): void {
    // do not track
  }

  trackCalendarOpened(): void {
    // do not track
  }

  trackCalendarDatesCleared(): void {
    // do not track
  }

  trackCalendarDatesConfirmed(): void {
    // do not track
  }

  trackNavigationClick(): void {
    // do not track
  }

  trackAddToCart = (): void => {
    // do not track
  };

  trackConversion = (
    _partnerId: string,
    orderLog: OrderLog,
    rentalOrderId?: string,
    resaleOrderId?: string,
  ): void => {
    const { customerData } = orderLog;

    // @ts-expect-error TODO: Investigate this
    window.criteo_q = window.criteo_q || [];

    const customerEmail = customerData.emailAddress;
    const totalPrice = orderLog.rentalTotal + orderLog.resaleTotal;

    // @ts-expect-error TODO: Investigate this
    window.criteo_q.push(
      {
        event: "setEmail",
        email: customerEmail,
        hash_method: "none",
      },

      {
        event: "trackTransaction",
        id: rentalOrderId ?? resaleOrderId,
        item: {
          id: 1,
          price: totalPrice,
          quantity: 1,
        },
      },
    );
  };

  trackEvent = (): void => {
    // do not track
  };

  trackOrderEdited(): void {
    // do not track
  }

  trackOrderCancelled(): void {
    // do not track
  }

  trackStartCheckout(): void {
    // do not track
  }
}
