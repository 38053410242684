import { Flex } from "@chakra-ui/react";
import React, { FC } from "react";

type Props = {
  isExpanded: boolean;
  toggleExpand(): void;
};

const ExpandSetButton: FC<Props> = ({ isExpanded, toggleExpand }: Props) => (
  <Flex
    onClick={toggleExpand}
    p="10px"
    height="55px"
    borderRadius="10px"
    alignItems="center"
    _hover={{
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    }}
  >
    <Flex pt="4px" fontFamily="normal" fontWeight="medium">
      {isExpanded ? "Hide" : "Expand Set"}
    </Flex>
    <Flex
      as="span"
      ml="10px"
      p="5px"
      width="30px"
      height="30px"
      justifyContent="center"
      alignItems="center"
      boxShadow="md"
      border="1px solid"
      borderColor="#f1f1f1"
      borderRadius="50px"
    >
      {isExpanded ? "-" : "+"}
    </Flex>
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default ExpandSetButton;
