const cartItem = {
  parts: ["brandName", "productName", "variantText", "priceContainer"],
  baseStyle: {
    brandName: {
      color: "black",
    },
    productName: {
      color: "black",
    },
    variantText: {
      color: "black",
    },
    priceContainer: {
      ml: "8px",
      mt: "5px",
    },
  },
  variants: {
    unavailable: {
      brandName: {
        color: "#B8BEC3",
      },
      productName: {
        color: "#B8BEC3",
      },
      variantText: {
        color: "#B8BEC3",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default cartItem;
