/* eslint-disable @typescript-eslint/no-explicit-any */
import { cmsPartnerIds, partnerNames } from "@/dep_constants/partnerIds";
import getSWRCacheValue from "@/dep_helpers/cache/getSWRCacheValue";
import generateCmsQueryString from "@/dep_helpers/content/generateCmsQueryString";
import { performCmsFetchRequest } from "@/dep_helpers/content/performCmsFetchRequest";
import APINotFoundPageResponseType from "@/dep_types/content/api/response/notFoundPageResponse";
import { ResponseErrorType } from "@/dep_types/content/error";
import { NotFoundPageResponseType } from "@/dep_types/content/notFoundPage";

import handleContentError from "../../handleContentError";
import notFoundPageDataMapper from "../../mappers/pages/notFoundPage";

const getNotFoundPageData = async (
  partnerId: string,
  isPreviewMode: boolean,
): Promise<NotFoundPageResponseType> => {
  const relationsToPopulate = [
    "searchButton",
    "homeButton",
    "sections.button",
    "sections.tiles.image",
    "sections.faqs",
    "sections.faq_group.faqs.categoriesEnabledFor",
    "sections.collectionIds",
  ];

  const cmsPartnerId = cmsPartnerIds[partnerId];
  const cacheKey = `ecomm-whitelabel-not-found-page-${cmsPartnerId}`;

  const query = generateCmsQueryString(
    partnerId,
    relationsToPopulate,
    true,
    isPreviewMode,
  );

  const path = `/not-found-pages?${query}`;
  const requestCallback = (): Promise<any> => performCmsFetchRequest(path);

  const partnerName = partnerNames[partnerId];
  const defaultErrorMessage =
    "No error was returned by the CMS API -- Unknown issue occurred.";
  const errorPrefix = `There was an error getting the CMS data for the not found page for ${partnerName} with message`;

  const requestData: APINotFoundPageResponseType & ResponseErrorType =
    await getSWRCacheValue(
      cacheKey,
      requestCallback,
      errorPrefix,
      defaultErrorMessage,
      isPreviewMode,
    );

  // TryCatch in the event that requestData is not what we expect
  // This should never happen because of our getSWRCacheValue
  // implementation but let's handle it just in case
  try {
    const notFoundPageData = requestData.data[0];

    return notFoundPageDataMapper(notFoundPageData, requestData.error);
  } catch (error) {
    return handleContentError(error);
  }
};

// eslint-disable-next-line import/no-default-export
export default getNotFoundPageData;
