const title = {
  baseStyle: {
    textTransform: "uppercase",
    fontFamily: "title",
    letterSpacing: "3px",
    fontWeight: 600,
  },
  variants: {
    hero: {
      fontSize: "64px",
      fontWeight: 900,
      letterSpacing: "0.08px",
      lineHeight: "75px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default title;
