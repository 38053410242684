export const brandValueProp = {
  parts: ["section", "title", "body", "button", "icon"],
  baseStyle: {
    section: {
      fontFamily: "normal",
      letterSpacing: "0.16px",
      color: "black",
      // Background color is set in the CMS
    },
    title: {
      textTransform: "none",
    },
  },
};
