import qs from "qs";

import { CmsClient } from "@/api/CmsClient";
import { ModalSizeType } from "@/revive/components/Modal/Modal";

export type ModalsCms = {
  attributes: {
    title: string;
    content: string;
    modalId: string;
    size: ModalSizeType;
  };
}[];

type CmsResponse = {
  data: ModalsCms;
};

export async function GetModals(partnerName: string): Promise<ModalsCms> {
  const partnerId = partnerName.toLowerCase();
  const query = qs.stringify({
    filters: {
      $or: [
        { partner: { partner_id: { $eq: partnerId } } },
        { partner: { partner_id: { $null: true } } },
      ],
    },
  });

  try {
    return await CmsClient()
      .get(`/modals?${query}`)
      .then(({ data }: { data: CmsResponse }) => data.data);
  } catch {
    throw Error(`Something went wrong getting product page from the CMS`);
  }
}
