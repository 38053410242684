const linkButton = {
  baseStyle: {
    color: "primary.50",
    _hover: {
      color: "primary.50",
      textDecoration: "underline",
      filter: "none",
    },
  },
  variants: {},
};

// eslint-disable-next-line import/no-default-export
export default linkButton;
