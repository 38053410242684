import { defineStyleConfig } from "@chakra-ui/react";

import { ButtonStyle } from "./Button";

export const ButtonStyleValues = {
  base: {
    color: "primary.600",
  },
  confirm: {
    color: "confirmation",
  },
  cancel: {
    color: "error",
  },
};

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: "none",
  },
  variants: {
    primary_arrive: (props) => {
      const buttonColor =
        ButtonStyleValues[props.buttonStyle as ButtonStyle].color;

      return {
        background: buttonColor,
        color: "white",
      };
    },
    secondary_arrive: (props) => {
      const buttonColor =
        ButtonStyleValues[props.buttonStyle as ButtonStyle].color;

      return {
        border: "md",
        borderColor: buttonColor,
        color: buttonColor,
      };
    },
    link_arrive: (props) => {
      const buttonColor =
        ButtonStyleValues[props.buttonStyle as ButtonStyle].color;

      return {
        height: "auto",
        textDecoration: "underline",
        textTransform: "uppercase",
        verticalAlign: "baseline",
        color: buttonColor,
      };
    },
  },
});
