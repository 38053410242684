/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { extendTheme } from "@chakra-ui/react";
import merge from "ts-deepmerge";

import CategoryTiles from "@/dep_theme/common/components/CategoryTiles";
import FAQValueProp from "@/dep_theme/common/components/FAQValueProp";
import { menuItem } from "@/dep_theme/common/components/MenuItem";
import PDPToggleButton from "@/dep_theme/common/components/PDPToggleButton";
import PriceContainer from "@/dep_theme/common/components/PriceContainer";
import textStyles from "@/dep_theme/common/textStyles";

import Accordion from "./common/components/Accordion";
import BorderBox from "./common/components/BorderBox";
import BrandPartnershipHero from "./common/components/BrandPartnershipHero";
import Breadcrumb from "./common/components/Breadcrumb";
import Button from "./common/components/Button";
import CartItem from "./common/components/CartItem";
import Checkbox from "./common/components/Checkbox";
import CheckoutCheckbox from "./common/components/CheckoutCheckbox";
import CheckoutLayout from "./common/components/CheckoutLayout";
import ColorButton from "./common/components/ColorButton";
import CustomText from "./common/components/CustomText";
import DateBox from "./common/components/DateBox";
import DiscountCode from "./common/components/DiscountCode";
import Drawer from "./common/components/Drawer";
import DropDownList from "./common/components/DropDownList";
import FAQCategorySection from "./common/components/FAQCategorySection";
import FAQContactUs from "./common/components/FAQContactUs";
import Footer from "./common/components/Footer";
import FormSectionHeading from "./common/components/FormSectionHeading";
import Heading from "./common/components/Heading";
import IconValueProp from "./common/components/IconValueProp";
import Input from "./common/components/Input";
import InputText from "./common/components/InputText";
import Label from "./common/components/Label";
import LinkButton from "./common/components/LinkButton";
import MediaValueProp from "./common/components/MediaValueProp";
import MenuItemDate from "./common/components/MenuItemDate";
import ModalContent from "./common/components/ModalContent";
import ModalHeader from "./common/components/ModalHeader";
import OrderNumber from "./common/components/OrderNumber";
import OrderStatusStep from "./common/components/OrderStatusStep";
import Popover from "./common/components/Popover";
import ProductConfigHeader from "./common/components/ProductConfigHeader";
import ProductSlider from "./common/components/ProductSlider";
import Progress from "./common/components/Progress";
import RichText from "./common/components/RichText";
import SearchResults from "./common/components/SearchResults";
import Select from "./common/components/Select";
import SingleLineDateRangeBox from "./common/components/SingleLineDateRangeBox";
import SiteLink from "./common/components/SiteLink";
import SiteNavigationLink from "./common/components/SiteNavigationLink";
import Tabs from "./common/components/Tabs";
import Text from "./common/components/Text";
import Title from "./common/components/Title";
import TripDiscountTag from "./common/components/TripDiscountTag";
import UnavailablePill from "./common/components/UnavailablePill";
import VariantSelector from "./common/components/VariantSelector";
import { brandValueProp } from "./common/components/brandValueProp";
import { colorSwatch } from "./common/components/colorSwatch";
import { productCard } from "./common/components/productCard";
import { topTextBanner } from "./common/components/topTextBanner";
import ArriveCustomization from "./whitelabel/arrive";
import BlanqiCustomization from "./whitelabel/blanqi";
import CitizenryCustomization from "./whitelabel/citizenry";
import YetiCustomization from "./whitelabel/yeti";

import partnerIds from "../dep_constants/partnerIds";

const getOverrides = (customizations: any): any => ({
  textStyles,
  components: {
    colorSwatch: merge(colorSwatch, customizations.colorSwatch || {}),
    Accordion: merge(Accordion, customizations.PartnerAccordion || {}),
    BorderBox: merge(BorderBox, customizations.PartnerBorderBox || {}),
    BrandPartnershipHero: merge(
      BrandPartnershipHero,
      customizations.PartnerBrandPartnershipHero || {},
    ),
    brandValueProp: merge(brandValueProp, customizations.brandValueProp || {}),
    Breadcrumb: merge(Breadcrumb, customizations.PartnerBreadcrumb || {}),
    Button: merge(Button, customizations.PartnerButton || {}),
    CartItem: merge(CartItem, customizations.PartnerCartItem || {}),
    CategoryTiles: merge(
      CategoryTiles,
      customizations.PartnerCategoryTiles || {},
    ),
    Checkbox: merge(Checkbox, customizations.PartnerCheckbox || {}),
    CheckoutCheckbox: merge(
      CheckoutCheckbox,
      customizations.PartnerCheckoutCheckbox || {},
    ),
    CheckoutLayout: merge(
      CheckoutLayout,
      customizations.PartnerCheckoutLayout || {},
    ),
    ColorButton: merge(ColorButton, customizations.PartnerColorButton || {}),
    CustomText: merge(CustomText, customizations.PartnerCustomText || {}),
    DateBox: merge(DateBox, customizations.PartnerDateBox || {}),
    DiscountCode: merge(DiscountCode, customizations.PartnerDiscountCode || {}),
    Drawer,
    DropDownList: merge(DropDownList, customizations.PartnerDropDownList || {}),
    FAQCategorySection: merge(
      FAQCategorySection,
      customizations.PartnerFAQCategorySection || {},
    ),
    FAQContactUs: merge(FAQContactUs, customizations.PartnerFAQContactUs || {}),
    FAQValueProp: merge(FAQValueProp, customizations.PartnerFAQValueProp || {}),
    Footer: merge(Footer, customizations.PartnerFooter || {}),
    FormSectionHeading: merge(
      FormSectionHeading,
      customizations.PartnerFormSectionHeading || {},
    ),
    Heading: merge(Heading, customizations.PartnerHeading || {}),
    IconValueProp: merge(
      IconValueProp,
      customizations.PartnerIconValueProp || {},
    ),
    Input,
    InputText: merge(InputText, customizations.PartnerInputText || {}),
    Label: merge(Label, customizations.PartnerLabel || {}),
    LinkButton: merge(LinkButton, customizations.PartnerLinkButton || {}),
    MediaValueProp: merge(
      MediaValueProp,
      customizations.PartnerMediaValueProp || {},
    ),
    MenuItem: merge(menuItem, customizations.PartnerMenuItem || {}),
    MenuItemDate: merge(MenuItemDate, customizations.PartnerMenuItemDate || {}),
    ModalContent: merge(ModalContent, customizations.PartnerModalContent || {}),
    ModalHeader: merge(ModalHeader, customizations.PartnerModalHeader || {}),
    OrderNumber: merge(OrderNumber, customizations.PartnerOrderNumber || {}),
    OrderStatusStep: merge(
      OrderStatusStep,
      customizations.PartnerOrderStatusStep || {},
    ),
    PDPToggleButton: merge(
      PDPToggleButton,
      customizations.PartnerPDPToggleButton || {},
    ),
    Popover,
    PriceContainer: merge(
      PriceContainer,
      customizations.PartnerPriceContainer || {},
    ),
    ProductCard: merge(productCard, customizations.productCard ?? {}),
    ProductConfigHeader: merge(
      ProductConfigHeader,
      customizations.PartnerProductConfigHeader || {},
    ),
    ProductSlider,
    Progress,
    RichText: merge(RichText, customizations.PartnerRichText || {}),
    SearchResults: merge(
      SearchResults,
      customizations.PartnerSearchResults || {},
    ),
    Select,
    SingleLineDateRangeBox,
    SiteLink: merge(SiteLink, customizations.PartnerSiteLink || {}),
    SiteNavigationLink: merge(
      SiteNavigationLink,
      customizations.PartnerSiteNavigationLink || {},
    ),
    Tabs: merge(Tabs, customizations.PartnerTabs || {}),
    Text: merge(Text, customizations.PartnerText || {}),
    Title: merge(Title, customizations.PartnerTitle || {}),
    topTextBanner: merge(topTextBanner, customizations.topTextBanner || {}),
    TripDiscountTag: merge(
      TripDiscountTag,
      customizations.PartnerTripDiscountTag || {},
    ),
    UnavailablePill,
    VariantSelector: merge(
      VariantSelector,
      customizations.PartnerVariantSelector || {},
    ),
  },
});

const resolveTheme = (themePartnerId: string): any => {
  const partnerId = themePartnerId;

  let customizations;

  if (partnerId === partnerIds.ARRIVE_OUTDOORS) {
    customizations = ArriveCustomization;
  } else if (partnerId === partnerIds.YETI) {
    customizations = YetiCustomization;
  } else if (partnerId === partnerIds.BLANQI) {
    customizations = BlanqiCustomization;
  } else if (partnerId === partnerIds.CITIZENRY) {
    customizations = CitizenryCustomization;
  } else {
    // TODO: We need to address this because when next is starting/launching the webpage the `partnerId` is null and is setting AO customization first.
    // This is happening because next on build is generating static content with this theme. We should address it in a long term solution with a new
    // BaseTheme.

    // eslint-disable-next-line no-console
    // console.warn('No Partner was configured for this theme page Using AO as default. Maybe is the static generation of an 404 page?');
    customizations = ArriveCustomization;
  }

  const themeOverrides = getOverrides(customizations);

  return extendTheme({
    ...themeOverrides,
  });
};

// eslint-disable-next-line import/no-default-export
export default resolveTheme;
