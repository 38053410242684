import { Box, Flex, Text, useMultiStyleConfig } from "@chakra-ui/react";
import React, { FC } from "react";

import OneTrustCookieLink from "@/dep_components/Commons/OneTrustCookieLink";
import { RichText } from "@/dep_components/Commons/RichText";
import SiteLink from "@/dep_components/Commons/SiteLink";
import PartnerFooterSocialLinks from "@/dep_components/Footer/PartnerFooterSocialLinks";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

const PartnerFooterLinks: FC = () => {
  const { footerLinks, keys } = useWhitelabelContext();
  const styles = useMultiStyleConfig("Footer");

  if (!footerLinks) {
    return null;
  }

  return (
    <Flex
      flexGrow={1}
      flexDirection={{
        base: "column",
        md: "row",
      }}
      fontSize="12px"
      fontFamily="normal"
      fontWeight="normal"
      justifyContent={{
        base: "flex-start",
        md: "space-between",
      }}
      textAlign={{
        base: "center",
        md: "left",
      }}
    >
      <Flex direction="column">
        <Box
          display={{
            base: "block",
            md: "none",
          }}
        >
          <PartnerFooterSocialLinks socialIcons={footerLinks.socialIcons} />
        </Box>
        <Text sx={styles.copyright}>{footerLinks.copyright}</Text>
        <Flex
          direction="row"
          textAlign="center"
          justifyContent={{
            base: "center",
            md: "flex-start",
          }}
          flexWrap="wrap"
          mb={{
            base: 4,
            md: 0,
          }}
        >
          {footerLinks.links.map((link) => (
            <Box key={`footer_link_${link.id}`} mr={4} mb={1}>
              <SiteLink textDecoration="underline" variant="footer" {...link}>
                {link.text}
              </SiteLink>
            </Box>
          ))}
          {!!keys?.ONE_TRUST_SCRIPT_ID && (
            <Box key="footer_link_one_trust_cookies" mr={4} mb={1}>
              <OneTrustCookieLink textDecoration="underline" variant="footer">
                Do Not Sell My Personal Information
              </OneTrustCookieLink>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        textAlign={{
          base: "center",
          md: "right",
        }}
        maxW={{
          base: "100%",
          md: "300px",
        }}
      >
        <Box
          display={{
            base: "none",
            md: "flex",
          }}
          w="100%"
        >
          <PartnerFooterSocialLinks socialIcons={footerLinks.socialIcons} />
        </Box>
        <RichText
          opacity={0.6}
          text={footerLinks.body}
          sx={styles.assistiveText}
        />
      </Flex>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default PartnerFooterLinks;
