import { Text as ChakraText } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export type TextSize = "sm" | "md";

type TextProps = {
  size?: TextSize;
};

export function Text({ size = "md", children }: PropsWithChildren<TextProps>) {
  return (
    <ChakraText
      fontFamily="body"
      fontSize={size}
      mb={"3"}
      sx={{ _last: { mb: 0 } }}
    >
      {children}
    </ChakraText>
  );
}
