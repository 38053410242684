const checkoutLayout = {
  parts: ["confirmationContainer"],
  baseStyle: {
    confirmationContainer: {
      backgroundColor: "secondary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default checkoutLayout;
