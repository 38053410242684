const brandPartnershipHero = {
  parts: ["section", "brands", "title", "subtitle", "button"],
  baseStyle: {
    section: {
      fontFamily: "normal",
      letterSpacing: "0.16px",
    },
    brands: {},
    title: {
      fontFamily: "title",
      fontWeight: 700,
      letterSpacing: "1px",
    },
    subtitle: {
      fontFamily: "normal",
      fontWeight: 400,
      letterSpacing: "0.16px",
    },
    button: {},
  },
};

// eslint-disable-next-line import/no-default-export
export default brandPartnershipHero;
