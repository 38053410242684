import { CloudinaryContext } from "cloudinary-react";
import Head from "next/head";
import { FC, ReactNode } from "react";

import { PartnerType } from "@/dep_types/content/partner";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import { ManagedArriveRentalContext } from "@/dep_components/context";
import { SearchContextProvider } from "@/dep_components/searchContext";
import { ManagedWhitelabelContext } from "@/dep_components/whitelabelContext";

import { OrderConfirmationProvider } from "@/dep_hooks/useOrderConfirmation";
import { OrderEditProvider } from "@/dep_hooks/useOrderEdit";

export type LayoutWrapperProps = {
  children: ReactNode;
  partnerId: string;
  partnerData: PartnerType | null;
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({
  children,
  partnerId,
  partnerData,
}: LayoutWrapperProps) => {
  return (
    <ManagedArriveRentalContext partnerId={partnerId}>
      <ManagedWhitelabelContext partnerData={partnerData}>
        <CloudinaryContext
          cloudName={commonKeys.CLOUDINARY_NAME}
          secure
          fetchFormat="auto"
        >
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
            />
            <meta name="format-detection" content="telephone=no" />
          </Head>
          <OrderConfirmationProvider>
            <OrderEditProvider>
              <SearchContextProvider>{children}</SearchContextProvider>
            </OrderEditProvider>
          </OrderConfirmationProvider>
        </CloudinaryContext>
      </ManagedWhitelabelContext>
    </ManagedArriveRentalContext>
  );
};

// eslint-disable-next-line import/no-default-export
export default LayoutWrapper;
