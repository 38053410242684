const customText = {
  baseStyle: {
    fontFamily: "normal",
    letterSpacing: "0.16px",
  },
  variants: {
    formTitle: {
      fontWeight: 300,
    },
    formSectionTitle: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    formHighlightTitle: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    formText: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    formSummaryValue: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
    formDiscountKey: {
      fontWeight: "medium",
      letterSpacing: "1px",
    },
  },
  defaultProps: {},
};

// eslint-disable-next-line import/no-default-export
export default customText;
