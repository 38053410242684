import mediaDataMapper from "@/dep_helpers/content/mappers/components/media";
import contentErrorDataMapper from "@/dep_helpers/content/mappers/error";
import APIPartnerDataType from "@/dep_types/content/api/data/partnerData";
import { ErrorObjectType } from "@/dep_types/content/error";
import { PartnerResponseType } from "@/dep_types/content/partner";

import footerInfoMapper from "./footerInfo";
import footerLinksMapper from "./footerLinks";
import headerInfoMapper from "./headerInfo";

const partner = (
  apiPartnerData: APIPartnerDataType,
  error?: ErrorObjectType,
): PartnerResponseType => {
  return {
    data: {
      name: apiPartnerData.attributes.name,
      saleType: apiPartnerData.attributes.saleType,
      darkLogo: apiPartnerData.attributes.darkLogo.data
        ? mediaDataMapper(apiPartnerData.attributes.darkLogo.data.attributes)
        : null,
      lightLogo: apiPartnerData.attributes.lightLogo.data
        ? mediaDataMapper(apiPartnerData.attributes.lightLogo.data.attributes)
        : null,
      favicon: apiPartnerData.attributes.favicon?.data
        ? mediaDataMapper(apiPartnerData.attributes.favicon.data.attributes)
        : null,
      hasMultipleBrands: apiPartnerData.attributes.hasMultipleBrands,
      privacyPolicyLink: apiPartnerData.attributes.privacyPolicyLink,
      termsOfUseLink: apiPartnerData.attributes.termsOfUseLink,
      zendeskChatKey: apiPartnerData.attributes.zendeskChatKey,
      googleSiteVerificationId:
        apiPartnerData.attributes.googleSiteVerificationId,
      gtmConversionId: apiPartnerData.attributes.gtmConversionId,
      fbDomainVerificationId: apiPartnerData.attributes.fbDomainVerificationId,
      fbPixelId: apiPartnerData.attributes.fbPixelId,
      tiktokId: apiPartnerData.attributes.tiktokId,
      hotjarId: apiPartnerData.attributes.hotjarId,
      awinConversionId: apiPartnerData.attributes.awinConversionId,
      attentiveConversionId: apiPartnerData.attributes.attentiveConversionId,
      rockerboxConversionTagSrc:
        apiPartnerData.attributes.rockerboxConversionTagSrc,
      rockerboxConversionTagKey:
        apiPartnerData.attributes.rockerboxConversionTagKey,
      criteoId: apiPartnerData.attributes.criteoId,
      headerInfo: headerInfoMapper(apiPartnerData.attributes.headerInfo),
      footerInfo: footerInfoMapper(apiPartnerData.attributes.footerInfo),
      footerLinks: footerLinksMapper(apiPartnerData.attributes.footerLinks),
      openExternalLinksInNewTab:
        apiPartnerData.attributes.openExternalLinksInNewTab,
      supportAlaskaHawaiiShipping:
        apiPartnerData.attributes.supportAlaskaHawaiiShipping,
      supportAlaskaHawaiiBilling:
        apiPartnerData.attributes.supportAlaskaHawaiiBilling,
      supportShippingToPOBoxes:
        apiPartnerData.attributes.supportShippingToPOBoxes,
      globalLabels: apiPartnerData.attributes.globalLabels,
      logoSize: apiPartnerData.attributes.logoSize,
      product: apiPartnerData.attributes.product,
    },
    error: error ? contentErrorDataMapper(error) : null,
  };
};

// eslint-disable-next-line import/no-default-export
export default partner;
