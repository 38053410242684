import { environment } from "../../dep_constants";

let overrides;

const local = {};

const development = {};

const staging = {};

const production = { AMPLITUDE_KEY: "00ecb1c84dacbee09521daa8d551a91a" };

if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.PRODUCTION) {
  overrides = production;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.STAGING) {
  overrides = staging;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === environment.DEVELOPMENT) {
  overrides = development;
} else {
  overrides = local;
}

const configurations = {
  ...overrides,
};

// eslint-disable-next-line import/no-default-export
export default configurations;
