import dynamic from "next/dynamic";
import React, { FC, ReactElement } from "react";

import { IconType } from "@/dep_types/content/components";

import KeepItIcon from "@/dep_components/icons/KeepItIcon";

const BoxIcon = dynamic(() => import("@/dep_components/icons/BoxIcon"));
const BubblesIcon = dynamic(() => import("@/dep_components/icons/BubblesIcon"));
const CalculatorIcon = dynamic(
  () => import("@/dep_components/icons/CalculatorIcon"),
);
const CalendarIcon = dynamic(
  () => import("@/dep_components/icons/CalendarIcon"),
);
const ChatIcon = dynamic(() => import("@/dep_components/icons/ChatIcon"));
const CheckCircleIcon = dynamic(
  () => import("@/dep_components/icons/CheckCircleIcon"),
);
const CoinsIcon = dynamic(() => import("@/dep_components/icons/CoinsIcon"));
const FacebookIcon = dynamic(
  () => import("@/dep_components/icons/FacebookIcon"),
);
const FacebookYetiIcon = dynamic(
  () => import("@/dep_components/icons/Yeti/FacebookIcon"),
);
const HouseIcon = dynamic(() => import("@/dep_components/icons/HouseIcon"));
const InstagramIcon = dynamic(
  () => import("@/dep_components/icons/InstagramIcon"),
);
const InstagramYetiIcon = dynamic(
  () => import("@/dep_components/icons/Yeti/InstagramIcon"),
);
const LikeIcon = dynamic(() => import("@/dep_components/icons/LikeIcon"));
const MailIcon = dynamic(() => import("@/dep_components/icons/MailIcon"));
const PackageIcon = dynamic(() => import("@/dep_components/icons/PackageIcon"));
const PhoneIcon = dynamic(() => import("@/dep_components/icons/PhoneIcon"));
const RecycleIcon = dynamic(() => import("@/dep_components/icons/RecycleIcon"));
const SpongeIcon = dynamic(() => import("@/dep_components/icons/SpongeIcon"));
const TikTokYetiIcon = dynamic(
  () => import("@/dep_components/icons/Yeti/TikTokIcon"),
);
const TentIcon = dynamic(() => import("@/dep_components/icons/TentIcon"));
const TreeIcon = dynamic(() => import("@/dep_components/icons/TreeIcon"));
const TruckIcon = dynamic(() => import("@/dep_components/icons/TruckIcon"));
const TwitterIcon = dynamic(() => import("@/dep_components/icons/TwitterIcon"));
const TwitterYetiIcon = dynamic(
  () => import("@/dep_components/icons/Yeti/TwitterIcon"),
);
const YoutubeIcon = dynamic(() => import("@/dep_components/icons/YoutubeIcon"));
const YoutubeYetiIcon = dynamic(
  () => import("@/dep_components/icons/Yeti/YoutubeIcon"),
);

const icons: Record<string, ReactElement> = {
  Box: <BoxIcon />,
  Bubbles: <BubblesIcon />,
  Calculator: <CalculatorIcon />,
  Calendar: <CalendarIcon />,
  Chat: <ChatIcon />,
  CheckCircle: <CheckCircleIcon />,
  Coins: <CoinsIcon />,
  Facebook: <FacebookIcon />,
  FacebookYeti: <FacebookYetiIcon />,
  House: <HouseIcon />,
  Instagram: <InstagramIcon />,
  InstagramYeti: <InstagramYetiIcon />,
  KeepIt: <KeepItIcon />,
  Like: <LikeIcon />,
  Mail: <MailIcon />,
  Package: <PackageIcon />,
  Phone: <PhoneIcon />,
  Recycle: <RecycleIcon />,
  Sponge: <SpongeIcon />,
  TikTokYeti: <TikTokYetiIcon />,
  Tent: <TentIcon />,
  Tree: <TreeIcon />,
  Truck: <TruckIcon />,
  Twitter: <TwitterIcon />,
  TwitterYeti: <TwitterYetiIcon />,
  Youtube: <YoutubeIcon />,
  YoutubeYeti: <YoutubeYetiIcon />,
};

const DynamicIcon: FC<IconType> = ({ source }) => <>{icons[source]}</>;

// eslint-disable-next-line import/no-default-export
export default DynamicIcon;
