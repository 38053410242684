const colorButton = {
  baseStyle: {
    padding: 1,
    width: "40px",
    height: "40px",
    border: "1px solid",
    borderColor: "gray.200",
    _hover: {
      border: "1px solid",
      borderColor: "gray.500",
    },
  },
  variants: {
    selected: {
      borderColor: "secondary.50",
      _hover: {
        borderColor: "secondary.50",
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default colorButton;
