export function getArrayFromQueryParam(
  param?: string | string[],
): string[] | undefined {
  if (!param) return;

  if (typeof param === "string") {
    return [param];
  }

  return param;
}
