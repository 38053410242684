const customText = {
  variants: {
    formTitle: {
      textTransform: "uppercase",
      fontFamily: "title",
      letterSpacing: "0.08px",
      fontWeight: 600,
      fontSize: "18px",
      color: "primary.600",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default customText;
