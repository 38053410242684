const categoryTiles = {
  parts: ["title", "card"],
  baseStyle: {
    title: {
      textAlign: "center",
      fontWeight: 700,
      fontSize: {
        base: "30px",
      },
      color: "gray.500",
      letterSpacing: "2px",
      lineHeight: "42px",
    },
    card: {
      textTransform: "uppercase",
      fontFamily: "normal",
      fontWeight: 700,
      letterSpacing: "2px",
      lineHeight: "42px",
      fontSize: "30px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default categoryTiles;
