import { forwardRef, Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import layoutConstants from "@/dep_constants/layout";

const Section = forwardRef<BoxProps, "section">((props, ref) => (
  <Box
    as="section"
    paddingY={{
      base: layoutConstants.sectionPadding.base,
      md: layoutConstants.sectionPadding.md,
      xl: layoutConstants.sectionPadding.xl,
    }}
    {...props}
    ref={ref}
  />
));

// eslint-disable-next-line import/no-default-export
export default Section;
