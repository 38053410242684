import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props extends BoxProps {
  children: ReactNode;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  variant?:
    | "step"
    | "productBrand"
    | "collectionHeader"
    | "collectionFiltersMenuItem"
    | "categoryFilterSelector"
    | "bold"
    | "loveItKeepIt";
  isCentered?: boolean;
}

const Label: FC<Props> = ({
  children,
  size,
  isCentered,
  variant,
  ...other
}: Props) => {
  const styles = useStyleConfig("Label", { size, variant });

  return (
    <Box sx={styles} textAlign={isCentered ? "center" : "left"} {...other}>
      {children}
    </Box>
  );
};

Label.defaultProps = {
  size: undefined,
  variant: undefined,
  isCentered: undefined,
};

// eslint-disable-next-line import/no-default-export
export default Label;
