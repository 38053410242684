import {
  CartItemType,
  CartProductItemType,
  CartSetItemType,
} from "../dep_types/CartTypes";

export const SET_SITE_RENTAL_DATES = "SET_SITE_RENTAL_DATES";
export const SET_SHOPPING_CART_RENTAL_DATES = "SET_SHOPPING_CART_RENTAL_DATES";
export const INIT_CART = "INIT_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const OPEN_DATE_SYNC_MODAL = "OPEN_DATE_SYNC_MODAL";
export const CLOSE_DATE_SYNC_MODAL = "CLOSE_DATE_SYNC_MODAL";
export const OPEN_SHOPPING_CART = "OPEN_SHOPPING_CART";
export const CLOSE_SHOPPING_CART = "CLOSE_SHOPPING_CART";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const CLEAR_CART = "CLEAR_CART";
export const SET_PARTNER_ID_THEME = "SET_PARTNER_ID_THEME";

export type Action =
  | {
      type: typeof SET_SITE_RENTAL_DATES;
      siteRentalDates: object;
    }
  | {
      type: typeof SET_SHOPPING_CART_RENTAL_DATES;
      shoppingCartRentalDates: object;
    }
  | {
      type: typeof INIT_CART;
      cartInitialItems: object[];
    }
  | {
      type: typeof ADD_TO_CART;
      addedCartItem: CartItemType;
      newCartItems: CartProductItemType[] | CartSetItemType[];
      addedToCartFlow: "OPEN_CART";
    }
  | {
      type: typeof REMOVE_FROM_CART;
      cartId: number;
    }
  | {
      type: typeof OPEN_DATE_SYNC_MODAL;
      cartItem: object;
    }
  | {
      type: typeof CLOSE_DATE_SYNC_MODAL;
    }
  | {
      type: typeof OPEN_SHOPPING_CART;
    }
  | {
      type: typeof CLOSE_SHOPPING_CART;
    }
  | {
      type: typeof UPDATE_CART_ITEMS;
      updatedCartItems: object[];
    }
  | {
      type: typeof CLEAR_CART;
    }
  | {
      type: typeof SET_PARTNER_ID_THEME;
      partnerIdTheme: string;
    };
