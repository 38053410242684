import { SVGAttributes } from "react";
import { PartialDeep } from "type-fest";

import { Accordion } from "@/revive/components/Accordion/Accordion.styles";
import { Button } from "@/revive/components/Button/Button.styles";
import { Container } from "@/revive/components/Container/Container.styles";
import { Heading } from "@/revive/components/Heading/Heading.styles";

import type { IconName } from "@/revive/components/Icon";

export const reviveTheme = {
  partnerId: 1,
  partnerName: "arrive-outdoors",
  borders: {
    sm: "1px solid",
    md: "2px solid",
    lg: "3px solid",
  },
  colors: {
    announcement: "#4D4EA1",
    confirmation: "#28B761",
    error: "#EA4335",
    black: "#1D1F22",
    white: "#fff",
    primary: {
      50: "#FFF4F2",
      100: "#FFF4F2",
      600: "#FF6553",
      700: "#FF6553",
    },
    secondary: {
      50: "#FAFFFE",
      100: "#FAFFFE",
      600: "#28B799",
    },
    tertiary: {
      50: "#5FA9D6",
      600: "#5FA9D6",
    },
    gray: {
      50: "#FAFBFD",
      100: "#F2F5F9",
      200: "#E3E8EF",
      300: "#C8C8D6",
      400: "#A5A4B1",
      500: "#908EA0",
    },
  },

  components: {
    Heading,
    Button,
    Accordion,
    Container,
  },
};

type SVGIconPaths = {
  d?: string;
};

export type ReviveIconAttributes = Pick<
  SVGAttributes<unknown>,
  "viewBox" | "fill"
> & {
  paths?: SVGIconPaths[];
};

export type ReviveTheme = PartialDeep<typeof reviveTheme> & {
  partnerId: number;
  partnerName: string;
  fonts?: {
    body?: string;
    heading?: string;
  };
  fontFaces?: string;
  icons?: { [k in IconName]?: ReviveIconAttributes };
};
