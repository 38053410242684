import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  TextProps,
} from "@chakra-ui/react";
import React, { FC } from "react";

import InformationIcon from "@/dep_components/icons/InformationIcon";

type PopoverProps = {
  heading: string;
  bodyText: string;
  placement: "bottom-end" | "bottom-start";
};

interface Props extends TextProps {
  popover: PopoverProps;
  text: string;
}

const CalendarDateTooltip: FC<Props> = ({ popover, text, ...other }) => (
  <Popover trigger="hover" placement={popover.placement}>
    <PopoverTrigger>
      <Box
        as="button"
        type="button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexBasis="50%"
        paddingX={2.5}
        paddingY={2}
        border="1px solid"
        borderColor="gray.200"
        color="gray.500"
        borderRadius="4px"
        transition="border-color ease .125s"
        _hover={{
          borderColor: "black",
          color: "black",
        }}
        {...other}
      >
        <Text as="span" size="sm" marginRight={1.5}>
          {text}
        </Text>
        <InformationIcon />
      </Box>
    </PopoverTrigger>
    <PopoverContent
      backgroundColor="black"
      color="white"
      fontFamily="normal"
      fontSize="13px"
      fontWeight="normal"
      lineHeight="1.2"
      letterSpacing="-0.02em"
      justifyContent="center"
      paddingY={7}
      paddingLeft={5}
      paddingRight={1.5}
      width="279px"
      borderRadius="10px"
      border="none"
    >
      <PopoverArrow backgroundColor="black" borderRadius="1px" />
      <PopoverHeader
        borderBottom="none"
        padding="0"
        marginBottom={4}
        fontWeight="medium"
        lineHeight="16px"
        letterSpacing="-0.04em"
      >
        {popover.heading}
      </PopoverHeader>
      <PopoverBody padding={0}>{popover.bodyText}</PopoverBody>
    </PopoverContent>
  </Popover>
);

// eslint-disable-next-line import/no-default-export
export default CalendarDateTooltip;
