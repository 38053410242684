import { defineStyleConfig } from "@chakra-ui/react";

export const colorSwatch = defineStyleConfig({
  baseStyle: {
    border: "1px",
    borderColor: "gray.200",
    _hover: {
      borderColor: "gray.400",
    },
  },
  variants: {
    active: {
      borderColor: "secondary.600",
      _hover: {
        borderColor: "secondary.600",
      },
    },
  },
});
