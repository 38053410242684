import { Box, Flex } from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";

import { ColorSwatch as TColorSwatch } from "@/dep_types";
import { useElementSize } from "@/hooks/useElementSize";

import { ColorSwatch } from "@/dep_components/Commons/Products/ProductCard/components/ColorSwatch";
import { ProductCardSliderButton } from "@/dep_components/Commons/Products/ProductCard/components/ProductCardSliderButton";

import { useProductCard } from "../hooks/useProductCard";

export function ProductCardSwatches() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {
    availableSwatches,
    selectedSwatch,
    updateSelectedSwatch,
    setPreviewSwatch,
    removePreviewSwatch,
  } = useProductCard();
  const [ref, { width }] = useElementSize();

  const slidesPerView = width && width > 300 ? 4 : 3;
  const totalSwatches = availableSwatches.length;
  const isSlider = totalSwatches > slidesPerView;
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: slidesPerView,
    },
    slideChanged: (s) => {
      setCurrentSlide(s.track.details.abs);
    },
  });

  return (
    <Box ref={ref}>
      <Flex gap={2}>
        {isSlider && (
          <ProductCardSliderButton
            direction="prev"
            onClick={() => instanceRef.current?.prev()}
            disabled={currentSlide === 0}
          />
        )}
        <Box
          ref={isSlider ? sliderRef : undefined}
          className={isSlider ? "keen-slider" : ""}
          display={!isSlider ? "flex" : ""}
          gap={!isSlider ? "5" : ""}
          textAlign="center"
        >
          {availableSwatches.map((swatch: TColorSwatch) => (
            <Box className="keen-slider__slide" key={swatch.id}>
              <ColorSwatch
                swatch={swatch}
                onClick={() => updateSelectedSwatch(swatch)}
                onMouseEnter={() => setPreviewSwatch(swatch)}
                onMouseLeave={removePreviewSwatch}
                isActive={selectedSwatch?.id === swatch.id}
              />
            </Box>
          ))}
        </Box>
        {isSlider && (
          <ProductCardSliderButton
            direction="next"
            onClick={() => instanceRef.current?.next()}
            disabled={currentSlide === totalSwatches - slidesPerView}
          />
        )}
      </Flex>
    </Box>
  );
}
