const siteNavigation = {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "medium",
    paddingY: 7,
    letterSpacing: "0.03px",
  },
  variants: {
    secondary: {
      display: "block",
      fontWeight: "normal",
      paddingY: 1.5,
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default siteNavigation;
