import { Box, Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import React, { FC, ReactElement, useCallback } from "react";

type Props = {
  children: ReactElement;
  isOpen: boolean;
  submitRentalDates: () => void;
  toggleComponent: ReactElement;
};

const MobileDatePicker: FC<Props> = ({
  children,
  isOpen,
  submitRentalDates,
  toggleComponent,
}: Props) => {
  const drawerContentContainerRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      // Hack because the Chakra Drawer component does not give us the
      // ability to update props on the DrawerContent's container element
      const contentContainerChild = node.firstElementChild as HTMLElement;

      // We need the calendar drawer to be stacked on top of the "Support"
      // button that is rendered by the third-party script from Zendesk
      contentContainerChild.style.zIndex = "999999";
    }
  }, []);

  return (
    <>
      {toggleComponent}
      <Drawer isOpen={isOpen} onClose={submitRentalDates} placement="bottom">
        <DrawerOverlay />
        <Box ref={drawerContentContainerRef}>
          <DrawerContent
            borderRadius="6px 6px 0 0"
            padding={5}
            maxHeight="100%"
            overflowY="scroll"
          >
            {children}
          </DrawerContent>
        </Box>
      </Drawer>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default MobileDatePicker;
