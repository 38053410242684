import { Text, useStyleConfig } from "@chakra-ui/react";
import Link from "next/link";
import React, { FC, ReactNode } from "react";

import TrackingService from "../../../dep_services/tracking/trackingService";

export interface SiteNavigationLinkType {
  children: ReactNode;
  link: string;
  menuItem: string;
  primaryCategory: string;
  secondaryCategory: string;
  onCloseDrawer: () => void;
  variant?: "secondary";
}

const SiteNavigationLink: FC<SiteNavigationLinkType> = ({
  children,
  link,
  menuItem,
  primaryCategory,
  secondaryCategory,
  variant,
  onCloseDrawer,
}) => {
  const styles = useStyleConfig("SiteNavigationLink", {
    variant,
  });

  return (
    <Link href={link} passHref prefetch={false}>
      <Text
        as="a"
        size={variant === "secondary" ? "md" : "lg"}
        sx={styles}
        onClick={() => {
          onCloseDrawer();
          TrackingService.trackNavigationClick(
            menuItem,
            primaryCategory,
            secondaryCategory,
          );
        }}
      >
        {children}
      </Text>
    </Link>
  );
};

SiteNavigationLink.defaultProps = {
  variant: undefined,
};

// eslint-disable-next-line import/no-default-export
export default SiteNavigationLink;
