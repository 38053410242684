/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from "react";

import { State } from "./context";
import {
  Action,
  ADD_TO_CART,
  CLEAR_CART,
  CLOSE_DATE_SYNC_MODAL,
  OPEN_SHOPPING_CART,
  CLOSE_SHOPPING_CART,
  INIT_CART,
  OPEN_DATE_SYNC_MODAL,
  REMOVE_FROM_CART,
  SET_PARTNER_ID_THEME,
  SET_SHOPPING_CART_RENTAL_DATES,
  SET_SITE_RENTAL_DATES,
  UPDATE_CART_ITEMS,
} from "./contextActions";

import { CartProductItemType, CartSetItemType } from "../dep_types/CartTypes";

const shoppingCartReducer: Reducer<any, any> = (
  state: State,
  action: Action,
) => {
  switch (action.type) {
    case SET_SITE_RENTAL_DATES: {
      return {
        ...state,
        siteRentalDates: action.siteRentalDates,
      };
    }
    case SET_SHOPPING_CART_RENTAL_DATES: {
      return {
        ...state,
        shoppingCartRentalDates: action.shoppingCartRentalDates,
      };
    }
    case INIT_CART: {
      return {
        ...state,
        cartItems: action.cartInitialItems,
        cartInitialized: true,
      };
    }
    case ADD_TO_CART: {
      return {
        ...state,
        cartItems: action.newCartItems,
        addedCartItem: action.addedCartItem,
        shoppingCartOpened: action.addedToCartFlow === "OPEN_CART",
      };
    }
    case REMOVE_FROM_CART: {
      const newItems = [...state.cartItems];
      const cartItem = newItems.find(
        (item: CartSetItemType | CartProductItemType) =>
          item.cartId.toString() === action.cartId.toString(),
      );

      if (cartItem) {
        const cartItemIndex = newItems.indexOf(cartItem);

        newItems.splice(cartItemIndex, 1);

        return {
          ...state,
          cartItems: newItems,
        };
      }

      return { ...state };
    }
    case OPEN_DATE_SYNC_MODAL: {
      return {
        ...state,
        dateSyncModalOpen: true,
        cartItemToSave: action.cartItem,
      };
    }
    case CLOSE_DATE_SYNC_MODAL: {
      return {
        ...state,
        dateSyncModalOpen: false,
        cartItemToSave: undefined,
      };
    }
    case OPEN_SHOPPING_CART: {
      return {
        ...state,
        shoppingCartOpened: true,
      };
    }
    case CLOSE_SHOPPING_CART: {
      return {
        ...state,
        shoppingCartOpened: false,
      };
    }
    case UPDATE_CART_ITEMS: {
      return {
        ...state,
        cartItems: action.updatedCartItems,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        cartItems: [],
      };
    }
    case SET_PARTNER_ID_THEME: {
      return {
        ...state,
        partnerIdTheme: action.partnerIdTheme,
      };
    }
    default:
      return { ...state };
  }
};

// eslint-disable-next-line import/no-default-export
export default shoppingCartReducer;
