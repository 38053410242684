import React, { FC } from "react";

import { BrandValueProp } from "@/dep_components/Sections/BrandValueProp";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type Props = {
  eventLocation: string;
  userMessage: string;
};

const ContentError: FC<Props> = ({ eventLocation, userMessage }) => {
  const { headerInfo } = useWhitelabelContext();

  const buttonData = headerInfo?.backToSiteButton
    ? {
        variant: "newPrimary",
        url: headerInfo.backToSiteButton.url,
        text: headerInfo.backToSiteButton.text,
        opensInNewWindow: false,
        trackingInfo: {
          name: "Content Error Button",
          location: eventLocation,
        },
      }
    : undefined;

  return (
    <BrandValueProp
      title="Something's gone wrong!"
      description={userMessage}
      variant="primary"
      button={buttonData}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default ContentError;
