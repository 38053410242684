/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from "react";

type Props = {
  hasWrapper: boolean;
  /**
   * TODO: Figure out what this is.
   */
  wrapper: any;
  children: ReactNode;
};

// NEW TODO: TEST ME
const ConditionalWrapper: FC<Props> = ({ hasWrapper, wrapper, children }) =>
  hasWrapper ? wrapper(children) : children;

// eslint-disable-next-line import/no-default-export
export default ConditionalWrapper;
