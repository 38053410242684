import {
  Box,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";

import ChevronIcon from "@/dep_components/icons/ChevronIcon";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import ShopAllProductsLink from "./ShopAllProductsLink";
import SiteNavigationSubcategory from "./SiteNavigationSubcategory";

import { allProductsCollectionSubstring } from "../../../dep_constants";
import TrackingService from "../../../dep_services/tracking/trackingService";
import { CategoryType } from "../../../dep_types";

type Props = {
  name: string;
  categoryGroupKey: string;
  subcategories: CategoryType[];
  onCloseDrawer: () => void;
};

const SiteNavigationCategory: FC<Props> = ({
  name,
  subcategories,
  categoryGroupKey,
  onCloseDrawer,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemCategories =
    subcategories &&
    subcategories.filter(
      (category) =>
        !category.collectionCategory.name
          .toLowerCase()
          .includes(allProductsCollectionSubstring.toLowerCase()),
    );
  const allProductsCategory =
    subcategories &&
    subcategories.find((category) =>
      category.collectionCategory.name
        .toLowerCase()
        .includes(allProductsCollectionSubstring.toLowerCase()),
    );
  const { isYeti } = useWhitelabelContext();

  return (
    <>
      <Text
        as="button"
        size="lg"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontWeight="medium"
        paddingY={7}
        letterSpacing={isYeti ? "0.03px" : "auto"}
        width="100%"
        onClick={() => {
          TrackingService.trackNavigationClick(name, "", "");
          onOpen();
        }}
      >
        {name}
        <ChevronIcon width="8px" stroke="gray.400" direction="right" />
      </Text>
      <Box
        as="nav"
        display="flex"
        flexDirection="column"
        position="absolute"
        top={0}
        bottom={0}
        left={isOpen ? 0 : "-100%"}
        width="100%"
        paddingLeft={5}
        paddingRight={8}
        paddingY={8}
        backgroundColor="white"
        overflowY="scroll"
        transition="left .25s ease"
        zIndex="docked"
      >
        <Text
          as="button"
          size="lg"
          fontWeight="medium"
          display="flex"
          alignItems="center"
          letterSpacing={isYeti ? "0.03px" : "auto"}
          paddingBottom={6}
          marginBottom={9}
          borderBottom="1px solid"
          borderColor="gray.100"
          onClick={onClose}
        >
          <ChevronIcon
            direction="left"
            width="8px"
            stroke="gray.400"
            marginRight={4}
          />
          {name}
        </Text>
        {itemCategories && (
          <UnorderedList listStyleType="none" margin={0} marginBottom={9}>
            {itemCategories.map((category) => (
              <ListItem
                key={category.collectionCategory.id}
                _notLast={{
                  marginBottom: 5,
                }}
              >
                <SiteNavigationSubcategory
                  key={category.collectionCategory.id}
                  parentCategoryName={name}
                  categoryGroup={categoryGroupKey}
                  onCloseDrawer={onCloseDrawer}
                  {...category}
                />
              </ListItem>
            ))}
          </UnorderedList>
        )}
        {allProductsCategory && (
          <ShopAllProductsLink
            link={`/group/${categoryGroupKey}/category/${allProductsCategory?.collectionCategory.id}`}
            menuItem={name}
            shopAllLabel={
              isYeti ? "SHOP ALL" : allProductsCategory.collectionCategory.name
            }
            onCloseDrawer={onCloseDrawer}
          />
        )}
      </Box>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SiteNavigationCategory;
