/* eslint-disable sonarjs/no-duplicate-string */
const button = {
  baseStyle: {},
  variants: {
    primary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      _hover: {
        _disabled: {},
      },
      _disabled: {},
    },
    newPrimary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      _hover: {
        _disabled: {},
      },
      _disabled: {},
    },
    newSecondary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      _hover: {},
    },
    secondaryLight: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      _hover: {},
    },
    secondaryDark: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      backgroundColor: "secondary.50",
      _hover: {
        backgroundColor: "secondary.100",
      },
    },
    tertiary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      _hover: {},
    },
    modalPrimary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      color: "white",
      backgroundColor: "secondary.50",
      _hover: {
        backgroundColor: "secondary.100",
      },
      _disabled: {},
    },
    modalTertiary: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      color: "secondary.50",
      border: "1px solid",
      borderColor: "secondary.50",
      backgroundColor: "white",
      _hover: {
        color: "gray.300",
        borderColor: "gray.300",
      },
    },
    orderConfirmationDark: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
    },
    orderConfirmationLight: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
    },
    iconButton: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
    },
    socialIcon: {
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      padding: 2,
    },
    checkoutPlaceOrder: {
      padding: "30px 60px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      backgroundColor: "secondary.50",
      color: "white",
      _hover: {
        backgroundColor: "secondary.100",
        _disabled: {},
      },
      _disabled: {
        padding: "15px 35px",
        letterSpacing: "2px",
        textTransform: "uppercase",
        fontSize: "15px",
        fontWeight: 600,
        borderRadius: "4px",
      },
    },
    reservation: {
      padding: "15px 35px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: 600,
      borderRadius: "4px",
      color: "white",
      width: "100%",
      height: "70px",
      backgroundColor: "secondary.50",
      _hover: {
        backgroundColor: "secondary.100",
        _disabled: {},
      },
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default button;
