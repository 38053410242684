import { useBreakpointValue } from "@chakra-ui/react";

const useIsMobile = (): boolean | undefined =>
  useBreakpointValue(
    {
      base: true,
      md: false,
    },
    "base",
  );

// eslint-disable-next-line import/no-default-export
export default useIsMobile;
