/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  VisuallyHidden,
} from "@chakra-ui/react";
import { DebouncedFunc } from "lodash";
import React, { FC, KeyboardEventHandler, useEffect } from "react";

import Container from "@/dep_components/Layout/Container";
import SearchYetiIcon from "@/dep_components/icons/Yeti/SearchIcon";
import { useSearchContext } from "@/dep_components/searchContext";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import useDebouncedCallback from "@/dep_hooks/useDebouncedCallback";

import SearchIcon from "./SearchIcon";

const SearchForm: FC = () => {
  const { onSearchChange, onSearchKeyUp, onSearchClear, searchTerm } =
    useSearchContext();

  const { isYeti } = useWhitelabelContext();

  const searchInputPadding = "5";

  const debouncedKeyUpHandler = useDebouncedCallback(
    onSearchKeyUp,
    500,
  ) as KeyboardEventHandler & DebouncedFunc<(...args: any[]) => Promise<any>>;

  useEffect(
    () =>
      function cleanup() {
        debouncedKeyUpHandler.cancel();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container
      display="flex"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      <FormControl id="search-module" display="flex" alignItems="center">
        <FormLabel
          margin="0"
          padding={searchInputPadding}
          marginLeft={{ base: -searchInputPadding, xl: 0 }}
        >
          {isYeti ? (
            <SearchYetiIcon fill="gray.300" />
          ) : (
            <SearchIcon stroke="gray.300" />
          )}
          <VisuallyHidden>
            {isYeti
              ? "Search for gear, categories..."
              : "Search for products, categories..."}
          </VisuallyHidden>
        </FormLabel>
        <Input
          placeholder={
            isYeti
              ? "Search for gear, categories..."
              : "Search for products, categories..."
          }
          value={searchTerm}
          onChange={onSearchChange}
          onKeyUp={debouncedKeyUpHandler}
          flex="1"
          border="none"
          borderRadius="none"
          height="100%"
          paddingX="3"
          paddingY={searchInputPadding}
          width="auto"
          minWidth="auto"
          color="black"
          _placeholder={{ color: "gray.400" }}
          required
        />
      </FormControl>
      {searchTerm && (
        <Box
          as="button"
          type="button"
          fontSize="16px"
          lineHeight="24px"
          color="gray.400"
          paddingX="2.5"
          paddingY={searchInputPadding}
          onClick={onSearchClear}
        >
          Clear
        </Box>
      )}
      <DrawerCloseButton
        order={{ base: 0, xl: -1 }}
        padding={searchInputPadding}
        marginRight={{ base: -searchInputPadding, xl: 0 }}
        marginLeft={{ base: 0, xl: -searchInputPadding }}
      />
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchForm;
