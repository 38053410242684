/* eslint-disable @typescript-eslint/no-explicit-any */
export const performCmsFetchRequest = async (
  path: string,
  method: string = "GET",
): Promise<any> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_CMS_API_URL}${path}`,
    {
      method,
    },
  );

  return response.json();
};

// eslint-disable-next-line import/no-default-export
export default performCmsFetchRequest;
