import APIHeroType from "@/dep_types/content/api/components/hero";
import { HeroType } from "@/dep_types/content/homepage";

import buttonDataMapper from "./linkedButton";
import mediaDataMapper from "./media";

const hero = (apiHeroData: APIHeroType): HeroType => ({
  componentKey: apiHeroData.__component ?? null,
  title: apiHeroData.title,
  richTextTitle: apiHeroData.richTextTitle ?? null,
  description: apiHeroData.description,
  image: {
    mobile: apiHeroData.image.mobileImage.data
      ? mediaDataMapper(apiHeroData.image.mobileImage.data.attributes)
      : null,
    tablet: apiHeroData.image.tabletImage.data
      ? mediaDataMapper(apiHeroData.image.tabletImage.data.attributes)
      : null,
    desktop: apiHeroData.image.desktopImage.data
      ? mediaDataMapper(apiHeroData.image.desktopImage.data.attributes)
      : null,
  },
  rentalButton: buttonDataMapper(apiHeroData.rentalButton),
  resaleButton: apiHeroData.resaleButton
    ? buttonDataMapper(apiHeroData.resaleButton)
    : null,
});

// eslint-disable-next-line import/no-default-export
export default hero;
