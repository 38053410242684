const faqContactUs = {
  parts: ["title", "body"],
  baseStyle: {
    title: {
      color: "gray.500",
      fontWeight: "bold",
      fontSize: "30px",
      letterSpacing: "2px",
      lineHeight: "42px",
      textTransform: "uppercase",
    },
    body: {
      color: "gray.500",
      fontSize: "15px",
      letterSpacing: "0.8px",
      lineHeight: "24px",
    },
  },
};

// eslint-disable-next-line import/no-default-export
export default faqContactUs;
