const shoppingCart = {
  CART_UNAVAILABLE_PLEASE_REVIEW:
    "Please review your cart. Some items are no longer available.",
  CART_UNAVAILABLE_FOR_RENTAL_DATES:
    "Some items in your cart are not available for your selected dates",
  CART_UNAVAILABLE_PLEASE_REVIEW_YETI:
    "Please review your bag. Some items are no longer available.",
  CART_UNAVAILABLE_FOR_RENTAL_DATES_YETI:
    "Some items in your bag are not available for your selected dates",
};

const messages = {
  shoppingCart,
};

// eslint-disable-next-line import/no-default-export
export default messages;
